import React from 'react';

import ProxyConnectedStatus from './proxy-connected-status';
import ProxyUnlinkButton, { ProxyUnlinkButtonProps } from './proxy-unlink-button';
import { ProxyConnectionButtonWrapper } from './styles';

type ProxyConnectionButtonProps = {
  profileId: ProxyUnlinkButtonProps['profileId'];
  unlinkIconType: ProxyUnlinkButtonProps['iconType'];
  placement: ProxyUnlinkButtonProps['placement'];
};

const ProxyConnectionButton: React.FC<ProxyConnectionButtonProps> = (props) => {
  const { profileId, unlinkIconType, placement } = props;

  return (
    <ProxyConnectionButtonWrapper>
      <ProxyUnlinkButton
        profileId={profileId}
        iconType={unlinkIconType}
        placement={placement}
      />
      <ProxyConnectedStatus />
    </ProxyConnectionButtonWrapper>
  );
};

export default ProxyConnectionButton;
