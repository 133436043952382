import styled from '@emotion/styled/macro';

import { textFont } from '../../../../../../ui/style-templates';

export const AdvancedGeoProxySettingsButtonText = styled.span`
  ${textFont()}
  color: var(--B5B5BA);
`;

export const AdvancedGeoProxySettingsButtonIconWrapper = styled.div`
  height: 20px;

  display: flex;
  align-items: center;

  & > div {
    width: 10px;
    height: 10px;
    transform: rotate(90deg);
  }
`;

export const AdvancedGeoProxySettingsButtonWrapper = styled.button`
  padding: 0;
  border: none;
  margin: 0;

  display: flex;
  align-self: center;

  background: none;
  cursor: pointer;

  ${AdvancedGeoProxySettingsButtonText},
  ${AdvancedGeoProxySettingsButtonIconWrapper} {
    margin-right: 8px;
  }

  :hover {
    ${AdvancedGeoProxySettingsButtonText} {
      color: var(--36363D);
    }

    svg path {
      stroke: var(--36363D);
    }
  }
`;
