import { message } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';

import {
  ProxyGroupExpandButton,
  ProxyGroupExpandButtonWrapper,
  ProxyGroupHeaderWrapper,
  ProxyGroupHint,
  ProxyGroupRightControlsWrapper,
} from './styles';
import { GROUPED_PROXY_MANAGER_ANALYTICS_EVENTS } from '../../../../../../common/constants/analytics';
import { determineIsProxyTruthy, GeoProxyType } from '../../../../../../common/constants/types';
import { IProxy } from '../../../../../interfaces';
import PerformanceObserverService from '../../../../../services/performance-observer/performance-observer.service';
import { useDefaultGeoProxy } from '../../../../../state/proxy/default-geoproxy.atom';
import { PROXY_CHECK_TOOLTIP_LOCATIONS } from '../../../../../state/proxy/proxy-check/constants';
import { showProxyCheckTooltip } from '../../../../../state/proxy/proxy-check/proxy-check-tooltip.atom';
import { updateProxyStatuses } from '../../../../../state/proxy/proxy-check/proxy-statuses.atom';
import { findUnusedGeoProxy } from '../../../../../state/proxy/proxy-groups/proxy-groups.atom';
import { toggleProxyGroup, useVisibleProxyGroupIds } from '../../../../../state/proxy/proxy-groups/proxy-visible-groups.atom';
import { IGroupedProxy, IProxyGroupHeader as IProxyGroupHeaderEntity } from '../../../../../state/proxy/proxy-groups/types';
import { closeProxyManager, getProxyManagerState, IProxyManagerState } from '../../../../../state/proxy/proxy-manager-modal-status.atom';
import { createGeoProxy } from '../../../../../state/proxy/proxy-operations/create-geoproxy.operations';
import { handleLinkArtificialProxy, linkProfileProxy } from '../../../../../state/proxy/proxy-operations/link-proxy.operations';
import { useTrafficData } from '../../../../../state/proxy/traffic-data.atom';
import { hideProxyContextMenu } from '../../../../../state/proxy-select-menu.atom';
import { openQuickPricing } from '../../../../../state/quick-pricing.atom';
import { IconArrowDown } from '../../../../../ui/gologin-header/icons/icon-arrow-down';
import TooltipWrapper from '../../../../../ui/tooltip-custom/tooltip-wrapper';
import { sendReactErrorToSentry } from '../../../../../utils/sentry.helper';
import { sendActionAnalytics } from '../../../../common/api';
import { NO_PROFILE_IN_GEOPROXY_COUNTRY_HEADER_ERROR, PROXY_OBSERVED_USER_ACTIONS } from '../../../constants';
import ProxyFlag, { BIG_FLAG_HEIGHT } from '../../../proxy-flag';
import { generateProxyAnalyticsData, getIsProxyArchived, restoreProxy } from '../../../proxy-helpers';
import { determineIsArtificialProxyId, getNoIdProxyId } from '../../../utils/proxy-id';
import PurchaseTrafficButton from '../purchase-traffic-button';
import { ProxyTitleWrapper } from '../ungrouped-proxy-item/styles';
import { updateRecentSelectedCountriesAtom } from '../../../../../state/proxy/recent-selected-proxies.atom';

const GROUP_HEADER_HINTS = {
  viewAvailableServers: 'proxyManager.groupHeaderHint.viewAvailableServers',
  connectToPrivateServer: 'proxyManager.groupHeaderHint.connectToPrivateServer',
} as const;

const GROUP_HEADER_HINTS_LIST = Object.values(GROUP_HEADER_HINTS);
type GroupHeaderHint = (typeof GROUP_HEADER_HINTS_LIST)[number];

type ProxyGroupHeaderProps = {
  proxyGroupHeader: IProxyGroupHeaderEntity;
  currentProfileId: string|null;
  availableTypes: GeoProxyType[];
  style: React.CSSProperties;
}

const ProxyGroupHeader: React.FC<ProxyGroupHeaderProps> = (props) => {
  const { proxyGroupHeader, currentProfileId, availableTypes, style } = props;

  const visibleProxyGroupIds = useVisibleProxyGroupIds();
  const trafficData = useTrafficData();
  const { connectionType: geoproxyTypeAddedLast } = useDefaultGeoProxy();

  const [groupHeaderHintKey, setGroupHeaderHintKey] = useState<GroupHeaderHint|null>(null);

  const finalStyle: React.CSSProperties = {
    ...style,
    paddingLeft: 0,
    height: 38,
    margin: '1px 4px',
  };

  const profileId = currentProfileId || '';
  const isDisabled = !proxyGroupHeader.isAvailable;
  if (isDisabled) {
    // to fix the incorrectly virtualized items
    finalStyle.position = 'static';
  }

  const handleMouseOverRow: React.MouseEventHandler<HTMLDivElement> = () =>
    setGroupHeaderHintKey(GROUP_HEADER_HINTS.connectToPrivateServer);

  const handleMouseOverArrow: React.MouseEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation();
    setGroupHeaderHintKey(GROUP_HEADER_HINTS.viewAvailableServers);
  };

  const handleMouseLeave: React.MouseEventHandler<HTMLDivElement> = () => setGroupHeaderHintKey(null);

  const linkUnusedGeoProxy = async (unusedGeoProxy: IGroupedProxy, handleProxySelect: IProxyManagerState['handleProxySelect']): Promise<void> => {
    const isArtificialProxy = handleLinkArtificialProxy(unusedGeoProxy, 'header');
    if (isArtificialProxy) {
      // not closing proxy manager
      return;
    }

    let proxyToLink = unusedGeoProxy;
    if (getIsProxyArchived(unusedGeoProxy)) {
      proxyToLink = await restoreProxy(unusedGeoProxy);
    }

    closeProxyManager();
    const selectedUnusedProxy: IGroupedProxy = { ...proxyToLink, profilesCount: proxyToLink.profilesCount + 1 };
    if (handleProxySelect) {
      let proxyId = selectedUnusedProxy.id;
      if (!proxyId && determineIsProxyTruthy(proxyToLink)) {
        proxyId = getNoIdProxyId(proxyToLink);
      }

      handleProxySelect(proxyId);

      return;
    }

    const [checkedProxy] = await Promise.all([
      updateProxyStatuses({
        proxies: [selectedUnusedProxy],
        profileId,
        isSharedProxy: false,
      }),
      linkProfileProxy({
        profileId,
        proxy: selectedUnusedProxy,
      }),
    ]);

    if (selectedUnusedProxy.country) {
      updateRecentSelectedCountriesAtom({
        countryCode: selectedUnusedProxy.country,
      });
    }

    const proxyAnalyticsData = generateProxyAnalyticsData(selectedUnusedProxy);
    sendActionAnalytics(GROUPED_PROXY_MANAGER_ANALYTICS_EVENTS.choseLocationViaBuiltInProxyClickedOnCountry, proxyAnalyticsData);
    if (!checkedProxy) {
      return;
    }

    const fullCheckedProxy: IProxy = { ...selectedUnusedProxy, ...checkedProxy, checkDate: moment().toDate() };
    showProxyCheckTooltip({
      profileIds: [profileId],
      proxies: [fullCheckedProxy],
      view: PROXY_CHECK_TOOLTIP_LOCATIONS.selectorProfileTable,
      timeout: 2000,
    });
  };

  const handleGroupRowClick: React.MouseEventHandler<HTMLDivElement> = async (event) => {
    event.stopPropagation();
    if (isDisabled) {
      return openQuickPricing('popup-buy-locked-proxy');
    }

    const { handleProxySelect } = getProxyManagerState();
    if (!(currentProfileId || handleProxySelect)) {
      sendReactErrorToSentry({
        transactionName: NO_PROFILE_IN_GEOPROXY_COUNTRY_HEADER_ERROR,
        message: NO_PROFILE_IN_GEOPROXY_COUNTRY_HEADER_ERROR.replaceAll('-', ' '),
      });

      return closeProxyManager();
    }

    const { groupId } = proxyGroupHeader;
    const unusedGeoProxy = findUnusedGeoProxy(groupId);
    if (unusedGeoProxy) {
      const isArtificialProxy = determineIsArtificialProxyId(unusedGeoProxy.id);
      if (!isArtificialProxy) {
        return linkUnusedGeoProxy(unusedGeoProxy, handleProxySelect);
      }
    }

    closeProxyManager();
    const geoProxyFormSubmitResult = await createGeoProxy({
      groupId,
      profileId,
      country: proxyGroupHeader.country,
      selectedConnectionType: geoproxyTypeAddedLast,
      availableConnectionTypes: availableTypes,
      trafficData,
      checkTooltipView: PROXY_CHECK_TOOLTIP_LOCATIONS.selectorProfileTable,
      handleProxySelect,
    });

    if (!geoProxyFormSubmitResult) {
      return;
    }

    if (typeof geoProxyFormSubmitResult === 'string') {
      return message.error(geoProxyFormSubmitResult);
    }

    const proxyAnalyticsData = generateProxyAnalyticsData(geoProxyFormSubmitResult);
    sendActionAnalytics(GROUPED_PROXY_MANAGER_ANALYTICS_EVENTS.createdLocationViaBuiltInProxyClickedOnCountry, proxyAnalyticsData);
  };

  const handleGroupArrowClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
    if (isDisabled) {
      return;
    }

    event.stopPropagation();
    const performanceObserverService = PerformanceObserverService.getInstance();
    performanceObserverService.handleUserAction({ userAction: PROXY_OBSERVED_USER_ACTIONS.openProxyGroup });
    toggleProxyGroup(proxyGroupHeader.groupId);
  };

  const handleContextMenu: React.MouseEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    hideProxyContextMenu();
  };

  const renderRightControls = (): JSX.Element | null => {
    if (isDisabled) {
      return <PurchaseTrafficButton />;
    }

    if (!groupHeaderHintKey) {
      return null;
    }

    return (
      <ProxyGroupHint>
        <Trans i18nKey={groupHeaderHintKey} />
      </ProxyGroupHint>
    );
  };

  const renderContent = (): JSX.Element => (
    <ProxyGroupHeaderWrapper
      style={finalStyle}
      isAlwaysOpaque={true}
      onClick={handleGroupRowClick}
      onMouseOver={handleMouseOverRow}
      onMouseLeave={handleMouseLeave}
      onContextMenu={handleContextMenu}
    >
      <ProxyGroupExpandButtonWrapper>
        <ProxyGroupExpandButton
          onClick={handleGroupArrowClick}
          onMouseOver={handleMouseOverArrow}
          isRotated={visibleProxyGroupIds.includes(proxyGroupHeader.groupId)}
          isDisabled={isDisabled}
        >
          <IconArrowDown
            padding={0}
            styleType='darkGray'
            iconColor='var(--proxy-group-header-arrow-color)'
          />
        </ProxyGroupExpandButton>
      </ProxyGroupExpandButtonWrapper>
      <ProxyFlag
        countryCode={proxyGroupHeader.country}
        height={BIG_FLAG_HEIGHT}
      />
      <ProxyTitleWrapper
        isProxyManagerWithGroups={true}
        isSemiTransparent={false}
      >
        <Trans i18nKey={`proxy.countries.${proxyGroupHeader.country.toLowerCase()}`} />
      </ProxyTitleWrapper>
      <ProxyGroupRightControlsWrapper>
        {renderRightControls()}
      </ProxyGroupRightControlsWrapper>
    </ProxyGroupHeaderWrapper>
  );

  if (isDisabled) {
    return (
      <TooltipWrapper
        value={<Trans i18nKey='proxyManager.groupHeaderHint.getAccessToAllCountries' />}
        style={{ width: 182, textAlign: 'center' }}
        // to fix the incorrectly virtualized items
        containerStyle={style}
        isTextEllipsis={false}
      >
        {renderContent()}
      </TooltipWrapper>
    );
  }

  return renderContent();
};

export default ProxyGroupHeader;
