import { purgeAppCache } from './meta-db';

export const defineIDBCacheDebugUtils = (): void => {
  (window as any).dbg = {
    idbCache: { purgeAll: async (): Promise<void> => {
      await purgeAppCache();

      log('purged all idb cache');
    } },
  };

  // TODO: add help
  log('defined window.dbg');
};

const log = (msg: string): void => {
  console.log(`[gologin dbg] ${msg}`);
};
