import { PrimitiveAtom, useAtomValue } from 'jotai';
import React from 'react';
import { useTranslation } from 'react-i18next';

import NameCellCore from './name-cell-core';
import ProfileEdit from './profile-edit';
import { ContainerButtons, ContainerTable, Row } from './styles';
import { IProfile } from '../../../interfaces';
import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import { IProfileRunStatus } from '../../../state/profiles-list.atom';
import { openProfilesSettings } from '../../../state/profiles-settings-atom';
import { IBasicTableEntity } from '../../../state/profiles-table/basic-table-entities-subtypes';
import {
  setProfilesTableEditingProfileName,
  useIsEditingProfileName,
} from '../../../state/profiles-table-editing-profile-name.atom';
import { parseTranslatedKey } from '../../../utils/translation.utils';
import { ILaunchProfileOrbita } from '../components/interfaces/launch-profile-orbita.interface';
import RunButtonCell from '../run-button-cell';

export interface INameCell {
  profileAtom: PrimitiveAtom<IProfile>;
  rowIdx: IBasicTableEntity['idx'];
  setProfiles: (set: (prevProfiles: IProfile[]) => IProfile[]) => void;
  renderDropDownMenu: (profile: IProfile & IProfileRunStatus) => JSX.Element | null;
  launchProfileOrbita: (opts: ILaunchProfileOrbita) => void;
}

const NameCell: React.FC<INameCell> = (props) => {
  const {
    profileAtom,
    rowIdx,
    setProfiles,
    renderDropDownMenu,
    launchProfileOrbita,
  } = props;

  const profile = useAtomValue(profileAtom);
  const { id: profileId } = profile;
  const isEditingName = useIsEditingProfileName(rowIdx);

  const { t: translation } = useTranslation();

  let { name: profileName } = profile;
  if (profile.defaultProps) {
    const { profileNameIsDefault = false } = profile.defaultProps;
    if (profile.isAutoGenerated && profileNameIsDefault) {
      profileName = parseTranslatedKey(translation('profiles.firstProfileName'));
    }
  }

  const onStartEditingName = (): void => {
    setProfilesTableEditingProfileName(rowIdx);
  };

  if (isEditingName) {
    return (
      <ContainerTable isEditMode={true} newStyle={!!NEW_FEATURES.header} onClick={(): void => openProfilesSettings([profileId])}>
        <Row>
          <ProfileEdit
            profileName={profileName}
            profile={profile}
            setProfiles={setProfiles}
            onDone={(): void => setProfilesTableEditingProfileName(null)}
          />
          <ContainerButtons>
            <RunButtonCell
              profile={profile}
              renderDropDownMenu={renderDropDownMenu}
              launchProfileOrbita={launchProfileOrbita}
            />
          </ContainerButtons>
        </Row>
      </ContainerTable>
    );
  }

  return (
    <ContainerTable newStyle={!!NEW_FEATURES.header} >
      <Row>
        <NameCellCore
          {...props}
          onStartEdit={onStartEditingName}
          profile={profile}
          profileName={profileName}
        />
        <ContainerButtons>
          <RunButtonCell
            profile={profile}
            renderDropDownMenu={renderDropDownMenu}
            launchProfileOrbita={launchProfileOrbita}
          />
        </ContainerButtons>
      </Row>
    </ContainerTable>
  );
};

export default React.memo(NameCell);
