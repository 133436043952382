import React from 'react';
import { Trans } from 'react-i18next';

import { ProxyPlaceholderContent, ProxyPlaceholderText } from './styles';
import { NEW_FEATURES } from '../../../../state/feature-toggle/new-features';
import { IconPlusFill } from '../../../../ui/gologin-header/icons';
import { IconWrapperWithDescription } from '../../../../ui/gologin-header/icons/wrapper';

type ProxyPlaceholderProps = {
  isInDrawer: boolean;
}

const ProxyPlaceholder: React.FC<ProxyPlaceholderProps> = (props) => {
  const { isInDrawer } = props;

  const placeholderTranslationKey = NEW_FEATURES.renameProxyToLocation ? 'proxies.chooseLocationOrProxy' : 'proxies.addOrPasteProxy';

  const renderPlaceholder = (): JSX.Element => {
    if (!isInDrawer) {
      return (
        <ProxyPlaceholderText>
          <Trans i18nKey={placeholderTranslationKey} />
        </ProxyPlaceholderText>
      );
    }

    return (
      <IconWrapperWithDescription
        iconColor='var(--B5B5BA-proxy-manager-placeholder)'
        iconHoveredColor='var(--2B2B31-proxy-manager-placeholder)'
        textColor='var(--81818A-proxy-manager-placeholder)'
        textHoveredColor='var(--2B2B31-proxy-manager-placeholder)'
        iconType='stroke'
        style={{ lineHeight: 'inherit' }}
      >
        <IconPlusFill padding={0} margin='0 8px 0 0' />
        <Trans i18nKey={placeholderTranslationKey} />
      </IconWrapperWithDescription>
    );
  };

  return (
    <div className='nowrap'>
      <ProxyPlaceholderContent isInDrawer={isInDrawer} newStyle={!!NEW_FEATURES.header}>
        {renderPlaceholder()}
      </ProxyPlaceholderContent>
    </div>
  );
};

export default ProxyPlaceholder;
