import React from 'react';

import { useProxyForm } from '../../../../../../state/proxy/custom-proxy-form-data.atom';
import { pasteProxyToForm } from '../../../../../../state/proxy/proxy-operations/paste-proxies-operations';
import CustomProxyStringInput from '../custom-proxy-string-input';

const CustomProxyChangeIpUrlInput: React.FC = () => {
  const customProxyForm = useProxyForm();

  const handlePasteChangeIpUrl: React.ClipboardEventHandler<HTMLInputElement> = async (event) => {
    const clipboardText = event.clipboardData.getData('Text');
    const isPossibleUrl = clipboardText.startsWith('http://') || clipboardText.startsWith('https://');
    if (isPossibleUrl) {
      // without event.preventDefault - pastes into field, without parsing
      return;
    }

    const pasteProxyRes = await pasteProxyToForm(customProxyForm, clipboardText);
    if (pasteProxyRes) {
      event.preventDefault();
    }
  };

  return (
    <CustomProxyStringInput
      fieldName='changeIpUrl'
      handlePaste={handlePasteChangeIpUrl}
    />
  );
};

export default CustomProxyChangeIpUrlInput;
