import styled from '@emotion/styled';
import { Popover } from '@material-ui/core';
import { Button, Icon, message, Table } from 'antd';
import React, { FC, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import { checkDoesLangExist, locales } from '../../../../common/locales';
import { IconSpinner } from '../../../ui/gologin-header/icons';
import IconDelete from '../../../ui/icons/IconDelete';
import IconReplace from '../../../ui/icons/IconReplace';
import GologinModal from '../../../ui/gologin-modal';
import { GologinInput } from '../../../ui/styles/input';
import sortArrayAfterDrag from '../../../utils/drag-sort';
import SwitchAutoLang from '../../common/components/profile-langs-input-block/switch-auto-lang';
import {
  closeProfileTableModal,
  LOCALES_MODAL_NAME,
  useProfileTableModalIsVisible,
} from '../../../state/profiles-table-modal.atom';

const ContainerTableModal = styled('div')`
  height: 540px;
`;

const TableLocales = styled(Table)`
  thead > tr > th {
    background-color: var(--FFFFFF);
  }

  .ant-checkbox {
    padding: 0;
   }
  .ant-checkbox {
  .ant-table-thead > tr > th {
    border-bottom: 0px solid var(--E8E8E7);
  }
`;

const HiddenDiv = styled('div')<{ visible: boolean }>`
  position: absolute;
  display: ${(props): string => props.visible ? 'flex' : 'none'};
  z-index: 1000;
  background: var(--FFFFFF);
  width: 770px;
  height: 506px;
  opacity: 0.7;
`;

const TableDiv = styled('div')`
  display: inline-block;
  width: 48%;
  margin-top: 5px;
  &:after {
    content: "";
    background-color: var(--EBEBEB);
    position: absolute;
    width: 2px;
    height: 500px;
    top: 250px;
    left: 50%;
    display: block;
  }
`;

const Row = styled('div')`
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  z-index: 1000;
`;

const LanguagesDiv = styled('div')`
  float: right;
  width: 48%;
  position: relative;
  z-index: 10000;
`;

const ContainerNameCol = styled('div')`
  flex-direction: row;
  display: flex;
  align-items: baseline;
`;

const ContainerNameLanguage = styled('div')`
  margin-top: 22px;
  padding-bottom: 20px;
  width: 345px;
  max-height: 480px;
  overflow: auto;
`;

const MarginIcon = styled('div')`
  margin-left: 10px;
`;

const LanguageNameSpan = styled('span')`
  cursor: pointer;
  margin-left: 8px;
  font-size: 14px;
  font-weight: 400;
`;

const MarginIconDelete = styled('div')`
  margin-left: 17px;
`;

const TitleSelectedLanguages = styled('p')`
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bolder;
  height: 42px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
`;

const MenuPopup = styled(Popover)`
  .MuiPopover-paper {
    background-color: var(--FFFFFF-antd);
    margin: 0;
    padding: 4px 0;
    text-align: left;
    list-style-type: none;
    background-clip: padding-box;
    border-radius: 4px;
    outline: none;
    box-shadow: var(--box-shadow);
  }
`;

declare interface ILocalesList {
  languages: string;
  languagesBasedOnIp?: string;
  toggleLocalesListModal: (save: boolean, autoLang?: boolean, language?: string) => void;
  autoLang?: boolean;
}

const LocalesManager: FC<ILocalesList> = (props) => {
  const { languages, languagesBasedOnIp = '', autoLang = false, toggleLocalesListModal } = props;

  const [isLoading, changeLoading] = useState<boolean>(true);
  const [localesList, updateLocalesList] = useState<any[]>([]);
  const [newSelectedLanguage, setNewSelectedLanguage] = useState<string>(languages);
  const [selectedRows, updateSelectedRows] = useState<string[]>([]);
  const [orderedLanguages, setOrderedLanguages] = useState<string[]>([]);
  const [isAutoLangSwitch, setIsAutoLangSwitch] = useState<boolean>(autoLang);
  const [anchorElSearchMenu, setAnchorElSearchMenu] = useState<HTMLElement|null>(null);

  let searchLanguageButtonRef = useRef<HTMLDivElement|null>(null);

  const isModalVisible = useProfileTableModalIsVisible(LOCALES_MODAL_NAME);

  const { t: translation } = useTranslation();
  const hasSwitchAutoLang = Object.prototype.hasOwnProperty.call(props, 'autoLang');
  const isAutomaticLanguages = isAutoLangSwitch && languagesBasedOnIp === translation('quickSettings.parameters.automatic');

  const getLocalesList = (): void => {
    if (!isModalVisible) {
      return;
    }

    const formattedLocalesList: { key: string; value: string }[] = [];
    for (const [key, value] of Object.entries(locales)) {
      formattedLocalesList.push({
        key,
        value: `${value} (${key})`,
      });
    }

    const newLanguages = isAutoLangSwitch ? languagesBasedOnIp : languages;

    const splittedLanguages = newLanguages.split(',');
    const formattedLanguages = splittedLanguages
      .map((item: string) => item.split(';')[0])
      .filter(langKey => formattedLocalesList.find(({ key }) => key === langKey));

    const formattedOrderedLanguages = formatOrderedLanguages(formattedLanguages);

    updateSelectedRows(formattedLanguages);
    setNewSelectedLanguage(languages);
    setOrderedLanguages(formattedOrderedLanguages);

    updateLocalesList(formattedLocalesList);
    changeLoading(false);
  };

  useEffect(() => {
    getLocalesList();
    setIsAutoLangSwitch(autoLang);
  }, [isModalVisible]);

  useLayoutEffect(() => {
    getLocalesList();
  }, [languagesBasedOnIp, isAutoLangSwitch]);

  const sortAlphabet = (a:any, b:any) => a > b ? 1 : a < b ? -1 : 0;

  const formatOrderedLanguages = (orderedArr: string[]) => {
    const resultArr = [];
    orderedArr.forEach((elem: string) => {
      resultArr.push({
        key: elem,
        name: `${locales[elem]} (${elem})`,
      });
    });

    return resultArr;
  };

  const composeLanguagesString = (languagesArr: string[]) => {
    let resultLanguagesString = '';
    languagesArr.forEach((elem, index) => {
      if (!index) {
        resultLanguagesString += elem;

        return;
      }
      resultLanguagesString += `,${elem};q=0.${10 - index}`;
    });
    setNewSelectedLanguage(resultLanguagesString);
  };

  const changeSelectedRows = (selectedRows: any[]) => {
    if (selectedRows.length > 10) {
      message.error(translation('languages.limitExceeded'));

      return;
    }

    const newOrderedLanguages = orderedLanguages.map((item: string) => {
      const isSelected = selectedRows.find(elem => elem === item);
      return isSelected ? item : '';
    }).filter(Boolean);
    selectedRows.forEach((item: string) => {
      const isExists = newOrderedLanguages.find(elem => elem === item);
      if (!isExists) {
        newOrderedLanguages.push(item);
      }
    });
    const formattedOrderedLanguages = formatOrderedLanguages(newOrderedLanguages);

    composeLanguagesString(newOrderedLanguages);
    setOrderedLanguages(formattedOrderedLanguages);
    updateSelectedRows(selectedRows);
  };

  const SortableItem = SortableElement(({ value }) => {
    if (isAutomaticLanguages) {
      return (
        <Row style={{ pointerEvents: 'none' }}>
          <MarginIcon
            style={{ cursor: 'pointer' }}
          >
            <IconReplace />
          </MarginIcon>
          <LanguageNameSpan>
            {languagesBasedOnIp}
          </LanguageNameSpan>
        </Row>
      );
    }

    return (
      <Row
        key={value.key}
        style={{ pointerEvents: isAutoLangSwitch ? 'none' : 'auto' }}
      >
        <MarginIcon
          style={{ cursor: 'pointer' }}
        >
          <IconReplace />
        </MarginIcon>
        <LanguageNameSpan>
          {value.name}
        </LanguageNameSpan>
        {!isAutoLangSwitch ? (
          <MarginIconDelete
            className='delete-language-icon'
            style={{ cursor: 'pointer' }}
            onClick={() => deleteLanguageFromList(value.key)}
          >
            <IconDelete className='delete-language-icon' />
          </MarginIconDelete>
        ) : null}
      </Row>
    );
  });

  const deleteLanguageFromList = (language: string) => {
    const newOrderedLanguagesArray: any[] =
      orderedLanguages.map((languageObj: any) => languageObj.key !== language ? languageObj : null).filter(Boolean);
    const newSelectedRows = selectedRows.map((elem: string) => elem !== language ? elem : '').filter(Boolean);

    composeLanguagesString(newOrderedLanguagesArray.map(elem => elem.key));
    updateSelectedRows(newSelectedRows);
    setOrderedLanguages(newOrderedLanguagesArray);
  };

  const SortableList = SortableContainer(({ items }) => {
    if (!isAutomaticLanguages) {
      return (
        <ContainerNameLanguage>
          {items.map((value, index) => (
            <SortableItem key={`item-${value.key}`} index={index} value={value} />
          ))}
        </ContainerNameLanguage>
      );
    }

    return (
      <ContainerNameLanguage>
        <Row style={{ pointerEvents: 'none' }}>
          <MarginIcon
            style={{ cursor: 'pointer' }}
          >
            <IconReplace />
          </MarginIcon>
          <LanguageNameSpan>
            {languagesBasedOnIp}
          </LanguageNameSpan>
        </Row>
      </ContainerNameLanguage>
    );
  });

  const shouldCancelStart = (e: any) => {
    let clickedOnFunctionalIcon = false;
    let elem = e.target;
    let attempts = 4;
    while (elem && attempts--) {
      const currentElemClass = elem.getAttribute('class');
      if (currentElemClass && currentElemClass.includes('delete-language-icon')) {
        clickedOnFunctionalIcon = true;
        break;
      }
      elem = elem.parentNode ? elem.parentNode : null;
    }

    return clickedOnFunctionalIcon;
  };

  const onSortEnd = async ({ oldIndex, newIndex }: any) => {
    if (oldIndex === newIndex) {
      return;
    }

    const newOrderedLanguagesArray = sortArrayAfterDrag(orderedLanguages, oldIndex, newIndex);

    composeLanguagesString(newOrderedLanguagesArray.map(elem => elem.key));
    setOrderedLanguages(newOrderedLanguagesArray);
  };

  const openPopup = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorElSearchMenu(event.currentTarget);
  };

  const closePopup = () => setAnchorElSearchMenu(null);

  const getContent = () => {
    if (!localesList.length) {
      return translation('localesManagerModal.emptyData');
    }

    const currentLanguages = isAutoLangSwitch ? languagesBasedOnIp : newSelectedLanguage;
    const separatedLanguages = (currentLanguages)
      .split(',')
      .map(item => {
        const [langKey] = item.split(';');
        if (checkDoesLangExist(langKey)) {
          return item;
        }

        return null;
      })
      .filter(Boolean);

    return (
      <ContainerTableModal>
        <TitleSelectedLanguages>
          {`Accept-Language header: ${isAutomaticLanguages ? languagesBasedOnIp : separatedLanguages}`}
          {hasSwitchAutoLang ? (
            <SwitchAutoLang
              autoLang={isAutoLangSwitch}
              onChange={(state): void => setIsAutoLangSwitch(state)}
            />
          ) : null}
        </TitleSelectedLanguages>
        <HiddenDiv visible={isAutoLangSwitch} />
        <TableDiv>
          <TableLocales
            rowSelection={{
              type: 'checkbox',
              onChange: changeSelectedRows,
              selectedRowKeys: selectedRows,
            }}
            size={'middle'}
            pagination={false}
            rowKey='key'
            dataSource={localesList}
            scroll={{ y: 450 }}
            sticky={true}
            locale={{ emptyText: translation('localesManagerModal.emptyData') }}
          >
            <Table.Column
              ellipsis={false}
              title={(
                <div>
                  <ContainerNameCol style={{ opacity: 0.5 }}>
                    <span>{translation('language.title')}</span>
                  </ContainerNameCol>
                  <div
                    style={{ position: 'absolute', width: 268, height: 30, top: 5 }}
                    ref={searchLanguageButtonRef}
                    onClick={openPopup}
                  />
                </div>
              )}
              dataIndex='value'
              key='value'
              sorter={(a: any, b: any) => sortAlphabet(a.value, b.value)}
              filterDropdown={({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <MenuPopup
                  open={!!anchorElSearchMenu}
                  anchorEl={anchorElSearchMenu}
                  onClose={closePopup}
                  transitionDuration={0}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  style={{ zIndex: 1300 }}
                >
                  <div style={{ padding: 8 }}>
                    <GologinInput
                      autoFocus={true}
                      placeholder={translation('localesManagerModal.searchLanguage')}
                      value={selectedKeys[0]}
                      onChange={(event): void => setSelectedKeys(event.target.value ? [event.target.value] : [])}
                      onPressEnter={(): void => {
                        confirm();
                        closePopup();
                      }}
                      style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Button
                      type='primary'
                      onClick={(): void => {
                        confirm();
                        closePopup();
                      }}
                      icon='search'
                      size='small'
                      style={{ width: 90, marginRight: 8 }}
                    >
                      {translation('localesManagerModal.search')}
                    </Button>
                    <Button
                      onClick={(): void => {
                        clearFilters();
                        closePopup();
                      }}
                      size='small'
                      style={{ width: 90 }}
                    >
                      {translation('localesManagerModal.reset')}
                    </Button>
                  </div>
                </MenuPopup>
              )}
              onFilterDropdownVisibleChange={(visible) => {
                if (visible) {
                  setTimeout(() => searchLanguageButtonRef.current?.click());
                }}}
              filterIcon={(filtered: any) => <Icon type='search' style={{ color: filtered ? 'var(--1890FF)' : undefined }} />}
              onFilter={(value, record) => record['value'].toString().toLowerCase().includes(value.toLowerCase())}
            />
          </TableLocales>
        </TableDiv>
        <LanguagesDiv>
          <SortableList
            items={orderedLanguages}
            onSortEnd={onSortEnd}
            lockAxis='y'
            lockToContainerEdges
            shouldCancelStart={shouldCancelStart}
          />
        </LanguagesDiv>
      </ContainerTableModal>
    );
  };

  return (
    <GologinModal
      isVisible={isModalVisible}
      onClose={closeProfileTableModal}
      title={translation('localesManagerModal.title')}
      width='800px'
      footer={(
        <>
          <Button
            onClick={closeProfileTableModal}
          >
            {translation('base.cancel')}
          </Button>
          <Button
            type='primary'
            onClick={() => toggleLocalesListModal(true, isAutoLangSwitch, newSelectedLanguage)}
            style={{ marginLeft: 8 }}
          >
            {translation('base.save')}
          </Button>
        </>
      )}
    >
      {isLoading ? <div style={{ fontSize: 32 }}><IconSpinner size={32} /></div> : getContent()}
    </GologinModal>
  );
};

export default LocalesManager;
