import { useState } from 'react';

import { evaluateGeoProxyModernSelectArrowColor } from './geoproxy-edit-form-fields/styles';
import { ISelectCustom } from '../../../../ui/modern-select/interfaces';

type GeoProxyFormSelectArrowResult = Required<Pick<ISelectCustom,
  'isArrowVisible' |
  'arrowStyle' |
  'onSelectOpen' |
  'onSelectClose' |
  'onSelectMouseOver' |
  'onSelectMouseLeave'
>>;

export const useProxyFormSelectArrow = (): GeoProxyFormSelectArrowResult => {
  const [isPopoverVisible, setIsPopoverVisible] = useState<boolean>(false);
  const [isSelectHovered, setIsSelectHovered] = useState<boolean>(false);

  const onSelectOpen = (): void => {
    setIsPopoverVisible(true);
  };

  const onSelectClose = (): void => {
    setIsPopoverVisible(false);
  };

  const onSelectMouseOver: React.MouseEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    setIsSelectHovered(true);
  };

  const onSelectMouseLeave: React.MouseEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    setIsSelectHovered(false);
  };

  const isArrowHighlighted = isPopoverVisible || isSelectHovered;
  const iconColor = evaluateGeoProxyModernSelectArrowColor(isArrowHighlighted);

  return {
    isArrowVisible: true,
    arrowStyle: { iconColor },
    onSelectOpen,
    onSelectClose,
    onSelectMouseOver,
    onSelectMouseLeave,
  };
};
