import { TFunction } from 'i18next';
import React from 'react';
import { Trans } from 'react-i18next';

import { IProfilesTableColumn } from './components/interfaces/app-settings.interface';
import { ILaunchProfileOrbita } from './components/interfaces/launch-profile-orbita.interface';
import { ProxyTypeColumn } from './components/TableColumns';
import ProxyParamCell from './components/TableColumns/ProxyParamColumn';
import CustomStatusCell from './custom-status-cell';
import DateCell, { DateCellField } from './date-cell';
import { RenderTableCellFn } from './interfaces';
import NameCell from './name-cell';
import NotesCell from './notes-cell';
import OrbitaVersionCell from './orbita-version-cell';
import OsCell from './os-cell';
import ProxyCell from './proxy-cell';
import ButtonQuickCreateProfile from './quick-create-profile';
import SharingCell from './sharing-cell';
import StatusCell from './status-cell';
import { IProfile, IProxy } from '../../interfaces';
import PerformanceObserverService from '../../services/performance-observer/performance-observer.service';
import { SingleProfileColumnSetting, getColumnSettings } from '../../state/columns-settings.atom';
import { NEW_FEATURES } from '../../state/feature-toggle/new-features';
import { IProfileRunStatus } from '../../state/profiles-list.atom';
import { IBasicTableProfile } from '../../state/profiles-table/basic-table-entities-subtypes';
import ColumnHeader from '../common/components/column-header';
import ColumnFilterResetButton from '../common/components/column-header/column-filter-reset-button';
import TagsCell from '../tags/components/tags';

const isElectron = !!window.require;
let ipcRenderer: Electron.IpcRenderer;
if (isElectron) {
  ({ ipcRenderer } = window.require('electron'));
}

type ColumnsListOpt = {
  translation: TFunction;
  launchProfileOrbita: (opts: ILaunchProfileOrbita) => Promise<void|null>;
  setProfiles: (set: (prevProfiles: IProfile[]) => IProfile[]) => void;
  renderDropDownMenu: (profile: IProfile & IProfileRunStatus, isInDrawer?: boolean) => JSX.Element;
}

export const getColumnsList = (props: ColumnsListOpt): SingleProfileColumnSetting[] => {
  const { translation, setProfiles, launchProfileOrbita, renderDropDownMenu } = props;
  const columnsSettings = getColumnSettings();

  const proxyColumnTitle = NEW_FEATURES.renameProxyToLocation ? 'profiles.tableColumns.location' : 'profiles.tableColumns.proxy';

  const renderProxyType: RenderTableCellFn = (_, profile) => <ProxyTypeColumn profileAtom={profile.atom} />;
  const renderOs: RenderTableCellFn = (_, profile) => <OsCell profileAtom={profile.atom} />;
  const renderOrbitaVersion: RenderTableCellFn = (_, profile) => <OrbitaVersionCell profileAtom={profile.atom} />;

  const syncProfile = (profileId: string, onlyUpload?: boolean): void => {
    ipcRenderer && ipcRenderer.invoke('retry-sync-profile', { profileId, onlyUpload });
  };

  const renderState: RenderTableCellFn = (_, profile) => (
    <StatusCell
      profileAtom={profile.atom}
      syncProfile={syncProfile}
      statusTextWidth={columnsSettings.status.width}
    />
  );

  const renderDate = (profile: IBasicTableProfile, field: DateCellField): JSX.Element => (
    <DateCell
      profileAtom={profile.atom}
      field={field}
      hasTooltip={field === 'lastActivity'}
    />
  );

  const renderProxy: RenderTableCellFn = (_, profile) => <ProxyCell profileAtom={profile.atom} rowIdx={profile.idx} />;

  const renderProxyParam = (profile: IBasicTableProfile, paramName: keyof IProxy): JSX.Element => (
    <ProxyParamCell
      profileAtom={profile.atom}
      paramName={paramName}
    />
  );

  const renderNotes: RenderTableCellFn = (_, profile) => (
    <NotesCell
      profileAtom={profile.atom}
    />
  );

  const onTagsPopupOpen = (profileTagsCount: number, totalTagsCount: number): void => {
    const performanceObserverService = PerformanceObserverService.getInstance();
    performanceObserverService.handleUserAction({
      userAction: 'open-tags-popup',
      profileTagsCount,
      totalTagsCount,
    });
  };

  const renderCustomStatus: RenderTableCellFn = (_, profile) => (
    <CustomStatusCell
      profileAtom={profile.atom}
      rowIdx={profile.idx}
      onTagsPopupOpen={onTagsPopupOpen}
    />
  );

  const renderTags: RenderTableCellFn = (_, profile) => (
    <TagsCell
      profileAtom={profile.atom}
      rowIdx={profile.idx}
      onTagsPopupOpen={onTagsPopupOpen}
      field='tags'
    />
  );

  const renderSharing: RenderTableCellFn = (_, profile) => (
    <SharingCell profileAtom={profile.atom} />
  );

  return [{
    colName: 'name',
    title: <ColumnHeader
      column='name'
      title={translation('base.name')}
      enableDrag={false}
      hasSort={true}
      sorterField='name'
    />,
    label: translation('base.name'),
    dataIndex: 'name',
    render: (_, record) => (
      <NameCell
        profileAtom={record.atom}
        rowIdx={record.idx}
        setProfiles={setProfiles}
        renderDropDownMenu={renderDropDownMenu}
        launchProfileOrbita={launchProfileOrbita}
      />
    ),
    minWidth: 240,
    width: columnsSettings.name.width < 240 ? 240 : columnsSettings.name.width,
    visible: columnsSettings.name.visible,
    sortField: 'name',
    className: NEW_FEATURES.header ? 'new-name-cell no-drag' : 'no-drag name-cell',
    actionsSlot: <ButtonQuickCreateProfile
      tooltipContent={<Trans i18nKey='tooltip.profilesTable.addProfile' />}
    />,
  }, {
    // TODO: rename to 'state' everywhere (after final decision is made)
    colName: 'status',
    title: <ColumnHeader
      column='status'
      title={translation('profiles.tableColumns.state')}
    />,
    label: translation('profiles.tableColumns.state'),
    dataIndex: 'status',
    render: renderState,
    minWidth: 110,
    width: columnsSettings.status.width,
    visible: columnsSettings.status.visible,
    className: NEW_FEATURES.header ? 'new-status-cell draggable' : 'draggable',
  }, {
    colName: 'notes',
    title: <ColumnHeader
      column='notes'
      title={translation('quickSettings.notes.title')}
    />,
    label: translation('quickSettings.notes.title'),
    dataIndex: 'notes',
    render: renderNotes,
    minWidth: 120,
    width: columnsSettings.notes.width,
    visible: columnsSettings.notes.visible,
    className: NEW_FEATURES.header ? 'new-notes-cell draggable' : 'notes-cell draggable',
    onCellClick: (_, event) => event.stopPropagation(),
  }, {
    colName: 'tags',
    title: <ColumnHeader
      column='tags'
      title={translation('quickSettings.tags.title')}
    />,
    label: translation('quickSettings.tags.title'),
    dataIndex: 'tags',
    render: renderTags,
    minWidth: 120,
    width: columnsSettings.tags.width,
    visible: columnsSettings.tags.visible,
    className: NEW_FEATURES.header ? 'new-tags-cell draggable' : 'tags-cell draggable',
    onCellClick: (_, event) => event.stopPropagation(),
    actionsSlot: <ColumnFilterResetButton field='tags' />,
  }, {
    colName: 'customStatus',
    title: <ColumnHeader
      column='customStatus'
      title={translation('profiles.tableColumns.customStatus')}
    />,
    label: translation('profiles.tableColumns.customStatus'),
    dataIndex: 'customStatus',
    render: renderCustomStatus,
    minWidth: 120,
    width: columnsSettings.customStatus.width,
    visible: columnsSettings.customStatus.visible,
    className: NEW_FEATURES.header ? 'new-tags-cell draggable' : 'tags-cell draggable',
    onCellClick: (_, event) => event.stopPropagation(),
    actionsSlot: <ColumnFilterResetButton field='custom-status' />,
  }, {
    colName: 'lastUpdate',
    title: <ColumnHeader
      column='lastUpdate'
      title={translation('profiles.tableColumns.lastUpdate')}
      hasSort={true}
      sorterField='updatedAt'
    />,
    label: translation('profiles.tableColumns.lastUpdate'),
    dataIndex: 'updatedAt',
    render: (_, profile) => renderDate(profile, 'updatedAt'),
    minWidth: 120,
    width: columnsSettings.lastUpdate.width,
    visible: columnsSettings.lastUpdate.visible,
    sortField: 'updatedAt',
    className: 'draggable',
  }, {
    colName: 'lastLaunch',
    title: <ColumnHeader
      column='lastLaunch'
      title={translation('profiles.tableColumns.lastLaunch')}
      hasSort={true}
      sorterField='lastActivity'
    />,
    label: translation('profiles.tableColumns.lastLaunch'),
    dataIndex: 'lastActivity',
    render: (_, profile) => renderDate(profile, 'lastActivity'),
    minWidth: 120,
    width: columnsSettings.lastLaunch.width,
    visible: columnsSettings.lastLaunch.visible,
    sortField: 'lastActivity',
    className: 'draggable',
  }, {
    colName: 'createdAt',
    title: <ColumnHeader
      column='createdAt'
      title={translation('profiles.tableColumns.createdAt')}
      hasSort={true}
      sorterField='createdAt'
    />,
    label: translation('profiles.tableColumns.createdAt'),
    dataIndex: 'createdAt',
    render: (_, profile) => renderDate(profile, 'createdAt'),
    minWidth: 120,
    width: columnsSettings.createdAt.width,
    visible: columnsSettings.createdAt.visible,
    sortField: 'createdAt',
    className: 'draggable',
  }, {
    colName: 'sharing',
    title: <ColumnHeader
      column='sharing'
      title={translation('profiles.tableColumns.sharing')}
      hasSort={true}
      sorterField='sharedEmails'
    />,
    label: translation('profiles.tableColumns.sharing'),
    dataIndex: 'sharedEmails',
    render: renderSharing,
    minWidth: 120,
    width: columnsSettings.sharing.width,
    visible: columnsSettings.sharing.visible,
    sortField: 'sharedEmails',
    className: 'draggable',
  }, {
    colName: 'proxyType',
    title: <ColumnHeader
      column='proxyType'
      title={translation('profiles.tableColumns.proxyType')}
      hasSort={true}
      sorterField='proxyType'
    />,
    label: translation('profiles.tableColumns.proxyType'),
    dataIndex: 'proxyType',
    render: renderProxyType,
    minWidth: 120,
    width: columnsSettings.proxyType.width,
    visible: columnsSettings.proxyType.visible,
    sortField: 'proxyType',
    className: 'draggable',
  }, {
    colName: 'proxy',
    title: <ColumnHeader column='proxy' title={translation(proxyColumnTitle)} />,
    label: translation(proxyColumnTitle),
    labelTooltip: 'profiles.tableColumnsTooltips.location',
    dataIndex: 'proxy',
    render: renderProxy,
    minWidth: 120,
    width: columnsSettings.proxy.width,
    visible: columnsSettings.proxy.visible,
    className: NEW_FEATURES.header ? 'new-proxy-cell draggable' : 'proxy-cell draggable',
    onCellClick: (_, event) => event.stopPropagation(),
    leftPadding: 24,
    actionsSlot: <ColumnFilterResetButton field='proxy-id' />,
  }, {
    colName: 'proxyPort',
    title: <ColumnHeader
      column='proxyPort'
      title={translation('profiles.tableColumns.proxyPort')}
    />,
    label: translation('profiles.tableColumns.proxyPort'),
    dataIndex: 'proxyPort',
    render: (_, profile) => renderProxyParam(profile, 'port'),
    minWidth: 120,
    width: columnsSettings.proxyPort.width,
    visible: columnsSettings.proxyPort.visible,
    className: 'draggable',
    onCellClick: (_, event) => event.stopPropagation(),
  }, {
    colName: 'proxyUsername',
    title: <ColumnHeader
      column='proxyUsername'
      title={translation('profiles.tableColumns.proxyUsername')}
    />,
    label: translation('profiles.tableColumns.proxyUsername'),
    dataIndex: 'proxyUsername',
    render: (_, profile) => renderProxyParam(profile, 'username'),
    minWidth: 120,
    width: columnsSettings.proxyUsername.width,
    visible: columnsSettings.proxyUsername.visible,
    className: 'draggable',
    onCellClick: (_, event) => event.stopPropagation(),
  }, {
    colName: 'proxyPassword',
    title: <ColumnHeader
      column='proxyPassword'
      title={translation('profiles.tableColumns.proxyPassword')}
    />,
    label: translation('profiles.tableColumns.proxyPassword'),
    dataIndex: 'proxyPassword',
    render: (_, profile) => renderProxyParam(profile, 'password'),
    minWidth: 120,
    width: columnsSettings.proxyPassword.width,
    visible: columnsSettings.proxyPassword.visible,
    className: 'draggable',
    onCellClick: (_, event) => event.stopPropagation(),
  }, {
    colName: 'os',
    title: <ColumnHeader
      column='os'
      title={translation('base.os')}
      hasSort={true}
      sorterField='os'
    />,
    label: translation('base.os'),
    dataIndex: 'os',
    render: renderOs,
    minWidth: 120,
    width: columnsSettings.os.width,
    visible: columnsSettings.os.visible,
    sortField: 'os',
    className: 'draggable',
  }, {
    colName: 'orbitaVersion',
    title: <ColumnHeader
      column='orbitaVersion'
      title={translation('profiles.tableColumns.orbitaVersion')}
    />,
    label: translation('profiles.tableColumns.orbitaVersion'),
    dataIndex: 'navigator',
    render: renderOrbitaVersion,
    minWidth: 120,
    width: columnsSettings.orbitaVersion.width,
    visible: columnsSettings.orbitaVersion.visible,
    className: 'draggable',
  }];
};
