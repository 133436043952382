import { IDBPDatabase, openDB } from 'idb';

import { purgeCacheDB } from './cache-db';
import { genIdbHash } from './gen-idb-hash';

const META_DB_NAME = 'gologin-databases';
const META_DB_VERSION = 1;
const META_DB_STORE_NAME = 'databases';

const DB_PREFIX = 'gologin-';

export const openMetaDb = async (): Promise<IDBPDatabase<DBMeta>> => openDB(META_DB_NAME, META_DB_VERSION, {
  upgrade (db) {
    if (db.objectStoreNames.contains(META_DB_STORE_NAME)) {
      return;
    }

    const objectStore = db.createObjectStore(META_DB_STORE_NAME, { keyPath: 'name' });
    objectStore.createIndex('name', 'name', { unique: true });
  },
});

export const loadOrInitDBMeta = async ({ userId, workspaceId, version }: DBMetaParams): Promise<DBMeta> => {
  const db = await openMetaDb();
  const dbName = genDbName({ userId, workspaceId, version });
  let res = await db.get(META_DB_STORE_NAME, dbName);
  if (!res) {
    const toCreate: DBMeta = {
      userId,
      workspaceId,
      version,
      name: dbName,
      lastSync: { lastSyncedTimestamp: 0 },
    };

    const createdKey = await db.add(META_DB_STORE_NAME, toCreate);
    res = await db.get(META_DB_STORE_NAME, createdKey);
  }

  return res;
};

export const removeDBMetaEntry = async (cacheDBName: DBMeta['name']): Promise<void> => {
  const db = await openMetaDb();

  return db.delete(META_DB_STORE_NAME, cacheDBName);
};

export const purgeAppCache = async (): Promise<void> => {
  const db = await openMetaDb();
  const metaEntries: DBMeta[] = await db.getAll(META_DB_STORE_NAME);
  const purgePromises = metaEntries.map((meta) => purgeCacheDB(meta.name));

  await Promise.all(purgePromises);
};

export const updateDBMetaLastSync = async (cacheDBName: DBMeta['name'], newLastSync: DBMeta['lastSync']): Promise<void> => {
  const db = await openMetaDb();
  const doc = await db.get(META_DB_STORE_NAME, cacheDBName);

  await db.put(META_DB_STORE_NAME, { ...doc, lastSync: newLastSync });
};

export type DBMetaParams = {
  userId: string;
  workspaceId: string;
  version: number;
}

export type DBMeta = DBMetaParams & {
  name: string;
  lastSync: { lastSyncedTimestamp: number };
};

const genDbName = ({ userId, workspaceId, version }: DBMetaParams): string =>
  DB_PREFIX + genIdbHash(`${userId}-${version}-${workspaceId}`);
