import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';

import { GROUPED_PROXY_MANAGER_ANALYTICS_EVENTS } from '../../../../../common/constants/analytics';
import { GeoProxyType } from '../../../../../common/constants/types';
import { calculateRemainingTrafficLimit, stringifyTrafficAmount } from '../../../../../common/proxy/traffic/utils';
import { useTrafficData } from '../../../../state/proxy/traffic-data.atom';
import { openQuickPricing } from '../../../../state/quick-pricing.atom';
import { itemRowIconProps } from '../../../../ui/gologin-header/account-menu/menu-items';
import { IconCheckSmall } from '../../../../ui/gologin-header/icons/icon-check-small';
import { IconHome } from '../../../../ui/gologin-header/icons/icon-home';
import { IconProxyDataCenterSmall } from '../../../../ui/gologin-header/icons/icon-proxy-datacenter-small';
import { IconProxyMobile } from '../../../../ui/gologin-header/icons/icon-proxy-mobile';
import GologinPopover from '../../../../ui/gologin-popover';
import { GologinPopoverHint } from '../../../../ui/gologin-popover/gologin-popover-hint';
import { sendActionAnalytics } from '../../../common/api';
import { IMenuItem } from '../../../quickProfiles/profile-dropdown-menu/interfaces';
import { TRAFFIC_LIMIT } from '../../constants/settings';
import { ProxyGroupAddButtonProps } from '../proxy-list/proxy-group-add-button';
import { GEO_PROXY_TYPE_HINTS, GeoProxyTypeHint } from '../proxy-list/proxy-group-add-button/proxy-group-add-button-menu/constants';
import {
  AddButtonPopoverRow,
  AddButtonPopoverRowContent,
  AddButtonPopoverRowRightControls,
  AddButtonPopoverRowTitle,
  PROXY_GROUP_ADD_BUTTON_POPOVER_WIDTH,
} from '../proxy-list/proxy-group-add-button/proxy-group-add-button-menu/styles';
import PurchaseTrafficButton from '../proxy-list/purchase-traffic-button';
import { PurchaseTrafficButtonText } from '../proxy-list/purchase-traffic-button/styles';

export const POPOVER_DEFAULT_PADDING: React.CSSProperties['padding'] = '6px 12px';

type GeoProxyTypeItem = Omit<IMenuItem, 'name'> & {
  name: GeoProxyType;
  hint: {
    translationKey: string;
  };
}

export type GeoProxyTypePopoverBase = Pick<ProxyGroupAddButtonProps, 'country'> & {
  defaultType: GeoProxyType;
};

type GeoProxyTypePopoverProps = GeoProxyTypePopoverBase & {
  anchorElement: HTMLElement | null;
  handleItemClick: (connectionType: GeoProxyType) => void;
  handleClose: () => void;
}

const GeoProxyTypePopover: React.FC<GeoProxyTypePopoverProps> = (props) => {
  const { country, defaultType, anchorElement, handleItemClick, handleClose } = props;

  const trafficData = useTrafficData();

  const [activeGeoProxyHint, setActiveGeoProxyHint] = useState<GeoProxyTypeHint>(GEO_PROXY_TYPE_HINTS[defaultType]);

  useEffect(() => {
    if (anchorElement) {
      sendActionAnalytics(GROUPED_PROXY_MANAGER_ANALYTICS_EVENTS.visitedSelectGeoProxyType, { proxyCountry: country });
    }
  }, [anchorElement]);

  const geoProxyTypeItems: GeoProxyTypeItem[] = [
    {
      name: GeoProxyType.Resident,
      translationKey: `proxyTypes.${GeoProxyType.Resident}`,
      icon: <IconHome {...itemRowIconProps} />,
      hint: { translationKey: GEO_PROXY_TYPE_HINTS[GeoProxyType.Resident] },
      onAction: () => handleItemClick(GeoProxyType.Resident),
    },
    {
      name: GeoProxyType.Mobile,
      translationKey: `proxyTypes.${GeoProxyType.Mobile}`,
      icon: <IconProxyMobile {...itemRowIconProps} />,
      hint: { translationKey: GEO_PROXY_TYPE_HINTS[GeoProxyType.Mobile] },
      onAction: () => handleItemClick(GeoProxyType.Mobile),
    },
    {
      name: GeoProxyType.DataCenter,
      translationKey: `proxyTypes.${GeoProxyType.DataCenter}`,
      icon: <IconProxyDataCenterSmall {...itemRowIconProps} />,
      hint: { translationKey: GEO_PROXY_TYPE_HINTS[GeoProxyType.DataCenter] },
      onAction: () => handleItemClick(GeoProxyType.DataCenter),
    },
  ];

  const onActionMenuItem = (onAction: () => void, isDisabled?: boolean): void => {
    if (isDisabled) {
      return;
    }

    onAction();
  };

  const renderMenuItemRightControls = (geoProxyType: GeoProxyType): JSX.Element => {
    const remainingTrafficLimit = calculateRemainingTrafficLimit(trafficData, geoProxyType);
    if (remainingTrafficLimit <= TRAFFIC_LIMIT) {
      return <PurchaseTrafficButton />;
    }

    return (
      <PurchaseTrafficButtonText>
        <Trans
          i18nKey='proxyManager.trafficRemainingLimit'
          values={{ limit: stringifyTrafficAmount(remainingTrafficLimit) }}
        />
      </PurchaseTrafficButtonText>
    );
  };

  const renderMenuItem = (menuItem: GeoProxyTypeItem): JSX.Element => {
    const { name, isDisabled, onAction, translationKey, icon } = menuItem;

    const handleMenuItemClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
      event.stopPropagation();
      const remainingTrafficLimit = calculateRemainingTrafficLimit(trafficData, name);
      if (remainingTrafficLimit > TRAFFIC_LIMIT) {
        return onActionMenuItem(onAction, isDisabled);
      }

      openQuickPricing('popup-buy-locked-proxy');
    };

    return (
      <AddButtonPopoverRow
        key={name}
        style={{ padding: POPOVER_DEFAULT_PADDING }}
        onClick={handleMenuItemClick}
        onMouseOver={(): void => {
          setActiveGeoProxyHint(GEO_PROXY_TYPE_HINTS[name]);
        }}
      >
        {icon}
        <AddButtonPopoverRowContent>
          <AddButtonPopoverRowTitle>
            <Trans i18nKey={translationKey} />
          </AddButtonPopoverRowTitle>
          <AddButtonPopoverRowRightControls style={{columnGap: '8px'}}>
            {name === defaultType ? (
              <IconCheckSmall
                padding={0}
                iconColor='var(--2B2B31-header)'
              />
            ) : null}
            {renderMenuItemRightControls(name)}
          </AddButtonPopoverRowRightControls>
        </AddButtonPopoverRowContent>
      </AddButtonPopoverRow>
    );
  };
  
  return (
    <GologinPopover
      anchorEl={anchorElement}
      onClose={(event): void => {
        event.stopPropagation();
        handleClose();
      }}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      extraTranslate={{ right: 3, bottom: 3 }}
      width={PROXY_GROUP_ADD_BUTTON_POPOVER_WIDTH}
      zIndex={1060}
    >
      {geoProxyTypeItems.map(renderMenuItem)}
      {activeGeoProxyHint ? (
        <GologinPopoverHint
          style={{ padding: POPOVER_DEFAULT_PADDING }}
          onClick={(event): void => event.stopPropagation()}
        >
          <Trans i18nKey={activeGeoProxyHint} />
        </GologinPopoverHint>
      ) : null}
    </GologinPopover>
  );
};

export default GeoProxyTypePopover;
