import React, { useEffect } from 'react';

import { useCurrentProfilesQuery } from '../../../state/profiles-table/profiles-query';

type ProfilesTableUpdaterProps = {
  isBlocked: boolean;
  callFetchProfilesByQuery: () => Promise<void>;
};

// This separate component is needed to not re-render entire table
// if we decide to change current profiles query
// because it is unnecessary - state changes will propogate thro other means
// but to still call the fetch with stuff from inside the table
const ProfilesTableUpdater: React.FC<ProfilesTableUpdaterProps> = (props) => {
  const {
    isBlocked,
    callFetchProfilesByQuery,
  } = props;

  const currentProfilesQuery = useCurrentProfilesQuery();

  useEffect(() => {
    if (isBlocked) {
      return;
    }

    callFetchProfilesByQuery();
  }, [currentProfilesQuery, isBlocked]);

  return null;
};

export default ProfilesTableUpdater;
