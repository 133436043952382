import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconHome: React.FC<IIconWrapper & {
  color?: string;
}> = (props) => (
  <IconWrapper {...props} iconType="stroke">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clipPath="url(#clip0_24331_5233)">
        <path
          d="M15.5 7.5L8.34116 0.818414C8.14905 0.639118 7.85094 0.639119 7.65884 0.818415L0.5 7.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke={props.color}
        />
        <path
          d="M2.5 8.5V15C2.5 15.2761 2.72386 15.5 3 15.5H6C6.27614 15.5 6.5 15.2761 6.5 15V12C6.5 11.7239 6.72386 11.5 7 11.5H9C9.27614 11.5 9.5 11.7239 9.5 12V15C9.5 15.2761 9.72386 15.5 10 15.5H13C13.2761 15.5 13.5 15.2761 13.5 15V8.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke={props.color}
        />
      </g>
    </svg>
  </IconWrapper>
);
