import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconVintageComputer: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M2.5 11.5H13.5C14.0304 11.5 14.5391 11.7107 14.9142 12.0858C15.2893 12.4609 15.5 12.9696 15.5 13.5V15.5H0.5V13.5C0.5 12.9696 0.710714 12.4609 1.08579 12.0858C1.46086 11.7107 1.96957 11.5 2.5 11.5Z'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.5 0.5H3.5C2.94772 0.5 2.5 0.947715 2.5 1.5V8.5C2.5 9.05229 2.94772 9.5 3.5 9.5H12.5C13.0523 9.5 13.5 9.05229 13.5 8.5V1.5C13.5 0.947715 13.0523 0.5 12.5 0.5Z'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.5 13.5H12.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  </IconWrapper>
);
