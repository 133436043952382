import { logout } from '../../utils/logout';
import { loadAllFromCacheDBStore } from '../idb-cache/cache-db';
import { getCacheDBName } from '../idb-cache/cache-db-name.atom';
import { putIntoObjectPool } from '../object-pool.atom';

export const performCacheBootstrap = async (): Promise<{ status: 'success' | 'error' }> => {
  try {
    const cacheDBName = getCacheDBName();
    if (!cacheDBName) {
      return { status: 'error' };
    }

    const [profiles, foldersProfiles] = await Promise.all([
      loadAllFromCacheDBStore(cacheDBName, 'profile'),
      loadAllFromCacheDBStore(cacheDBName, 'folder-profile'),
    ]);

    putIntoObjectPool([...profiles, ...foldersProfiles]);

    return { status: 'success' };
  } catch (err) {
    console.error(err);
    await logout();

    return { status: 'error' };
  }
};
