import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconClose: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg width={props.size || 16} height={props.size || 16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.5 2.5L2.5 13.5" strokeWidth={props.strokeWidth || 1} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M2.5 2.5L13.5 13.5" strokeWidth={props.strokeWidth || 1} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  </IconWrapper>
);
