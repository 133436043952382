import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import { handleAcceptedTransactions } from '../handle-accepted-transaction';

export const acceptDeleteProfiles = async (profileIds: string[]): Promise<void> => {
  if (!NEW_FEATURES.objectPool) {
    return;
  }

  return handleAcceptedTransactions(profileIds.map((profileId) => ({
    entity: { id: profileId },
    entityType: 'profile',
    transactionType: 'D',
  })));
};
