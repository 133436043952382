/* eslint-disable max-lines */
type CountryCode = string;
type CityName = string;

export const CITIES_BY_COUNTRY: Record<CountryCode, CityName[]> = {
  'TR': [
    'Izmir',
    'Ankara',
    'Antalya',
    'Kayseri',
    'Tekirdag',
    'Adapazari',
    'Aydin',
    'Mersin',
    'Eskisehir',
    'Konya',
    'Kosekoy',
    'Gaziantep',
    'Mugla',
    'Denizli',
    'Istanbul',
    'Samsun',
    'Diyarbakir',
    'Bursa',
    'Balikesir',
    'Adana',
    'Erzurum',
    'Antakya',
    'Sanliurfa',
    'Bolu',
    'Afyonkarahisar',
    'Mardin',
    'Kahramanmaras',
    'Corum',
    'Magnesia Ad Sipylum',
    'Trabzon',
    'Sivas',
    'Ordu',
    'Kirklareli',
    'Zonguldak',
    'Edirne',
    'Van',
    'Merkez',
    'Rize',
    'Kutahya',
    'Yalova',
    'Batman',
    'Isparta',
    'Nevsehir',
    'Bilecik',
    'Atakum',
    'Elazig',
    'Aksaray',
    'Amasya',
    'Usak',
    'Osmaniye',
    'Tokat Province',
    'Giresun',
    'Karabuk',
    'Kastamonu',
    'Duezce',
  ],
  'IN': [
    'Hyderabad',
    'Kochi',
    'Indore',
    'Ahmedabad',
    'Jaipur',
    'Bhopal',
    'Guwahati',
    'Lucknow',
    'Chennai',
    'Dehradun',
    'Bhubaneswar',
    'Kolkata',
    'Surat',
    'Gurugram',
    'Nagpur',
    'Ludhiana',
    'Chandigarh',
    'Patna',
    'Mumbai',
    'Delhi',
    'Pune',
    'Bengaluru',
    'New Delhi',
    'Aizawl',
    'Kanpur',
    'Kozhikode',
    'Sikar',
    'Thrissur',
    'Raipur',
    'Malappuram',
    'Rajkot',
    'Thane',
    'Noida',
    'Vadodara',
    'Varanasi',
    'Coimbatore',
    'Jodhpur',
    'Thiruvananthapuram',
    'Ranchi',
    'Meerut',
    'Kollam',
    'Shimla',
    'Palakkad',
    'Karnal',
    'Ghaziabad',
    'Visakhapatnam',
    'Sagar',
    'Mohali',
    'Vijayawada',
    'Navi Mumbai',
  ],
  'BR': [
    'Fortaleza',
    'Campinas',
    'Natal',
    'Betim',
    'Porto Alegre',
    'Recife',
    'Guarulhos',
    'Florianopolis',
    'Juiz De Fora',
    'Uberaba',
    'Sao Luis',
    'Aracaju',
    'Volta Redonda',
    'Aparecida De Goiania',
    'Curitiba',
    'Sao Jose Dos Campos',
    'Maceio',
    'Blumenau',
    'Feira De Santana',
    'Jundiai',
    'Belo Horizonte',
    'Salvador',
    'Vitoria',
    'Santo Andre',
    'Ribeirao Preto',
    'Duque De Caxias',
    'Sao Bernardo Do Campo',
    'Maringa',
    'Cuiaba',
    'Sao Goncalo',
    'Santos',
    'Praia Grande',
    'Vila Velha',
    'Carapicuiba',
    'Rio De Janeiro',
    'Barueri',
    'Manaus',
    'Belem',
    'Joao Pessoa',
    'Porto Velho',
    'Suzano',
    'Joinville',
    'Osasco',
    'Serra',
    'Canoas',
    'Caruaru',
    'Sao Vicente',
    'Bauru',
    'Limeira',
    'Niteroi',
    'Itaquaquecetuba',
    'Uberlandia',
    'Itajai',
    'Contagem',
    'Ponta Grossa',
    'Brasilia',
    'Nova Iguacu',
    'Campo Grande',
    'Sorocaba',
    'Caxias Do Sul',
    'Mogi Das Cruzes',
    'Sao Paulo',
    'Teresina',
    'Londrina',
    'Macapa',
    'Jaboatao Dos Guararapes',
    'Cariacica',
    'Cascavel',
    'Montes Claros',
    'Goiania',
    'Sao Jose Do Rio Preto',
    'Campina Grande',
    'Franca',
    'Piracicaba',
    'Marilia',
    'Vitoria Da Conquista',
    'Boa Vista',
    'Sinop',
    'Aracatuba',
    'Cotia',
    'Valparaiso De Goias',
    'Francisco Morato',
    'Macae',
    'Sobral',
    'Jaragua Do Sul',
    'Rondonopolis',
    'Itaborai',
    'Divinopolis',
    'Pelotas',
    'Guaruja',
    'Viamao',
    'Arapiraca',
    'Botucatu',
    'Petropolis',
    'Joao Monlevade',
    'Atibaia',
    'Ipatinga',
    'Olinda',
    'Nova Friburgo',
    'Itabuna',
    'Sao Jose',
    'Gravatai',
    'Petrolina',
    'Imperatriz',
    'Balneario Camboriu',
    'Camacari',
    'Sao Joao De Meriti',
    'Rio Grande',
    'Colombo',
    'Aguas Lindas De Goias',
    'Dourados',
    'Lages',
    'Sumare',
    'Taboao Da Serra',
    'Cabo Frio',
    'Novo Hamburgo',
    'Marica',
    'Sao Leopoldo',
    'Itanhaem',
    'Chapeco',
    'Anapolis',
    'Hortolandia',
    'Ribeirao Das Neves',
    'Santa Maria',
    'Mossoro',
    'Diadema',
    'Cachoeiro De Itapemirim',
    'Passo Fundo',
    'Paulista',
    'Maua',
    'Juazeiro Do Norte',
    'Americana',
    'Presidente Prudente',
    'Criciuma',
    'Taubate',
    'Alvorada',
    'Santarem',
    'Sao Carlos',
    'Indaiatuba',
    'Governador Valadares',
    'Araraquara',
    'Rio Claro',
    'Ananindeua',
    'Belford Roxo',
    'Sao Jose Dos Pinhais',
    'Ourinhos',
    'Pocos De Caldas',
    'Rio Branco',
    'Santa Luzia',
    'Sete Lagoas',
    'Palmas',
    'Campos Dos Goytacazes',
    'Parnamirim',
    'Caraguatatuba',
    'Parauapebas',
    'Palhoca',
    'Foz Do Iguacu',
    'Camaragibe',
    'Nilopolis',
    'Varzea Paulista',
    'Patos De Minas',
    'Rio Do Sul',
    'Linhares',
    'Castanhal',
    'Porto Seguro',
    'Araguaina',
    'Jacarei',
    'Maraba',
    'Angra Dos Reis',
    'Barra Mansa',
    'Catanduva',
    'Guaratingueta',
    'Teixeira De Freitas',
    'Tatui',
    'Itapevi',
    'Catalao',
    'Queimados',
    'Tubarao',
    'Valinhos',
    'Varginha',
    'Caucaia',
    'Paranagua',
    'Pindamonhangaba',
    'Campo Largo',
    'Fazenda Rio Grande',
    'Maracanau',
    'Jau',
    'Luziania',
    'Franco Da Rocha',
    'Parnaiba',
    'Embu',
    'Santa Cruz Do Sul',
    'Braganca Paulista',
    'Pouso Alegre',
    'Mage',
    'Garanhuns',
    'Guarapuava',
    'Vitoria De Santo Antao',
  ],
  'LT': [
    'Vilnius',
    'Kaunas',
    'Klaipeda',
    'Siauliai',
  ],
  'DO': [
    'Santo Domingo',
    'Santiago De Los Caballeros',
    'Santo Domingo Este',
  ],
  'UY': [
    'Montevideo',
    'Maldonado',
  ],
  'GT': [
    'Guatemala City',
  ],
  'MX': [
    'Tijuana',
    'Puebla City',
    'Culiacan',
    'Guadalajara',
    'Gustavo Adolfo Madero',
    'Zapopan',
    'Mexicali',
    'Hermosillo',
    'Mexico City',
    'Ciudad Juarez',
    'Monterrey',
    'Toluca',
    'Veracruz',
    'Chihuahua City',
    'Queretaro City',
    'Iztapalapa',
    'Ciudad Nezahualcoyotl',
    'Merida',
    'Tuxtla Gutierrez',
    'Saltillo',
    'Leon',
    'Acapulco De Juarez',
    'Reynosa',
    'Ciudad Obregon',
    'Durango',
    'Xalapa',
    'Tampico',
    'Guadalupe',
    'San Luis Potosi City',
    'Pachuca',
    'Morelia',
    'Centro',
    'Aguascalientes',
    'Oaxaca City',
    'Naucalpan',
    'Ecatepec',
    'Apodaca',
    'Juarez',
    'Torreon',
    'Cuautitlan Izcalli',
    'Cuernavaca',
    'Tepic',
    'Azcapotzalco',
    'Tlaxcala City',
    'Mazatlan',
    'Tlalnepantla',
    'Ensenada',
    'Nogales',
    'Venustiano Carranza',
    'Campeche',
  ],
  'DE': [
    'Berlin',
    'Hamburg',
    'Cologne',
    'Munich',
    'Frankfurt Am Main',
    'Essen',
    'Dusseldorf',
    'Hanover',
    'Dortmund',
    'Stuttgart',
    'Duisburg',
    'Dresden',
    'Nuremberg',
    'Leipzig',
    'Wuppertal',
    'Bremen',
    'Mannheim',
    'Karlsruhe',
    'Bochum',
  ],
  'LK': [
    'Colombo',
  ],
  'US': [
    'New York',
    'Los Angeles',
    'Chicago',
    'Houston',
    'Miami',
    'Seattle',
    'Newark',
    'Denver',
    'San Diego',
    'Indianapolis',
    'Washington',
    'Fort Worth',
    'Philadelphia',
    'San Antonio',
    'Atlanta',
    'Sacramento',
    'Minneapolis',
    'Louisville',
    'Detroit',
    'Richmond',
    'Queens',
    'Phoenix',
    'Dallas',
    'Portland',
    'Tampa',
    'San Jose',
    'Las Vegas',
    'Charlotte',
    'Austin',
    'Springfield',
    'Orlando',
    'Jacksonville',
    'Brooklyn',
    'Columbus',
    'The Bronx',
    'Arlington',
    'Memphis',
    'Hialeah',
    'Columbia',
    'Nashville',
    'Fayetteville',
    'Baltimore',
    'Wilmington',
    'Lancaster',
    'Fresno',
    'Aurora',
    'Boston',
    'Tucson',
    'Fort Lauderdale',
    'Wichita',
    'Katy',
    'Mesa',
    'Knoxville',
    'Bakersfield',
    'Birmingham',
    'Greenville',
    'Lincoln',
    'Tacoma',
    'Virginia Beach',
    'Staten Island',
    'Spokane',
    'Omaha',
    'Riverside',
    'Raleigh',
    'Lafayette',
    'Rochester',
    'Lexington',
    'Henderson',
    'Dayton',
    'Kissimmee',
    'Spring',
    'Pittsburgh',
    'Kansas City',
    'Albuquerque',
    'El Paso',
    'Saint Paul',
    'Buffalo',
    'Cincinnati',
    'Greensboro',
    'Vancouver',
    'St Louis',
    'Oklahoma City',
    'Milwaukee',
    'Tappahannock',
    'Cleveland',
    'San Francisco',
    'Oakland',
    'Gainesville',
    'Colorado Springs',
    'Salt Lake City',
    'Stockton',
    'Silver Spring',
    'Providence',
    'Fort Wayne',
    'Tallahassee',
    'Plano',
    'Glendale',
    'Long Beach',
    'Concord',
    'Honolulu',
    'Tulsa',
    'Canton',
    'Reno',
    'Athens',
    'Hollywood',
    'Fontana',
    'Montgomery',
    'Chandler',
    'Akron',
    'Naples',
    'Albany',
    'Charleston',
    'Irvine',
    'Marietta',
    'Grand Rapids',
    'Lawrenceville',
    'New Orleans',
    'Auburn',
    'Woodbridge',
    'Jackson',
    'Alexandria',
    'Baton Rouge',
    'Lakeland',
    'Peoria',
    'Fredericksburg',
    'Madison',
    'Boise',
    'Monroe',
    'Bloomington',
    'Lake Worth',
    'Decatur',
    'West Palm Beach',
  ],
  'PL': [
    'Katowice',
    'Warsaw',
    'Krakow',
    'Wroclaw',
    'Lublin',
    'Szczecin',
    'Lodz',
    'Gdynia',
    'Poznan',
    'Gdansk',
    'Bydgoszcz',
    'Rzeszow',
    'Bialystok',
    'Zielona Gora',
    'Olsztyn',
    'Zabrze',
    'Gliwice',
    'Kielce',
  ],
  'EE': [
    'Tallinn',
  ],
  'AR': [
    'Salta',
    'Quilmes',
    'Cordoba',
    'Lanus',
    'San Miguel De Tucuman',
    'Mendoza',
    'Buenos Aires',
    'Mar Del Plata',
    'Corrientes',
    'La Plata',
    'Rosario',
    'Avellaneda',
    'San Luis',
    'Ituzaingo',
    'Berazategui',
    'Formosa',
    'Parana',
    'Merlo',
    'Neuquen',
    'Pilar',
    'Florencio Varela',
    'Moreno',
    'Monte Grande',
    'Bahia Blanca',
    'Moron',
    'San Juan',
    'San Miguel',
    'Jose C Paz',
    'Villa Ballester',
    'Caseros',
    'Resistencia',
    'Hurlingham',
    'Lomas De Zamora',
    'San Salvador De Jujuy',
    'Isidro Casanova',
    'Posadas',
    'Santa Fe',
    'La Rioja',
    'Comodoro Rivadavia',
    'Rio Gallegos',
    'San Fernando',
    'Jose Maria Ezeiza',
    'Laferrere',
    'Rio Cuarto',
    'San Justo',
    'Catamarca',
  ],
  'SA': [
    'Dammam',
    'Jeddah',
    'Riyadh',
    'Medina',
    'Al Qatif',
  ],
  'IT': [
    'Turin',
    'Rome',
    'Milan',
    'Naples',
    'Bologna',
    'Catania',
    'Padova',
    'Palermo',
    'Bari',
    'Florence',
    'Verona',
    'Genoa',
    'Brescia',
    'Cagliari',
    'Pescara',
  ],
  'UZ': [
    'Samarkand',
    'Tashkent',
    'Bukhara',
  ],
  'IL': [
    'Jerusalem',
    'Tel Aviv',
    'Rishon Letsiyyon',
    'Haifa',
    'Petah Tikva',
    'Ramat Gan',
    'Holon',
  ],
  'FR': [
    'Marseille',
    'Lyon',
    'Toulouse',
    'Paris',
    'Rennes',
    'Nantes',
    'Bordeaux',
    'Lille',
    'Montpellier',
    'Strasbourg',
    'Nice',
    'Angers',
    'Le Mans',
    'Rouen',
  ],
  'VN': [
    'Ho Chi Minh City',
    'Hanoi',
    'Da Nang',
    'Bien Hoa',
    'Can Tho',
    'Haiphong',
    'Nha Trang',
  ],
  'MA': [
    'Casablanca',
    'Marrakesh',
    'Rabat',
    'Meknes',
    'Fes',
    'Agadir',
    'Tangier',
    'Sale',
    'Oujda',
    'Kenitra',
    'Tetouan',
    'El Jadida',
    'Beni Mellal',
  ],
  'AT': [
    'Vienna',
    'Graz',
  ],
  'SV': [
    'San Salvador',
  ],
  'CR': [
    'San Jose',
    'Heredia',
    'Alajuela',
  ],
  'CA': [
    'Calgary',
    'Surrey',
    'Toronto',
    'Vancouver',
    'Edmonton',
    'Montreal',
    'Winnipeg',
    'Regina',
    'Victoria',
    'Hamilton',
    'Scarborough',
    'Saskatoon',
    'Longueuil',
    'Ottawa',
    'Laval',
    'Brampton',
    'Quebec',
    'Mississauga',
    'Burnaby',
    'Windsor',
    'Kitchener',
    'Gatineau',
  ],
  'SK': [
    'Bratislava',
    'Kosice',
    'Nitra',
  ],
  'PH': [
    'Mabalacat',
    'Paranaque City',
    'Manila',
    'Batangas',
    'Baguio City',
    'Taguig',
    'Cebu City',
    'General Trias',
    'Dasmarinas',
    'Marikina City',
    'Quezon City',
    'Davao City',
    'Bacolod City',
    'Cabanatuan City',
    'Iloilo City',
    'Tarlac City',
    'Talavera',
    'Mandaluyong City',
    'Bacoor',
    'Cagayan De Oro',
    'Lahug',
    'Caloocan City',
    'Makati City',
    'Lucena City',
    'Antipolo City',
    'Tanza',
    'Zamboanga City',
    'Malabon',
    'San Jose Del Monte',
    'Las Pinas',
    'Imus',
    'Butuan',
    'Urdaneta',
    'Calamba',
    'Tuguegarao City',
    'Lipa City',
    'Olongapo City',
    'Cabuyao',
    'City Of Muntinlupa',
    'San Pedro',
    'Carmona',
    'Angeles City',
    'San Pablo City',
    'Nasugbu',
    'Santa Rosa',
    'Naga',
    'Dagupan',
    'Iligan',
  ],
  'ES': [
    'Valencia',
    'Seville',
    'Madrid',
    'Barcelona',
    'Malaga',
    'Bilbao',
    'Vigo',
    'Murcia',
    'Valladolid',
    'A Coruna',
    'Palma',
    'Santa Cruz De Tenerife',
    'Alicante',
    'Terrassa',
    'Gijon',
    'Zaragoza',
    'Granada',
    'Las Palmas De Gran Canaria',
  ],
  'SN': [
    'Dakar',
  ],
  'RO': [
    'Cluj Napoca',
    'Iasi',
    'Timisoara',
    'Bucharest',
    'Craiova',
    'Arad',
    'Galati',
    'Brasov',
    'Oradea',
    'Ploiesti',
    'Constanta',
    'Pitesti',
    'Sibiu',
  ],
  'CY': [
    'Nicosia',
    'Limassol',
  ],
  'JM': [
    'Kingston',
    'Montego Bay',
  ],
  'MN': [
    'Ulan Bator',
  ],
  'CO': [
    'Barranquilla',
    'Bogota',
    'Santiago De Cali',
    'Bucaramanga',
    'Medellin',
    'Cartagena',
    'Cucuta',
    'Neiva',
    'Ibague',
    'Pereira',
    'Pasto',
    'Manizales',
    'Santa Marta',
    'Villavicencio',
    'Valledupar',
    'Chia',
    'Tunja',
    'Popayan',
  ],
  'PE': [
    'Trujillo',
    'Lima',
    'Arequipa',
    'Piura',
    'Cusco',
    'Huancayo',
    'Tacna',
    'Chiclayo',
    'Chimbote',
    'Ica',
    'Huanuco',
    'Pucallpa',
  ],
  'MD': [
    'Chisinau',
  ],
  'BO': [
    'Santa Cruz',
    'La Paz',
    'Cochabamba',
  ],
  'AE': [
    'Dubai',
    'Sharjah',
    'Abu Dhabi',
    'Ajman',
    'Ras Al Khaimah',
    'Al Ain City',
  ],
  'QA': [
    'Doha',
  ],
  'RU': [
    'Moscow',
    'St Petersburg',
    'Kazan',
    'Perm',
    'Nizhniy Novgorod',
    'Voronezh',
    'Samara',
    'Ufa',
    'Yekaterinburg',
    'Rostov On Don',
    'Krasnodar',
    'Novosibirsk',
    'Chelyabinsk',
  ],
  'HN': [
    'Tegucigalpa',
    'San Pedro Sula',
  ],
  'MY': [
    'Kota Kinabalu',
    'Petaling Jaya',
    'Kuching',
    'Kuala Lumpur',
    'George Town',
    'Shah Alam',
    'Johor Bahru',
    'Puchong Batu Dua Belas',
    'Ipoh',
  ],
  'CZ': [
    'Brno',
    'Prague',
    'Ostrava',
    'Olomouc',
  ],
  'KG': [
    'Bishkek',
  ],
  'AU': [
    'Adelaide',
    'Melbourne',
    'Sydney',
    'Brisbane',
    'Perth',
  ],
  'SE': [
    'Gothenburg',
    'Stockholm',
    'Malmo',
  ],
  'GE': [
    'Tbilisi',
    'Batumi',
    'Kutaisi',
  ],
  'ID': [
    'Semarang',
    'Medan',
    'Malang',
    'Jakarta',
    'Tangerang',
    'Bogor',
    'Bandung',
    'Yogyakarta',
    'Makassar',
    'Surabaya',
    'Bekasi',
    'Denpasar',
    'Pekanbaru',
    'South Tangerang',
    'Magelang',
    'Kediri',
    'Pontianak',
    'Manado',
    'Banjarmasin',
    'Sidoarjo',
    'Balikpapan',
    'Palembang',
    'Surakarta',
    'Batam',
    'Tasikmalaya',
    'Depok',
    'Cirebon',
    'Bandar Lampung',
    'Jember',
    'Samarinda',
  ],
  'OM': [
    'Muscat',
  ],
  'TW': [
    'Taoyuan District',
    'Kaohsiung City',
    'New Taipei',
    'Tainan City',
    'Taipei',
    'Taichung',
    'Hsinchu',
    'Zhongli District',
    'Chang Hua',
  ],
  'HK': [
    'Central',
  ],
  'PT': [
    'Lisbon',
    'Porto',
    'Coimbra',
    'Braga',
    'Funchal',
    'Vila Nova De Gaia',
    'Amadora',
    'Setubal',
    'Almada',
  ],
  'NL': [
    'Amsterdam',
    'Rotterdam',
    'The Hague',
    'Almere Stad',
    'Naaldwijk',
  ],
  'DZ': [
    'Oran',
    'Algiers',
    'Tlemcen',
    'Batna City',
    'Blida',
    'Bejaia',
    'Constantine',
    'Skikda',
    'Setif',
    'Djelfa',
  ],
  'ZA': [
    'Johannesburg',
    'Pretoria',
    'Durban',
    'Cape Town',
    'Port Elizabeth',
    'Bloemfontein',
    'Centurion',
    'Boksburg',
  ],
  'EG': [
    'Tanta',
    'Al Mansurah',
    'Giza',
    'Cairo',
    'Alexandria',
    'Asyut',
    'Zagazig',
    'Ismailia',
    'Port Said',
    'Damietta',
  ],
  'AO': [
    'Luanda',
  ],
  'GB': [
    'London',
    'Manchester',
    'Glasgow',
    'Birmingham',
    'Southampton',
    'Leicester',
    'Cardiff',
    'Liverpool',
    'Sheffield',
    'Coventry',
    'Hull',
    'Croydon',
    'Hackney',
    'Derby',
    'Nottingham',
    'Bristol',
    'Edinburgh',
    'Reading',
    'Ilford',
    'Leeds',
    'Newcastle Upon Tyne',
    'Southwark',
    'Slough',
    'Islington',
    'Belfast',
    'Bradford',
    'Wolverhampton',
    'Northampton',
    'Stoke On Trent',
    'Plymouth',
    'Milton Keynes',
    'Norwich',
  ],
  'TN': [
    'Tunis',
    'Aryanah',
    'Bizerte',
    'Sousse',
    'Sfax',
  ],
  'XK': [
    'Pristina',
  ],
  'UA': [
    'Odesa',
    'Kyiv',
    'Dnipro',
    'Lviv',
    'Ternopil',
    'Zaporizhzhya',
    'Vinnytsia',
    'Kryvyi Rih',
    'Kharkiv',
    'Chernivtsi',
    'Poltava',
    'Mykolayiv',
    'Cherkasy',
    'Khmelnytskyi',
  ],
  'VE': [
    'Maracaibo',
    'Caracas',
    'Ciudad Guayana',
    'Maracay',
    'Valencia',
    'Barquisimeto',
  ],
  'TH': [
    'Bangkok',
    'Chiang Mai',
    'Khon Kaen',
  ],
  'NP': [
    'Kathmandu',
    'Patan',
    'Biratnagar',
    'Ilam',
    'Kaski',
    'Bharatpur',
  ],
  'CL': [
    'Concepcion',
    'Antofagasta',
    'Santiago',
    'Port Montt',
    'Rancagua',
    'Arica',
    'Vina Del Mar',
    'Temuco',
    'Valparaiso',
    'Iquique',
    'La Serena',
    'Talca',
  ],
  'BH': [
    'Manama',
  ],
  'BG': [
    'Plovdiv',
    'Varna',
    'Sofia',
    'Burgas',
    'Rousse',
    'Stara Zagora',
  ],
  'GR': [
    'Athens',
    'Thessaloniki',
  ],
  'KW': [
    'Kuwait City',
  ],
  'JO': [
    'Amman',
  ],
  'BE': [
    'Brussels',
    'Antwerp',
    'Ghent',
    'Liege',
  ],
  'PK': [
    'Islamabad',
    'Lahore',
    'Rawalpindi',
    'Karachi',
    'Peshawar',
    'Muzaffargarh',
    'Gujranwala',
    'Faisalabad',
    'Multan',
  ],
  'JP': [
    'Tokyo',
    'Yokohama',
    'Umeda',
  ],
  'CH': [
    'Zurich',
    'Lausanne',
    'Geneva',
    'Bern',
  ],
  'KH': [
    'Phnom Penh',
  ],
  'ZM': [
    'Lusaka',
  ],
  'TG': [
    'Lome',
  ],
  'BD': [
    'Dhaka',
    'Chittagong',
    'Rajshahi',
  ],
  'PA': [
    'Panama City',
  ],
  'BY': [
    'Minsk',
    'Hrodna',
    'Gomel',
    'Brest',
  ],
  'NO': [
    'Oslo',
  ],
  'CI': [
    'Abidjan',
  ],
  'EC': [
    'Quito',
    'Guayaquil',
    'Cuenca',
    'Ambato',
    'Machala',
    'Portoviejo',
    'Loja',
  ],
  'FI': [
    'Helsinki',
    'Tampere',
    'Espoo',
  ],
  'PY': [
    'Asuncion',
    'Ciudad Del Este',
  ],
  'NG': [
    'Lagos',
    'Port Harcourt',
    'Katsina',
    'Abuja',
  ],
  'RS': [
    'Novi Sad',
    'Belgrade',
    'Nis',
  ],
  'IE': [
    'Dublin',
  ],
  'AL': [
    'Tirana',
  ],
  'HR': [
    'Zagreb',
    'Split',
  ],
  'MK': [
    'Skopje',
  ],
  'HU': [
    'Budapest',
    'Miskolc',
    'Szeged',
    'Szekesfehervar',
    'Debrecen',
    'Pecs',
    'Kecskemet',
  ],
  'KE': [
    'Nairobi',
  ],
  'NZ': [
    'Auckland',
    'Wellington',
    'Christchurch',
    'Hamilton',
  ],
  'KR': [
    'Seoul',
    'Suwon',
    'Gwanak Gu',
    'Buk Gu',
    'Seongnam Si',
    'Gangnam Gu',
    'Seo Gu',
    'Goyang Si',
    'Incheon',
    'Yongin Si',
  ],
  'SI': [
    'Ljubljana',
    'Maribor',
  ],
  'AM': [
    'Yerevan',
  ],
  'AZ': [
    'Baku',
  ],
  'LV': [
    'Riga',
  ],
  'ET': [
    'Addis Ababa',
  ],
  'IR': [
    'Tehran',
  ],
  'TT': [
    'Port Of Spain',
    'Chaguanas',
  ],
  'NI': [
    'Managua',
  ],
  'GH': [
    'Accra',
  ],
  'BA': [
    'Sarajevo',
    'Banja Luka',
    'Tuzla',
  ],
  'NA': [
    'Windhoek',
  ],
  'BS': [
    'Nassau',
  ],
  'CW': [
    'Willemstad',
  ],
  'ZW': [
    'Harare',
  ],
  'PR': [
    'San Juan',
  ],
  'CN': [
    'Shanghai',
    'Shenzhen',
    'Guangzhou',
  ],
  'HT': [
    'Port Au Prince',
  ],
  'BW': [
    'Gaborone',
  ],
  'GY': [
    'Georgetown',
  ],
  'LB': [
    'Beirut',
  ],
  'MU': [
    'Port Louis',
  ],
  'SR': [
    'Paramaribo',
  ],
  'MV': [
    'Male',
  ],
  'MO': [
    'Macao',
  ],
  'KZ': [
    'Astana',
    'Almaty',
  ],
  'MZ': [
    'Maputo',
  ],
  'GA': [
    'Libreville',
  ],
  'IQ': [
    'Erbil',
    'Baghdad',
  ],
  'RW': [
    'Kigali',
  ],
  'DK': [
    'Copenhagen',
  ],
  'IS': [
    'Reykjavik',
  ],
  'BB': [
    'Bridgetown',
  ],
  'ME': [
    'Podgorica',
  ],
  'LA': [
    'Vientiane',
  ],
  'UG': [
    'Kampala',
  ],
  'LC': [
    'Castries',
  ],
  'MM': [
    'Yangon',
  ],
  'MG': [
    'Antananarivo',
  ],
  'DM': [
    'Roseau',
  ],
  'LU': [
    'Luxembourg',
  ],
  'YE': [
    'Sanaa',
  ],
  'BN': [
    'Bandar Seri Begawan',
  ],
  'CU': [
    'Havana',
  ],
  'TZ': [
    'Dar Es Salaam',
  ],
};
