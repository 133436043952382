import { useTranslate } from '@tolgee/react';
import { message } from 'antd';
import React, { Dispatch, FC, MouseEvent, SetStateAction, useContext, useEffect, useState } from 'react';

import {
  ModalDivider,
  ModalUpgradeButtonContainer,
  ModalUpgradeCardPaymentButton,
} from './domElements';
import { Col } from './styles';
import { E_ACTION_INFO, E_ANALYTICS_ACTIONS } from '../../../../common/constants/analytics';
import { sendGA4PurchaseEvent } from '../../../GA4';
import { useQuery } from '../../../hooks';
import { IPlan } from '../../../interfaces/plan';
import { Limits } from '../../../interfaces/workspaces/limits';
import { history } from '../../../services';
import {
  useCurrentTabShownCurrency,
  useDiscountObjById,
  usePriceConfig,
  usePromoDiscountAvaliable,
  userContext,
  workspaceContext,
} from '../../../state';
import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import { useSelectedDiscount, useSelectedPlan } from '../../../state/pricing/pricing-page.atom';
import { useIsUpgradeDiscountAvaliable } from '../../../state/pricing/upgrade-discount.atom';
import { getExternalCheckoutUrl } from '../../../utils/open-site';
import { sendReactErrorToSentry } from '../../../utils/sentry.helper';
import { getGoogleAnalyticsSessionId, sendActionAnalytics, sendErrorPaymentAnalytics } from '../../common/api';
import { Spinner } from '../../modalsComponents/components/checkout-modal/styles';
import {
  checkPlanMaxProfiles,
  isDiscountAnnual,
  getSelectedPlan,
} from '../../modalsComponents/components/checkout-modal/utils';
import { checkStripeSession, createCheckoutSession, createLimitsPurchaseSession, IGetStripeSessionUrlParams } from '../api';
import { E_PAYMENT_METHODS, E_PAYMENT_METHODS_ANALYTICS, E_PERIOD } from '../interfaces';
import { handlePaymentCreationError } from '../utils/sentry';

const isElectron = !!window.require;

interface IStripeForm {
  plans: IPlan[];
  hasSuccessPayment: boolean;
  needChangePlan: boolean;
  getCheckmarkLine: () => JSX.Element;
  openSuccessModal: (planId: string) => void;
  workspaceId: string;
  setThreeDSecureUrl: Dispatch<SetStateAction<string>>;
  setShowThreeDSecureModal: Dispatch<SetStateAction<boolean>>;
  setIsCloseModalDisabled: Dispatch<SetStateAction<boolean>>;
  setIsLoadingModalShown: Dispatch<SetStateAction<boolean>>;
  setClosePaymentModal: Dispatch<SetStateAction<boolean>>;
  isLimitsPurchase: boolean;
  isPurchaseWithAddonLimits: boolean;
  addonLimits?: Limits;
}

const StripeForm: FC<IStripeForm> = (props) => {
  const {
    hasSuccessPayment,
    plans,
    getCheckmarkLine,
    openSuccessModal,
    workspaceId,
    setIsLoadingModalShown,
    setClosePaymentModal,
    isLimitsPurchase,
    isPurchaseWithAddonLimits,
    addonLimits,
  } = props;

  const tabCurrency = useCurrentTabShownCurrency();
  const isUpgrade = useIsUpgradeDiscountAvaliable();
  const priceConfig = usePriceConfig();

  const selectedPlan = useSelectedPlan();
  const selectedDiscount = useSelectedDiscount();
  const selectedDiscounObj = useDiscountObjById(selectedDiscount);

  const { profiles: userProfilesCount } = useContext(userContext);
  const { profilesCount: workspaceProfilesCount } = useContext(workspaceContext);

  const { t: translation } = useTranslate();

  const isAnnual = isDiscountAnnual(selectedDiscount);
  const planPeriod = isAnnual ? E_PERIOD.ANNUAL : E_PERIOD.MONTHLY;
  const profilesCount = NEW_FEATURES.workspaces ? workspaceProfilesCount : userProfilesCount;
  const promoDiscount = usePromoDiscountAvaliable();

  const query = useQuery();

  useEffect(() => {
    if (!NEW_FEATURES.newStripeCheckout) {
      return;
    }

    const checkoutStatus = query.get('status');
    const sessionId = query.get('session_id');

    query.delete('status');
    query.delete('session_id');
    if (!checkoutStatus) {
      return;
    }

    if (checkoutStatus === 'success' && sessionId) {
      checkSession(sessionId);
    }
  }, []);

  const checkSession = (sessionId: string): void => {
    setIsLoadingModalShown(true);
    checkStripeSession(sessionId)
      .then(checkTransactionResponse => {
        const { paymentId = '', userId, amount, planId, planName: checkoutPlanName } = checkTransactionResponse;
        sendGA4PurchaseEvent({
          userId,
          amount,
          planId,
          planName: checkoutPlanName,
          paymentId,
        });

        setIsLoadingModalShown(false);
        openSuccessModal(planId);
      })
      .catch(error => {
        setClosePaymentModal(true);
        const errorMessage = error?.message || error?.body?.message || '';
        sendErrorPaymentAnalytics({
          messageText: errorMessage,
          extra: {},
          method: E_PAYMENT_METHODS.STRIPE,
          paymentError: {
            paymentMethod: E_PAYMENT_METHODS_ANALYTICS.STRIPE,
            description: errorMessage || 'undefined error',
          },
        });

        if (!errorMessage) {
          return;
        }

        message.error(errorMessage);
      })
      .finally(() => {
        setIsLoadingModalShown(false);
      });
  };

  const stripeForm = (): JSX.Element => {
    const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);

    const handleLimitsPurchase = async (): Promise<void> => {
      const { url: checkoutUrl } = await createLimitsPurchaseSession({
        workspaceId,
        planId: selectedPlan,
        currency: tabCurrency,
        paymentMethod: E_PAYMENT_METHODS.STRIPE,
        addon: { limits: addonLimits },
      });

      await handleRedirect(checkoutUrl);
    };

    const handleRedirect = async (checkoutUrl: string): Promise<void> => {
      if (!checkoutUrl) {
        finishBtnLoading();

        return;
      }

      const checkoutPageUrl = await getExternalCheckoutUrl({ checkoutUrl });
      if (isElectron) {
        window.require('electron').shell.openExternal(checkoutPageUrl)
          .catch((error) => {
            const errorMessage = error instanceof Error ? error.message : 'unknown';
            sendReactErrorToSentry({ message: errorMessage, transactionName: 'open-stripe-checkout-url' });
          });

        finishBtnLoading();

        return;
      }

      window.open(checkoutPageUrl, '_blank');
      finishBtnLoading();
    };

    const handleSubmit = async (event: MouseEvent<HTMLButtonElement>): Promise<void> => {
      if (isLimitsPurchase) {
        await handleLimitsPurchase();

        return;
      }

      const actionInfo = isUpgrade ? E_ACTION_INFO.UPGRADE : E_ACTION_INFO.EMPTY;
      sendActionAnalytics(E_ANALYTICS_ACTIONS.clickedPayNow, { actionInfo, paymentMethod: E_PAYMENT_METHODS.STRIPE }).catch(() => null);
      event.preventDefault();
      if (hasSuccessPayment) {
        history.replace('/profileList');

        return;
      }

      const planToPay = getSelectedPlan(plans, selectedPlan);
      const profilesStatus = planToPay && checkPlanMaxProfiles({
        translation,
        planToPay,
        profiles: profilesCount,
        paymentMethod: E_PAYMENT_METHODS.STRIPE,
      });

      if (!profilesStatus) {
        return;
      }

      handleCheckout(event);
    };

    const finishBtnLoading = (): void => {
      setTimeout(() => {
        setIsBtnLoading(false);
      }, 3000);
    };

    const handleCheckout = async (event: MouseEvent<HTMLButtonElement>): Promise<void> => {
      setIsBtnLoading(true);
      const createCheckoutSessionsParams: IGetStripeSessionUrlParams = {
        plan: selectedPlan,
        discount: selectedDiscount,
        workspaceId,
        gaSession: getGoogleAnalyticsSessionId(),
        currency: tabCurrency,
        isUpgrade,
      };

      if (isPurchaseWithAddonLimits) {
        createCheckoutSessionsParams.addon = { limits: addonLimits };
      }

      const { url: checkoutUrl } = await createCheckoutSession(createCheckoutSessionsParams)
        .catch((error: any) => {
          handlePaymentCreationError({
            error,
            selectedDiscounObj,
            planPeriod,
            method: E_PAYMENT_METHODS.STRIPE,
            paymentMethod: E_PAYMENT_METHODS_ANALYTICS.STRIPE,
            promoDiscount,
            plans,
            selectedPlan,
            priceConfig,
            tabCurrency,
          });

          return { url: '' };
        });

      await handleRedirect(checkoutUrl);
    };

    return (
      <Col>
        <ModalDivider />
        {getCheckmarkLine()}
        <ModalUpgradeButtonContainer paddingBottom={true}>
          <ModalUpgradeCardPaymentButton
            type='button'
            onClick={handleSubmit}
          >
            {isBtnLoading ?
              <Spinner size={19} thickness={2} /> :
              translation('pricing.forms.stripe.submitBtnTxt')}
          </ModalUpgradeCardPaymentButton>
        </ModalUpgradeButtonContainer>
      </Col>
    );
  };

  return stripeForm();
};

export default StripeForm;
