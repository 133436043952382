import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { GEOPROXY_COUNTRY_KEY_NAME, GEOPROXY_TYPE_ADDED_LAST_KEY_NAME } from '../../../common/constants/local-storage';
import { GeoProxyType, determineIsGeoProxyType } from '../../../common/constants/types';
import { DEFAULT_SELECTED_GEOPROXY_COUNTRY, DEFAULT_SELECTED_GEOPROXY_TYPE } from '../../features/proxy/constants';
import { makeGeoProxyCustomName } from '../../features/proxy/proxy-helpers';
import { determineIsCityAvailableInCountry, evaluateGeoProxyCustomNameCounter } from '../../features/proxy/utils/default-proxy-data';
import { NEW_FEATURES } from '../feature-toggle/new-features';
import { defaultGeoProxyAtom, geoProxyCustomNameCountersAtom, updateDefaultGeoProxy } from './default-geoproxy.atom';

export type GeoProxyFormProxy = {
  country: string;
  city: string;
  connectionType: GeoProxyType;
  customName: string;
};

const userFilledGeoProxyFormAtom = atom<Partial<GeoProxyFormProxy>>({});

const geoProxyFormAtom = atom<GeoProxyFormProxy>(get => {
  const userFilledGeoProxyForm = get(userFilledGeoProxyFormAtom);
  const defaultGeoProxy = get(defaultGeoProxyAtom);
  const geoProxyCustomNameCounters = get(geoProxyCustomNameCountersAtom);

  const country = userFilledGeoProxyForm.country || defaultGeoProxy.country;

  let city = '';
  if (userFilledGeoProxyForm.city && determineIsCityAvailableInCountry(country, userFilledGeoProxyForm.city)) {
    ({ city } = userFilledGeoProxyForm);
  } else if (defaultGeoProxy.city && determineIsCityAvailableInCountry(country, defaultGeoProxy.city)) {
    ({ city } = defaultGeoProxy);
  } else if (defaultGeoProxy.city === 'auto') {
    city = '';
  }

  let customNameCounter = geoProxyCustomNameCounters[country];
  if (customNameCounter) {
    customNameCounter++;
  } else {
    customNameCounter = evaluateGeoProxyCustomNameCounter(country).counter;
  }

  const autoGeneratedCustomName = makeGeoProxyCustomName({
    countryCode: country,
    counter: customNameCounter,
  });

  return {
    country,
    city,
    connectionType: userFilledGeoProxyForm.connectionType || defaultGeoProxy.connectionType,
    customName: userFilledGeoProxyForm.customName,
  };
});

const getGeoProxyForm = (): Partial<GeoProxyFormProxy> => getDefaultStore().get(userFilledGeoProxyFormAtom);
const setGeoProxyForm = (geoProxy: Partial<GeoProxyFormProxy>): void => getDefaultStore().set(userFilledGeoProxyFormAtom, geoProxy);

export const useGeoProxyForm = (): GeoProxyFormProxy => useAtomValue(geoProxyFormAtom);

export const updateGeoProxyForm = (partialGeoProxy: Partial<GeoProxyFormProxy>): void => {
  const currentGeoProxyForm = getGeoProxyForm();
  const newGeoProxyForm: Partial<GeoProxyFormProxy> = {
    ...currentGeoProxyForm,
    ...partialGeoProxy,
  };

  setGeoProxyForm(newGeoProxyForm);
  updateDefaultGeoProxy(partialGeoProxy);
};

const geoProxyLastSelectedCountryAtom = atom<string>(localStorage.getItem(GEOPROXY_COUNTRY_KEY_NAME) ?? DEFAULT_SELECTED_GEOPROXY_COUNTRY);

const initGeoProxyLastSelectedTypeAtom = (): GeoProxyType => {
  let geoProxyLastSelectedType: GeoProxyType | null = null;
  const geoProxyLastSelectedTypeInStorage = localStorage.getItem(GEOPROXY_TYPE_ADDED_LAST_KEY_NAME);
  if (geoProxyLastSelectedTypeInStorage && determineIsGeoProxyType(geoProxyLastSelectedTypeInStorage)) {
    geoProxyLastSelectedType = geoProxyLastSelectedTypeInStorage;
  }

  return geoProxyLastSelectedType ?? DEFAULT_SELECTED_GEOPROXY_TYPE;
};

const geoProxyLastSelectedTypeAtom = atom<GeoProxyType>(initGeoProxyLastSelectedTypeAtom());

const geoProxyLastSelectedCountryPersistentAtom = atom(
  get => get(geoProxyLastSelectedCountryAtom),
  (_get, set, newCountry: string) => {
    set(geoProxyLastSelectedCountryAtom, newCountry);
    localStorage.setItem(GEOPROXY_COUNTRY_KEY_NAME, newCountry);
  },
);

const geoProxyLastSelectedTypePersitentAtom = atom(
  get => get(geoProxyLastSelectedTypeAtom),
  (_get, set, newType: GeoProxyType) => {
    set(geoProxyLastSelectedTypeAtom, newType);
    localStorage.setItem(GEOPROXY_TYPE_ADDED_LAST_KEY_NAME, newType);
  },
);

export const setGeoProxyLastSelectedCountry = (country: string): void => getDefaultStore().set(geoProxyLastSelectedCountryPersistentAtom, country);
export const useGeoProxyLastSelectedCountry = (): string => useAtomValue(geoProxyLastSelectedCountryPersistentAtom);
export const setGeoProxyLastSelectedType = (newType: GeoProxyType): void => getDefaultStore().set(geoProxyLastSelectedTypePersitentAtom, newType);
export const getGeoProxyLastSelectedType = (): GeoProxyType => getDefaultStore().get(geoProxyLastSelectedTypePersitentAtom);
export const useGeoProxyLastSelectedType = (): GeoProxyType => useAtomValue(geoProxyLastSelectedTypePersitentAtom);

export const updateGeoProxyLastSelectedTypeFromAvailable = (availableTypes: GeoProxyType[]): void => {
  if (availableTypes.includes(getGeoProxyLastSelectedType())) {
    const geoProxyLastSelectedTypeInStorage = localStorage.getItem(GEOPROXY_TYPE_ADDED_LAST_KEY_NAME);
    if (!geoProxyLastSelectedTypeInStorage) {
      setGeoProxyLastSelectedType(getGeoProxyLastSelectedType());
    }

    return;
  }

  const DEFAULT_GEOPROXY_TYPE = NEW_FEATURES.proxyGroupsV2 ? GeoProxyType.Resident : GeoProxyType.DataCenter;
  if (!availableTypes || availableTypes.includes(DEFAULT_GEOPROXY_TYPE)) {
    return setGeoProxyLastSelectedType(DEFAULT_GEOPROXY_TYPE);
  }

  const [firstAvailableType] = availableTypes;
  setGeoProxyLastSelectedType(firstAvailableType);
};
