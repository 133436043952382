import React, { memo } from 'react';
import { areEqual } from 'react-window';

import { GROUPED_PROXY_MANAGER_ANALYTICS_EVENTS } from '../../../../../common/constants/analytics';
import { determineIsProxyTruthy, determineIsTorOrFreeProxy } from '../../../../../common/constants/types';
import { isNotNull } from '../../../../../common/typescript/predicates';
import { IArchivedProxy, IProxy } from '../../../../interfaces';
import PerformanceObserverService from '../../../../services/performance-observer/performance-observer.service';
import { NEW_FEATURES } from '../../../../state/feature-toggle/new-features';
import {
  openProxyContextMenu,
  ProxyContextMenuProxyLocation,
  updateProxySelectMenuPosition,
  useIsSelectProxyModeOpened,
} from '../../../../state/proxy-select-menu.atom';
import {
  determineIsProxyManagerEmptyListPlaceholder,
  determineIsProxyManagerListSection,
  getIsProxyGroupAddButton,
  getIsProxyGroupHeader,
  IProxyManagerListEntity,
} from '../../../../state/proxy/proxy-groups/types';
import {
  closeProxyManager,
  getProxyManagerState,
  useProxyManagerState,
} from '../../../../state/proxy/proxy-manager-modal-status.atom';
import { handleLinkArtificialProxy, linkProfileProxy } from '../../../../state/proxy/proxy-operations/link-proxy.operations';
import { updateRecentSelectedCountriesAtom } from '../../../../state/proxy/recent-selected-proxies.atom';
import { toggleIsProxySelected, updateSelectedProxies, useSelectedProxies } from '../../../../state/proxy/selected-proxies.atom';
import { sendReactErrorToSentry } from '../../../../utils/sentry.helper';
import { sendActionAnalytics } from '../../../common/api';
import {
  ARTIFICIAL_GEOPROXY_CONTEXT_MENU_WARN_MESSAGE,
  ARTIFICIAL_GEOPROXY_CONTEXT_MENU_WARNING,
  DEFAULT_SELECTED_GEOPROXY_COUNTRY,
  GEOPROXY_TYPE_TAG,
  PROXY_COUNTRY_TAG,
  PROXY_GROUP_ID_TAG,
  PROXY_ID_TAG,
  PROXY_OBSERVED_USER_ACTIONS,
} from '../../constants';
import { generateProxyAnalyticsData, getIsProxyArchived, getIsProxyEditable, restoreProxy, selectProxiesWithShift } from '../../proxy-helpers';
import { getNoIdProxyId } from '../../utils/proxy-id';
import { EmptyProxyListPlaceholder } from './empty-proxy-list-placeholder';
import GroupedProxyItem from './grouped-proxy-item';
import ProxyGroupAddButton from './proxy-group-add-button';
import ProxyGroupHeader from './proxy-group-header';
import ProxySectionItem from './proxy-section-item';
import UngroupedProxyItem from './ungrouped-proxy-item';

type ProxyRowProps = {
  data: {
    proxyManagerEntities: (IProxy|IArchivedProxy|IProxyManagerListEntity|null)[];
    currentProxy?: IProxy;
    localProxyElementLocation: string | null;
  };
  index: number;
  style: React.CSSProperties;
}

const ProxyRow: React.FC<ProxyRowProps> = (props) => {
  const { data, index, style } = props;

  const { currentProfileId } = useProxyManagerState();
  const isSelectProxyModeOpened = useIsSelectProxyModeOpened();
  const { lastSelectedProxy, selectedProxies } = useSelectedProxies();

  const { proxyManagerEntities, currentProxy, localProxyElementLocation } = data;
  let proxyEntity = proxyManagerEntities[index];
  // to keep the last proxy visible right above the proxy-manager multi-select panel
  if (!proxyEntity) {
    return <div />;
  }

  if (getIsProxyArchived(currentProxy) && proxyEntity.id === currentProxy.id) {
    proxyEntity = currentProxy;
  }

  const areCheckboxesVisible = !!selectedProxies.length;
  const styleBase: React.CSSProperties = { ...style, width: 'auto', right: 0 };

  const handleRowClick: React.MouseEventHandler<Element> = async (event) => {

    const isArtificialProxy = handleLinkArtificialProxy(proxyEntity, 'proxy-item');
    const isTorOrFreeProxy = determineIsTorOrFreeProxy(proxyEntity);
    if (isArtificialProxy && !isTorOrFreeProxy) {
      // not closing proxy manager
      return;
    }

    let proxyToLink = proxyEntity;
    if (getIsProxyArchived(proxyEntity)) {
      proxyToLink = await restoreProxy(proxyEntity);
    }

    const { handleProxySelect } = getProxyManagerState();
    event.preventDefault();

    if (isSelectProxyModeOpened) {
      if (!event.shiftKey) {
        return toggleIsProxySelected(proxyToLink.id);
      }

      return selectProxiesWithShift({
        shiftKey: event.shiftKey,
        selectedProxies,
        proxiesToSelectFrom: proxyManagerEntities.filter(isNotNull),
        proxy: proxyToLink,
        lastSelectedProxy,
      });
    }

    if (NEW_FEATURES.proxyGroupsV2 && proxyEntity?.groupId) {
      const proxyAnalyticsData = generateProxyAnalyticsData(proxyToLink);
      sendActionAnalytics(GROUPED_PROXY_MANAGER_ANALYTICS_EVENTS.choseLocationViaBuiltInProxyClickedOnServerTypeInExpandedGroup, proxyAnalyticsData).catch(() => null);
    }

    if (handleProxySelect) {
      let proxyId = proxyEntity.id;
      if (!proxyId && determineIsProxyTruthy(proxyToLink)) {
        proxyId = getNoIdProxyId(proxyToLink);
      }

      handleProxySelect(proxyId);

      return;
    }

    if (currentProfileId) {
      const performanceObserverService = PerformanceObserverService.getInstance();
      performanceObserverService.handleUserAction({ userAction: PROXY_OBSERVED_USER_ACTIONS.linkProxyManagerProxy });

      closeProxyManager();
      linkProfileProxy({
        profileId: currentProfileId,
        proxy: proxyToLink,
      });
    }
    if (proxyToLink.country) {
      updateRecentSelectedCountriesAtom({
        countryCode: proxyToLink.country,
      });
    }
  };

  const handleContextMenu = (event: React.MouseEvent<Element>, proxyContextMenuProxyLocation: ProxyContextMenuProxyLocation): void => {
    event.preventDefault();
    const isProxyEditable = getIsProxyEditable(proxyEntity);
    if (!isProxyEditable) {
      return sendReactErrorToSentry({
        transactionName: ARTIFICIAL_GEOPROXY_CONTEXT_MENU_WARNING,
        message: ARTIFICIAL_GEOPROXY_CONTEXT_MENU_WARN_MESSAGE,
        level: 'warning',
        tags: [
          [PROXY_ID_TAG, proxyEntity?.id],
          [PROXY_GROUP_ID_TAG, proxyEntity?.groupId],
          [GEOPROXY_TYPE_TAG, proxyEntity?.connectionType],
          [PROXY_COUNTRY_TAG, proxyEntity?.country],
        ],
      });
    }

    updateSelectedProxies({ selectedProxy: proxyEntity });
    updateProxySelectMenuPosition({ x: event.pageX, y: event.pageY });
    openProxyContextMenu(proxyContextMenuProxyLocation);
  };

  const isCurrentProxy = currentProxy ? currentProxy.id === proxyEntity.id : false;

  switch (true) {
    case !NEW_FEATURES.proxyGroupsV2:
      return (
        <UngroupedProxyItem
          key={proxyEntity.id}
          ungroupedProxy={proxyEntity}
          currentProfileId={currentProfileId}
          isCurrentProxy={isCurrentProxy}
          isCheckboxVisible={areCheckboxesVisible}
          handleRowClick={handleRowClick}
          handleContextMenu={handleContextMenu}
          localProxyElementLocation={localProxyElementLocation}
          style={styleBase}
        />
      );
    case determineIsProxyManagerListSection(proxyEntity):
      return (
        <ProxySectionItem
          id={proxyEntity.id}
          title={proxyEntity.title}
          isContextMenuEnabled={proxyEntity.isContextMenuEnabled}
          style={styleBase}
        />
      );
    case determineIsProxyManagerEmptyListPlaceholder(proxyEntity):
      return (
        <EmptyProxyListPlaceholder
          localProxyElementLocation={localProxyElementLocation}
          style={styleBase}
        />
      );
    case getIsProxyGroupHeader(proxyEntity):
      return (
        <ProxyGroupHeader
          key={proxyEntity.id}
          proxyGroupHeader={proxyEntity}
          currentProfileId={currentProfileId}
          availableTypes={proxyEntity.types}
          style={styleBase}
        />
      );
    case getIsProxyGroupAddButton(proxyEntity):
      return (
        <ProxyGroupAddButton
          key={proxyEntity.id}
          country={proxyEntity.country || DEFAULT_SELECTED_GEOPROXY_COUNTRY}
          groupId={proxyEntity.groupId}
          availableTypes={proxyEntity.types}
          style={styleBase}
        />
      );
    case !!proxyEntity.groupId:
      return (
        <GroupedProxyItem
          key={proxyEntity.id}
          groupedProxy={proxyEntity}
          currentProfileId={currentProfileId}
          isCheckboxVisible={areCheckboxesVisible}
          handleRowClick={handleRowClick}
          handleContextMenu={handleContextMenu}
          style={styleBase}
          localProxyElementLocation={localProxyElementLocation}
        />
      );
    default:
      return (
        <UngroupedProxyItem
          key={proxyEntity.id}
          ungroupedProxy={proxyEntity}
          currentProfileId={currentProfileId}
          isCurrentProxy={isCurrentProxy}
          isCheckboxVisible={areCheckboxesVisible}
          handleRowClick={handleRowClick}
          handleContextMenu={handleContextMenu}
          localProxyElementLocation={localProxyElementLocation}
          style={styleBase}
        />
      );
  }
};

export default memo(ProxyRow, areEqual);
