import { E_PERIOD, IDiscountShort, IPreviewPlan, IUpgradeDiscount } from './interfaces';
import { E_DISCOUNT_TYPE } from '../../interfaces';

// export const STRIPE_API_KEY = 'pk_test_51Nzz1OCmlB0iaisIfDFv25AxwWhGpnPiCbbYKwNK9BI1Nh5FGLMgx3UR664vI7Fj5a2ExKelpKCPWQLn2Aq5mUmU00oG9qfK6J';
export const STRIPE_API_KEY = process.env.GOLOGIN_STRIPE_API_KEY;

export type BackgroundType = 'gray'|'green'|'white'|'black';
export const BACKGROUNDS: Record<BackgroundType, string> = {
  gray: 'var(--F2F2F2-new-pricing)',
  green: 'var(--00A987-new-pricing)',
  black: 'var(--36363D-new-pricing-custom)',
  white: 'var(--FFFFFF-new-pricing)',
};

export const DISCOUNT_IDS = {
  Monthly: '5f9695b11f9a50d848314702',
  Annual: '5f9695b11f9a50d848314701',
} as const;

export const DiscountIds = Object.values(DISCOUNT_IDS);
export type DiscountId = (typeof DiscountIds)[number];

export const PLAN_IDS = {
  Professional: '5e3aab2c8481ec0b30795d1f',
  Business: '5e3aab4f8481ec0b30795d20',
  Enterprise: '5e3aab7d8481ec0b30795d21',
  ForeverFree: '5e3aaad78481ec0b30795d1e',
  Custom3k: '5e79088cff111196bf9a3729',
  Easy: '5e9d494f4fe29c3c69826252',
  Custom6k: '5e79088cff111196bf9a3730',
  Custom10k: '605b395119cbecc84cc9ce0d',
  Custom2k: '5e79088cff111196bf9a3731',
  Custom6kERI: '5e79088cff111196bf9a3732',
  Custom5k: '5e79088cff111196bf9a3733',
  Custom8k: '5e79088cff111196bf9a3734',
  Custom$1: '62a8577b7c60487d6ec73c55',
  OneAppFree: '62a8577b7c60487d6ec73c56',
  Custom$2: '65e1c53eba66695317796eb2',
  Custom15k: '6686a0937bdeb8449233cd62',
  Custom20k: '6686a3e97bdeb8449233cd64',
  Custom30k: '6687d2ad7bdeb8449233cd68',
  Custom50k: '6687d32d7bdeb8449233cd6b',
  Custom100k: '6687d3df7bdeb8449233cd6',
} as const;

export const PlanIds = Object.values(PLAN_IDS);
export type PlanId = (typeof PlanIds)[number];

export const PLAN_NAMES = {
  Professional: 'Professional',
  Business: 'Business',
  Enterprise: 'Enterprise',
  ForeverFree: 'Forever Free',
  Custom3k: 'Custom 3k',
  Easy: 'Easy',
  Custom6k: 'Custom 6k',
  Custom10k: 'Custom 10k',
  Custom2k: 'Custom 2k',
  Custom6kERI: 'Custom 6k eRI',
  Custom5k: 'Custom 5k',
  Custom8k: 'Custom 8k',
  Custom$1: 'Custom $1',
  OneAppFree: 'OneApp Free',
  Custom$2: 'Custom $2',
  Custom15k: 'Custom 15k',
  Custom20k: 'Custom 20k',
  Custom30k: 'Custom 30k',
  Custom50k: 'Custom 50k',
  Custom100k: 'Custom 100k',
};

export const PlanNames = Object.values(PLAN_NAMES);
export type PlanName = (typeof PlanNames)[number];

const BASE_PLAN_OPTS: Omit<IPreviewPlan, 'id'> = {
  support: 'expert',
  background: 'white',
  buttonStyle: 'gray',
  isPopular: false,
  isCustomPlan: false,
  androidApp: true,
  fingerprintsDatabases: true,
  cloudBrowsing: true,
};

export const PREVIEW_PLANS: IPreviewPlan[] = [
  {
    ...BASE_PLAN_OPTS,
    id: PLAN_IDS.Professional,
  },
  {
    ...BASE_PLAN_OPTS,
    id: PLAN_IDS.Business,
    buttonStyle: 'green',
    isPopular: true,
  },
  {
    ...BASE_PLAN_OPTS,
    id: PLAN_IDS.Enterprise,
  },
  {
    ...BASE_PLAN_OPTS,
    id: PLAN_IDS.Custom2k,
    support: 'personal',
    background: 'black',
    isCustomPlan: true,
  },
];

type TableRowIdType = 'header'|
  'maxProfiles'|
  'maxShares'|
  'maxAccountShares'|
  'androidApp'|
  'fingerprintsDatabases'|
  'remoteInstances'|
  'cloudBrowsing'|
  'restEnabled'|
  'footer';

export type RowType = 'header'|'value'|'boolean'|'footer';

export interface IRow {
  id: TableRowIdType;
  type: RowType;
  borderWidth: string;
}

const borderWidthValue: Record<RowType, string> = {
  header: '1px 1px 0',
  value: '0px 1px',
  boolean: '0px 1px',
  footer: '0 1px 1px',
};

export const TABLE_ROWS: IRow[] = [
  {
    id: 'header',
    type: 'header',
    borderWidth: borderWidthValue.header,
  },
  {
    id: 'maxProfiles',
    type: 'value',
    borderWidth: borderWidthValue.value,
  },
  {
    id: 'maxShares',
    type: 'value',
    borderWidth: borderWidthValue.value,
  },
  {
    id: 'maxAccountShares',
    type: 'value',
    borderWidth: borderWidthValue.value,
  },
  {
    id: 'androidApp',
    type: 'boolean',
    borderWidth: borderWidthValue.boolean,
  },
  {
    id: 'fingerprintsDatabases',
    type: 'boolean',
    borderWidth: borderWidthValue.boolean,
  },
  {
    id: 'remoteInstances',
    type: 'value',
    borderWidth: borderWidthValue.value,
  },
  {
    id: 'cloudBrowsing',
    type: 'boolean',
    borderWidth: borderWidthValue.boolean,
  },
  {
    id: 'restEnabled',
    type: 'boolean',
    borderWidth: borderWidthValue.boolean,
  },
  {
    id: 'footer',
    type: 'footer',
    borderWidth: borderWidthValue.footer,
  },
];

export const REVIEW_STARS = [5, 5, 5, 5, 4, 5, 4, 5, 5, 5, 4, 5];

export const EMPTY_DISCOUNT: IDiscountShort = {
  active: false,
  discountType: E_DISCOUNT_TYPE.PERCENT,
  value: 0,
};

export const EMPTY_UPGRADE_DISCOUNT: IUpgradeDiscount = {
  active: false,
  discountType: E_DISCOUNT_TYPE.FIX,
  value: 0,
  periodType: E_PERIOD.MONTHLY,
  amountInCurrency: {
    usd: 0,
    rub: 0,
    pkr: 0,
    bdt: 0,
  },
};

export const CENTS_IN_DOLLAR = 100;
export const DOLLARS_IN_DOLLAR = 1;

