import { atom, WritableAtom } from "jotai";

export const atomWithPersistence = <T>(key: string, initialValue: T): WritableAtom<T, T> => {
  const getInitialValue = () => {
    const item = localStorage.getItem(key);

    return item ? JSON.parse(item) : initialValue;
  };


  const baseAtom = atom(getInitialValue());

  baseAtom.onMount = (setAtom) => {
    const onStorageChange = () => {
      const item = localStorage.getItem(key);
      if (item) {
        setAtom(JSON.parse(item));
      }
    };

    window.addEventListener('storage', onStorageChange);

    return () => {
      window.removeEventListener('storage', onStorageChange);
    };
  };

  const persistentAtom = atom(
    (get) => get(baseAtom),
    (get, set, update: T) => {
      set(baseAtom, update);
      localStorage.setItem(key, JSON.stringify(update));
    },
  );

  return persistentAtom;
};