import { SubmitFoldersProfilesUpdateResp } from '../../../features/folders/api';
import { getCurrentWorkspaceId } from '../../../state/current-workspace-id.atom';
import { getFoldersList } from '../../../state/folders/folders-list.atom';
import { getObjectPoolEntitiesByType } from '../../object-pool.atom';
import { handleAcceptedTransactions } from '../handle-accepted-transaction';

export const acceptManageFoldersProfiles = async (resp: SubmitFoldersProfilesUpdateResp): Promise<SubmitFoldersProfilesUpdateResp> => {
  const { workspace: workspaceId, folder: folderName, action, updatedProfiles: profileIds, foldersProfiles = [] } = resp;
  if (workspaceId !== getCurrentWorkspaceId()) {
    // TODO: send error
    return resp;
  }

  if (action === 'remove') {
    const foldersList = getFoldersList();
    const folder = foldersList.find((foldersListFolder) => foldersListFolder.name === folderName);
    if (!folder) {
      // TODO: send error
      return resp;
    }

    const { id: folderId } = folder;
    const objectPoolFoldersProfiles = getObjectPoolEntitiesByType('folder-profile');
    const entities = objectPoolFoldersProfiles.filter((folderProfile) =>
      folderProfile.folder === folderId && profileIds.includes(folderProfile.profile),
    );

    await handleAcceptedTransactions(entities.map((entity) => ({
      entity,
      entityType: 'folder-profile',
      transactionType: 'D',
    })));

    return resp;
  }

  if (!(action === 'add' && foldersProfiles.length)) {
    // TODO: send error
    return resp;
  }

  await handleAcceptedTransactions(foldersProfiles.map((folderProfile) => ({
    entityType: 'folder-profile',
    transactionType: 'P',
    entity: folderProfile,
  })));

  return resp;
};
