import css, { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled/macro';
import { Radio } from 'antd';

const PROXY_EDIT_FORM_FOOTER_HEIGHT = 44;

export const ProxyEditFormFooterWrapper = styled('footer')`
  height: ${PROXY_EDIT_FORM_FOOTER_HEIGHT}px;
  padding: 10px 20px 0;
  border-top: 1px solid var(--EBEBEB-proxy-manager-update-form);
  display: flex;
  justify-content: space-between;
`;

export const ProxyFormContent = styled('div')<{ newDesign: boolean }>`
  height: 226px;
  padding: ${props => (props.newDesign ? '12px 16px 14px 16px' : '21px 20px 0')};
  overflow-y: auto;
 
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const ProxyFormInputsBlock = styled('div')`
  padding: 8px 0;
`;

export const RadioButton = styled(Radio.Button)`
  width: 33.33%;
  height: 32px;
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  padding: 8px 0;
`;

export const ProxyFormRowEl = styled('div')`
  clear: both;
  padding: 6px 0;
  margin-bottom: 8px;
  max-height: 32px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  justify-items: flex-start;
`;

export const ProxyFormRowElOld = styled('div')`
  clear: both;
  margin-bottom: 19px;
`;

export const ProxyFormLeft = styled('div')`
  float: left;
  width: 74px;
  flex-shrink: 0;
  letter-spacing: 0.25px;
  line-height: 20px;
  font-size: 14px;

  color: var(--767676);
`;

export const ProxyFormRightOld = styled('div')`
  margin-left: 82px;
  display: flex;
  position: relative;
`;

export const ProxyFormRight = styled('div')`
  margin-left: 18px;
  display: flex;
  width: auto;
  max-width: 100%;
  position: relative;
`;

export const CheckProxyButtonWrapper = styled.div`
  position: absolute;
  left: -24px;
`;

export const ProxyFormInputEl = styled('input')<{ inputWidth?: number }>`
  border: none;
  background: none;
  outline: none;
  max-width: 100%;
  padding: 0;
  height: 21px;
  font-size: 14px;
  font-family: 'Roboto';
  font-style: normal;
  letter-spacing: 0.25px;
  line-height: 20px;
  color: var(--222222);

  ${(props): SerializedStyles => css`
    width: ${props.inputWidth || 206}px;
  `}

  &:focus {
    outline: none !important;
    box-shadow: none;
  }

  &::placeholder {
    color: var(--CDCDCD-proxy-manager-update-form);
  }
`;

export const HostPortSplitter = styled('div')`
  width: 12px;
  text-align: center;
  margin: 0px;
`;

export const FormButtonsContainer = styled('div')`
  padding-bottom: 20px;
`;

export const DeleteProxyBtn = styled('button')`
  color: var(--F5222D-proxy-manager-update-form);
  outline: none;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  background: none;
  border: none;
  padding: 0;
  display: flex;
  align-items: center;

  &:focus,
  &:visited {
    color: var(--F5222D-proxy-manager-update-form);
    outline: none;
    text-decoration: none;
  }

  &:hover {
    color: var(--00A987-proxy-manager);
    outline: none;
    text-decoration: none;
  }
`;

export const FooterBtnsContainer = styled('div')`
  width: 90px;
`;

export const FooterMidBtnsContainer = styled('div')`
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-top: -11px;
`;

export const FooterBtn = styled('button')`
  color: var(--CDCDCD-proxy-manager-update-form);
  outline: none;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  letter-spacing: 0.44px;
  margin: 0 7px;
  background: none;
  border: none;
  padding: 4px;
  width: 24px;
  height: 24px;
  text-align: center;

  &:focus,
  &:visited {
    color: var(--CDCDCD-proxy-manager-update-form);
    outline: none;
    text-decoration: none;
  }

  &:hover {
    color: var(--00A987-proxy-manager);
    outline: none;
    text-decoration: none;
  }
`;
