import { atom, getDefaultStore, useAtomValue } from 'jotai';

type LayoutHeights = {
  header: number;
}

const defaultLayoutHeights: LayoutHeights = {
  header: 72,
};

const layoutHeightsAtom = atom<LayoutHeights>(defaultLayoutHeights);

export const useLayoutHeights = (): LayoutHeights => useAtomValue(layoutHeightsAtom);

export const setLayoutHeights = (newLayoutHeights: LayoutHeights): void => getDefaultStore().set(layoutHeightsAtom, newLayoutHeights);
