import { logout } from '../../utils/logout';
import { getLastSyncDate } from '../delta-sync/last-sync-date.atom';
import { performGlobalDeltaSync } from '../delta-sync/perform-global-delta-sync';
import { getCacheDBName } from '../idb-cache/cache-db-name.atom';
import { updateDBMetaLastSync } from '../idb-cache/meta-db';

// TODO: replace console.error with Sentry
export const performRemoteBootstrap = async (): Promise<void> => {
  try {
    await performGlobalDeltaSync();

    const lastSync = getLastSyncDate();
    const cacheDBName = getCacheDBName();
    if (!(lastSync && cacheDBName)) {
      console.error({
        message: 'remote bootstrap save failed to save last sync date',
        lastSync,
        cacheDBName,
      });

      return;
    }

    updateDBMetaLastSync(cacheDBName, { lastSyncedTimestamp: +lastSync })
      .catch(console.error);
  } catch (err) {
    console.error(err);

    return logout();
  }
};
