import { GeoProxyType } from '../../common/constants/types';
import { IProxyCredentialsNew } from '../features/proxy/components/interfaces/proxy-credentials-new.interface';
import { ITableProxyConnectedProfile } from '../state/proxies.context/api';

export const PROXY_MODES = <const>{
  geolocation: 'geolocation',
  http: 'http',
  socks4: 'socks4',
  socks5: 'socks5',
  possh: 'possh',
  tor: 'tor',
  gologin: 'gologin',
  none: 'none',
};

export const PROXY_MODES_LIST = Object.values(PROXY_MODES);

export type ProxyMode = typeof PROXY_MODES_LIST[number];

export type FreeProxyRegion = 'us'|'ca'|'uk'|'de'|'in';
export type TorProxyRegion = 'us'|'uk'|'de'|'fr'|'eu';

export interface IProxy extends IProxyCredentialsNew {
  id: string;
  createdAt: Date;
  profilesCount: number;
  customName?: string;
  autoProxyRegion?: FreeProxyRegion;
  torProxyRegion?: TorProxyRegion;
  region?: string;
  country?: string;
  timezone?: string;
  city?: string;
  status?: boolean;
  checkDate?: Date;
  error?: string;
  origin?: string;
  languages?: string;
  isInvisible?: boolean;
  customSortingInGroup?: 'start' | 'end';
  notes?: string;
  connectedProfiles?: ITableProxyConnectedProfile[];
  connectionType?: GeoProxyType;
  isRestoredByOwner?: boolean;
  user?: string;
}

export type ProxyStatusForChecker = {
  id: string;
  status?: boolean;
  checkDate?: Date;
}

export interface IArchivedProxy extends IProxy {
  name: string;
  isArchivedProxy?: boolean;
}

export interface IArchivedProxyInBrowser {
  id: string;
  name: string;
  country: string;
}

export type MouseEnteredState = {
  [key: string]: boolean;
}