import React, {
  FC,
  memo,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { Trans } from 'react-i18next';
import { useLocation, useRouteMatch } from 'react-router-dom';

import ArchiveProxiesHeaderText from './archive-proxies/archive-proxies-header-text';
import { HeaderContainer } from './container';
import { ContentContainer } from './content-container';
import FolderItem from './folder-item';
import FoldersMenu from './folders-menu';
import HeaderRightPart from './header-right-part';
import { IconArrowBack, IconMeatballs } from './icons';
import Search from './search';
import {
  BackText,
  ContainerBack,
  ContainerFolders,
  SearchAndFoldersWrapper,
  SearchWrapper,
} from './styles';
import {
  PROXY_PAGE_ANALYTICS_ACTION_INFO_DEFAULT,
  ProxyPageAnalyticsEvent,
} from '../../../common/constants/analytics';
import {
  PROFILE_LIST_PAGE,
  PROXY_PAGE,
} from '../../../common/constants/routes';
import { sendActionAnalytics } from '../../features/common/api';
import ShareFolderModal from '../../features/folders/components/share-folder-modal';
import InviteMembersModal from '../../features/modalsComponents/components/invite-members';
import { IFolder } from '../../interfaces';
import { performGlobalDeltaSync } from '../../object-pool/delta-sync/perform-global-delta-sync';
import { history } from '../../services';
import { resetToDefaultSearchState, userContext } from '../../state';
import { NEW_FEATURES } from '../../state/feature-toggle/new-features';
import { setLayoutHeights, useLayoutHeights } from '../../state/layout-heights.atom';
import { resetProfilesTable } from '../../state/profiles-list.atom';
import { forceReloadProfilesQuery } from '../../state/profiles-table/profiles-query';
import { resetProxiesTable } from '../../state/proxy/proxy-table/proxies-table-list.atom';
import {
  getSelectedProxyFolder,
  setSelectedProxyFolder,
  useIsArchiveProxiesFolderSelected,
  useProxyFolders,
} from '../../state/proxy/proxy-table/proxy-folders.atom';
import { setSelectedFolderId } from '../../state/selected-folder.atom';

const HeaderNew: FC = () => {
  const proxyFolders = useProxyFolders();
  const isArchiveProxiesFolderSelected = useIsArchiveProxiesFolderSelected();
  const layoutHeights = useLayoutHeights();

  const [
    anchorElFolders,
    setAnchorElFolders,
  ] = React.useState<HTMLElement | null>(null);

  const [hasBackHeader, setHasBackHeader] = useState<boolean>(false);
  const [shareFolderVisible, setShareFolderVisible] = useState<boolean>(false);
  const [folderToShare, setFolderToShare] = useState<IFolder | null>(null);

  const headerRef = useRef<HTMLDivElement>(null);
  const containerFoldersRef = useRef<HTMLDivElement | null>(null);

  const { folders, updateSelectedFolder } = useContext(userContext);

  const location = useLocation();
  const isProxyPage = location.pathname === PROXY_PAGE;

  useEffect(() => {
    const newHasBackHeader = location.pathname !== PROFILE_LIST_PAGE;
    setHasBackHeader(newHasBackHeader);
  }, [location.pathname]);

  const transformScroll = (event: WheelEvent): void => {
    if (!event.deltaY) {
      return;
    }

    let isTrackpad = false;
    if (event.wheelDeltaY) {
      if (event.wheelDeltaY === event.deltaY * -3) {
        isTrackpad = true;
      }
    } else if (event.deltaMode === 0) {
      isTrackpad = true;
    }

    const fullPercent = 100;
    const speed = 80;
    if (!isTrackpad && event.currentTarget) {
      event.currentTarget.scrollLeft +=
        (event.deltaY + event.deltaX) / (fullPercent / speed);
      event.preventDefault();
    }
  };

  useEffect(() => {
    if (!(containerFoldersRef && containerFoldersRef.current)) {
      return;
    }

    containerFoldersRef.current.addEventListener('wheel', transformScroll);

    return () => {
      containerFoldersRef.current?.removeEventListener(
        'wheel',
        transformScroll,
      );
    };
  }, []);

  const changeSelectedFolder = (selectedFolder: IFolder): void => {
    const folderNameToSave = selectedFolder.systemFolder
      ? ''
      : selectedFolder.name;

    const folderId = !selectedFolder.systemFolder ? selectedFolder.id : null;

    localStorage.setItem('SelectedFolder', folderNameToSave);
    updateSelectedFolder(folderNameToSave);
    resetProfilesTable();
    setSelectedFolderId(folderId);

    if (NEW_FEATURES.objectPool) {
      const isAllProfiles = selectedFolder.systemFolder && selectedFolder.name === 'allProfiles';
      if (isAllProfiles) {
        performGlobalDeltaSync();
      }

      forceReloadProfilesQuery();
    } else {
      history.replace('/');
    }
  };

  const changeProxySelectedFolder = (folderSelected: IFolder): void => {
    const currentProxyFolder = getSelectedProxyFolder();
    resetProxiesTable();
    if (currentProxyFolder === folderSelected.name) {
      // to refresh proxy table list when current folder is selected
      return history.push(`${PROXY_PAGE}?r`);
    }

    setSelectedProxyFolder(folderSelected.name);
  };

  const renderBackHeader = (): JSX.Element => (
    <ContainerBack
      onClick={(): void => {
        if (isProxyPage) {
          sendActionAnalytics(ProxyPageAnalyticsEvent.clickedBackButton, {
            actionInfo: PROXY_PAGE_ANALYTICS_ACTION_INFO_DEFAULT,
          });
        }

        setHasBackHeader(false);
        resetToDefaultSearchState();
        history.replace('/');
      }}
      style={{ display: !hasBackHeader ? 'none' : 'flex' }}
    >
      <IconArrowBack
        styleType="black"
        iconColor="var(--81818A-header)"
        iconHoveredColor="var(--767676-header)"
      />
      {isProxyPage ? null : (
        <BackText>
          <Trans i18nKey="base.backToAllProfile" />
        </BackText>
      )}
    </ContainerBack>
  );

  const onCloseShare = (): void => {
    setShareFolderVisible(false);
  };

  const foldersList = isProxyPage
    ? proxyFolders
    : [{ name: 'allProfiles', systemFolder: true }, ...folders];

  const isSearchShown = [PROFILE_LIST_PAGE, PROXY_PAGE].includes(
    history.location.pathname,
  );

  const shouldShowArchiveProxiesFolderText =
    isProxyPage && isArchiveProxiesFolderSelected;

  const isProfileListPage = !!useRouteMatch(PROFILE_LIST_PAGE);

  useLayoutEffect(() => {
    const measureHeight = (): void => {
      if (headerRef.current) {
        setLayoutHeights({ ...layoutHeights, header: headerRef.current.getBoundingClientRect().height });
      }
    };

    setTimeout(measureHeight, 0);
  }, [isProfileListPage]);

  return (
    <>
      <HeaderContainer
        ref={headerRef}
        isSticky={isProfileListPage}
        id="main-header"
      >
        <ContentContainer>
          {renderBackHeader()}
          <SearchAndFoldersWrapper
            isBackButtonHidden={!!(hasBackHeader && !isProxyPage)}
            shouldMatchProfilesHeaderPosition={isProxyPage}
          >
            <SearchWrapper isProxyPage={isProxyPage}>
              <Search
                isSearchShown={isSearchShown}
                entityType={isProxyPage ? 'proxies' : 'profiles'}
              />
            </SearchWrapper>
            <ContainerFolders ref={containerFoldersRef}>
              {foldersList.map((item) => (
                <FolderItem
                  key={item.name}
                  item={item}
                  changeSelectedFolder={changeSelectedFolder}
                  changeProxySelectedFolder={changeProxySelectedFolder}
                />
              ))}
            </ContainerFolders>
            {isProxyPage ? null : (
              <IconMeatballs
                styleType={
                  anchorElFolders
                    ? 'grayWithBackground'
                    : 'activeGrayWithBackground'
                }
                margin="0 0 0 8px"
                onClick={(event): void =>
                  setAnchorElFolders(event.currentTarget)}
                iconColor={
                  anchorElFolders
                    ? 'var(--2B2B31-header)'
                    : 'var(--98989F-header)'
                }
                highlight={!!anchorElFolders}
                iconHoveredColor="var(--2B2B31-header)"
                hoveredHighlight={true}
              />
            )}
          </SearchAndFoldersWrapper>
          <HeaderRightPart />
        </ContentContainer>
      </HeaderContainer>
      <FoldersMenu
        anchorElFolders={anchorElFolders}
        setAnchorElFolders={setAnchorElFolders}
        onClick={changeSelectedFolder}
        setFolderToShare={setFolderToShare}
        setShareFolderVisible={setShareFolderVisible}
      />
      {!NEW_FEATURES.workspaceInviteInFolders &&
      shareFolderVisible &&
      !!folderToShare?.id ? (
        <ShareFolderModal
            shareFolderVisible={shareFolderVisible}
            shareFolder={folderToShare.name}
            foldersIdsToShare={[folderToShare.id]}
            onClose={onCloseShare}
          />
        ) : null}
      {NEW_FEATURES.workspaceInviteInFolders &&
      shareFolderVisible &&
      !!folderToShare ? (
        <InviteMembersModal
            visible={shareFolderVisible}
            onClose={onCloseShare}
            initWithFolderId={folderToShare.id}
          />
        ) : null}
      {shouldShowArchiveProxiesFolderText ? <ArchiveProxiesHeaderText /> : null}
    </>
  );
};

export default memo(HeaderNew);
