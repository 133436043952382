import css, { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled';

export const ProxySelectorWrapper = styled.div<{ isAppearedAboveBackdrop: boolean }>`
  height: 100%;
  width: 100%;
  padding-right: 32px;
  margin-left: 24px;

  position: relative;

  cursor: pointer;

  z-index: 122;
`;
