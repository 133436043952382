import { atom, getDefaultStore, useAtomValue } from 'jotai';

type AffiliateProgramOpts = {
  affLinkDomain: string;
}

const AFFILIATE_PROGRAM_OPTS: AffiliateProgramOpts = {
  affLinkDomain: 'gologin.com/join',
};

const affiliateProgramOptsAtom = atom<AffiliateProgramOpts>(AFFILIATE_PROGRAM_OPTS);

export const useAffiliateProgramOpts = (): AffiliateProgramOpts => useAtomValue(affiliateProgramOptsAtom);

export const setAffiliateProgramDomain = (affLinkDomain: string): void => {
  const oldAffiliateProgramOpts = getDefaultStore().get(affiliateProgramOptsAtom);
  getDefaultStore().set(affiliateProgramOptsAtom, { ...oldAffiliateProgramOpts, affLinkDomain });
};
