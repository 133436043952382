import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconPinpoint: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M13.5 6C13.5 10.5 8 15.5 8 15.5C8 15.5 2.5 10.5 2.5 6C2.5 4.54131 3.07946 3.14236 4.11091 2.11091C5.14236 1.07946 6.54131 0.5 8 0.5C9.45869 0.5 10.8576 1.07946 11.8891 2.11091C12.9205 3.14236 13.5 4.54131 13.5 6Z'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8 8.5C9.38071 8.5 10.5 7.38071 10.5 6C10.5 4.61929 9.38071 3.5 8 3.5C6.61929 3.5 5.5 4.61929 5.5 6C5.5 7.38071 6.61929 8.5 8 8.5Z'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  </IconWrapper>
);
