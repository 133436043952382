export type Limits = {
  maxMembers?: number;
  maxProfiles?: number;
  maxProfileShares?: number;
  maxCloudLaunches?: number;
}

export type Addon = {
  limits?: Limits;
};

export const EMPTY_LIMITS: Limits = {
  maxMembers: 0,
  maxProfiles: 0,
  maxProfileShares: 0,
  maxCloudLaunches: 0,
} as const;

export type PricingChosenLimits = {
  planId: string;
  discountId: string;
  limits: Limits;
};
