import { getObjectPool, setObjectPool } from './object-pool.atom';
import type { ObjectPoolEntity, ObjectPoolProfile } from './types';
import { IProfile } from '../interfaces';
import { NEW_FEATURES } from '../state/feature-toggle/new-features';

export const reconcileProfilesListIntoObjectPool = (newProfilesList: IProfile[]): void => {
  if (!NEW_FEATURES.objectPool) {
    return;
  }

  const objectPool = getObjectPool();
  const updatedProfileIds = new Set<string>();
  const newObjectPool = objectPool.reduce<ObjectPoolEntity[]>((acc, objectPoolEntity) => {
    if (objectPoolEntity.objectPoolEntityType !== 'profile') {
      acc.push(objectPoolEntity);

      return acc;
    }

    const profile = newProfilesList.find(({ id }) => objectPoolEntity.id === id);
    if (!profile) {
      // we keep those profiles, which aren't in the list due to resolvers
      acc.push(objectPoolEntity);

      return acc;
    }

    acc.push(reconcileProfileToObjectPool(profile, objectPoolEntity));
    updatedProfileIds.add(profile.id);

    return acc;
  }, []);

  const newlyAddedObjectPoolProfiles = newProfilesList.reduce<ObjectPoolProfile[]>((acc, profile) => {
    if (!updatedProfileIds.has(profile.id)) {
      acc.push(reconcileProfileToObjectPool(profile, null));
    }

    return acc;
  }, []);

  setObjectPool(newObjectPool.concat(newlyAddedObjectPoolProfiles));
};

const reconcileProfileToObjectPool = (profile: IProfile, objectPoolProfile: ObjectPoolProfile | null): ObjectPoolProfile => {
  const newTagIds = profile.tags.map(({ id }) => id);

  return {
    ...objectPoolProfile,
    ...profile,
    tagIds: newTagIds,
    objectPoolEntityType: 'profile',
  };
};
