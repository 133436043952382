import * as Sentry from '@sentry/react';

import { ITemplatesCtx } from '../../../state';
import { getCurrentWorkspaceId } from '../../../state/current-workspace-id.atom';
import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import { getFolderById } from '../../../state/folders/folders-list.atom';
import { getLatestLocalOrbitaVersion } from '../../../state/latest-local-orbita-version.atom';
import { getLocalUserDevice } from '../../../state/local-user-device.atom';
import { upsertProfileRunStatuses } from '../../../state/profile-run-statuses.atom';
import { getProfilesList, getProfilesTableGroupHeaders, mapAndSetProfilesList } from '../../../state/profiles-list.atom';
import { getBasicTableEntityById, getBasicTableProfileIds } from '../../../state/profiles-table/basic-table-entities.atom';
import { getProfilesTableGroupField } from '../../../state/profiles-table/profiles-table-group-field.atom';
import { setProfilesTableEditingProfileName } from '../../../state/profiles-table-editing-profile-name.atom';
import { callProfilesTableRowMeasurer } from '../../../state/profiles-table-row-measurers.atom';
import { getTagById } from '../../../state/tags/tags-list.atom';
import { updateTemplateProfileName } from '../../../utils/parse-name-formatting';
import { getParsedName } from '../../../utils/parse-name-formatting/get-parsed-name';
import { getUserScreenResolution } from '../../../utils/user-hardware.utils';
import { getUserOS } from '../../../utils/user-os';
import { randomWords } from '../../common/randomizer';
import { addTagRequest } from '../../tags/api';
import { quickCreateBrowser } from '../api';

export interface ICreateQuickProfileOpts {
  workspaceId: string;
  templateCtx: ITemplatesCtx;
  folderName: string;
  customStatusId?: string | null;
}

const isElectron = !!window.require;
let ipcRenderer: Electron.IpcRenderer;
if (isElectron) {
  ({ ipcRenderer } = window.require('electron'));
}

export const quickCreateProfile = async (templateCtx: ITemplatesCtx): Promise<void> => {
  const selectedFolderName = localStorage.getItem('SelectedFolder') || '';
  const opts: ICreateQuickProfileOpts = {
    workspaceId: getCurrentWorkspaceId(),
    templateCtx,
    folderName: selectedFolderName,
  };

  const groupField = getProfilesTableGroupField();
  const [groupHeader] = getProfilesTableGroupHeaders();
  if (groupField && groupHeader) {
    const { filter: groupFilter } = groupHeader;
    switch (groupFilter.type) {
      case 'custom-status':
        opts.customStatusId = groupFilter.customStatusId;
        break;
      case 'folder': {
        const { folderId } = groupFilter;
        const { name: folderName } = getFolderById(folderId) || {};
        if (folderName) {
          opts.folderName = folderName;
        }

        break;
      }
      default:
        break;
    }
  }

  await createQuickProfile(opts);

  isElectron && ipcRenderer.invoke('check-profiles');
};

export const createQuickProfile = async (opts: ICreateQuickProfileOpts): Promise<void> => {
  const {
    workspaceId,
    templateCtx,
    folderName,
    customStatusId,
  } = opts;

  const profilesLoaded = getProfilesList().length;
  const transaction = Sentry.startTransaction({ name: 'create-quick-profile' });
  transaction.setTag('loaded-profiles-count', profilesLoaded);
  const prepareSpan = transaction.startChild({ op: 'prepare-data' });

  const { dropFiles, singleProfileQuick = '' } = templateCtx.selectedTemplate.profileName;
  let name = getParsedName(dropFiles, singleProfileQuick);
  if (!name) {
    name = randomWords();
  }

  const userOs = await getUserOS();
  const needToUpdateTemplateProfileName = NEW_FEATURES.templateProfileName && dropFiles;
  if (needToUpdateTemplateProfileName) {
    updateTemplateProfileName(dropFiles, 1, templateCtx);
  }

  prepareSpan.finish();
  const requestSpan = transaction.startChild({ op: 'http', description: 'create-profile-request' });

  const localUserDeviceInfo = getLocalUserDevice();
  const { screen } = localUserDeviceInfo;
  const userScreenResolution = getUserScreenResolution(screen);

  const userLatestOrbitaVersion = getLatestLocalOrbitaVersion();
  const newProfile = await quickCreateBrowser({
    name,
    os: userOs.name,
    osSpec: userOs.spec,
    workspaceId,
    template: templateCtx.selectedTemplate.id,
    folderName,
    userLatestOrbitaVersion,
    resolution: userScreenResolution,
  });

  if (customStatusId) {
    const customStatus = getTagById(customStatusId);
    if (customStatus) {
      const newTags = newProfile.tags.filter((tag) => tag.field !== 'custom-status');
      newTags.push(customStatus);
      newProfile.tags = newTags;

      addTagRequest({ browserIds: [newProfile.id], workspace: workspaceId, ...customStatus });
    }
  }

  requestSpan.finish();
  const updateSpan = transaction.startChild({ op: 'ui', description: 'update-profiles-list' });

  upsertProfileRunStatuses([{ id: newProfile.id, status: 'profileStatuses.ready' }]);
  mapAndSetProfilesList(profiles => {
    const newProfilesList = [...profiles];
    const countPinnedProfiles = newProfilesList.filter(profile => profile.isPinned).length;
    newProfilesList.splice(countPinnedProfiles, 0, newProfile);

    return newProfilesList;
  });

  updateSpan.finish();
  const activateNameEditSpan = transaction.startChild({ op: 'ui', description: 'activate-name-edit' });
  const createdBasicTableProfile = getBasicTableEntityById(newProfile.id);
  if (createdBasicTableProfile) {
    setProfilesTableEditingProfileName(createdBasicTableProfile.idx);
  }

  activateNameEditSpan.finish();

  const profileIds = getBasicTableProfileIds();
  profileIds.forEach(profileId => callProfilesTableRowMeasurer(profileId));

  transaction.finish();
};
