import { CheckoutOpenOptions } from '@paddle/paddle-js';

import {
  IGetSigmaPaymentLink,
  IPricingDataProps,
  IPromoDiscount,
  IRawPricingData,
  IRawPricingDataByTempToken,
  ISendPaymentIntentAnalytics,
  IPricingDataByTempTokenProps,
  TCurrency,
  IPostPaymentIntentResult,
  ICheckPaymentIntentResult,
  IPostPaymentIntent,
  ICheckPaypalOrder,
  ICheckPaypalOrderResult,
  IGetSigmaPaymentLinkParams,
  PaymentMethod,
} from './interfaces';
import { API_BASE_URL } from '../../../common/constants/constants';
import { Addon, Limits } from '../../interfaces/workspaces/limits';
import { http } from '../../services';
import { sendUserEventToServer } from '../../utils/send-analytics-to-server';
import { sendReactAnalyticsError } from '../../utils/sentry.helper';

export const requestPromoDiscount = (body: { workspaceId: string }): Promise<IPromoDiscount> =>
  http(`${API_BASE_URL}/user/promo_discount`, {
    method: 'POST',
    body: JSON.stringify(body),
  }).then((res: any) => res.body);

export const postPaymentIntent = (opts: IPostPaymentIntent): Promise<ICheckPaymentIntentResult> =>
  http(`${API_BASE_URL}/billing/stripe/payment-intents`, {
    method: 'POST',
    body: JSON.stringify(opts),
  }).then((data: any) => data.body);

export const createIPayTotalPayment = async (body: any) => {
  try {
    const data = await http(`${API_BASE_URL}/billing/create_ipaytotal_payment`, {
      method: 'POST',
      body: JSON.stringify(body),
    });

    return data.body;
  } catch (error) {
    console.log('Error:', error);
    throw error;
  }
};

export const checkIPayTotalTransaction = async (transaction: string) => {
  try {
    const data = await http(`${API_BASE_URL}/billing/check_ipaytotal_transaction`, {
      method: 'POST',
      body: JSON.stringify({ transaction }),
    });

    return data.body;
  } catch (error) {
    console.log('Error:', error);
    throw error;
  }
};

export const updateIPayTotalTransaction = async (transaction: string, apiResponse: any) => {
  try {
    const data = await http(`${API_BASE_URL}/billing/update_ipaytotal_transaction`, {
      method: 'POST',
      body: JSON.stringify({ transaction, apiResponse }),
    });

    return data.body;
  } catch (error) {
    console.log('Error:', error);
    throw error;
  }
};

export const postPaymentIntentResult = async (body: IPostPaymentIntentResult): Promise<ICheckPaymentIntentResult> => {
  const { paymentIntentId = '' } = body;
  try {
    const data = await http(`${API_BASE_URL}/billing/stripe/payment-intents/${paymentIntentId}/check`, {
      method: 'POST',
      body: JSON.stringify(body),
    });

    return data.body;
  } catch (error) {
    console.log('Error:', error);
    throw error;
  }
};

export const changePlan = async (planId: string, workspaceId?: string) =>
  http(`${API_BASE_URL}/billing/plan`, {
    method: 'POST',
    body: JSON.stringify({
      planId,
      workspaceId,
    }),
  });

export const upgradePlan = async (planId: string, discountId: string, workspaceId: string): Promise<IPlanUpgradeResponse> =>
  http(`${API_BASE_URL}/billing/plan/upgrade`, {
    method: 'POST',
    body: JSON.stringify({
      planId,
      discountId,
      workspaceId,
    }),
  }).then((res: any) => res.body);

export const createPaypalSubIntent = async (planId: string, customId: string[]): Promise<{ paymentLink: string }> =>
  http(`${API_BASE_URL}/billing/paypal/subscriptions/payment-intents`, {
    method: 'POST',
    body: JSON.stringify({
      planId,
      customId,
    }),
  }).then((res: any) => res.body);

export const checkPaypalOrder = async (opts: ICheckPaypalOrder): Promise<ICheckPaypalOrderResult> =>
  http(`${API_BASE_URL}/billing/check_paypal_order`, {
    method: 'POST',
    body: JSON.stringify(opts),
  });

export const checkAffiliateProgram = async (affiliateCookieValue: string): Promise<void> =>
  http(`${API_BASE_URL}/user/affiliate_check`, {
    method: 'POST',
    body: JSON.stringify({ affiliateCookieValue }),
  });

export const fetchUserVisitedPageAnalytics = (): Promise<void> => sendUserEventToServer('visitedPaymentPage');

export const sendPaymentIntentAnalytics = (options: ISendPaymentIntentAnalytics) => http(`${API_BASE_URL}/analytics/payment_intent_analytics`, {
  method: 'POST',
  body: JSON.stringify(options),
}).catch(sendReactAnalyticsError);

export const postSelectedTrialPlan = (planId: string) => http(`${API_BASE_URL}/billing/trial_plan`, {
  method: 'POST',
  body: JSON.stringify({
    planId,
  }),
});

export interface IGetAutoLoginToken {
  auto_login_token: string;
}

export const getAutoLoginToken = (): Promise<IGetAutoLoginToken> =>
  http(`${API_BASE_URL}/user/auto-login`, {
    method: 'GET',
  }).then((res: any) => res.body);

export const getSigmaPaymentLink = (opts: IGetSigmaPaymentLinkParams): Promise<IGetSigmaPaymentLink> =>
  http(`${API_BASE_URL}/billing/sigma/payment-sessions`, {
    method: 'POST',
    body: JSON.stringify(opts),
  }).then((res: any) => res.body);

export const sendError = (errorInfo: any): any =>
  http(`${API_BASE_URL}/billing/payment_errors`, {
    method: 'POST',
    body: JSON.stringify(errorInfo),
  })
    .catch(() => { });

export interface IWorkspaceInfo {
  id: string;
  name: string;
  owner: string;
  planId: string;
  planName: string;
  planMembersMax: number;
  planProfilesMax: number;
  profilesCount: number;
  planExpiresAt: Date;
  paymentIsTrial?: boolean;
  activeSharesCount: number;
  paymentDiscount: string;

  isUnpaid?: boolean;
  isPlanUnlimited: boolean;
}

export const getWorkspaceInfo = (workspaceId: string): Promise<IWorkspaceInfo> =>
  http(`${API_BASE_URL}/workspaces/${workspaceId}`, {
    method: 'GET',
  }).then((res: any) => res.body);

export interface IGetSessionUrl {
  url: string;
}

export interface IGetSessionUrlParams {
  plan: string;
  discount: string;
  workspaceId: string | undefined;
  currency?: TCurrency;
  isUpgrade?: boolean;
  addon?: Addon;
}

export interface IGetStripeSessionUrlParams extends IGetSessionUrlParams {
  gaSession: string;
}

export const createCheckoutSession = (createStripeSessionParams: IGetStripeSessionUrlParams): Promise<IGetSessionUrl> =>
  http(`${API_BASE_URL}/billing/stripe/payment-sessions`, {
    method: 'POST',
    body: JSON.stringify(createStripeSessionParams),
  }).then((res: any) => res.body);

export type LimitsPaymentSessionParams = {
  workspaceId: string;
  planId: string;
  currency: TCurrency;
  paymentMethod: PaymentMethod;
  addon: Addon;
}

export const createLimitsPurchaseSession = (createLimitsPurchaseParams: LimitsPaymentSessionParams): Promise<IGetSessionUrl> =>
  http(`${API_BASE_URL}/billing/payment-sessions/addons`, {
    method: 'POST',
    body: JSON.stringify(createLimitsPurchaseParams),
  }).then((res: any) => res.body);

export interface ICheckSession {
    userId: string;
    amount: number;
    paymentId: string;
    planName: string;
    planId: string;
  }

export const checkStripeSession = (sessionId: string): Promise<ICheckSession> =>
  http(`${API_BASE_URL}/billing/stripe/payment-sessions/${sessionId}/check`, {
    method: 'POST',
    body: JSON.stringify({
      sessionId,
    }),
  }).then((res: any) => res.body);

export const requestPricingData = async ({
  workspaceId,
}: IPricingDataProps): Promise<IRawPricingData> =>
  http(`${API_BASE_URL}/billing/pricing-data`, {
    method: 'POST',
    body: JSON.stringify({
      workspaceId,
    }),
  }).then((res: any) => res.body);

export const requestPricingDataByTempToken = async ({
  autoLoginToken,
  workspaceId,
  accessToken,
}: IPricingDataByTempTokenProps): Promise<IRawPricingDataByTempToken> => {
  const data = await fetch(`${API_BASE_URL}/billing/pricing-data-by-temp-token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${autoLoginToken}`,
    },
    body: JSON.stringify({
      autoLoginToken,
      workspaceId,
      accessToken,
    }),
  });

  return data.json();
};

export const checkCryptomusOrder = (orderId: string): Promise<ICheckSession> =>
  http(`${API_BASE_URL}/billing/cryptomus/payment-sessions/${orderId}/check`, {
    method: 'POST',
    body: JSON.stringify({
      orderId,
    }),
  }).then((res: any) => res.body);

export const createCryptomusSession = (createCryptomusSessionParams: IGetSessionUrlParams): Promise<IGetSessionUrl> =>
  http(`${API_BASE_URL}/billing/cryptomus/payment-sessions`, {
    method: 'POST',
    body: JSON.stringify(createCryptomusSessionParams),
  }).then((res: any) => res.body);

export const checkPaypalSubscription = (subscriptionId: string): Promise<{ paymentId: string }> =>
  http(`${API_BASE_URL}/billing/paypal/subscriptions/${subscriptionId}/check`, {
    method: 'POST',
    body: JSON.stringify({
      subscriptionId,
    }),
  });

export const createPaddleSession = (createPaddleSessionParams: IGetSessionUrlParams): Promise<CheckoutOpenOptions> =>
  http(`${API_BASE_URL}/billing/paddle/payment-sessions`, {
    method: 'POST',
    body: JSON.stringify(createPaddleSessionParams),
  }).then((res: any) => res.body);
