import { atom, getDefaultStore, useAtomValue } from "jotai";
import { RECENT_SELECTED_COUNTRIES_KEY_NAME } from '../../../common/constants/constants';

type RecentCountry = {
  countryCode: string;
}

const recentSelectedCountriesAtom = atom<RecentCountry[]>([]);

const loadRecentSelectedCountries = (): RecentCountry[] => {
  const storedCountries = localStorage.getItem(RECENT_SELECTED_COUNTRIES_KEY_NAME);
  return storedCountries ? JSON.parse(storedCountries) : [];
};

const initialRecentSelectedCountries = loadRecentSelectedCountries();
getDefaultStore().set(recentSelectedCountriesAtom, initialRecentSelectedCountries);

export const setRecentSelectedCountriesAtom = (countries: RecentCountry[]): void => {
  getDefaultStore().set(recentSelectedCountriesAtom, countries);
  localStorage.setItem(RECENT_SELECTED_COUNTRIES_KEY_NAME, JSON.stringify(countries));
};

export const getRecentSelectedCountriesAtom = (): RecentCountry[] => getDefaultStore().get(recentSelectedCountriesAtom);
export const useRecentSelectedCountriesAtom = (): RecentCountry[] => useAtomValue(recentSelectedCountriesAtom);

export const updateRecentSelectedCountriesAtom = (newCountry: RecentCountry): void => {
  const currentCountries = getDefaultStore().get(recentSelectedCountriesAtom);
  const filteredCountriesFromDuplicates = currentCountries.filter(country => country.countryCode !== newCountry.countryCode);

  const updatedCountries = [newCountry, ...filteredCountriesFromDuplicates.slice(0, 2)];
  setRecentSelectedCountriesAtom(updatedCountries as RecentCountry[]);
};
