import { API_BASE_URL } from '../../../common/constants/constants';
import { IWorkspaceFolder } from '../../interfaces/workspaces';
import { ObjectPoolFolderProfile } from '../../object-pool/types';
import { http } from '../../services';

export const requestFolderInvites = async (folderId: string) => {
  try {
    const data = await http(`${API_BASE_URL}/share/folder/${folderId}`)

    return data.body;
  } catch (error) {
    throw error;
  }
}

export const updateInviteFolderRole = async (inviteId: string, role: 'administrator' | 'redactor' | 'guest') => {
  const params = JSON.stringify({
    role,
  });

  try {
    await http(`${API_BASE_URL}/share/folder/${inviteId}/role`, {
      method: 'PATCH',
      body: params,
    });
  } catch (error) {
    throw error;
  }
}

export const shareFolder = async (folderIds: [string], role: 'administrator' | 'redactor' | 'guest', email: string) => {
  const params = JSON.stringify({
    recepient: email,
    role,
    folderIds,
  });

  try {
    await http(`${API_BASE_URL}/share/folder`, {
      method: 'POST',
      body: params,
    });
  } catch (error) {
    throw error;
  }
}

export const removeFolderInvite = async (inviteId: string): Promise<void> => {
  try {
    await http(`${API_BASE_URL}/share/folder/${inviteId}`, {
      method: 'DELETE',
    });
  } catch (error) {
    throw error;
  }
}

export const getFoldersList = async () => {
  try {
    const data = await http(`${API_BASE_URL}/folders`);

    return data.body;
  } catch (error) {
    throw error;
  }
}

export const getProfileFolders = async (profileId: string) => {
  try {
    const data = await http(`${API_BASE_URL}/folders/profile?profileId=${profileId}`);

    return data.body;
  } catch (error) {
    throw error;
  }
}

export const addFolder = async (name: string, associatedProfiles: string[]) => {
  try {
    const data = await http(`${API_BASE_URL}/folders/folder`, {
      method: 'POST',
      body: JSON.stringify({
        name,
        associatedProfiles,
      }),
    });

    return data.body
  } catch (error) {
    throw error;
  }
}

export const createWorkspaceFolder = (workspaceId: string, name: string): Promise<IWorkspaceFolder> =>
  http(`${API_BASE_URL}/workspaces/${workspaceId}/folders`, {
    method: 'POST',
    body: JSON.stringify({
      name,
    }),
  }).then((res: any) => res.body);

export const renameFolder = async (name: string, newName: string): Promise<any> => {
  try {
    const data = await http(`${API_BASE_URL}/folders/folder/rename`, {
      method: 'PATCH',
      body: JSON.stringify({
        name,
        newName,
      }),
    });

    return data.body;
  } catch (error) {
    throw error;
  }
}

export const renameWorkspaceFolder = (workspaceId: string, oldName: string, newName: string): Promise<IWorkspaceFolder> =>
  http(`${API_BASE_URL}/workspaces/${workspaceId}/folders/rename`, {
    method: 'PATCH',
    body: JSON.stringify({
      oldName,
      newName,
    }),
  }).then((res: any) => res.body);

export const removeFolder = async (name: string) => {
  try {
    await http(`${API_BASE_URL}/folders/folder?name=${encodeURIComponent(name)}`, {
      method: 'DELETE',
    });
  } catch (error) {
    throw error;
  }
}

export const removeWorkspaceFolder = (workspaceId: string, name: string): Promise<void> =>
  http(`${API_BASE_URL}/workspaces/${workspaceId}/folders`, {
    method: 'DELETE',
    body: JSON.stringify({
      name,
    }),
  });

export type SubmitFoldersProfilesUpdateParams = {
  workspaceId: string;
  name: string;
  profiles: string[];
  action: 'add' | 'remove';
};

type FolderProfile = Omit<ObjectPoolFolderProfile, 'objectPoolEntityType'>;
export type SubmitFoldersProfilesUpdateResp = {
  workspace: string;
  folder: string; // folder name
  action: SubmitFoldersProfilesUpdateParams['action'];
  updatedProfiles: string[]; // profile ids
  foldersProfiles?: FolderProfile[];
};

export const submitFoldersProfilesUpdate = (params: SubmitFoldersProfilesUpdateParams): Promise<SubmitFoldersProfilesUpdateResp> => {
  const { workspaceId } = params;
  const toSend: Omit<SubmitFoldersProfilesUpdateParams, 'workspaceId'> & { workspaceId?: string } = {
    ...params,
  };

  delete toSend.workspaceId;

  return http(`${API_BASE_URL}/workspaces/${workspaceId}/folders/profiles`, {
    method: 'PATCH',
    body: JSON.stringify(toSend),
  }).then((res: any) => res.body);
};

export const changeFoldersOrder = async (foldersOrder: any) => {
  try {
    await http(`${API_BASE_URL}/folders/order`, {
      method: 'PATCH',
      body: JSON.stringify({
        foldersOrder,
      }),
    });
  } catch (error) {
    throw error;
  }
}

export interface IFolderOrder {
  name: string;
  order: number;
}

export const changeWorkspaceFolderOrder = (workspaceId: string, folders: IFolderOrder[]): Promise<void> =>
  http(`${API_BASE_URL}/workspaces/${workspaceId}/folders/order`, {
    method: 'PATCH',
    body: JSON.stringify({
      folders,
    }),
  });
