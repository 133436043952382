import { fetchDeltaSyncData } from './api';
import { getLastSyncDate, setLastSyncDate } from './last-sync-date.atom';
import { getCurrentWorkspaceId } from '../../state/current-workspace-id.atom';
import { handleAcceptedTransactions } from '../transactions/handle-accepted-transaction';
import type { ObjectPoolEntity, ObjectPoolFolderProfile, ObjectPoolProfile, ObjectPoolTransaction } from '../types';

const MIN_DATE = new Date(0);
const DEFAULT_PAGE_SIZE = 5000;
const MAX_REQUESTS = 100;

export const performGlobalDeltaSync = async (): Promise<void> => {
  let retriesLeft = MAX_REQUESTS;
  while (retriesLeft) {
    retriesLeft--;
    const deltaSyncRes = await performPartialDeltaSync();
    if (!deltaSyncRes || deltaSyncRes.isSyncDone) {
      return;
    }
  }
};

const performPartialDeltaSync = async (): Promise<{ isSyncDone: boolean } | null> => {
  const localPageSize = localStorage.getItem('@gologin-exp:object-pool-page-size');
  const pageSize = localPageSize ? +localPageSize : DEFAULT_PAGE_SIZE;
  const lastSyncDate = getLastSyncDate();
  const syncData = await fetchDeltaSyncData({
    workspaceId: getCurrentWorkspaceId(),
    syncSince: (lastSyncDate || MIN_DATE).toISOString(),
    pageSize,
  });

  const { workspaces = [], latestSyncDate = null, isSyncDone = false } = syncData || {};
  const [workspace] = workspaces;
  if (!workspace) {
    console.warn('No workspace in delta-sync');

    return null;
  }

  if (!latestSyncDate) {
    console.warn('No latest sync date in delta-sync');

    return null;
  }

  const { profiles, foldersProfiles } = workspace;

  const profileObjectPoolEntities: ObjectPoolProfile[] = profiles.map((profile) => ({ ...profile, objectPoolEntityType: 'profile' }));
  const folderProfileObjectPoolEntities: ObjectPoolFolderProfile[] = foldersProfiles.map((fp) => ({ ...fp, objectPoolEntityType: 'folder-profile' }));

  // TODO: create single transaction object with an array
  const objectPoolTransactions: ObjectPoolTransaction<ObjectPoolEntity>[] = [
    ...profileObjectPoolEntities,
    ...folderProfileObjectPoolEntities,
  ].map((entity) => ({
    entity,
    entityType: entity.objectPoolEntityType,
    transactionType: entity.deleted ? 'D' : 'P',
  }));

  handleAcceptedTransactions(objectPoolTransactions);
  setLastSyncDate(new Date(latestSyncDate));

  return { isSyncDone };
};
