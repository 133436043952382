import React from 'react';
import { Trans } from 'react-i18next';

import { determineIsProxyMode } from '../../../../../../../common/constants/types';
import { ProxyMode } from '../../../../../../interfaces';
import { updateProxyForm, useProxyForm } from '../../../../../../state/proxy/custom-proxy-form-data.atom';
import { ModernSelect } from '../../../../../../ui/modern-select';
import { ISelectItem } from '../../../../../../ui/modern-select/interfaces';
import {
  GEOPROXY_MODERN_SELECT_POPOVER_STYLE,
  GEOPROXY_MODERN_SELECT_POPOVER_WIDTH,
  GEOPROXY_MODERN_SELECT_STYLE,
  GEOPROXY_MODERN_SELECT_Z_INDEX,
} from '../../geoproxy-edit-form-fields/styles';
import { useProxyFormSelectArrow } from '../../proxy-form-select-arrow.hook';

const CUSTOM_PROXY_MODE_SELECTABLE_MODES: ProxyMode[] = ['http', 'socks4', 'socks5'];

const CUSTOM_PROXY_MODE_SELECT_ITEMS: ISelectItem[] = CUSTOM_PROXY_MODE_SELECTABLE_MODES.map((mode) => ({
  title: <Trans i18nKey={`proxyManager.proxyForm.customProxy.modes.${mode}`} />,
  value: mode,
}));

const CustomProxyModeSelect: React.FC = () => {
  const customProxyForm = useProxyForm();

  const arrowProps = useProxyFormSelectArrow();
  const proxyProtocol = customProxyForm.mode === 'geolocation' ? 'http' : customProxyForm.mode;

  const handleSelectMode = (newMode: string): void => {
    const isProxyMode = determineIsProxyMode(newMode);
    if (isProxyMode) {
      return updateProxyForm({ mode: newMode });
    }

    // TODO: sentry
  };

  return (
    <ModernSelect
      currentValue={proxyProtocol}
      itemList={CUSTOM_PROXY_MODE_SELECT_ITEMS}
      onSelected={handleSelectMode}
      style={GEOPROXY_MODERN_SELECT_STYLE}
      popoverStyle={GEOPROXY_MODERN_SELECT_POPOVER_STYLE}
      popoverWidth={GEOPROXY_MODERN_SELECT_POPOVER_WIDTH}
      zIndex={GEOPROXY_MODERN_SELECT_Z_INDEX}
      {...arrowProps}
    />
  );
};

export default CustomProxyModeSelect;
