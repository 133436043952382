import { isNotNull } from '../../../common/typescript/predicates';
import { IProfile } from '../../interfaces';
import { getFolderById } from '../../state/folders/folders-list.atom';
import { getTagById } from '../../state/tags/tags-list.atom';
import type { PopulatedObjectPoolProfile } from '../types';

export const populateObjectPoolProfileFields = (objectPoolProfile: PopulatedObjectPoolProfile): IProfile => {
  const restructuredFields = {
    tags: objectPoolProfile.tagIds.map(getTagById).filter(isNotNull),
    folders: objectPoolProfile.folders.map((fp) => getFolderById(fp.folder)).filter(isNotNull).map(({ name }) => name),
    proxyEnabled: (objectPoolProfile.proxy?.mode || 'none') !== 'none',
  } as const;

  delete objectPoolProfile.tagIds;
  delete objectPoolProfile.objectPoolEntityType;

  const profile: IProfile = {
    canBeRunning: true,
    ...objectPoolProfile,
    ...restructuredFields,
  };

  return profile;
};
