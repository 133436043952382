import React, { useRef } from 'react';

import { ADD_BUTTON_ARROW_ACTIVE_BACKGROUND_COLOR, ADD_BUTTON_ARROW_ACTIVE_COLOR, ADD_BUTTON_ARROW_COLOR, IconArrowDownWrapper } from './styles';
import { GROUPED_PROXY_MANAGER_ANALYTICS_EVENTS } from '../../../../../../../common/constants/analytics';
import { GeoProxyType } from '../../../../../../../common/constants/types';
import { updateDefaultGeoProxy } from '../../../../../../state/proxy/default-geoproxy.atom';
import { PROXY_CHECK_TOOLTIP_LOCATIONS } from '../../../../../../state/proxy/proxy-check/constants';
import { createGeoProxy, GeoProxySubmitParams } from '../../../../../../state/proxy/proxy-operations/create-geoproxy.operations';
import { useTrafficData } from '../../../../../../state/proxy/traffic-data.atom';
import { DivAnchor } from '../../../../../../types';
import { IconArrowDown } from '../../../../../../ui/gologin-header/icons/icon-arrow-down';
import { sendActionAnalytics } from '../../../../../common/api';
import { generateProxyAnalyticsData } from '../../../../proxy-helpers';
import GeoProxyTypePopover, { GeoProxyTypePopoverBase } from '../../../geoproxy-type-popover';

type ProxyGroupAddButtonMenuProps = GeoProxyTypePopoverBase & {
  groupId: string;
  availableTypes: GeoProxyType[];
  proxyGroupAddButtonMenuAnchorEl: DivAnchor;
  toggleProxyGroupAddButtonMenuAnchorEl: (newAnchorEl: DivAnchor) => void;
  closeProxyGroupAddButtonMenu: () => void;
  setIsArrowHovered: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProxyGroupAddButtonMenu: React.FC<ProxyGroupAddButtonMenuProps> = (props) => {
  const {
    groupId,
    country,
    availableTypes,
    defaultType,
    proxyGroupAddButtonMenuAnchorEl,
    toggleProxyGroupAddButtonMenuAnchorEl,
    closeProxyGroupAddButtonMenu,
    setIsArrowHovered,
  } = props;

  const trafficData = useTrafficData();

  const buttonWrapperRef = useRef<DivAnchor>(null);

  const isHighlighted = !!proxyGroupAddButtonMenuAnchorEl;

  const handleClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation();
    toggleProxyGroupAddButtonMenuAnchorEl(buttonWrapperRef.current);
  };

  const handleItemClick = (connectionType: GeoProxyType): void => {
    updateDefaultGeoProxy({ connectionType });

    const geoProxyCreateParams: GeoProxySubmitParams = {
      groupId,
      profileId: '',
      country,
      selectedConnectionType: connectionType,
      availableConnectionTypes: availableTypes,
      trafficData,
      checkTooltipView: PROXY_CHECK_TOOLTIP_LOCATIONS.proxyGroupedListItem,
    };

    createGeoProxy(geoProxyCreateParams).then((geoProxyFormSubmitResult) => {
      if (!geoProxyFormSubmitResult || typeof geoProxyFormSubmitResult === 'string') {
        return;
      }

      const proxyAnalyticsData = generateProxyAnalyticsData(geoProxyFormSubmitResult);
      sendActionAnalytics(
        GROUPED_PROXY_MANAGER_ANALYTICS_EVENTS.createdLocationViaBuiltInProxyClickedOnServerTypeInExpandedGroup,
        proxyAnalyticsData,
      );
    });

    handleClose();
  };

  const handleClose = (): void => {
    closeProxyGroupAddButtonMenu();
  };

  return (
    <>
      <IconArrowDownWrapper
        ref={buttonWrapperRef}
        isHighlighted={isHighlighted}
        onClick={handleClick}
        onMouseOver={(): void => setIsArrowHovered(true)}
        onMouseLeave={(): void => setIsArrowHovered(false)}
      >
        <IconArrowDown
          padding='4px 2px'
          margin='0 0 0 5px'
          borderRadius={4}
          hoveredHighlight={true}
          highlight={isHighlighted}
          iconColor={isHighlighted ? ADD_BUTTON_ARROW_ACTIVE_COLOR : ADD_BUTTON_ARROW_COLOR}
          iconHoveredColor={ADD_BUTTON_ARROW_ACTIVE_BACKGROUND_COLOR}
        />
      </IconArrowDownWrapper>
      <GeoProxyTypePopover
        defaultType={defaultType}
        country={country}
        anchorElement={proxyGroupAddButtonMenuAnchorEl}
        handleItemClick={handleItemClick}
        handleClose={handleClose}
      />
    </>
  );
};

export default ProxyGroupAddButtonMenu;
