import { acceptUnpinTransaction } from '../../../object-pool/transactions/accepters/accept-unpin-transaction';
import { handleAcceptedTransactions } from '../../../object-pool/transactions/handle-accepted-transaction';
import { getCurrentWorkspaceId } from '../../../state/current-workspace-id.atom';
import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import { getAllProfilesFolderId } from '../../../state/folders/all-profiles-folder-id.atom';
import { reloadProfilesTableProfiles } from '../../../state/profiles-table/profiles-query';
import { getSelectedFolderId } from '../../../state/selected-folder.atom';
import { PinAction, PinProfileParams, getIsNewPinResult, pin, unpin } from '../api';

export const modifyProfilePin = async (profileId: string, setTo: PinAction): Promise<void> => {
  const currentWorkspaceId = getCurrentWorkspaceId();
  const pinOptions: PinProfileParams = { workspaceId: currentWorkspaceId, profileId };

  let selectedFolderId = getSelectedFolderId();
  if (!selectedFolderId && NEW_FEATURES.dragAndDrop) {
    selectedFolderId = getAllProfilesFolderId();
  }

  if (selectedFolderId) {
    pinOptions.folderId = selectedFolderId;
  }

  if (setTo === 'pinned') {
    await pin(pinOptions);
    const pinResult = await pin(pinOptions);
    if (getIsNewPinResult(pinResult) && NEW_FEATURES.objectPool) {
      await handleAcceptedTransactions([{
        entityType: 'folder-profile',
        transactionType: 'P',
        entity: pinResult,
      }]);
    }
  } else {
    await unpin(pinOptions);
    if (NEW_FEATURES.objectPool) {
      await acceptUnpinTransaction(pinOptions.folderId, profileId);
    }
  }

  reloadProfilesTableProfiles();
};
