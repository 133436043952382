import { API_BASE_URL } from '../../../common/constants/constants';
import { http } from '../../services';
import type { ObjectPoolFolderProfile, ObjectPoolProfile } from '../types';

type FetchDeltaSyncDataParams = {
  workspaceId: string;
  syncSince: string;
  pageSize: number;
}

export type FetchDeltaSyncData = {
  workspaces: Array<{
    profiles: Omit<ObjectPoolProfile, 'objectPoolEntityType'>[];
    foldersProfiles: Omit<ObjectPoolFolderProfile, 'objectPoolEntityType'>[];
  }>;
  latestSyncDate: string;
  isSyncDone: boolean;
}

export const fetchDeltaSyncData = async ({
  workspaceId,
  syncSince,
  pageSize,
}: FetchDeltaSyncDataParams): Promise<FetchDeltaSyncData> =>
  http(`${API_BASE_URL}/delta-sync/v1?onlyWorkspaceId=${workspaceId}&syncSince=${syncSince}&pageSize=${pageSize}`, {
    method: 'GET',
  }).then((res: any) => res.body);
