import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

type ToggleableEyeProps = {
  state: 'visible' | 'hidden';
  toggle: () => void;
};

export const IconOpenedEye: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.5 6C0.5 6 3.5 0.5 8 0.5C12.5 0.5 15.5 6 15.5 6C15.5 6 12.5 11.5 8 11.5C3.5 11.5 0.5 6 0.5 6Z"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 8.5C9.38071 8.5 10.5 7.38071 10.5 6C10.5 4.61929 9.38071 3.5 8 3.5C6.61929 3.5 5.5 4.61929 5.5 6C5.5 7.38071 6.61929 8.5 8 8.5Z"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </IconWrapper>
);

export const IconClosedEye: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.5001 5.73438C14.7301 6.90237 15.5001 8.00238 15.5001 8.00238C15.5001 8.00238 12.0001 13.0024 8.00009 13.0024C7.48809 13.0024 6.98509 12.9204 6.49609 12.7774"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.275 11.725C2.048 10.243 0.5 8 0.5 8C0.5 8 4 3 8 3C9.326 3 10.605 3.553 11.712 4.288"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M1 15L15 1" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  </IconWrapper>
);

const ToggleableEye: React.FC<ToggleableEyeProps> = ({ state, toggle, ...props }) => (
  <div onClick={toggle} style={{ cursor: 'pointer' }}>
    {state === 'visible' ? (
      <IconOpenedEye {...props} iconColor="var(--36363D)" iconHoveredColor="var(--36363D)" />
    ) : (
      <IconClosedEye {...props} iconColor="var(--98989F)" iconHoveredColor="var(--36363D)" />
    )}
  </div>
);

export default ToggleableEye;
