import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { CITIES_BY_COUNTRY } from '../../../../../../../common/constants/cities-by-country';
import { NEW_FEATURES } from '../../../../../../state/feature-toggle/new-features';
import { useGeoProxyCountriesAvailability } from '../../../../../../state/proxy/geoproxy-countries.atom';
import { updateGeoProxyForm, useGeoProxyForm } from '../../../../../../state/proxy/geoproxy-form-data.atom';
import { useRecentSelectedCountriesAtom } from '../../../../../../state/proxy/recent-selected-proxies.atom';
import { useIsSearchInputInProxyManagerFilledAtom } from '../../../../../../state/proxy/search-input-proxy-manager.atom';
import { ModernSelect } from '../../../../../../ui/modern-select';
import { ISelectItem } from '../../../../../../ui/modern-select/interfaces';
import { VpnUfoCountryWithTypes } from '../../../../../profileSettingsComponents/proxyTab/vpn-ufo.types';
import ProxyFlag, { BIG_FLAG_HEIGHT } from '../../../../proxy-flag';
import { makeGeoProxyCustomName } from '../../../../proxy-helpers';
import { evaluateGeoProxyCustomNameCounter } from '../../../../utils/default-proxy-data';
import { useProxyFormSelectArrow } from '../../proxy-form-select-arrow.hook';
import {
  GEOPROXY_MODERN_SELECT_POPOVER_STYLE,
  GEOPROXY_MODERN_SELECT_POPOVER_WIDTH,
  GEOPROXY_MODERN_SELECT_STYLE,
  GEOPROXY_MODERN_SELECT_Z_INDEX,
} from '../styles';

const GeoProxyCountrySelect: React.FC = () => {
  const geoProxyForm = useGeoProxyForm();
  const { availableProxies } = useGeoProxyCountriesAvailability();
  const recentSelectedCountries = useRecentSelectedCountriesAtom();
  const shouldFlagBeNeutral = useIsSearchInputInProxyManagerFilledAtom();

  const arrowProps = useProxyFormSelectArrow();

  const { t: translate } = useTranslation();

  const handleSelectCountry = (country: string, city?: string): void => {
    updateGeoProxyForm({ country, city });
    const { counter } = evaluateGeoProxyCustomNameCounter(country);
    const customName = makeGeoProxyCustomName({ countryCode: country, city: '', counter });
    updateGeoProxyForm({ customName });
  };

  useEffect(() => {
    if (!!NEW_FEATURES.proxyEditFormCities) {
      const { counter } = evaluateGeoProxyCustomNameCounter(geoProxyForm.country);
      const customName = makeGeoProxyCustomName({ countryCode: geoProxyForm.country, city: geoProxyForm.city || '', counter });
      updateGeoProxyForm({ customName });
    }
  }, [geoProxyForm.country, geoProxyForm.city]);

  const renderTitle = (): string => translate(`proxy.countries.${geoProxyForm.country.toLowerCase()}`);

  const renderCountryFlag = (countryCode: VpnUfoCountryWithTypes['countryCode']): JSX.Element => (
    <ProxyFlag countryCode={countryCode} height={BIG_FLAG_HEIGHT} />
  );

  const renderCountryName = (countryCode: VpnUfoCountryWithTypes['countryCode']): string =>
    `${translate(`proxy.countries.${countryCode.toLowerCase()}`)}`;

  const countrySelectableItems: ISelectItem[] = availableProxies.map(({ countryCode }) => ({
    title: renderCountryName(countryCode),
    cities: CITIES_BY_COUNTRY[countryCode] || [],
    flag: renderCountryFlag(countryCode),
    value: countryCode,
  }));
  console.log(countrySelectableItems);

  const countryRecentSelectableItems: ISelectItem[] = recentSelectedCountries
    .filter(({ countryCode }) => countrySelectableItems.some(item => item.value === countryCode))
    .map(({ countryCode }) => ({
      title: renderCountryName(countryCode),
      flag: renderCountryFlag(countryCode),
      value: countryCode,
    }));

    useEffect(() => {
      const isCurrentCountryAvailable = countrySelectableItems.some(item => item.value === geoProxyForm.country);
      if (!isCurrentCountryAvailable) {
        const defaultCountry = 'US'; 
        updateGeoProxyForm({ country: defaultCountry });
        const { counter } = evaluateGeoProxyCustomNameCounter(defaultCountry);
        const customName = makeGeoProxyCustomName({ countryCode: defaultCountry, city: 'New York', counter });
        updateGeoProxyForm({ customName });
      }
    }, [countrySelectableItems, geoProxyForm.country]);

  const countrySelectableItemsGroups =
    countryRecentSelectableItems.length > 0 && !shouldFlagBeNeutral
      ? [
          {
            groupTitle: 'Recent countries',
            selectItems: [...countryRecentSelectableItems],
          },
          {
            groupTitle: 'All countries',
            selectItems: [...countrySelectableItems],
          },
        ]
      : [
          {
            groupTitle: 'All countries',
            selectItems: [...countrySelectableItems],
          },
        ];

  console.log(countrySelectableItemsGroups);

  return (
    <>
      {shouldFlagBeNeutral ? (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ProxyFlag showEmptyFlag height={BIG_FLAG_HEIGHT} />
        </div>
      ) : (
        <ProxyFlag countryCode={geoProxyForm.country} height={BIG_FLAG_HEIGHT} />
      )}

      <ModernSelect
        title={renderTitle()}
        currentValue={geoProxyForm.country}
        itemList={countrySelectableItemsGroups}
        onSelected={handleSelectCountry}
        hasSearch={true}
        style={GEOPROXY_MODERN_SELECT_STYLE}
        popoverStyle={GEOPROXY_MODERN_SELECT_POPOVER_STYLE}
        popoverWidth={GEOPROXY_MODERN_SELECT_POPOVER_WIDTH}
        zIndex={GEOPROXY_MODERN_SELECT_Z_INDEX}
        {...arrowProps}
      />
    </>
  );
};

export default GeoProxyCountrySelect;
