import React, { FC } from 'react';

import CustomPreviewCard from './custom-card';
import PreviewCard from './preview-card';
import { IPlan } from '../../../../interfaces/plan';
import { usePlansList } from '../../../../state';
import { PLAN_IDS } from '../../constants';
import { IMappedPlan } from '../../interfaces';
import { getMappedCustomPlans, getMappedPlans } from '../../plans-data';
import { CardContainer } from '../../styles';

interface IPreviewCards {
  selectedDiscount: string;
  selectedCustomPlanId: string;
  getPlanInfo: (planId: string, field: keyof IPlan) => JSX.Element | string;
  onPlanSelect: (id: string, isUpgrade?: boolean) => Promise<void>;
  setSelectedCustomPlanId: (planId: string) => void;
}

const PreviewCards: FC<IPreviewCards> = (props) => {
  const {
    selectedDiscount,
    selectedCustomPlanId,
    getPlanInfo,
    onPlanSelect,
    setSelectedCustomPlanId,
  } = props;

  const plans = usePlansList();
  const previewPlans = getMappedPlans(plans);
  const previewCustomPlans = getMappedCustomPlans(plans);

  const getPreviewCard = (previewPlan: IMappedPlan): JSX.Element => {
    if (previewPlan.id === PLAN_IDS.Custom2k) {
      return (
        <CustomPreviewCard
          key={previewPlan.id}
          previewCustomPlans={previewCustomPlans}
          selectedCustomPlanId={selectedCustomPlanId}
          setSelectedCustomPlanId={setSelectedCustomPlanId}
          selectedDiscount={selectedDiscount}
          onPlanSelect={onPlanSelect}
          getPlanInfo={getPlanInfo}
        />
      );
    }

    return (
      <PreviewCard
        key={previewPlan.id}
        selectedDiscount={selectedDiscount}
        previewPlan={previewPlan}
        onPlanSelect={onPlanSelect}
        getPlanInfo={getPlanInfo}
      />
    );
  };

  return (
    <CardContainer>
      {previewPlans.map((previewPlan) => getPreviewCard(previewPlan))}
    </CardContainer>
  );
};

export default PreviewCards;
