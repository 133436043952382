import * as Sentry from '@sentry/react';
import { getDefaultStore } from 'jotai';

import { GeoProxyType } from '../../../../common/constants/types';
import { calculateRemainingAllTypesTrafficLimitsObject } from '../../../../common/proxy/traffic/utils';
import { LOAD_TRAFFIC_DATA_TRANSACTION, TRAFFIC_DATA_LOAD_TRIGGER_TAG, TrafficDataLoadTrigger } from '../../../features/proxy/constants';
import { IGeolocationProxyFullData } from '../../../interfaces';
import { getTrafficData } from '../../../ui/gologin-header/proxy-traffic-popover/api';
import { getProxyTypeVisibility, proxyTypeVisibilityAtom, setProxyTypeVisibility } from '../proxy-type-visibility.atom';
import { TRAFFIC_DATA_DEFAULT, trafficDataAtom, updateIsTrafficDataLoading, updateTrafficData } from '../traffic-data.atom';

export const loadTrafficData = async (trafficDataLoadTrigger: TrafficDataLoadTrigger): Promise<IGeolocationProxyFullData> => {
  const transaction = Sentry.startTransaction({ name: LOAD_TRAFFIC_DATA_TRANSACTION });
  transaction.setTag(TRAFFIC_DATA_LOAD_TRIGGER_TAG, trafficDataLoadTrigger);

  updateIsTrafficDataLoading(true);
  const trafficData = await getTrafficData();
  const normalizedTrafficData = typeof trafficData === 'string' ? TRAFFIC_DATA_DEFAULT : trafficData;

  const previousTrafficData = getDefaultStore().get(trafficDataAtom);
  const currentVisibilityOfProxies = getProxyTypeVisibility();

  const previousRemainingTraffic = calculateRemainingAllTypesTrafficLimitsObject(previousTrafficData);
  const newRemainingTraffic = calculateRemainingAllTypesTrafficLimitsObject(normalizedTrafficData);

  const updatedVisibility = Object.values(GeoProxyType).reduce((acc, type) => { 
    const gotMoreTraffic = newRemainingTraffic[type] > previousRemainingTraffic[type];
    acc[type] = gotMoreTraffic || currentVisibilityOfProxies[type];

    return acc;
  }, {} as Record<GeoProxyType, boolean>);

  setProxyTypeVisibility(updatedVisibility);
  updateTrafficData(normalizedTrafficData);

  transaction.finish();

  return normalizedTrafficData;
};
