import styled from '@emotion/styled';
import { Button, message } from 'antd';
import React, { FC, memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import { E_ANALYTICS_ACTIONS } from '../../../../common/constants/analytics';
import { IProfile } from '../../../interfaces';
import { workspaceContext } from '../../../state';
import { mapAndSetProfilesList } from '../../../state/profiles-list.atom';
import { getBasicTableProfileIds } from '../../../state/profiles-table/basic-table-entities.atom';
import {
  DELETE_OR_LEAVE_PROFILE_MODAL_NAME,
  startClosingProfileTableModal,
  useProfilesTableModalProfiles,
  useProfileTableModalIsVisible,
} from '../../../state/profiles-table-modal.atom';
import { callProfilesTableRowMeasurer } from '../../../state/profiles-table-row-measurers.atom';
import { resetProfilesTableSelectedIds } from '../../../state/profiles-table-selected-ids.atom';
import { decrementProxyProfilesCount } from '../../../state/proxy/proxy-list.atom';
import GologinModal from '../../../ui/gologin-modal';
import { sendActionAnalytics } from '../../common/api';
import { submitProfilesDelete, removeMultipleInvites } from '../../quickProfiles/api';
import { acceptDeleteProfiles } from '../../../object-pool/transactions/accepters/accept-delete-profiles';

const DivModal = styled('div')`
  display: flex;
  justify-content: space-between;
`;

const isElectron = !!window.require;
let ipcRenderer: Electron.IpcRenderer;
if (isElectron) {
  ({ ipcRenderer } = window.require('electron'));
}

export const calculateProfileDeleteOrLeave = (profile?: IProfile): 'delete'|'leave' =>
  (!profile?.role || profile.role === 'owner') ? 'delete' : 'leave';

const RemoveProfileModal: FC = () => {
  const { id: workspaceId } = useContext(workspaceContext);

  const { t: translation } = useTranslation();

  const isModalVisible = useProfileTableModalIsVisible(DELETE_OR_LEAVE_PROFILE_MODAL_NAME);
  const [modalProfile] = useProfilesTableModalProfiles();

  const modalAction = calculateProfileDeleteOrLeave(modalProfile);
  const { id: modalProfileId = '', name: modalProfileName = '', proxy: modalProfileProxy = null } = modalProfile || {};

  const removeProfilesFromListAndClose = (): void => {
    mapAndSetProfilesList(prevProfiles => prevProfiles.filter(profile => profile.id !== modalProfileId));
    resetProfilesTableSelectedIds();
    startClosingProfileTableModal();

    const profileIds = getBasicTableProfileIds();
    profileIds.forEach(profileId => callProfilesTableRowMeasurer(profileId));
  };

  const removeProfile = async (): Promise<void> => {
    if (ipcRenderer) {
      ipcRenderer.invoke('stop-profile', modalProfileId);
      ipcRenderer.invoke('del-profile-ext-folder', modalProfileId);
    }

    const profileIds = [modalProfileId];
    await submitProfilesDelete(workspaceId, profileIds);
    await acceptDeleteProfiles(profileIds);
    message.success(translation('tableProfiles.notification.profilesDeleted', { count: 1 }));

    removeProfilesFromListAndClose();
    sendActionAnalytics(E_ANALYTICS_ACTIONS.clickedConfirmDeleteProfile, { profile: modalProfileId });
  };

  const leaveProfile = async (): Promise<void> => {
    const inviteId = modalProfile.shareId;
    if (!inviteId) {
      startClosingProfileTableModal();

      return;
    }

    ipcRenderer && ipcRenderer.invoke('del-profile-ext-folder', modalProfileId);
    await removeMultipleInvites([inviteId]);

    removeProfilesFromListAndClose();
  };

  const onAction = (): void => {
    startClosingProfileTableModal();
    switch (modalAction) {
      case 'delete':
        removeProfile();
        decrementProxyProfilesCount(modalProfileProxy?.id);
        break;
      case 'leave':
        leaveProfile();
        break;
      default:
    }
  };

  const modalActionText = translation(`base.${String(modalAction)}`);
  const modalTitle = `${modalActionText} ${translation('base.profile').toLowerCase()} ${modalProfileName}?`;

  return (
    <GologinModal
      width='350px'
      isVisible={isModalVisible}
      onClose={startClosingProfileTableModal}
      title={modalTitle}
    >
      <DivModal>
        <Button onClick={startClosingProfileTableModal}>
          {translation('base.cancel')}
        </Button>
        <Button
          type='danger'
          onClick={onAction}
        >
          {translation(`base.${String(modalAction)}`)}
        </Button>
      </DivModal>
    </GologinModal>
  );
};

export default memo(RemoveProfileModal);
