import React, { useRef, useState } from 'react';
import { Trans } from 'react-i18next';

import { GeoProxyTypeSelectArrowIconWrapper, GeoProxyTypeSelectTitle, GeoProxyTypeSelectWrapper } from './styles';
import { GeoProxyType } from '../../../../../../../common/constants/types';
import { updateDefaultGeoProxy } from '../../../../../../state/proxy/default-geoproxy.atom';
import { useGeoProxyForm, updateGeoProxyForm } from '../../../../../../state/proxy/geoproxy-form-data.atom';
import { DivAnchor } from '../../../../../../types';
import { IconArrowDown } from '../../../../../../ui/gologin-header/icons/icon-arrow-down';
import GeoProxyTypePopover from '../../../geoproxy-type-popover';
import { evaluateGeoProxyModernSelectArrowColor } from '../styles';

const GeoProxyTypeSelect: React.FC = () => {
  const geoProxyForm = useGeoProxyForm();

  const [geoProxyTypePopoverTarget, setGeoProxyTypePopoverTarget] = useState<DivAnchor>(null);

  const arrowWrapperRef = useRef<DivAnchor>(null);

  const isHighlighted = !!geoProxyTypePopoverTarget;

  const handleSelectClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    setGeoProxyTypePopoverTarget(arrowWrapperRef?.current || null);
  };

  const handleItemClick = (connectionType: GeoProxyType): void => {
    updateGeoProxyForm({ connectionType }); 
    updateDefaultGeoProxy({ connectionType });
    // TODO: add analytics in the upcoming MR
    // sendActionAnalytics(GROUPED_PROXY_MANAGER_ANALYTICS_EVENTS.createdGeoProxyViaSelectGeoProxyType, proxyAnalyticsData);

    handleClose();
  };
 

  const handleClose = (): void => {
    setGeoProxyTypePopoverTarget(null);
  };

  return (
    <>
      <GeoProxyTypeSelectWrapper onClick={handleSelectClick}>
        <GeoProxyTypeSelectTitle>
          <Trans i18nKey={`proxyTypes.${geoProxyForm.connectionType}`} />
        </GeoProxyTypeSelectTitle>
        <GeoProxyTypeSelectArrowIconWrapper ref={arrowWrapperRef} >
          <IconArrowDown
            padding={0}
            iconColor={evaluateGeoProxyModernSelectArrowColor(isHighlighted)}
          />
        </GeoProxyTypeSelectArrowIconWrapper>
      </GeoProxyTypeSelectWrapper>
      <GeoProxyTypePopover
        defaultType={geoProxyForm.connectionType}
        country={geoProxyForm.country}
        anchorElement={geoProxyTypePopoverTarget}
        handleItemClick={handleItemClick}
        handleClose={handleClose}
      />
    </>
  );
};

export default GeoProxyTypeSelect;
