import styled from "@emotion/styled";


export const RenderMenuItemRightControlsWrapper = styled.div`
  padding: 16px;
  padding-left: 8px;
  padding-bottom: 0;
  margin-bottom: 16px;
`;

 export const HintTextWrapper = styled.span`
 display: flex;
 align-items: flex-start;
 column-gap: 8px;
`;


export const RowTitleWrapper = styled.div`
  padding: 16px 4px 16px 16px;
  margin: 0;
  display: flex;
  column-gap: 8px;
  width: max-content;
  cursor: pointer;
`;
