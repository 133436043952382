import styled from '@emotion/styled';

import { BACKGROUNDS, BackgroundType } from '../../constants';

export const Card = styled.div<{backgroundColor: BackgroundType}>`
  position: relative;
  width: 296px;
  height: 541px;
  display: flex;
  flex-direction: column;
  padding: 24px 24px 32px;
  background-color: ${({ backgroundColor }): string => BACKGROUNDS[backgroundColor]};
  border-radius: 16px;
  border: 1px solid var(--D2D2D5-new-pricing);
`;

export const CardContentContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const CardTag = styled.span<{ isCustomPlan: boolean }>`
  color: var(--98989F-new-pricing);
  font-family: 'Roboto';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

export const CardTitle = styled.h3<{ isCustomPlan: boolean }>`
  font-family: Roboto Flex;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  margin-top: 16px;
  color: ${({ isCustomPlan }): string => isCustomPlan ? 'var(--FFFFFF-new-pricing-custom)': 'var(--36363D-new-pricing)'};
`;

export const CardList = styled('ul')<{ isCustomPlan: boolean }>`
  margin: ${({ isCustomPlan }): string => isCustomPlan ? '24px 0 0 0' : '25px 0 0 0'};
  list-style: none;
  padding: 0;
`;

export const CardListItem = styled.li<{ isCustomPlan: boolean }>`
  margin-top: 10px;
  display: flex;
  column-gap: 4px;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: ${({ isCustomPlan }): string => isCustomPlan ? 'var(--FFFFFF-new-pricing-custom)': 'var(--36363D-new-pricing)'};
  
  &:first-of-type {
    margin-top: 0;
  }
`;

export const DescriptionPlan = styled('span')`
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.44px;
  white-space: nowrap;
  margin-left: 4px;
  display: flex;
  align-items: center;
`;

export const IconContainer = styled('div')`
  width: 16px;
  height: 16px;
  margin-bottom: 2px;
`;

export const IconCheckboxContainer = styled(IconContainer)<{ isSelectOpen?: boolean }>`
  svg:not(:root) path {
    stroke: ${({ isSelectOpen }): string => isSelectOpen ? 'var(--36363D-new-pricing-select)' : 'var(--98989F-new-pricing-select)'};
  }
`;

export const CardListProfilesItem = styled('div')`
  display: flex;
  column-gap: 4px;
  align-items: center;
`;

export const CardPriceContainer = styled.div<{ isPriceMoreFiveDigits: boolean }>`
  margin-top: 24px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  column-gap: 8px;
  margin-left: ${({ isPriceMoreFiveDigits }): string => (isPriceMoreFiveDigits ? '-25px' : '0')};
  margin-right: ${({ isPriceMoreFiveDigits }): string => (isPriceMoreFiveDigits ? '-25px' : '0')};
`;

export const CardPriceDiscount = styled.span<{ isCustomPlan: boolean }>`
  display: block;
  color: ${({ isCustomPlan }): string => isCustomPlan ? 'var(--FFFFFF-new-pricing-custom)': 'var(--36363D-new-pricing)'};
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.44px;
`;

export const CardPrice = styled(CardPriceDiscount)<{ isCustomPlan: boolean }>`
  color: ${({ isCustomPlan }): string => isCustomPlan ? 'var(--D2D2D5-new-pricing-custom)': 'var(--98989F-new-pricing)'};
  text-decoration: line-through;
`;

export const SelectButtonContainer = styled.div`
  padding: 4px 4px 4px 8px;
  display: flex;
  align-items: center;
  column-gap: 4px;
  border-radius: 8px;
  background-color: var(--F9F9F9-new-pricing-limits-select-custom);
`;

export const SelectTitle = styled.span`
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--222222-new-pricing-limits-select-custom);
`;

export const ContainerGreenText = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  justify-content: center;
  margin-top: 12px;
`;
