export interface ISentrySpanData {
  started?: number;
}

export const APP_START_TRANSACTION_SPANS = [
  'electron-load',
  'mount-html',
  'prepare-dom-load',
  'prepare-app-load',
  'from-init-app-to-first-effect',
  'from-first-effect-to-start-setup-i18n',
  'from-end-setup-i18n-to-last-line-in-effect-inside-app-component',
  'from-end-setup-i18n-to-start-load-list-cities',
  'from-end-load-list-cities-to-load-root-route',
  'from-end-load-root-route-to-first-effect-in-private-route',
  'from-load-root-route-to-load-sign-up-page',
  'from-first-effect-private-route-to-load-profiles-table',
] as const;

export type AppStartTransactionSpan = typeof APP_START_TRANSACTION_SPANS[number];
export type IAppStartTransaction = Record<AppStartTransactionSpan, ISentrySpanData>;

