import { NEW_FEATURES } from '../../state/feature-toggle/new-features';
import { deleteFromCacheDBStore, putIntoCacheDBStore } from '../idb-cache/cache-db';
import { getCacheDBName } from '../idb-cache/cache-db-name.atom';
import { putIntoObjectPool, removeFromObjectPoolByIds } from '../object-pool.atom';
import type { ObjectPoolEntity, ObjectPoolEntityType, ObjectPoolTransaction } from '../types';

export const handleAcceptedTransactions = async <T extends ObjectPoolEntity>(transactions: Array<ObjectPoolTransaction<T>>): Promise<void> => {
  if (!NEW_FEATURES.objectPool) {
    return;
  }

  const toPut: ObjectPoolEntity[] = [];
  const toDelete: Array<ObjectPoolEntity['id']> = [];
  const toPutByType = new Map<ObjectPoolEntityType, ObjectPoolEntity[]>();
  const toDeleteByType = new Map<ObjectPoolEntityType, Array<ObjectPoolEntity['id']>>();
  transactions.forEach((transaction) => {
    if (transaction.transactionType === 'P') {
      // typescript is too stupid to handle this
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const objectPoolEntity: ObjectPoolEntity = { ...transaction.entity, objectPoolEntityType: transaction.entityType };
      toPut.push(objectPoolEntity);
      const arrOfEntityType = toPutByType.get(objectPoolEntity.objectPoolEntityType);
      if (arrOfEntityType) {
        arrOfEntityType.push(objectPoolEntity);

        return;
      }

      toPutByType.set(objectPoolEntity.objectPoolEntityType, [objectPoolEntity]);
    } else if (transaction.transactionType === 'D') {
      const entityId = transaction.entity.id;
      const arrOfEntityType = toDeleteByType.get(transaction.entityType);
      toDelete.push(entityId);
      if (arrOfEntityType) {
        arrOfEntityType.push(entityId);

        return;
      }

      toDeleteByType.set(transaction.entityType, [entityId]);
    }
  });

  const cacheDBName = getCacheDBName();
  if (toPut.length) {
    putIntoObjectPool(toPut);
    if (cacheDBName) {
      toPutByType.forEach((entities, entityType) => {
        putIntoCacheDBStore({ dbName: cacheDBName, entityType, entities });
      });
    }
  }

  if (toDelete.length) {
    removeFromObjectPoolByIds(toDelete);
    if (cacheDBName) {
      toDeleteByType.forEach((entityIds, entityType) => {
        deleteFromCacheDBStore({ dbName: cacheDBName, entityType, entityIds });
      });
    }
  }
};
