import { IIconWrapper } from '../../../../ui/gologin-header/icons/wrapper';

const DEFAULT_BUTTON_ICON_PROPS: IIconWrapper = {
  padding: 0,
  iconColor: 'var(--767676-proxy-manager-icon-buttons)',
  iconHoveredColor: 'var(--00A987-proxy-manager)',
};

const ICON_DEFAULT_COLOR: IIconWrapper['iconColor'] = 'var(--767676)';
const ICON_ACTIVE_COLOR: IIconWrapper['iconColor'] = 'var(--36363D)';

type ProxySelectorButtonsColorsEvaluateParams = {
  isNewProxyEditForm: boolean;
  isAddFormActive: boolean;
}

type ProxySelectorButtonsColorsEvaluateResult = {
  plusIconProps: IIconWrapper;
  pasteIconProps: IIconWrapper;
}

export const evaluateProxySelectorButtonsProps = ({
  isNewProxyEditForm,
  isAddFormActive,
}: ProxySelectorButtonsColorsEvaluateParams): ProxySelectorButtonsColorsEvaluateResult => {
  const defaultIconProps = DEFAULT_BUTTON_ICON_PROPS;
  if (!isNewProxyEditForm) {
    return {
      plusIconProps: defaultIconProps,
      pasteIconProps: defaultIconProps,
    };
  }

  defaultIconProps.iconColor = ICON_DEFAULT_COLOR;
  defaultIconProps.iconHoveredColor = ICON_ACTIVE_COLOR;
  let plusIconProps = defaultIconProps;
  if (isAddFormActive) {
    plusIconProps = { ...defaultIconProps, iconColor: ICON_ACTIVE_COLOR };
  }

  return {
    plusIconProps,
    pasteIconProps: defaultIconProps,
  };
};
