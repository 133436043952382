import React from 'react';
import { Trans } from 'react-i18next';

import { stringifyRemainingTrafficAmount } from '../../../../../../common/proxy/traffic/utils';
import { useGeoProxyForm } from '../../../../../state/proxy/geoproxy-form-data.atom';
import { useAreGeoProxyAdvancedSettingsVisible } from '../../../../../state/proxy/proxy-form-tabs.atom';
import { useTrafficData } from '../../../../../state/proxy/traffic-data.atom';
import { ProxyFormLeft, ProxyFormRight, ProxyFormRowEl } from '../styles';
import AdvancedGeoProxySettingsButton from './advanced-geoproxy-settings-button';
import GeoProxyCitySelect from './geoproxy-city-select';
import GeoProxyCountrySelect from './geoproxy-country-select';
import GeoProxyCustomNameInput from './geoproxy-country-select/geoproxy-custom-name-input';
import GeoProxyTypeSelect from './geoproxy-type-select';
import { RemainingTrafficLimit } from './styles';

const GeoProxyEditFormFields: React.FC = () => {
  const areGeoProxyAdvancedSettingsVisible = useAreGeoProxyAdvancedSettingsVisible();
  const geoProxyForm = useGeoProxyForm();
  const trafficData = useTrafficData();
  const renderGeoProxyAdvancedSettings = (): JSX.Element | null => {
    if (!areGeoProxyAdvancedSettingsVisible) {
      return null;
    }

    return (
      <>
        <ProxyFormRowEl>
          <ProxyFormLeft>
            <Trans i18nKey="proxyManager.proxyForm.geoProxy.name" />
          </ProxyFormLeft>
          <ProxyFormRight style={{ width: '100%' }}>
            <GeoProxyCustomNameInput />
          </ProxyFormRight>
        </ProxyFormRowEl>
        <ProxyFormRowEl>
          <ProxyFormLeft>
            <Trans i18nKey="proxyManager.proxyForm.geoProxy.type" />
          </ProxyFormLeft>
          <ProxyFormRight style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <GeoProxyTypeSelect />
            <RemainingTrafficLimit>
              <Trans
                i18nKey="proxyManager.trafficRemainingLimit"
                values={{ limit: stringifyRemainingTrafficAmount(trafficData, geoProxyForm.connectionType, true) }}
              />
            </RemainingTrafficLimit>
          </ProxyFormRight>
        </ProxyFormRowEl>
      </>
    );
  };

  return (
    <div>
      <ProxyFormRowEl>
        <ProxyFormLeft>
          <Trans i18nKey="proxyManager.proxyForm.geoProxy.country" />
        </ProxyFormLeft>
        <ProxyFormRight style={{ maxWidth: '167px' }}>
          <GeoProxyCountrySelect />
        </ProxyFormRight>
      </ProxyFormRowEl>
      <ProxyFormRowEl>
        <ProxyFormLeft>
          <Trans i18nKey="proxyManager.proxyForm.geoProxy.city" />
        </ProxyFormLeft>
        <ProxyFormRight>
          <GeoProxyCitySelect />
        </ProxyFormRight>
      </ProxyFormRowEl>
      <ProxyFormRowEl>
        <AdvancedGeoProxySettingsButton />
      </ProxyFormRowEl>
      {renderGeoProxyAdvancedSettings()}
    </div>
  );
};

export default GeoProxyEditFormFields;
