import { NEW_FEATURES } from './feature-toggle/new-features';
import { loadGeoProxyCountries } from './proxy/geoproxy-countries.atom';
import { loadTrafficData } from './proxy/proxy-operations/load-geoproxy-traffic-data.operations';
import { IUpdateWorkspaceContextData, IWorkspaceContextData } from './workspace.context';
import { TRAFFIC_DATA_LOAD_TRIGGERS } from '../features/proxy/constants';

// TODO: this is temporary fix of refreshing country & traffic after buying plan
export const onWorkspaceDataUpdate = (prev: IWorkspaceContextData, update: Partial<IUpdateWorkspaceContextData>): void => {
  const prevPlanExpiresAt = prev?.planExpiresAt ? +(new Date(prev.planExpiresAt)) : -1;
  const nextPlanExpiresAt = update?.planExpiresAt ? +(new Date(update.planExpiresAt)) : -1;

  const isExpiresUpdated =
    prevPlanExpiresAt !== -1 &&
    nextPlanExpiresAt !== -1 &&
    prevPlanExpiresAt !== nextPlanExpiresAt;

  if (NEW_FEATURES.proxyGroupsV2 && isExpiresUpdated) {
    loadTrafficData(TRAFFIC_DATA_LOAD_TRIGGERS.workspaceRefresh);
    loadGeoProxyCountries();
  }
};
