import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { EMPTY_LIMITS, Limits } from '../../interfaces/workspaces/limits';

const workspaceLimitsAtom = atom<Limits>(EMPTY_LIMITS);
const isWorkspaceLimitsAvaliableAtom = atom<boolean>(false);

export const setWorkspaceLimits = (data: Limits): void => getDefaultStore().set(workspaceLimitsAtom, data);
export const getWorkspaceLimits = (): Limits => getDefaultStore().get(workspaceLimitsAtom);
export const useWorkspaceLimits = (): Limits => useAtomValue(workspaceLimitsAtom);

export const setIsWorkspaceLimitsAvaliable = (data: boolean): void => getDefaultStore().set(isWorkspaceLimitsAvaliableAtom, data);
export const getIsWorkspaceLimitsAvaliable = (): boolean => getDefaultStore().get(isWorkspaceLimitsAvaliableAtom);
export const useIsWorkspaceLimitsAvaliable = (): boolean => useAtomValue(isWorkspaceLimitsAvaliableAtom);
