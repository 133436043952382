import { css } from '@emotion/core';
import styled from '@emotion/styled/macro';

import { gologinTableCheckboxStyles } from '../../../ui/gologin-checkbox/styles';
import { ModernModal } from '../../../ui/modern-modal';

export const IconMeatballsContainer = styled.div<{ isVisible?: boolean }>`
  cursor: pointer;
  visibility: ${(props): string => props.isVisible ? 'visible' : 'hidden'};
`;

export const PricingModal = styled(ModernModal)`
  width: 1330px !important;
  top: 16px;

  .ant-modal-close{
    top: 32px;
    right: 32px;
    width: 25px;
  }

  .ant-modal-close-x {
    width: auto;
    height: auto;
  }

  .ant-modal-content {
    background: var(--FFFFFF-new-pricing);
  }

  @media screen and (max-width: 1440px) {
    width: 1009px !important;
    height: 3350px;
  }

  @media screen and (max-width: 1060px) {
    width: 980px !important;
    height: 3350px;
  }
`;

export const ContainerContent = styled('div')<{ newStyle: boolean }>`
  ${props => props.newStyle ? css `
    display: flex;
    flex-direction: column;
    flex: 1;
  ` : css `
    padding: 30px 86px;

    @media screen and (max-width: 500px) {
      padding: 5% 1%;
    }
    @media screen and (max-width: 325px) {
      padding: 5% 0;
    }
  `}

  /* TODO: remove when proxy-table is merged with gologin-table */
  .proxy-table {
    ${gologinTableCheckboxStyles}
  }
`;

export const ContainerPreTable = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const ContainerTableName = styled('div')`
@media screen and (max-width: 420px) {
  width: 50%;
}
`;

export const SpinnerContainer = styled('div')`
  font-size: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 150px;
`;

export const TextTableName = styled('p')`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  color: var(--000000-profile-table);
  @media screen and (max-width: 500px) {
    padding-left: 7%;
  }
  @media screen and (max-width: 325px) {
    padding-left: 4%;
  }
`;
