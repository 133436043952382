import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DescriptionContainer, IconPasteContainer, LanguageParamContainer } from './styles';
import { checkDoesLangExist } from '../../../../../../common/locales';
import { getLanguageByBasedIp } from '../../../../../features/common/get-data-by-based-ip';
import LocalesManager from '../../../../../features/modalsComponents/components/locales-manager';
import { useLocalUserDevice } from '../../../../../state/local-user-device.atom';
import { closeProfileTableModal, LOCALES_MODAL_NAME, openProfilesTableModal } from '../../../../../state/profiles-table-modal.atom';
import { useProxyList } from '../../../../../state/proxy/proxy-list.atom';
import { IconPaste } from '../../../../../ui/gologin-header/icons';
import TooltipCustom from '../../../../../ui/tooltip-custom';
import { IChangeSetting } from '../../../interfaces';
import { SeparatedContainer } from '../../../styles';

interface ILanguage extends IChangeSetting {
  profileLanguage: string;
  profileAutoLang: boolean;
  profileId: string;
  proxyId: string;
  isFreeOrTorProxy: boolean;
}

export const Language: React.FC<ILanguage> = (props) => {
  const {
    changeSetting, profileLanguage = '', profileAutoLang, proxyId, profileId, isFreeOrTorProxy,
  } = props;

  const localUserDevice = useLocalUserDevice();
  const { languages: realLocalLanguages = '', country: realLocalCountry = '' } = localUserDevice;

  const [languagesBasedOnIp, setLanguagesBasedOnIp] = useState<string>('');
  const [anchorElTooltip, setAnchorElTooltip] = useState<HTMLElement|null>(null);

  const proxyList = useProxyList();

  const { t: translation } = useTranslation();
  const proxyFromCtx = proxyList.find(listedProxy => listedProxy.id === proxyId);

  const getData = async (): Promise<void> => {
    let languagesByBasedIp = translation('quickSettings.parameters.automatic');
    if (!isFreeOrTorProxy) {
      languagesByBasedIp = getLanguageByBasedIp({
        proxyFromCtx,
        realLocalLanguages,
        realLocalCountry,
      });
    }

    setLanguagesBasedOnIp(languagesByBasedIp);
  };

  useEffect(() => {
    getData();
  }, [proxyId, profileId, proxyFromCtx, isFreeOrTorProxy, realLocalLanguages, realLocalCountry]);

  const separatedLanguages = (profileAutoLang ? languagesBasedOnIp : profileLanguage)
    .split(',')
    .map(item => item.split(';')[0])
    .filter(key => checkDoesLangExist(key));

  const setProfileNavigatorLanguage = (save: boolean, autoLang = true, language?: string): void => {
    closeProfileTableModal();
    if (!(save && language)) {
      return;
    }

    changeSetting({ navigator: { language }, autoLang });
  };

  const onPasteLanguage = async (): Promise<void> => {
    const clipboardText = await navigator.clipboard.readText();
    const languageFromClipboard = clipboardText
      .split(',')
      .reduce((acc, currentLang) => {
        const [key, order] = currentLang.split(';');
        let { lastIndex, combinedLanguages } = acc;
        const maxLanguages = 10;
        const doesLangExist = checkDoesLangExist(key);
        if (lastIndex >= maxLanguages || !doesLangExist) {
          return acc;
        }

        const orderLang = order ? ';' + order : `;q=0.${maxLanguages - lastIndex}`;
        lastIndex += 1;
        combinedLanguages = combinedLanguages + key + (!lastIndex ? '' : orderLang) + ',';

        return { lastIndex, combinedLanguages };
      }, { lastIndex: 0, combinedLanguages: '' });

    let { combinedLanguages } = languageFromClipboard;
    if (combinedLanguages.endsWith(',')) {
      combinedLanguages = combinedLanguages.slice(0, -1);
    }

    if (!combinedLanguages) {
      return;
    }

    changeSetting({ navigator: { language: combinedLanguages, autoLang: true } });
  };

  const renderLanguages = (): JSX.Element => {
    if (isFreeOrTorProxy && profileAutoLang) {
      return (
        <span>
          {languagesBasedOnIp}
        </span>
      );
    }

    return (
      <>
        {separatedLanguages.map((lang, index) => (
          <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }} key={index}>
            {lang}
          </span>
        ))}
      </>
    );
  };

  return (
    <>
      <LanguageParamContainer onClick={(): void => openProfilesTableModal(LOCALES_MODAL_NAME, [])}>
        <SeparatedContainer>
          {renderLanguages()}
        </SeparatedContainer>
        <DescriptionContainer>
          {translation('quickSettings.parameters.' + (profileAutoLang ? 'basedIP' : 'manual'))}
        </DescriptionContainer>
        <IconPasteContainer>
          <IconPaste
            iconColor='var(--98989F-profile-new-settings)'
            padding={0}
            iconHoveredColor='var(--2B2B31-profile-new-settings)'
            onClick={(event): void => {
              event.stopPropagation();
              onPasteLanguage();
            }}
            onMouseEnter={(event): void => setAnchorElTooltip(event.currentTarget)}
            onMouseLeave={(): void => setAnchorElTooltip(null)}
          />
        </IconPasteContainer>
      </LanguageParamContainer>
      <TooltipCustom
        anchorEl={anchorElTooltip}
        value={translation('quickSettings.tooltip.pasteLanguage') || 'Paste languages'}
      />
      <LocalesManager
        languages={profileLanguage}
        languagesBasedOnIp={languagesBasedOnIp}
        toggleLocalesListModal={setProfileNavigatorLanguage}
        autoLang={profileAutoLang}
      />
    </>
  );
};
