import React from 'react';
import { Trans } from 'react-i18next';

import { ProxyEditFormFooterTabWrapper } from './styles';
import { useProxyFormCurrentTab, updateProxyFormTab } from '../../../../../../state/proxy/proxy-form-tabs.atom';
import { useProxyManagerState } from '../../../../../../state/proxy/proxy-manager-modal-status.atom';
import { IconPinpoint } from '../../../../../../ui/gologin-header/icons/icon-pinpoint';
import { IconVintageComputer } from '../../../../../../ui/gologin-header/icons/icon-vintage-computer';
import TooltipWrapper from '../../../../../../ui/tooltip-custom/tooltip-wrapper';
import { PROXY_EDIT_FORM_TABS, ProxyEditFormTab } from '../../constants';

type ProxyEditFormFooterTabProps = {
  tabName: ProxyEditFormTab;
}

const ProxyEditFormFooterTab: React.FC<ProxyEditFormFooterTabProps> = (props) => {
  const { tabName } = props;

  const proxyFormCurrentTab = useProxyFormCurrentTab();
  const { modalEditingProxyId } = useProxyManagerState();

  let isDisabled = false;
  let Icon = IconVintageComputer;
  let tooltipTranslationKey = 'proxyManager.proxyForm.customProxyTabTooltip';
  if (tabName === PROXY_EDIT_FORM_TABS.geoProxy) {
    isDisabled = !!modalEditingProxyId;
    Icon = IconPinpoint;
    tooltipTranslationKey = 'proxyManager.proxyForm.geoProxyTabTooltip';
  }

  const handleTabClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    if (!isDisabled) {
      updateProxyFormTab(tabName);
    }
  };

  return (
    <TooltipWrapper
      value={<Trans i18nKey={tooltipTranslationKey} />}
      isTextEllipsis={false}
      containerStyle={{zIndex: 12}}
    >
      <ProxyEditFormFooterTabWrapper
        key={tabName}
        isActive={tabName === proxyFormCurrentTab}
        isDisabled={isDisabled}
        onClick={handleTabClick}
      >
        <Icon iconColor='var(--B5B5BA)' />
      </ProxyEditFormFooterTabWrapper>
    </TooltipWrapper>
  );
};

export default ProxyEditFormFooterTab;
