import css, { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled/macro';

const antDotIconHideStyles = css`
  opacity: 0;

  tr.row-visible-dot & {
    opacity: 1;
  }

  tr:not(.row-active):hover & {
    opacity: 1;
  }

  .ant-drawer-body &,
  tr.row-active .allow-hover & {
    opacity: 1;
  }
`;

export const CheckProxyButtonWrapper = styled.div<{
  newStyle: boolean;
  isTooltipVisible: boolean;
  isCheckButtonVisible?: boolean;
}>`
  width: 16px;
  height: 16px;
  display: flex;
  flex-direction: column;
  position: relative;

  ${(props): SerializedStyles | null => (props.isCheckButtonVisible ? null : antDotIconHideStyles)}

  ${(props): SerializedStyles | null =>
    props.newStyle
      ? css`
          display: ${props.isTooltipVisible || props.isCheckButtonVisible ? 'flex' : 'none'};
          background-color: inherit;
        `
      : null}
`;

export const MouseAreaWrapper = styled.div`
  position: absolute; 
  top: -19px;
  left: -9px;
  right: -9px;
  bottom: -18px;
`;
