import css from '@emotion/css';
import styled from '@emotion/styled/macro';

export const ProxySelectorButtonsWrapper = styled.div<{ isVisible?: boolean }>`
  display: flex;
  white-space: nowrap;
  visibility: ${(props): React.CSSProperties['visibility'] => props.isVisible ? 'visible' : 'hidden'};
`;

const proxySelectorButtonBaseStyle = css`
  outline: none;
  text-decoration: none;
`;

export const ProxySelectorButton = styled.button`
  width: 16px;
  height: 16px;
  padding: 0;
  border: none;
  margin-left: 12px;

  cursor: pointer;
  background: none;

  ${proxySelectorButtonBaseStyle}

  &:focus,
  &:visited,
  &:hover {
    ${proxySelectorButtonBaseStyle}
  }
`;
