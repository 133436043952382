import React from 'react';
import { IconWrapper, IIconWrapper } from '../gologin-header/icons/wrapper';

export const IconClean: React.FC<IIconWrapper> = props => (
  <IconWrapper {...props}>
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_10669_103111)">
        <path d="M14.9996 0.5L9.51562 6.898" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M8.5 6.5C8.89782 6.5 9.27936 6.65804 9.56066 6.93934C9.84196 7.22064 10 7.60218 10 8C9.97715 8.22819 9.92574 8.45261 9.847 8.668C9.35596 9.75196 9.13124 10.9376 9.19161 12.1261C9.25197 13.3146 9.59566 14.4714 10.194 15.5H1C1 10.529 3.529 6.5 8.5 6.5Z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M13 9.5H16" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12 12.5H16" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13 15.5H16" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M6.63833 6.71387C6.50735 7.08867 6.46862 7.48952 6.52541 7.88247C6.58219 8.27542 6.73283 8.6489 6.96456 8.97128C7.1963 9.29367 7.5023 9.55547 7.85667 9.73451C8.21104 9.91356 8.60333 10.0046 9.00033 9.99987C9.1369 9.99746 9.27303 9.98375 9.40733 9.95887"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M4.13981 12.5C3.97789 13.4919 3.95265 14.5013 4.06481 15.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6.79863 12.5C6.74398 13.5114 6.8558 14.5249 7.12963 15.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_10669_103111">
          <rect width="16" height="16" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  </IconWrapper>
);
