import css, { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled/macro';

export const CheckProxyTooltipWrapper = styled.div<{ padding: string; marginTop: number; translateLeft: number }>`
  width: 318px;
  padding: ${(props): string => props.padding};

  ${(props): SerializedStyles | null =>
    props.marginTop
      ? css`
          margin-top: ${props.marginTop}px;
        `
      : null};

  ${(props): SerializedStyles | null =>
    props.translateLeft
      ? css`
          transform: translateX(${props.translateLeft}px);
        `
      : null};
`;

export const CheckProxyTooltipContent = styled.div<{ isProxyManagerWithGroups: boolean; isDarkTheme: boolean }>`
  min-height: 32px;
  outline: var(--gologin-tooltip-outline);
  border-radius: 4px;

  text-align: left;
  text-decoration: none;
  word-wrap: break-word;

  color: var(--000000-proxy-manager-check-proxy-button);
  background: var(--FFFFFF-proxy-tooltip-background);

  ${(props): SerializedStyles =>
    props.isDarkTheme
      ? css`
          box-shadow: -2px 0 8px var(--00000026-antd);
        `
      : css`
          box-shadow: 0px 1px 4px var(--00000003-proxy-manager-check-proxy-button),
            0px 4px 8px var(--00000005-proxy-manager-check-proxy-button),
            0px 1px 12px var(--0000001F-proxy-manager-check-proxy-button);
        `}
`;

