import { CITIES_BY_COUNTRY } from '../../../../common/constants/cities-by-country';
import {
  GEOPROXY_COUNT_KEY_PREFIX,
  GEOPROXY_CITY_KEY_PREFIX,
  GEOPROXY_FORM_ADVANCED_SETTINGS_KEY,
  CURRENT_PROXY_FORM_TAB_KEY,
} from '../../../../common/constants/local-storage';
import { DEFAULT_SELECTED_GEOPROXY_CITY } from '../constants';
import { PROXY_EDIT_FORM_TABS, ProxyEditFormTab } from '../proxy-manager/proxy-edit-view/constants';

type CountryCode = string;
type CityName = string;

export type GeoProxyCustomNameCounterParams = {
  counter: number;
  storageKey: string;
}

export const evaluateGeoProxyCustomNameCounter = (countryCode: CountryCode): GeoProxyCustomNameCounterParams => {
  const storageKey = `${GEOPROXY_COUNT_KEY_PREFIX}${countryCode.toLowerCase()}`;
  const currentCounter = +(localStorage.getItem(storageKey) || '0');

  return {
    counter: currentCounter + 1,
    storageKey,
  };
};

export const storeGeoProxyCustomNameCounter = ({ storageKey, counter }: GeoProxyCustomNameCounterParams): void =>
  localStorage.setItem(storageKey, `${counter}`);

type GeoProxyCityStorageKey = `${typeof GEOPROXY_CITY_KEY_PREFIX}${CountryCode}`

const evaluateGeoProxyCityStorageKey = (countryCode: CountryCode): GeoProxyCityStorageKey =>
  `${GEOPROXY_CITY_KEY_PREFIX}${countryCode.toUpperCase()}`;

export const readStoredGeoProxyCity = (countryCode: CountryCode): CityName => {
  const storageKey = evaluateGeoProxyCityStorageKey(countryCode);
  const storedCity = localStorage.getItem(storageKey) || DEFAULT_SELECTED_GEOPROXY_CITY;

  return storedCity;
};

export const storeGeoProxyCity = (countryCode: CountryCode, city: CityName): void => {
  const storageKey = evaluateGeoProxyCityStorageKey(countryCode);
  localStorage.setItem(storageKey, city);
};

export const removeGeoProxyCityFromStore = (countryCode: CountryCode): void => {
  const storageKey = evaluateGeoProxyCityStorageKey(countryCode);
  localStorage.removeItem(storageKey);
};

export const determineIsCityAvailableInCountry = (countryCode: CountryCode, city: CityName): boolean => {
  const citiesForCountry = CITIES_BY_COUNTRY[countryCode.toUpperCase()] || [];

  return citiesForCountry.some(availableCity => availableCity === city);
};

export const readAreStoredGeoProxyAdvancedSettingsVisible = (): boolean =>
  localStorage.getItem(GEOPROXY_FORM_ADVANCED_SETTINGS_KEY) === 'true';

export const storeAreGeoProxyAdvancedSettingsVisible = (areVisible: boolean): void =>
  localStorage.setItem(GEOPROXY_FORM_ADVANCED_SETTINGS_KEY, `${areVisible}`);

const determineIsProxyFormTab = (value: string): value is ProxyEditFormTab =>
  value === PROXY_EDIT_FORM_TABS.customProxy || value === PROXY_EDIT_FORM_TABS.geoProxy;

export const readStoredProxyFormTab = (): ProxyEditFormTab | null => {
  const storedValue = localStorage.getItem(CURRENT_PROXY_FORM_TAB_KEY);
  if (!(storedValue && determineIsProxyFormTab(storedValue))) {
    return null;
  }

  return storedValue;
};

export const storeProxyFormTab = (tab: ProxyEditFormTab): void =>
  localStorage.setItem(CURRENT_PROXY_FORM_TAB_KEY, tab);
