import React, { FC, memo } from 'react';

import { IBasicTableEntity } from '../../../state/profiles-table/basic-table-entities-subtypes';
import TagsCell from '../../tags/components/tags';

interface ICustomStatusCell {
  profileAtom: IBasicTableEntity['atom'];
  rowIdx: IBasicTableEntity['idx'];
  onTagsPopupOpen: (profileTagsCount: number, totalTagsCount: number) => void;
}

const CustomStatusCell: FC<ICustomStatusCell> = (props) => {
  const {
    profileAtom,
    rowIdx,
    onTagsPopupOpen,
  } = props;

  return (
    <TagsCell
      profileAtom={profileAtom}
      rowIdx={rowIdx}
      onTagsPopupOpen={onTagsPopupOpen}
      field='custom-status'
    />
  );
};

export default memo(CustomStatusCell);
