import { useTranslate } from '@tolgee/react';
import { message } from 'antd';
import React, { MouseEvent, FC, useContext, Dispatch, SetStateAction, useEffect } from 'react';
import 'react-coinbase-commerce/dist/coinbase-commerce-button.css';

import { ModalUpgradeButtonContainer, ModalUpgradeCryptoButton } from './domElements';
import { ButtonPay } from './styles';
import { E_ACTION_INFO, E_ANALYTICS_ACTIONS } from '../../../../common/constants/analytics';
import { FREE_PLAN_ID } from '../../../../common/constants/constants';
import { sendGA4PurchaseEvent } from '../../../GA4';
import { useQuery } from '../../../hooks';
import { IPlan } from '../../../interfaces/plan';
import { Limits } from '../../../interfaces/workspaces/limits';
import { history } from '../../../services';
import {
  useCurrentTabShownCurrency,
  useDiscountObjById,
  usePriceConfig,
  usePromoDiscountAvaliable,
  userContext,
  workspaceContext,
} from '../../../state';
import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import { useIsUpgradeDiscountAvaliable } from '../../../state/pricing/upgrade-discount.atom';
import { getExternalCheckoutUrl } from '../../../utils/open-site';
import { sendReactErrorToSentry } from '../../../utils/sentry.helper';
import { sendActionAnalytics, sendErrorPaymentAnalytics } from '../../common/api';
import { ANNUAL_DISCOUNT, MONTH_DISCOUNT } from '../../modalsComponents/components/checkout-modal/constants';
import PriceList from '../../modalsComponents/components/checkout-modal/price-list';
import { checkPlanMaxProfiles, isDiscountAnnual, getSelectedPlan } from '../../modalsComponents/components/checkout-modal/utils';
import { checkCryptomusOrder, createCryptomusSession, createLimitsPurchaseSession, IGetSessionUrlParams } from '../api';
import { E_PAYMENT_METHODS, E_PAYMENT_METHODS_ANALYTICS, E_PERIOD } from '../interfaces';
import { handlePaymentCreationError } from '../utils/sentry';

const isElectron = !!window.require;

interface ICryptoForm {
  selectedPlan: string;
  selectedDiscount: string;
  hasSuccessPayment?: boolean;
  plans: IPlan[];
  getCheckmarkLine?: () => JSX.Element;
  setIsLoadingModalShown: Dispatch<SetStateAction<boolean>>;
  closePaymentModal: (set: boolean) => void;
  openSuccessModal: (planId: string) => void;
  pricingPlan?: IPlan;
  workspaceId?: string;
  isLimitsPurchase?: boolean;
  isRenewWithAddonLimits?: boolean;
  addonLimits?: Limits;
}

const CryptoForm: FC<ICryptoForm> = (props) => {
  const {
    selectedPlan,
    selectedDiscount,
    hasSuccessPayment,
    plans,
    getCheckmarkLine,
    setIsLoadingModalShown,
    closePaymentModal,
    openSuccessModal,
    pricingPlan,
    workspaceId = '',
    isLimitsPurchase,
    isRenewWithAddonLimits,
    addonLimits,
  } = props;

  const isUpgrade = useIsUpgradeDiscountAvaliable();

  const { _id, profiles: userProfilesCount } = useContext(userContext);
  const { profilesCount: workspaceProfilesCount } = useContext(workspaceContext);

  const tabCurrency = useCurrentTabShownCurrency();

  const query = useQuery();
  const { t: translation } = useTranslate();

  useEffect(() => {
    const checkoutStatus = query.get('status');
    const orderId = query.get('order_id');

    query.delete('status');
    query.delete('session_id');
    if (!checkoutStatus) {
      return;
    }

    if (checkoutStatus === 'success' && orderId) {
      checkOrder(orderId);
    }
  }, []);

  const isAnnual = isDiscountAnnual(selectedDiscount);
  const planPeriod = isAnnual ? E_PERIOD.ANNUAL : E_PERIOD.MONTHLY;
  const profilesCount = NEW_FEATURES.workspaces ? workspaceProfilesCount : userProfilesCount;

  const discountId = isAnnual ? ANNUAL_DISCOUNT : MONTH_DISCOUNT;
  const selectedDiscounObj = useDiscountObjById(discountId);
  const promoDiscount = usePromoDiscountAvaliable();
  const priceConfig = usePriceConfig();

  const handleLimitsPurchase = async (): Promise<void> => {
    const { url: checkoutUrl } = await createLimitsPurchaseSession({
      workspaceId,
      planId: selectedPlan,
      currency: tabCurrency,
      paymentMethod: E_PAYMENT_METHODS.CRYPTO,
      addon: { limits: addonLimits },
    });

    await handleRedirect(checkoutUrl);
  };

  const handleRedirect = async (checkoutUrl: string): Promise<void> => {
    const checkoutPageUrl = await getExternalCheckoutUrl({ checkoutUrl });
    if (isElectron) {
      window.require('electron').shell.openExternal(checkoutPageUrl)
        .catch((error) => {
          const errorMessage = error instanceof Error ? error.message : 'unknown';
          sendReactErrorToSentry({ message: errorMessage, transactionName: 'open-cryptomus-checkout-url' });
        });

      return;
    }

    window.open(checkoutPageUrl, '_blank');
  };

  const handleCheckout = async (event: MouseEvent<HTMLButtonElement>): Promise<void> => {
    const actionInfo = isUpgrade ? E_ACTION_INFO.UPGRADE : E_ACTION_INFO.EMPTY;
    sendActionAnalytics(E_ANALYTICS_ACTIONS.clickedPayNow, { actionInfo, paymentMethod: E_PAYMENT_METHODS.CRYPTO }).catch(() => null);
    event.preventDefault();
    event.stopPropagation();
    if (selectedPlan === FREE_PLAN_ID) {
      return;
    }

    if (isLimitsPurchase) {
      await handleLimitsPurchase();

      return;
    }

    if (hasSuccessPayment) {
      history.replace('/profileList');

      return;
    }

    const planToPay = getSelectedPlan(plans, selectedPlan);
    const status = planToPay && checkPlanMaxProfiles({ translation, planToPay, profiles: profilesCount, paymentMethod: E_PAYMENT_METHODS.CRYPTO });
    if (!status) {
      return;
    }

    const createCheckoutSessionsParams: IGetSessionUrlParams = {
      plan: selectedPlan,
      discount: selectedDiscount,
      workspaceId,
      currency: tabCurrency,
      isUpgrade,
    };

    if (isRenewWithAddonLimits) {
      createCheckoutSessionsParams.addon = { limits: addonLimits };
    }

    const { url: checkoutUrl } = await createCryptomusSession(createCheckoutSessionsParams)
      .catch((error: any) => {
        handlePaymentCreationError({
          error,
          selectedDiscounObj,
          planPeriod,
          method: E_PAYMENT_METHODS.CRYPTO,
          paymentMethod: E_PAYMENT_METHODS_ANALYTICS.CRYPTO,
          promoDiscount,
          plans,
          selectedPlan,
          priceConfig,
          tabCurrency,
        });

        return { url: '' };
      });

    if (!checkoutUrl) {
      return;
    }

    await handleRedirect(checkoutUrl);
  };

  const handleCheckOrderError = (error: any): void => {
    closePaymentModal(true);
    setIsLoadingModalShown(false);
    const errorMessage = error?.message || error?.body?.message || '';
    sendErrorPaymentAnalytics({
      messageText: errorMessage,
      extra: {},
      method: E_PAYMENT_METHODS.CRYPTO,
      paymentError: {
        paymentMethod: E_PAYMENT_METHODS_ANALYTICS.CRYPTO,
        description: errorMessage || 'undefined error',
      },
    });

    if (!errorMessage) {
      return;
    }

    message.error(errorMessage);
  };

  const checkOrder = async (orderId: string): Promise<void> => {
    setIsLoadingModalShown(true);
    const checkOrderResponse = await checkCryptomusOrder(orderId).catch(error => {
      handleCheckOrderError(error);
    });

    if (!checkOrderResponse) {
      return;
    }

    const { paymentId = '', userId, amount, planId, planName: checkoutPlanName } = checkOrderResponse;
    sendGA4PurchaseEvent({
      userId,
      amount,
      planId,
      planName: checkoutPlanName,
      paymentId,
    });

    openSuccessModal(planId);
  };

  if (NEW_FEATURES.pricing) {
    if (!pricingPlan) {
      return null;
    }

    return (
      <>
        <PriceList
          pricingPlan={pricingPlan}
          isAnnual={isAnnual}
        />
        <ButtonPay>
          <ModalUpgradeCryptoButton
            onClick={handleCheckout}
          >
            {translation('pricing.forms.crypto.submitBtnTxt')}
          </ModalUpgradeCryptoButton>
        </ButtonPay>
      </>
    );
  }

  return (
    <>
      {getCheckmarkLine ? getCheckmarkLine() : null}
      <ModalUpgradeButtonContainer paddingBottom={isAnnual}>
        <ButtonPay>
          <ModalUpgradeCryptoButton
            onClick={handleCheckout}
          >
            {translation('pricing.forms.crypto.submitBtnTxt')}
          </ModalUpgradeCryptoButton>
        </ButtonPay>
      </ModalUpgradeButtonContainer>
    </>
  );
};

export default CryptoForm;
