import css, { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled/macro';
import { PaperProps, Popover, PopoverOrigin, PopoverReference } from '@material-ui/core';
import React, { FC } from 'react';

interface IGologinPopoverElement {
  width?: number;
  maxHeight?: number;
  rightTranslate?: number;
  bottomTranslate?: number;
  popoverStyle?: SerializedStyles;
}

export const gologinPopoverElementBaseStyles = css`
  padding: 4px;
  outline: var(--gologin-popover-outline);
  border-radius: 4px;

  background: var(--gologin-popover-background);
  box-shadow: var(--box-shadow-popup);
`;

export const GologinPopoverElement = styled(Popover)<IGologinPopoverElement>`
  .MuiPopover-paper {
    display: flex;
    flex-direction: column;

    gap: 1px;

    width: ${(props): string => props.width + 'px'};
    ${(props): SerializedStyles | null => props.maxHeight ? css`max-height: ${props.maxHeight}px` : null}

    ${gologinPopoverElementBaseStyles}

    ${(props): SerializedStyles | null => props.rightTranslate || props.bottomTranslate ? css`
      transform: translateX(${props.rightTranslate}px) translateY(${props.bottomTranslate}px) !important;
    `: null}

    ${(props): SerializedStyles | null => props.popoverStyle || null}
  }
`;

type TranslateRightBottom = { right: number; bottom: number };
type TranslateLeftTop = { left: number; top: number };
type TranslateAll = TranslateRightBottom & TranslateLeftTop;

const calcTranslate = (extraTranslate?: Partial<TranslateAll>): TranslateRightBottom => {
  const { top = 0, right = 0, bottom = 0, left = 0 } = extraTranslate || {};

  return { right: right - left, bottom: bottom - top };
};

type GologinPopoverProps = {
  trigger?: 'hover' | 'click';
  anchorEl: HTMLElement | null;
  anchorPosition?: TranslateLeftTop;
  onOpen?: (event: React.MouseEvent<HTMLDivElement>) => void;
  onClose?: (event: React.MouseEvent<HTMLDivElement>) => void;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  extraTranslate?: Partial<TranslateAll>;
  width?: number;
  maxHeight?: number;
  zIndex?: number;
  popoverStyle?: SerializedStyles;
}

const GologinPopover: FC<GologinPopoverProps> = (props) => {
  const {
    trigger = 'click',
    children,
    anchorEl,
    anchorPosition,
    onOpen = (): void => void 0,
    onClose,
    anchorOrigin = {
      vertical: 'bottom',
      horizontal: 'center',
    },
    transformOrigin = {
      vertical: 'top',
      horizontal: 'center',
    },
    extraTranslate,
    width = 212,
    maxHeight,
    zIndex = 2000,
    popoverStyle,
  } = props;

  const { right: rightTranslate, bottom: bottomTranslate } = calcTranslate(extraTranslate);

  let paperProps: Partial<PaperProps> = {};
  if (trigger === 'hover') {
    paperProps = { onMouseEnter: onOpen, onMouseLeave: onClose };
  }

  let isOpen = !!anchorEl;
  let anchorReference: PopoverReference = 'anchorEl';
  if (anchorPosition) {
    isOpen = !!(anchorPosition.left + anchorPosition.top);
    anchorReference = 'anchorPosition';
  }

  return (
    <GologinPopoverElement
      open={isOpen}
      anchorEl={anchorEl}
      anchorPosition={anchorPosition}
      anchorReference={anchorReference}
      onClose={onClose}
      transitionDuration={0}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      style={{ zIndex }}
      popoverStyle={popoverStyle}
      width={width}
      maxHeight={maxHeight}
      rightTranslate={rightTranslate}
      bottomTranslate={bottomTranslate}
      PaperProps={paperProps}
    >
      {children}
    </GologinPopoverElement>
  );
};

export default GologinPopover;
