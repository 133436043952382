export const normalizeOrbitaVersions = (supportedOrbitaVersions: `${number}`[] = []): `${number}`[] =>
  supportedOrbitaVersions.reverse().map((version) => {
    switch (version) {
      case '13':
        return '103';
      case '10':
        return '100';
      default:
        return version;
    }
  });

export const extractNumberBrowserVersion = (userAgent = ''): string => {
  const matchChrome = userAgent.match(/Chrome\/(\d+)\./);
  if (matchChrome) {
    return matchChrome[1];
  }

  const matchGeneral = userAgent.match(/\b(\d+)\./);

  return matchGeneral ? matchGeneral[1] : '';
};

export const getBrowserVersionCurrent = (userAgent = ''): string => {
  const match = userAgent.match(/Chrome\/([\d.]+)/);

  return match ? match[1] : '';
};

export const getMajorVersionByUa = (ua = ''): string => {
  const chromeVersionMatch = ua.match(/[\s\S\w]+Chrome\/(\d+)/) || [];
  if (!chromeVersionMatch) {
    return '';
  }

  const [_, version = ''] = chromeVersionMatch;

  if (version === '100') {
    return '10';
  }

  if (version === '103') {
    return '13';
  }

  return version;
};
