import css from '@emotion/css';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';

import { E_ANALYTICS_ACTIONS } from '../../../../common/constants/analytics';
import { GeoProxyType } from '../../../../common/constants/types';
import { stringifyRemainingTrafficAmount } from '../../../../common/proxy/traffic/utils';
import { sendActionAnalytics } from '../../../features/common/api';
import { TRAFFIC_DATA_LOAD_TRIGGERS } from '../../../features/proxy/constants';
import {
  GEO_PROXY_TYPE_HINTS,
  GeoProxyTypeHint,
} from '../../../features/proxy/proxy-manager/proxy-list/proxy-group-add-button/proxy-group-add-button-menu/constants';
import { IMenuItem } from '../../../features/quickProfiles/profile-dropdown-menu/interfaces';
import { loadTrafficData } from '../../../state/proxy/proxy-operations/load-geoproxy-traffic-data.operations';
import { setTrafficModalTrialHintClosed, useIsTrafficModalTrialHintClosed, useProxyTypeVisibility } from '../../../state/proxy/proxy-type-visibility.atom';
import { useIsTrafficDataLoading, useTrafficData } from '../../../state/proxy/traffic-data.atom';
import GologinPopover from '../../gologin-popover';
import { GologinPopoverHint } from '../../gologin-popover/gologin-popover-hint';
import { itemRowIconProps } from '../account-menu/menu-items';
import { IconClose, IconSpinner } from '../icons';
import { IconHome } from '../icons/icon-home';
import { IconProxyDataCenterSmall } from '../icons/icon-proxy-datacenter-small';
import { IconProxyMobile } from '../icons/icon-proxy-mobile';
import { IconThreeEllipses } from '../icons/icon-three-ellipses';
import { IconTrialTrafficGift } from '../icons/icon-trial-traffic-gift';
import { IconWrapperWithDescription } from '../icons/wrapper';
import ProxyTrafficPopover from '../proxy-traffic-popover';
import PriceList from '../proxy-traffic-popover/price-list';
import {
  IconArrow,
  IconCloseWrapper,
  ProxyTrafficItemNumber,
  ProxyTrafficModalContentWrapper,
  ProxyTrafficModalHeader,
  ProxyTrafficModalHeaderButton,
  ProxyTrafficModalHeaderTitle,
  ProxyTrafficModalItem,
  ProxyTrafficModalItemRow,
  ProxyTrafficModalLoaderWrapper,
  ProxyTrafficModalProxyTypeHintWrapper,
  ProxyTrafficModalTrialHintWrapper,
  ProxyTrafficStorageWrapper,
  ProxyyTrafficItemNumeral,
  VolumeProxyContainer,
} from './styles';

type GeoProxyTypeItem = Omit<IMenuItem, 'name' | 'onAction'> & {
  name: GeoProxyType;
  hint: {
    translationKey: string;
  };
};

type ProxyTrafficModalProps = {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  isTrial: boolean;
};

const ProxyTrafficModal: React.FC<ProxyTrafficModalProps> = (props) => {
  const { anchorEl, onClose, isTrial } = props;

  const trafficData = useTrafficData();
  const isTrafficDataLoading = useIsTrafficDataLoading();
  const proxyTypeVisibility = useProxyTypeVisibility();

  const [anchorElPriceList, setAnchorElPriceList] = useState<HTMLElement | null>(null);
  const [selectedType, setSelectedType] = useState<GeoProxyType>(GeoProxyType.Mobile);
  const [activeGeoProxyHint, setActiveGeoProxyHint] = useState<GeoProxyTypeHint | null>(null);
  const [anchorElBuyProxy, setAnchorElBuyProxy] = React.useState<HTMLElement | null>(null);

  const geoProxyTypeItems: GeoProxyTypeItem[] = [
    {
      name: GeoProxyType.Resident,
      translationKey: `proxyTypes.${GeoProxyType.Resident}`,
      icon: <IconHome {...itemRowIconProps} />,
      hint: { translationKey: GEO_PROXY_TYPE_HINTS[GeoProxyType.Resident] },
    },
    {
      name: GeoProxyType.Mobile,
      translationKey: `proxyTypes.${GeoProxyType.Mobile}`,
      icon: <IconProxyMobile {...itemRowIconProps} />,
      hint: { translationKey: GEO_PROXY_TYPE_HINTS[GeoProxyType.Mobile] },
    },
    {
      name: GeoProxyType.DataCenter,
      translationKey: `proxyTypes.${GeoProxyType.DataCenter}`,
      icon: <IconProxyDataCenterSmall {...itemRowIconProps} />,
      hint: { translationKey: GEO_PROXY_TYPE_HINTS[GeoProxyType.DataCenter] },
    },
  ];

  const openPriceList = (type: GeoProxyType, currentTarget: HTMLDivElement): void => {
    setSelectedType(type);
    setAnchorElPriceList(currentTarget);
    sendActionAnalytics(E_ANALYTICS_ACTIONS.visitedBuyProxyDataModalViaLocationData);
  };

  const isTrialHintClose = useIsTrafficModalTrialHintClosed();

  const handleClickOpenTrafficModal: React.MouseEventHandler<HTMLDivElement> = (event) => {
    loadTrafficData(TRAFFIC_DATA_LOAD_TRIGGERS.trafficModal);
    setAnchorElBuyProxy(event.currentTarget);
  };

  const handleClose = (): void => {
    setTrafficModalTrialHintClosed(true);
  };

  const handleCloseTrafficModal = (): void => setAnchorElBuyProxy(null);

  const renderMenuItem = (menuItem: GeoProxyTypeItem): JSX.Element => {
    const { name, translationKey, icon } = menuItem;

    if (!proxyTypeVisibility[name]) {
      return null;
    }

    const handleMenuItemClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
      event.stopPropagation();
    };

    const stringifiedRemainingTrafficAmount = stringifyRemainingTrafficAmount(trafficData, name, true);

    return (
      <ProxyTrafficModalItem
        key={name}
        onClick={handleMenuItemClick}
        onMouseOver={(): void => {
          setActiveGeoProxyHint(GEO_PROXY_TYPE_HINTS[name]);
        }}
        onMouseLeave={(): void => {
          setActiveGeoProxyHint(null);
        }}
      >
        <ProxyTrafficModalItemRow style={{ justifyContent: 'flex-start', columnGap: '8px' }}>
          {icon}
          <span style={{ color: 'var(--767676)' }}>
            <Trans i18nKey={translationKey} />
          </span>
        </ProxyTrafficModalItemRow>
        <ProxyTrafficModalItemRow style={{ alignItems: 'bottom', columnGap: '8px' }}>
          <ProxyTrafficStorageWrapper>
            <ProxyTrafficItemNumber>{stringifiedRemainingTrafficAmount}</ProxyTrafficItemNumber>
            <ProxyyTrafficItemNumeral>GB</ProxyyTrafficItemNumeral>
          </ProxyTrafficStorageWrapper>
          <VolumeProxyContainer onClick={(event): void => openPriceList(name, event.currentTarget)}>
            <IconWrapperWithDescription
              iconColor="var(--00A987)"
              iconHoveredColor="var(--00997A)"
              textColor="var(--00A987)"
              textHoveredColor="var(--00997A)"
              iconType="stroke"
              style={{ display: 'flex', columnGap: '8px' }}
            >
              <span>Top Up</span>
              <IconArrow />
            </IconWrapperWithDescription>
          </VolumeProxyContainer>
        </ProxyTrafficModalItemRow>
      </ProxyTrafficModalItem>
    );
  };

  const renderPopoverContent = (): JSX.Element => {
    if (isTrafficDataLoading && !anchorElBuyProxy) {
      return (
        <ProxyTrafficModalLoaderWrapper>
          <IconSpinner size={24} padding={0} />
        </ProxyTrafficModalLoaderWrapper>
      );
    }

    return (
      <ProxyTrafficModalContentWrapper>
        <ProxyTrafficModalHeader>
          <ProxyTrafficModalHeaderTitle>
            <Trans i18nKey="proxyManager.proxyGroupAddButtonModalTitle" />
          </ProxyTrafficModalHeaderTitle>
          <ProxyTrafficModalHeaderButton>
            <IconThreeEllipses
              styleType={'grayWithBackground'}
              iconColor={'var(--2B2B31-header-right-part)'}
              onClick={handleClickOpenTrafficModal}
              iconHoveredColor="var(--2B2B31-header-right-part)"
              iconType="fill"
              padding={8}
              margin="0 -2px 0 0"
            />
          </ProxyTrafficModalHeaderButton>
        </ProxyTrafficModalHeader>
        {geoProxyTypeItems.map(renderMenuItem)}
        <ProxyTrafficModalProxyTypeHintWrapper onClick={(event): void => event.stopPropagation()}>
          <Trans i18nKey={activeGeoProxyHint || GEO_PROXY_TYPE_HINTS.default} />
        </ProxyTrafficModalProxyTypeHintWrapper>
        <PriceList
          anchorEl={anchorElPriceList}
          onClose={(): void => {
            setAnchorElPriceList(null);
          }}
          price={trafficData.prices}
          geoProxyType={selectedType}
          availableForPurchase={trafficData.availableForPurchase}
          bundle={trafficData.bundlePrices}
        />
        <ProxyTrafficPopover anchorEl={anchorElBuyProxy} onClose={handleCloseTrafficModal} isTrial={false} />
        {isTrial && !isTrialHintClose ? (
          <ProxyTrafficModalTrialHintWrapper>
            <GologinPopoverHint
              style={{ padding: '0', display: 'flex', alignItems: 'center', columnGap: '5px' }}
              onClick={(event): void => event.stopPropagation()}
            >
              <IconTrialTrafficGift iconColor={'var(--00A987)'}></IconTrialTrafficGift>
              <Trans i18nKey={GEO_PROXY_TYPE_HINTS.trialDefault} />
              <IconCloseWrapper>
                <IconClose
                  onClick={handleClose}
                  iconColor="var(--B5B5BA)"
                  iconHoveredColor="var(--gologin-popover-active-icon-color)"
                  iconType="stroke"
                  size={9}
                  strokeWidth={2}
                />
              </IconCloseWrapper>
            </GologinPopoverHint>
          </ProxyTrafficModalTrialHintWrapper>
        ) : null}
      </ProxyTrafficModalContentWrapper>
    );
  };

  return (
    <>
      <GologinPopover
        anchorEl={anchorEl}
        onClose={(event): void => {
          event.stopPropagation();
          onClose();
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        extraTranslate={{ left: 9, bottom: 4 }}
        width={300}
        popoverStyle={css`
          padding: 16px;
          overflow: visible;
          gap: 0;
          background-color: var(--F9F9F9);
          color: var(--767676);
          border-radius: 8px;
        `}
        zIndex={1100}
      >
        {renderPopoverContent()}
      </GologinPopover>
    </>
  );
};

export default ProxyTrafficModal;
