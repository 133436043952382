import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { calculateSelectableConnectionTypes } from '../../../common/proxy/traffic/utils';
import { IGeolocationProxyFullData, IProxyTraffic } from '../../interfaces';

const TRAFFIC_DATA_BY_TYPE: IProxyTraffic = {
  trafficUsed: 0,
  trafficLimit: 0,
  trafficUsedBytes: 0,
  trafficLimitBytes: 0,
};

const MOBILE_BUNDLE_PRICES = [
  { dataAmount: 2, price: { usd: 1500 } },
  { dataAmount: 5, price: { usd: 3500 } },
  { dataAmount: 10, price: { usd: 7000 } },
  { dataAmount: 20, price: { usd: 13000 } },
  { dataAmount: 50, price: { usd: 30000 } },
  { dataAmount: 100, price: { usd: 55000 } },
];

const RESIDENT_BUNDLE_PRICES = [
  { dataAmount: 2, price: { usd: 1500 } },
  { dataAmount: 5, price: { usd: 3000 } },
  { dataAmount: 10, price: { usd: 6000 } },
  { dataAmount: 20, price: { usd: 11000 } },
  { dataAmount: 50, price: { usd: 25000 } },
  { dataAmount: 100, price: { usd: 45000 } },
];

const DATA_CENTER_BUNDLE_PRICES = [
  { dataAmount: 5, price: { usd: 1000 } },
  { dataAmount: 10, price: { usd: 2000 } },
  { dataAmount: 20, price: { usd: 4000 } },
  { dataAmount: 50, price: { usd: 10000 } },
  { dataAmount: 100, price: { usd: 20000 } },
];

export const TRAFFIC_DATA_DEFAULT: IGeolocationProxyFullData = {
  prices: {
    resident: 0,
    mobile: 0,
    dataCenter: 0,
  },
  availableForPurchase: [5, 10, 20, 50, 100],
  residentTrafficData: TRAFFIC_DATA_BY_TYPE,
  mobileTrafficData: TRAFFIC_DATA_BY_TYPE,
  dataCenterTrafficData: TRAFFIC_DATA_BY_TYPE,
  bundlePrices: {
    mobile: MOBILE_BUNDLE_PRICES,
    resident: RESIDENT_BUNDLE_PRICES,
    dataCenter: DATA_CENTER_BUNDLE_PRICES,
  },
};

export const trafficDataAtom = atom<IGeolocationProxyFullData>(TRAFFIC_DATA_DEFAULT);
const isTrafficDataLoadingAtom = atom<boolean>(true);

const setTrafficData = (trafficData: IGeolocationProxyFullData): void =>
  getDefaultStore().set(trafficDataAtom, trafficData);
export const getTrafficData = (): IGeolocationProxyFullData => getDefaultStore().get(trafficDataAtom);
const setIsTrafficDataLoading = (isLoading: boolean): void =>
  getDefaultStore().set(isTrafficDataLoadingAtom, isLoading);

export const useTrafficData = (): IGeolocationProxyFullData => useAtomValue(trafficDataAtom);
export const useIsTrafficDataLoading = (): boolean => useAtomValue(isTrafficDataLoadingAtom);

export const updateIsTrafficDataLoading = (isLoading: boolean): void => {
  setIsTrafficDataLoading(isLoading);
};

export const updateTrafficData = (trafficData: IGeolocationProxyFullData): void => {
  setTrafficData(trafficData);
  updateIsTrafficDataLoading(false);
};

export const clearTrafficData = (): void => {
  updateIsTrafficDataLoading(true);
  setTrafficData(TRAFFIC_DATA_DEFAULT);
};
