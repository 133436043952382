import styled from '@emotion/styled';
import { IIconWrapper } from '../../../../../ui/gologin-header/icons/wrapper';
import { ISelectCustom } from '../../../../../ui/modern-select/interfaces';
import { ADD_BUTTON_ARROW_ACTIVE_COLOR, ADD_BUTTON_ARROW_COLOR } from '../../proxy-list/proxy-group-add-button/proxy-group-add-button-menu/styles';

export const GEOPROXY_MODERN_SELECT_STYLE: React.CSSProperties = {
  position: 'relative',
  top: 0,
  width: 'fit-content',
  height: '100%',
  maxWidth: '100%',
};

export const GEOPROXY_MODERN_SELECT_POPOVER_STYLE: React.CSSProperties = {
  marginLeft: '-16px',
};

export const GEOPROXY_MODERN_SELECT_POPOVER_WIDTH: ISelectCustom['popoverWidth'] = '220px';
export const GEOPROXY_MODERN_SELECT_Z_INDEX: ISelectCustom['zIndex'] = 1060;

export const evaluateGeoProxyModernSelectArrowColor = (isHighlighted: boolean): IIconWrapper['iconColor'] =>
  isHighlighted ? ADD_BUTTON_ARROW_ACTIVE_COLOR : ADD_BUTTON_ARROW_COLOR;


export const GEOPROXY_MODERN_SELECT_RECENT_COUNTRIES_TITLE: string = 'Recent countries';
export const GEOPROXY_MODERN_SELECT_ALL_COUNTRIES_TITLE: string = 'All countries';

export const RemainingTrafficLimit = styled.span`
  color: var(--B5B5BA);
  margin-left: auto;
`;