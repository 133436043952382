import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { CITIES_BY_COUNTRY } from '../../../../../../../common/constants/cities-by-country';
import { NEW_FEATURES } from '../../../../../../state/feature-toggle/new-features';
import { useGeoProxyCountries } from '../../../../../../state/proxy/geoproxy-countries.atom';
import { updateGeoProxyForm, useGeoProxyForm } from '../../../../../../state/proxy/geoproxy-form-data.atom';
import { IconCity } from '../../../../../../ui/gologin-header/icons/icon-city';
import { ModernSelect } from '../../../../../../ui/modern-select';
import { ISelectItem } from '../../../../../../ui/modern-select/interfaces';
import TooltipWrapper from '../../../../../../ui/tooltip-custom/tooltip-wrapper';
import { useProxyFormSelectArrow } from '../../proxy-form-select-arrow.hook';
import {
  GEOPROXY_MODERN_SELECT_POPOVER_STYLE,
  GEOPROXY_MODERN_SELECT_POPOVER_WIDTH,
  GEOPROXY_MODERN_SELECT_STYLE,
  GEOPROXY_MODERN_SELECT_Z_INDEX,
} from '../styles';

const GeoProxyCitySelect: React.FC = () => {
  const geoProxyForm = useGeoProxyForm();
  const geoProxyCountries = useGeoProxyCountries();

  const arrowProps = useProxyFormSelectArrow();

  const { t: translation } = useTranslation();

  const disabledSelectTooltipText = translation('proxyManager.proxyForm.geoProxy.defaultCity');

  const isCitySelectEnabled = !!NEW_FEATURES.proxyEditFormCities;

  const selectedCountry = geoProxyCountries.find(country => country.countryCode === geoProxyForm.country.toUpperCase());
  const selectedCountryCities = selectedCountry?.cityList || [];

  const citySelectableItems: ISelectItem[] = selectedCountryCities.map(cityName => ({
    title: cityName,
    flag: (
      <>
        <IconCity margin="2px 8px 2px 0" padding="0" iconColor="var(--98989F)" />
      </>
    ),
    value: cityName,
  }));

  citySelectableItems.unshift({
    title: 'The fastest server',
    value: 'The fastest server',
    isSearchIgnored: true,
  });

  const selectItems = [
    {
      groupTitle: 'Available cities',
      selectItems: [...citySelectableItems],
    },
  ];

  let currentValue = geoProxyForm.city;
  const titleStyle: React.CSSProperties = {};

  const handleSelectCity = (city: string): void => {
    updateGeoProxyForm({ city: city });
  };

  const renderSelect = (): JSX.Element =>
    selectedCountryCities?.length > 0 ? (
      <ModernSelect
        currentValue={geoProxyForm.city}
        itemList={selectItems}
        title={currentValue || 'The fastest server'}
        hasSearch
        onSelected={handleSelectCity}
        style={GEOPROXY_MODERN_SELECT_STYLE}
        titleStyle={titleStyle}
        popoverStyle={GEOPROXY_MODERN_SELECT_POPOVER_STYLE}
        popoverWidth={GEOPROXY_MODERN_SELECT_POPOVER_WIDTH}
        zIndex={GEOPROXY_MODERN_SELECT_Z_INDEX}
        disabled={!isCitySelectEnabled}
        {...arrowProps}
      />
    ) : (
      <TooltipWrapper
        value={<Trans i18nKey="proxyManager.proxyForm.geoProxy.cityTooltip" />}
        isTextEllipsis={false}
        style={{ width: 172, textAlign: 'center' }}
      >
        <div>The fastest server</div>
      </TooltipWrapper>
    );

  if (isCitySelectEnabled) {
    return renderSelect();
  }

  currentValue = disabledSelectTooltipText;
  titleStyle.color = 'var(--36363D)';

  return (
    <TooltipWrapper
      value={<Trans i18nKey="proxyManager.proxyForm.geoProxy.cityTooltip" />}
      isTextEllipsis={false}
      style={{ width: 172, textAlign: 'center' }}
    >
      <div>The fastest server</div>
    </TooltipWrapper>
  );
};

export default GeoProxyCitySelect;
