import styled from '@emotion/styled';
import React, { FC } from 'react';

import { openTableCustomization, useTableCustomizationMenu } from './table-customization-menu-state.atom';
import { IconGear } from '../../../ui/gologin-header/icons';
import { borderRadiusContainer } from '../../../ui/style-templates';

const OuterGradientContainer = styled('div')`
  position: absolute;

  display: flex;
  flex-direction: row-reverse;
  align-items: center;

  padding-right: 16px;

  width: 80px;
  height: 54px;
  font-family: 'Roboto';
  font-style: normal;
  cursor: pointer;
  z-index: 100;
  right: 0;
  bottom: 0;
  background: linear-gradient(270deg, var(--FFFFFF-profile-table) 60%, var(--00D4FF00) 100%);
  ${borderRadiusContainer()};
`;

export const OutlineContainer = styled('div')<{ isSelected?: boolean }>`
  border-radius: 4px;
  padding: 8px;

  svg {
    stroke: ${(props): string => props.isSelected ? 'var(--36363D)' : 'var(--98989F)'};
  }

  &:hover svg, &:focus svg {
    stroke: var(--36363D);
  }
`;

const TableCustomizationSettingsButton: FC = () => {
  const { rootAnchorEl } = useTableCustomizationMenu();
  const isSelected = !!rootAnchorEl;

  return (
    <OuterGradientContainer>
      <OutlineContainer onClick={openTableCustomization} isSelected={isSelected}>
        <IconGear padding={0} />
      </OutlineContainer>
    </OuterGradientContainer>
  );
};

export default TableCustomizationSettingsButton;
