import React, { useEffect, useRef, useState } from 'react';
import { Trans } from 'react-i18next';

import { E_ANALYTICS_ACTIONS } from '../../../../../../common/constants/analytics';
import { GeoProxyType } from '../../../../../../common/constants/types';
import { stringifyRemainingTrafficAmount } from '../../../../../../common/proxy/traffic/utils';
import { loadTrafficData } from '../../../../../state/proxy/proxy-operations/load-geoproxy-traffic-data.operations';
import { useIsTrafficDataLoading, useTrafficData } from '../../../../../state/proxy/traffic-data.atom';
import { IconPlusFill, IconSpinner } from '../../../../../ui/gologin-header/icons';
import { IconHome } from '../../../../../ui/gologin-header/icons/icon-home';
import { IconProxyDataCenterSmall } from '../../../../../ui/gologin-header/icons/icon-proxy-datacenter-small';
import { IconProxyMobile } from '../../../../../ui/gologin-header/icons/icon-proxy-mobile';
import PriceList from '../../../../../ui/gologin-header/proxy-traffic-popover/price-list';
import {
  ProxyTrafficPopoverLoaderWrapper,
  ProxyTrafficPopoverRow,
  ProxyTrafficPopoverTrialHintWrapper,
  VolumeProxyContainer,
} from '../../../../../ui/gologin-header/proxy-traffic-popover/styles';
import { GologinPopoverHint } from '../../../../../ui/gologin-popover/gologin-popover-hint';
import { sendActionAnalytics } from '../../../../common/api';
import { IMenuItem } from '../../../../quickProfiles/profile-dropdown-menu/interfaces';
import { itemRowIconProps } from '../../../../quickProfiles/profile-dropdown-menu/styles';
import { TRAFFIC_DATA_LOAD_TRIGGERS } from '../../../constants';
import { GEO_PROXY_TYPE_HINTS } from '../../../proxy-manager/proxy-list/proxy-group-add-button/proxy-group-add-button-menu/constants';
import {
  AddButtonPopoverRowContent,
  AddButtonPopoverRowRightControls,
  AddButtonPopoverRowTitle,
} from '../../../proxy-manager/proxy-list/proxy-group-add-button/proxy-group-add-button-menu/styles';
import { HintTextWrapper, RenderMenuItemRightControlsWrapper, RowTitleWrapper } from './styles';

type GeoProxyTypeItem = Omit<IMenuItem, 'name' | 'onAction'> & {
  name: GeoProxyType;
  hint: {
    translationKey: string;
  };
};

interface ProxyDataAvailabilityProps {
  geoProxyType?: GeoProxyType;
}

const geoProxyTypeItems: GeoProxyTypeItem[] = [
  {
    name: GeoProxyType.Resident,
    translationKey: `proxyTypes.${GeoProxyType.Resident}`,
    icon: <IconHome {...itemRowIconProps} />,
    hint: { translationKey: GEO_PROXY_TYPE_HINTS[GeoProxyType.Resident] },
  },
  {
    name: GeoProxyType.Mobile,
    translationKey: `proxyTypes.${GeoProxyType.Mobile}`,
    icon: <IconProxyMobile {...itemRowIconProps} />,
    hint: { translationKey: GEO_PROXY_TYPE_HINTS[GeoProxyType.Mobile] },
  },
  {
    name: GeoProxyType.DataCenter,
    translationKey: `proxyTypes.${GeoProxyType.DataCenter}`,
    icon: <IconProxyDataCenterSmall {...itemRowIconProps} />,
    hint: { translationKey: GEO_PROXY_TYPE_HINTS[GeoProxyType.DataCenter] },
  },
];

const ProxyDataAvailability: React.FC<ProxyDataAvailabilityProps> = ({ geoProxyType }) => {
  const [anchorElPriceList, setAnchorElPriceList] = useState<HTMLElement | null>(null);
  const [activeGeoProxyHint, setActiveGeoProxyHint] = useState<React.ReactNode | null>(null);
  const [showHint, setShowHint] = useState(false);
  const [isLoadingTrafficData, setIsLoadingTrafficData] = useState(false);
  const [selectedType, setSelectedType] = useState<GeoProxyType>(GeoProxyType.DataCenter);
  const [popoverPosition, setPopoverPosition] = useState({
    top: 'calc(100%)',
    left: '0',
    bottom: 'auto',
    right: 'auto',
    padding: '6px 0 0 0',
  });

  const isTrafficDataLoading = useIsTrafficDataLoading();
  const [bgColor, setBgColor] = useState('var(--FFFFFF-proxy-tooltip-background)');
  const [textColor, setTextColor] = useState('var(--36363D-proxy-tooltip-text)');
  const trafficData = useTrafficData();
  const popoverRef = useRef<HTMLDivElement>(null);
  const volumeRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (geoProxyType) {
      setSelectedType(geoProxyType);
    }
  }, [geoProxyType]);

  const handleClickRefreshTraffic: React.MouseEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation();
    setIsLoadingTrafficData(true);
    loadTrafficData(TRAFFIC_DATA_LOAD_TRIGGERS.proxyTooltip).then(() => {
      setIsLoadingTrafficData(false);
    });
  };

  const openPriceList = (type: GeoProxyType, currentTarget: HTMLDivElement | null): void => {
    setSelectedType(type);
    setAnchorElPriceList(currentTarget);
    sendActionAnalytics(E_ANALYTICS_ACTIONS.visitedBuyProxyDataModalViaCheckTooltip);
  };

  useEffect(() => {
    const calculatePosition = () => {
      if (popoverRef.current) {
        const rect = popoverRef.current.getBoundingClientRect();
        const distanceFromLeft = rect.left;
        const distanceFromBottom = window.innerHeight - rect.bottom;
        const popoverHeight = popoverRef.current.offsetHeight + 10;
        const popoverWidth = popoverRef.current.offsetWidth + 2;
        if (distanceFromBottom < popoverHeight) {
          if (distanceFromLeft < popoverWidth) {
            setPopoverPosition({
              top: 'auto',
              bottom: '0',
              left: 'calc(100%)',
              right: '0',
              padding: '0 0 0 4px',
            });
          } else {
            setPopoverPosition({
              top: 'auto',
              bottom: '0',
              left: 'auto',
              right: 'calc(100%)',
              padding: '0 4px 0 0',
            });
          }
        } else if (distanceFromLeft < popoverWidth) {
          setPopoverPosition({
            top: 'calc(100%)',
            left: 'auto',
            bottom: 'auto',
            right: '0',
            padding: '6px 0 0 0',
          });
        } else {
          setPopoverPosition({
            top: 'calc(100%)',
            left: 'auto',
            bottom: 'auto',
            right: 'auto',
            padding: '6px 0 0 0',
          });
        }
      }
    };

    calculatePosition();
    window.addEventListener('resize', calculatePosition);

    return () => window.removeEventListener('resize', calculatePosition);
  }, [geoProxyType]);
  const getTypeName = (type: GeoProxyType) => {
    switch (type) {
      case GeoProxyType.Mobile:
        return 'Mobile';
      case GeoProxyType.DataCenter:
        return 'Data Center';
      case GeoProxyType.Resident:
        return 'Residential';
      default:
        return '';
    }
  };

  useEffect(() => {
    if (geoProxyType) {
      const stringifiedRemainingTrafficAmount = stringifyRemainingTrafficAmount(trafficData, geoProxyType);
      if (stringifiedRemainingTrafficAmount === '0.0') {
        setBgColor('var(--FFF2F2-proxy-tooltip-background-error)');
        setTextColor('var(--EB5757-proxy-tooltip-text-error)');
        setActiveGeoProxyHint(
          <span style={{ display: 'inline' }}>
            <Trans
              i18nKey={'proxies.tooltip.DataShortageHint'}
              values={{ GeoProxyTypeName: getTypeName(geoProxyType) }}
              components={{
                b: <b style={{ fontWeight: '500' }} />,
                underline: <span style={{ textDecoration: 'underline' }} />,
              }}
            />
          </span>,
        );
      } else {
        let icon;
        setBgColor('var(--FFFFFF-proxy-tooltip-background)');
        setTextColor('var(--36363D-proxy-tooltip-text)');
        switch (geoProxyType) {
          case GeoProxyType.Mobile:
            icon = <IconProxyMobile color='#B5B5BA' />;
            break;
          case GeoProxyType.DataCenter:
            icon = <IconProxyDataCenterSmall color='#B5B5BA' />;
            break;
          case GeoProxyType.Resident:
            icon = <IconHome color='#B5B5BA' />;
            break;
          default:
            icon = null;
        }

        setActiveGeoProxyHint(
          <HintTextWrapper>
            <div style={{ paddingTop: '1px' }}>
              {icon ? React.cloneElement(icon, { padding: 0, display: 'block', color: '#B5B5BA' }) : null}
            </div>
            <span style={{ display: 'inline' }}>
              <Trans
                i18nKey={'proxies.tooltip.DataHint'}
                values={{ GeoProxyTypeName: getTypeName(geoProxyType) }}
                components={{
                  b: <b style={{ fontWeight: '500' }} />,
                  underline: <span style={{ textDecoration: 'underline' }} />,
                }}
              />
            </span>
          </HintTextWrapper>,
        );
      }
    }
  }, [geoProxyType, trafficData]);

  const renderMenuItemRightControls = (geoProxyType: GeoProxyType): JSX.Element => {
    const stringifiedRemainingTrafficAmount = stringifyRemainingTrafficAmount(trafficData, geoProxyType);

    return (
      <RenderMenuItemRightControlsWrapper
        style={{ color: `${textColor}` }}
        onClick={(event): void => openPriceList(geoProxyType, event.currentTarget)}
        ref={volumeRef}
      >
        <VolumeProxyContainer>
          {isTrafficDataLoading && isLoadingTrafficData ? (
            <ProxyTrafficPopoverLoaderWrapper style={{ height: 'auto' }}>
              <IconSpinner size={15} padding={0} />
            </ProxyTrafficPopoverLoaderWrapper>
          ) : (
            <Trans i18nKey='proxyManager.trafficRemainingLimit' values={{ limit: stringifiedRemainingTrafficAmount }} />
          )}
          <IconPlusFill
            padding={0}
            margin='0 0 0 8px'
            iconColor='var(--00A987-header-buy-proxy)'
            iconHoveredColor='var(--00997A-header-buy-proxy)'
          />
        </VolumeProxyContainer>
      </RenderMenuItemRightControlsWrapper>
    );
  };

  const renderMenuItem = (menuItem: GeoProxyTypeItem): JSX.Element => {
    const { name, translationKey, icon } = menuItem;
    const stringifiedRemainingTrafficAmount = stringifyRemainingTrafficAmount(trafficData, name);

    const isLowTraffic = stringifiedRemainingTrafficAmount === '0.0';
    const iconColor = isLowTraffic ? '#EB5757' : '#B5B5BA';

    return (
      <ProxyTrafficPopoverRow
        key={name}
        onClick={(event): void => openPriceList(name, volumeRef.current)}
        onMouseOver={() => setShowHint(true)}
        style={{
          height: 'auto',
          padding: '0',
          backgroundColor: bgColor,
        }}
      >
        <AddButtonPopoverRowContent
          style={{
            cursor: 'pointer',
          }}
        >
          <RowTitleWrapper
            style={{
              backgroundColor: bgColor,
              color: textColor,
              alignItems: 'center',
            }}
          >
            {React.cloneElement(icon, { color: iconColor })}
            <AddButtonPopoverRowTitle style={{ maxWidth: '100%', color: textColor }}>
              {stringifiedRemainingTrafficAmount === '0.0' ? (
                <Trans
                  i18nKey={'proxies.tooltip.DataShortagePreview'}
                  components={{
                    b: <b style={{ fontWeight: '500' }} />,
                    underline: <span style={{ textDecoration: 'underline' }} />,
                  }}
                />
              ) : (
                <Trans
                  i18nKey={'proxies.tooltip.DataPreview'}
                  components={{
                    b: <b style={{ fontWeight: '500' }} />,
                    underline: <span style={{ textDecoration: 'underline' }} />,
                  }}
                />
              )}
            </AddButtonPopoverRowTitle>
          </RowTitleWrapper>
          <AddButtonPopoverRowRightControls style={{ minWidth: 'max-content' }}>
            {renderMenuItemRightControls(name)}
          </AddButtonPopoverRowRightControls>
        </AddButtonPopoverRowContent>
      </ProxyTrafficPopoverRow>
    );
  };

  const renderPopoverContent = (): JSX.Element => (
    <>
      <div
        onMouseLeave={(): void => {
          setShowHint(false);
        }}
        onClick={(event): void => {
          event.stopPropagation();
        }}
        ref={popoverRef}
        style={{
          position: 'relative',
          minWidth: '100%',
          borderTop: '0.5px solid var(--D2D2D5-proxy-tooltip-border-top)',
          boxSizing: 'content-box',
        }}
      >
        {geoProxyTypeItems.filter((item) => item.name === selectedType).map(renderMenuItem)}
        {showHint ? (
          <ProxyTrafficPopoverTrialHintWrapper
            style={{
              top: popoverPosition.top,
              bottom: popoverPosition.bottom,
              left: popoverPosition.left,
              right: popoverPosition.right,
              padding: popoverPosition.padding,
              minWidth: '100%',
              marginLeft: '0',
              outline: 'none',
              borderRadius: '0',
              boxShadow: 'none',
              backgroundColor: 'transparent',
            }}
          >
            <GologinPopoverHint
              style={{
                minWidth: '100%',
                padding: '12px 10px 12px 16px',
                backgroundColor: 'var(--FFFFFF-proxy-tooltip-background)',
                outline: 'var(--gologin-popover-outline)',
                borderRadius: '4px',
                boxShadow: 'var(--box-shadow-popup)',
                cursor: 'pointer',
              }}
              onClick={(event): void => {
                handleClickRefreshTraffic(event);
              }}
            >
              <div
                style={{
                  color: 'var(--36363D-proxy-tooltip-text)',
                }}
              >
                {activeGeoProxyHint}
              </div>
            </GologinPopoverHint>
          </ProxyTrafficPopoverTrialHintWrapper>
        ) : null}
      </div>
    </>
  );

  return (
    <>
      {renderPopoverContent()}
      <PriceList
        anchorEl={anchorElPriceList}
        onClose={(): void => {
          setAnchorElPriceList(null);
        }}
        price={trafficData.prices}
        geoProxyType={selectedType}
        availableForPurchase={trafficData.availableForPurchase}
        visionSide='right'
        bundle={trafficData.bundlePrices}
      />
    </>
  );
};

export default ProxyDataAvailability;
