import * as Sentry from '@sentry/react';
import { message } from 'antd';
import { changeLanguage } from 'i18next';
import React, { FC, memo, useEffect, useRef, useState } from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { useQuery } from '../../../../hooks';
import useGlobalAntStyles from '../../../../hooks/use-global-ant-styles';
import { history } from '../../../../services';
import { REGISTER_CONFIG } from '../../../../services/http/config';
import { switchTheme } from '../../../../state/theme.atom';
import { reactLoadingCompletedHandler } from '../../../../utils/app-loading.helper';
import { isEmailValid } from '../../../../utils/email-validator';
import { ReactError } from '../../../../utils/sentry-parameters/custom-errors';
import { sendActionAnalytics } from '../../../common/api';
import LoadingPage from '../../../common/loading-page';
import { SHARE_LINK_KEY } from '../../../share-links/constants';
import { handleRedirectViaShareLink } from '../../../share-links/share-link-page/utils';
import { checkAuthentication } from '../../helpers';
import OAuth from '../../o-auth';
import {
  PageWrapper,
  AuthContentContainer,
  AuthTextField,
  Form,
  NavigationContainer,
  NavigationLink,
  SubmitButton,
  Title,
} from '../../StyledComponents';
import { TurnstileCaptcha } from '../../StyledComponents/Captcha';
import { createUser } from '../api';
import { sendStats } from '../helpers';
import { startFirstSession } from '../../../../state/first-session-status.atom';

interface IMessageOAuth {
  type: 'open-oauth';
  message: {
    url: string;
  };
}

interface ICaptchaMessage {
  type: 'captcha-result';
  message: {
    captchaToken: string;
  };
}

interface IMessageShowCaptcha {
  type: 'show-captcha';
}

type IMessage = ICaptchaMessage|IMessageOAuth|IMessageShowCaptcha;

const isElectron = !!window.require;
const { captchaUrl } = REGISTER_CONFIG;

type TErrorInputs = 'email'|'password'|'confirmPassword';

const SignUp: FC = () => {
  const [errorInputs, setErrorInputs] = useState<TErrorInputs[]>([]);
  const [captchaToken, setCaptchaToken] = useState<string>('');

  const [isFrameLoaded, setIsFrameLoaded] = useState(false);
  const [isFrameHidden, setIsFrameHidden] = useState(true);
  const [isNeedToShowCaptcha, setIsNeedToShowCaptcha] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isElectronState, setIsElectronState] = useState<boolean>(isElectron);

  const emailFieldRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const passwordFieldRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const confirmPasswordFieldRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  const { t: translation } = useTranslation();
  const query = useQuery();

  const { areAntStylesLoading } = useGlobalAntStyles();

  useEffect(() => {
    checkAuthentication();
    sendStats();

    const locale = query.get('locale');
    const isQueryElectron = query.get('isElectron') === 'true' || isElectron;
    setIsElectronState(isQueryElectron);

    const shareLink = query.get('share-link');
    if (shareLink) {
      sendActionAnalytics('visited sign up via share profile link');
    }

    locale && changeLanguage(locale);
  }, []);

  useEffect(() => {
    if (!isLoading) {
      return;
    }

    const isNeedToShowLoader = !captchaToken && isFrameHidden;
    setIsLoading(isNeedToShowLoader);

    if (captchaToken) {
      onCreateFormSubmit({ force: true });
    }
  }, [captchaToken, isFrameHidden]);

  useEffect(() => {
    if (!isNeedToShowCaptcha) {
      return;
    }

    setIsFrameHidden(false);
  }, [isNeedToShowCaptcha]);

  const getTitle = (): string => {
    const titleKey = query.get('share-link') ?
      'authAndShare.registration.title' : 'auth.registration.title';

    return translation(titleKey);
  };

  const shareLink = sessionStorage.getItem(SHARE_LINK_KEY);

  const createAccount = async (token?: string): Promise<void> => {
    const email = emailFieldRef.current.value;
    const password = passwordFieldRef.current.value;
    const confirmPassword = confirmPasswordFieldRef.current.value;

    const isFormFilledOut = email && password && confirmPassword;
    if (!isFormFilledOut && token) {
      return;
    }

    if (!captchaToken) {
      message.error(translation('auth.registration.captchaNotVerified'));

      return;
    }

    setIsLoading(true);

    let localGoogleClientId;
    if (isElectron) {
      localGoogleClientId = localStorage.getItem('localGoogleClientId');
    }

    try {
      const response = await createUser({
        localGoogleClientId,
        email,
        passwordConfirm: confirmPassword,
        password,
        captchaToken: token || captchaToken,
      }).catch(() => {
        setIsLoading(false);
        setCaptchaToken('');
        requestNewCaptchaToken();
      });

      if (!response) {
        return;
      }

      if (window.dataLayer && Array.isArray(window.dataLayer)) {
        window.dataLayer.push({ event: 'CompleteRegistration' });
      }

      if (window.ga) {
        window.ga('set', '&uid', response.body?._id);
        window.ga('set', '&cd2', response.body?._id);
        window.ga('send', 'CompleteRegistration-ga');
      }

      startFirstSession();
      switchTheme('light');
      history.replace('/quiz');
    } finally {
      setIsLoading(false);
    }
  };

  const handleFrameLoaded = (): void => {
    setIsFrameLoaded(true);
  };

  const requestNewCaptchaToken = (): void => {
    const captchaIframe = document.getElementById('captcha-iframe') as HTMLIFrameElement;
    if (!captchaIframe) {
      return;
    }

    captchaIframe.contentWindow?.postMessage('request-new-token', '*');
  };

  const handleMessage = (ev: { data: IMessage }): void => {
    switch (ev.data.type) {
      case 'open-oauth': {
        const { message } = ev.data;
        window.require('electron').shell.openExternal(message.url);

        break;
      }
      case 'captcha-result': {
        const { message } = ev.data;
        const { captchaToken: token } = message;
        setCaptchaToken(token);

        break;
      }
      case 'show-captcha':
        setIsNeedToShowCaptcha(true);

        break;
      default:
        break;
    }
  };

  const onCreateFormSubmit = ({ force, event }: {force?: boolean; event?: React.FormEvent<HTMLFormElement>}): void => {
    event?.preventDefault();

    if (isLoading && !force) {
      return;
    }

    const isNeedToShowLoader = !captchaToken && isFrameHidden;
    if (isNeedToShowLoader) {
      setIsLoading(isNeedToShowLoader);

      return;
    }

    const email = emailFieldRef.current.value;
    const password = passwordFieldRef.current.value;
    const confirmPassword = confirmPasswordFieldRef.current.value;

    const isEmailValidationSuccess = isEmailValid(email);
    if (!isEmailValidationSuccess) {
      emailFieldRef.current.focus();
      setErrorInputs(['email']);

      return;
    }

    if (password !== confirmPassword) {
      setErrorInputs(['password', 'confirmPassword']);

      return;
    }

    createAccount();
  };

  useEffect(() => {
    reactLoadingCompletedHandler();
    if (!isElectron && shareLink) {
      handleRedirectViaShareLink(shareLink);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('message', handleMessage);

    return (): void => window.removeEventListener('message', handleMessage);
  }, [handleMessage]);

  const handleCaptchaFrameError = (error: unknown): void => {
    console.error(error);

    const errorMessage = error instanceof Error ? error.message : 'unknown';
    Sentry.captureException(new ReactError(errorMessage), (scope) => {
      scope.setLevel('error');
      scope.setTransactionName('turnstile-captcha-challenge-error');
      scope.setFingerprint(['turnstile-captcha-challenge-error']);

      return scope;
    });
  };

  if (areAntStylesLoading) {
    return <LoadingPage />;
  }

  return (
    <PageWrapper>
      <AuthContentContainer>
        <Title>
          {getTitle()}
        </Title>
        <OAuth.Buttons isElectron={isElectronState} isIFrame={true} />
        <Form onSubmit={(event): void => onCreateFormSubmit({ event })}>
          <AuthTextField
            status={errorInputs.includes('email') ? 'error' : 'default'}
            ref={emailFieldRef}
            placeholder={translation('auth.registration.form.emailInputPlaceholder') || ''}
            type='email'
            required={true}
            autoFocus={true}
          />
          <AuthTextField
            status={errorInputs.includes('password') ? 'error' : 'default'}
            ref={passwordFieldRef}
            placeholder={translation('auth.registration.form.passwordInputPlaceholder') || ''}
            type='password'
            minLength={8}
            maxLength={256}
            required={true}
          />
          <AuthTextField
            status={errorInputs.includes('confirmPassword') ? 'error' : 'default'}
            ref={confirmPasswordFieldRef}
            placeholder={translation('auth.registration.form.confirmPasswordInputPlaceholder') || ''}
            type='password'
            minLength={8}
            maxLength={256}
            required={true}
          />
          <SubmitButton type='submit' loading={isLoading}>
            {translation('auth.registration.form.submitBtnTxt')}
          </SubmitButton>
        </Form>
        <TurnstileCaptcha
          id='captcha-iframe'
          src={captchaUrl}
          onLoad={handleFrameLoaded}
          onError={handleCaptchaFrameError}
          isFrameHidden={isFrameHidden}
        />
        <NavigationContainer style={{ marginTop: isFrameHidden ? 32 : 27 }}>
          <NavigationLink to='/sign_in'>
            {translation('auth.registration.links.logIn')}
          </NavigationLink>
        </NavigationContainer>
      </AuthContentContainer>
      <Helmet>
        <title>
          {translation('auth.registration.windowTitle')}
          {' - GoLogin'}
        </title>
      </Helmet>
    </PageWrapper>
  );
};

export default memo(SignUp);
