import React, { FC, useCallback, useEffect, useState } from 'react';

import { SettingsSections } from './sections-group';
import { buildProfile } from './utils/build-profile';
import { getProfileInfo } from './utils/get-profile';
import {
  IFullProfile,
  IProfile,
} from '../../../interfaces';
import {
  getProfileSettingsFullProfile,
  setProfileSettingsFullProfile,
  useProfileSettingsFullProfile,
} from '../../../state/profile-settings-full-profile.atom';
import { mapAndSetProfilesList, useProfilesList } from '../../../state/profiles-list.atom';
import {
  getProfile,
  refreshFullProfile,
  updateFullProfilesMap,
} from '../../../state/profiles-map.atom';
import { useProfilesSettingsState } from '../../../state/profiles-settings-atom';
import { IProfileDrawer } from '../../drawer/interfaces';
import { updateProfile } from '../../updateProfile/api';
import { ContentContainer, SettingsSectionsContainer } from '../styles';

interface IProfileSettingsContent extends IProfileDrawer {
  profileData: IProfile;
}

const ProfileSettingsContent: FC<IProfileSettingsContent> = (props) => {
  const { profileData } = props;

  const fullProfile = useProfileSettingsFullProfile();

  const { profileIds } = useProfilesSettingsState();
  const isMultiProfilesSettings = profileIds.length > 1;

  const [cookiesLoading, setCookiesLoading] = useState<boolean>(false);

  const currentProfile = getProfile(profileData.id);

  const changeSetting = useCallback((newData: Partial<IFullProfile>): void => {
    updateProfileData(newData);
    saveProfileData(buildProfile(fullProfile, newData));
  }, [fullProfile]);

  useEffect(() => {
    if (!(profileData.id && currentProfile)) {
      return;
    }

    getProfileInfo({
      profileData: currentProfile,
      updateProfile: refreshFullProfile,
      setCookiesLoading,
    });
  }, [profileData.id, isMultiProfilesSettings]);

  useEffect(() => {
    if (!currentProfile) {
      return;
    }

    setProfileSettingsFullProfile(currentProfile);
  }, [currentProfile]);

  useEffect(() => {
    const profileSettingsFullProfile = getProfileSettingsFullProfile();
    const newProfile = buildProfile(profileSettingsFullProfile, profileData);
    updateFullProfilesMap([newProfile]);
    setProfileSettingsFullProfile(newProfile);
  }, [profileData]);

  const updateProfileData = (newData: Partial<IFullProfile>): void => {
    const profileSettingsFullProfile = getProfileSettingsFullProfile();
    mapAndSetProfilesList(profiles => {
      const newProfiles = profiles.map(profile => {
        if (profile.id === profileSettingsFullProfile.id) {
          return buildProfile(profileSettingsFullProfile, newData);
        }

        return profile;
      });

      updateFullProfilesMap(newProfiles);

      return newProfiles;
    });
  };

  const saveProfileData = async (updatedProfile: IFullProfile): Promise<void> => {
    const profileSettingsFullProfile = getProfileSettingsFullProfile();
    await updateProfile({ profileId: profileSettingsFullProfile.id, profileData: updatedProfile });
  };

  return (
    <ContentContainer>
      <SettingsSectionsContainer>
        <SettingsSections
          {...props}
          fullProfile={fullProfile}
          changeSetting={changeSetting}
          cookiesLoading={cookiesLoading}
          updateStateProfile={updateProfileData}
        />
      </SettingsSectionsContainer>
    </ContentContainer>
  );
};

export default ProfileSettingsContent;
