import { findObjectPoolFolderProfile } from '../../object-pool.atom';
import type { ObjectPoolFolderProfile } from '../../types';
import { handleUpdatedFoldersProfiles } from '../handle-updated-folders-profiles';

export const acceptUnpinTransaction = async (folderId?: string, profileId?: string): Promise<void> => {
  if (!(folderId && profileId)) {
    return;
  }

  const folderProfile = findObjectPoolFolderProfile(folderId, profileId);
  if (!folderProfile) {
    return;
  }

  const updatedFolderProfile: ObjectPoolFolderProfile = { ...folderProfile, isPinned: false };

  return handleUpdatedFoldersProfiles({ foldersProfiles: [updatedFolderProfile] });
};
