import { css, SerializedStyles } from '@emotion/core';
import styled from '@emotion/styled';
import { Popover } from '@material-ui/core';

import { gologinPopoverElementBaseStyles } from '../../gologin-popover';
import { GologinPopoverHint } from '../../gologin-popover/gologin-popover-hint';
import { GologinPopoverItemRow } from '../../gologin-popover/gologin-popover-item-row';
import IconArrowDown3 from '../../icons/IconArrowDown3';
import { textFont } from '../../style-templates';
import { IconWrapperWithDescription } from '../icons/wrapper';

const STANDARD_TEXT_LINE_HEIGHT = 20;
const HINT_MAXIMUM_LINES = 4;
const TRIAL_HINT_DISTANCE = 12;

// the element height must be adjusted by appropriate translation texts
// so the need in ellipsis is not occurred
const unsafeVerticalEllipsis = css`
  display: -webkit-box;
  -webkit-line-clamp: ${HINT_MAXIMUM_LINES};
  -webkit-box-orient: vertical;

  text-overflow: ellipsis;
  overflow-y: hidden;
`;

export const ProxyTrafficModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 4px;
`;

export const ProxyTrafficModalHeaderTitle = styled.div`
  font-size: 14px;
  letter-spacing: 0.25px;
`;

export const ProxyTrafficModalHeaderButton = styled.div`
  align-self: stretch;
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`;

export const ProxyTrafficModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  border-radius: 8px;
`;

export const ProxyTrafficItemNumber = styled.span`
  font-family: Roboto Flex;
  font-size: 28px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.02em;
  text-align: left;

  color: var(--36363D);
`;

export const ProxyyTrafficItemNumeral = styled.span`
  font-family: Roboto Flex;
  font-size: 22px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: -0.02em;
  text-align: left;
  padding-top: 1px;
  
  color: var(--767676);
`;

export const ProxyTrafficStorageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 8px;
`;

export const ProxyTrafficModalProxyTypeHintWrapper = styled(GologinPopoverHint)`
  width: 271px;
  height: ${STANDARD_TEXT_LINE_HEIGHT * HINT_MAXIMUM_LINES}px;
  padding: 0;
  padding-right: 16px;

  ${unsafeVerticalEllipsis}
`;

export const ProxyTrafficModalTrialHintWrapper = styled.div`
  ${gologinPopoverElementBaseStyles}

  width: 100%;
  box-sizing: border-box;
  padding: 5px 32px 5px 8px;
  position: absolute;
  top: calc(100% + ${TRIAL_HINT_DISTANCE}px);
  left: 0;
  border-radius: 8px;
  background-color: var(--F9F9F9);
  color: var(--767676);
  column-gap: 4px;

  //styleName: 14 sp • Body 2;
  letter-spacing: 0.25px;
`;

export const ProxyTrafficModalLoaderWrapper = styled.div`
  height: 183px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 24px;
`;

export const ProxyTrafficModalItem = styled(GologinPopoverItemRow)`
  cursor: default;
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  align-items: center;

  background-color: var(--FFFFFF);
  border: 0.5px solid var(--D2D2D5);
  border-radius: 8px;
  padding: 12px 0 8px 16px;

  &:hover {
    background-color: var(--FFFFFF);
  }
`;

export const ProxyTrafficModalItemRow = styled.div`
  cursor: default;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const BuyProxyPopoverCustom = styled(Popover)<{ isDarkTheme: boolean }>`
  .MuiPopover-paper {
    color: var(--000000DE-header-buy-proxy);
    margin-left: -24px;
    margin-top: 4px;
    padding: 16px;
    width: 271px;
    background: var(--FFFFFF-header-buy-proxy);

    border-radius: 4px;
    line-height: 0;

    ${(props): SerializedStyles | null =>
      !props.isDarkTheme
        ? css`
            box-shadow: 0 3px 6px var(--0000001A-header-buy-proxy), 0 4px 8px var(--00000014-header-buy-proxy),
              0 1px 12px var(--0000000A-header-buy-proxy);
          `
        : null}
  }
`;

export const ItemRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  line-height: 20px;

  color: var(--2B2B31-header-buy-proxy);
`;

export const IconCloseWrapper = styled.div`
  position: absolute;
  top: 3px;
  right: 5px;
  cursor: pointer;
`;

export const IconArrow = styled(IconArrowDown3)``;

export const VolumeProxyContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  ${textFont};

  column-gap: 7.5px;
  padding: 6px 8px;
  padding-bottom: 8px;
  padding-right: 18px;
  transform: translateY(6px);
`;

export const IconContainerWithDescription = styled(IconWrapperWithDescription)`
  ${textFont};
  margin-right: 8px;
  padding-right: 8px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  white-space: nowrap;
`;
