import { getDefaultStore, atom, useAtomValue } from 'jotai';

import { NEW_FEATURES } from './feature-toggle/new-features';

type QuickPricingVariant = 'popup' | 'popup-buy-locked-proxy';
const DEFAULT_QUICK_PRICING_VARIANT = 'popup';

const isQuickPricingVisibleAtom = atom<boolean>(false);
const shouldOpenQuickPricingAtLaunchAtom = atom<boolean>(false);
const quickPricingVariant = atom<QuickPricingVariant>(DEFAULT_QUICK_PRICING_VARIANT);

const setIsQuickPricingVisible = (isVisible: boolean): void => getDefaultStore().set(isQuickPricingVisibleAtom, isVisible);
const setQuickPricingVariant = (variant: QuickPricingVariant): void => getDefaultStore().set(quickPricingVariant, variant);

export const useIsQuickPricingVisible = (): boolean => useAtomValue(isQuickPricingVisibleAtom);
export const useQuickPricingVariant = (): QuickPricingVariant => useAtomValue(quickPricingVariant);

export const setShouldOpenQuickPricingAtLaunch = (newShouldOpenQuickPricingAtLaunch: boolean): void =>
  getDefaultStore().set(shouldOpenQuickPricingAtLaunchAtom, newShouldOpenQuickPricingAtLaunch);

export const openQuickPricing = (variant: QuickPricingVariant = DEFAULT_QUICK_PRICING_VARIANT): void => {
  setIsQuickPricingVisible(true);
  if (variant === 'popup-buy-locked-proxy' && !NEW_FEATURES.renameProxyToLocation) {
    variant = 'popup';
  }

  setQuickPricingVariant(variant);
};

export const closeQuickPricing = (): void => {
  setIsQuickPricingVisible(false);
};

export const onProfileLaunched = (): void => {
  const shouldOpenQuickPricingAtLaunch = getDefaultStore().get(shouldOpenQuickPricingAtLaunchAtom);
  if (!shouldOpenQuickPricingAtLaunch) {
    return;
  }

  setShouldOpenQuickPricingAtLaunch(false);
  const delayToShowMs = 5000;
  setTimeout(openQuickPricing, delayToShowMs);
};

