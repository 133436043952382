import { Button, Popconfirm, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CookiesManager from './cookies-manager';
import { ICookie } from './interfaces/interfaces';
import { ButtonImport, ContainerNameCol, CookiesValue, Row, TableProfile } from './styles/styles';
import { mapAndSetProfilesList } from '../../../../state/profiles-list.atom';
import {
  closeProfileTableModal,
  openProfilesTableModal,
  PROFILE_COOKIES_MANAGER_MODAL_NAME,
  PROFILE_COOKIES_MODAL_NAME,
  useProfilesTableModalProfiles,
  useProfileTableModalIsVisible,
} from '../../../../state/profiles-table-modal.atom';
import { IconSpinner } from '../../../../ui/gologin-header/icons';
import GologinModal from '../../../../ui/gologin-modal';
import TooltipWrapper from '../../../../ui/tooltip-custom/tooltip-wrapper';
import { requestShortCookies, sendActionAnalytics } from '../../../common/api';
import { cleanCookies, getProfileCookies } from '../../../quickProfiles/api';

const MAX_COOKIE_NAME_LENGTH = 32;

const CookiesListModal = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [cookiesList, setCookiesList] = useState<unknown[]>([]);

  const { t: translation } = useTranslation();

  const isModalVisible = useProfileTableModalIsVisible(PROFILE_COOKIES_MODAL_NAME);
  const [modalProfile = { id: '', name: '' } ] = useProfilesTableModalProfiles();

  const { id: profileId, name: profileName } = modalProfile;

  const updateProfileCookies = async (): Promise<void> => {
    const newCookiesList = await requestShortCookies(profileId);
    mapAndSetProfilesList(profiles => profiles.map((profile) => {
      if (profile.id !== profileId) {
        return profile;
      }

      return {
        ...profile,
        cookies: newCookiesList,
      };
    }));
  };

  const getCookiesList = async (): Promise<void> => {
    if (!profileId) {
      setCookiesList([]);
      setIsLoading(true);

      return;
    }

    let getCookiesListRequest: ICookie[] = await getProfileCookies(profileId);
    updateProfileCookies();
    getCookiesListRequest = getCookiesListRequest.map((elem: ICookie, index: number) => ({ ...elem, key: index + 1 }));
    setCookiesList(getCookiesListRequest);
    setIsLoading(false);
  };

  useEffect(() => {
    getCookiesList().catch(() => null);
  }, [profileId, profileName]); // eslint-disable-line react-hooks/exhaustive-deps

  const sortAlphabet = (left: string, right: string): number => {
    if (left > right) {
      return 1;
    }

    if (left < right) {
      return -1;
    }

    return 0;
  };

  const modalOpenImport = (): void => {
    closeProfileTableModal();
    openProfilesTableModal(PROFILE_COOKIES_MANAGER_MODAL_NAME, [profileId]);
  };

  const removeCookies = async (): Promise<void> => {
    await cleanCookies(profileId);
    updateProfileCookies();
    setCookiesList([]);
  };

  const showButtonClear = (): JSX.Element|null => {
    if (!cookiesList.length) {
      return null;
    }

    return (
      <Button
        type='danger'
        ghost={true}
        style={{
          backgroundColor: 'var(--B70000)',
          color: 'var(--B70000)',
          borderColor: 'var(--B70000)',
          height: 30,
          marginLeft: 20,
          fontSize: 12,
          borderRadius: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {translation('base.clear')}
      </Button>
    );
  };

  const showButtonImport = (): JSX.Element|null => {
    if (!cookiesList.length) {
      return null;
    }

    return (
      <ButtonImport
        onClick={(): void => modalOpenImport()}
        style={{
          width: 70,
          height: 30,
          paddingLeft: 13,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {translation('base.import')}
      </ButtonImport>
    );
  };

  const showTable = (): JSX.Element => (
    <Button onClick={(): void => modalOpenImport()} type='primary'>
      {translation('cookieManagerModal.importCookiesBtn.label')}
    </Button>
  );

  const exportCookies = async (): Promise<void> => {
    sendActionAnalytics('exported cookies');
    const fullCookies = await getProfileCookies(profileId, false);
    const saveFileElement = document.createElement('a');
    const outputFile = new Blob(['\ufeff', JSON.stringify(fullCookies)], { type: 'text/plain' });

    saveFileElement.href = URL.createObjectURL(outputFile);
    saveFileElement.download = `Cookies_${profileName}.json`;

    saveFileElement.click();
  };

  const showButtonExport = (): JSX.Element|null => {
    if (!cookiesList.length) {
      return null;
    }

    return (
      <ButtonImport
        onClick={(): Promise<void> => exportCookies()}
        style={{
          width: 70,
          height: 30,
          paddingLeft: 13,
          marginLeft: 20,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {translation('base.export')}
      </ButtonImport>
    );
  };

  const renderTitle = (value: string): JSX.Element|string => {
    if (value.length > MAX_COOKIE_NAME_LENGTH) {
      return (
        <TooltipWrapper isTextEllipsis={false} isTooltipHoverable={true} hasTooltipAnimation={true} value={value}>
          <CookiesValue>
            {value}
          </CookiesValue>
        </TooltipWrapper>
      );
    }

    return value;
  };

  const getContent = (): JSX.Element => (
    <div>
      <Row>
        {showButtonImport()}
        {showButtonExport()}
        <Popconfirm
          placement='top'
          title={translation('cookieManagerModal.clearCookiesBtn.confirmDialog.text')}
          onConfirm={removeCookies}
          okText={translation('base.yes')}
          cancelText={translation('base.no')}
        >
          {showButtonClear()}
        </Popconfirm>
      </Row>
      <TableProfile
        pagination={false}
        rowKey='key'
        dataSource={cookiesList}
        scroll={{ y: 500 }}
        locale={{ emptyText: showTable }}
      >
        <Table.Column
          title={(
            <ContainerNameCol style={{ opacity: 0.5 }}>
              <span>
                {translation('cookieManagerModal.cookiesTable.columns.name')}
              </span>
            </ContainerNameCol>
          )}
          dataIndex='name'
          key='name'
          sorter={(left: ICookie, right: ICookie): number => sortAlphabet(left.name, right.name)}
          render={renderTitle}
        />
        <Table.Column
          title={(
            <ContainerNameCol style={{ opacity: 0.5 }}>
              <span>
                URL
              </span>
            </ContainerNameCol>
          )}
          dataIndex='url'
          key='url'
          sorter={(left: ICookie, right: ICookie): number => sortAlphabet(left.url, right.url)}
        />
        <Table.Column
          title={(
            <ContainerNameCol style={{ opacity: 0.5 }}>
              <span>
                {translation('cookieManagerModal.cookiesTable.columns.value')}
              </span>
            </ContainerNameCol>
          )}
          dataIndex='value'
          key='value'
          sorter={(left: ICookie, right: ICookie): number => sortAlphabet(left.value, right.value)}
          render={renderTitle}
        />
        <Table.Column
          title={(
            <ContainerNameCol style={{ opacity: 0.5 }}>
              <span>
                {translation('cookieManagerModal.cookiesTable.columns.expiresAt')}
              </span>
            </ContainerNameCol>
          )}
          dataIndex='expirationDate'
          key='expirationDate'
          sorter={(left: ICookie, right: ICookie): number => left.expirationDate - right.expirationDate}
        />
      </TableProfile>
    </div>
  );

  return (
    <>
      <GologinModal
        isVisible={isModalVisible}
        onClose={closeProfileTableModal}
        width={'780px'}
        title={translation('cookieManagerModal.title')}
      >
        {isLoading ?
          <IconSpinner size={32} padding={0} /> :
          getContent()}
      </GologinModal>
      <CookiesManager />
    </>
  );
};

export default CookiesListModal;
