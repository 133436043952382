import css, { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled';

const PROXY_EDIT_FORM_FOOTER_TAB_SIZE: React.CSSProperties['width'] = '32px';

export const ProxyEditFormFooterTabWrapper = styled.div<{ isActive: boolean; isDisabled: boolean }>`
  width: ${PROXY_EDIT_FORM_FOOTER_TAB_SIZE};
  height: ${PROXY_EDIT_FORM_FOOTER_TAB_SIZE};
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  ${(props): SerializedStyles | null => props.isDisabled ? null : css`
    cursor: pointer;
  
    :hover {
      svg path {
        /* TODO: add colour var in the upcoming MR */
        stroke: var(--36363D);
      }
    }
  `}

  ${(props): SerializedStyles => props.isActive ? css`
    svg path {
      /* TODO: add colour var in the upcoming MR */
      stroke: var(--36363D);
    }
  ` : css`
    background: inherit;
    svg path {
      /* TODO: add colour var in the upcoming MR */
      stroke: var(--B5B5BA);
    }
  `}
`;
