import { useState, useEffect, useCallback } from 'react';

export const useInputWidth = (value: string, minWidth: number, maxWidth: number) => {
  const [width, setWidth] = useState(minWidth);

  const calculateWidth = useCallback(() => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (context) {
      context.font = '14px Roboto';  
      const textWidth = context.measureText(value).width;
      const newWidth = Math.min(Math.max(textWidth + 5, minWidth), maxWidth);
      setWidth(newWidth);
    }
  }, [value, minWidth, maxWidth]);

  useEffect(() => {
    calculateWidth();
  }, [calculateWidth]);

  return width;
};