import React from 'react';
import { Trans } from 'react-i18next';

import ChangeLimitSelector from './change-limits-selector';
import GeoProxyTrafficListItem from './geoproxy-traffic-list-item';
import { CardList, CardListItem, CardListProfilesItem, DescriptionPlan, IconContainer } from './styles';
import { E_ANALYTICS_ACTIONS } from '../../../../../common/constants/analytics';
import { Limits } from '../../../../interfaces/workspaces/limits';
import { NEW_FEATURES } from '../../../../state/feature-toggle/new-features';
import {
  getChosenLimitsByPLan,
  updateChosenLimitsByPLan,
  useAddonLimitsChosen,
  useChosenLimitsByPlan,
  useShouldShowMemberLimitButtons,
} from '../../../../state/pricing/pricing-page.atom';
import IconPlanCheck from '../../../../ui/icons/IconPlanCheck';
import { sendActionAnalytics } from '../../../common/api';
import { PlanId } from '../../constants';
import { IPreviewPlan } from '../../interfaces';

export type PreviewCardContentProps = {
  isCustomPlan: boolean;
  getMaxProfilesItem: () => JSX.Element;
  planName: string;
  maxAccountShares?: number;
  geoProxyTraffic: IPreviewPlan['geoProxyTraffic'];
  planId: PlanId;
}

export const PreviewCardContent: React.FC<PreviewCardContentProps> = (props) => {
  const { isCustomPlan, getMaxProfilesItem, planName, maxAccountShares = 0, geoProxyTraffic, planId } = props;
  const { maxMembers: maxMembersShown = 0 } = useChosenLimitsByPlan(planId);
  const shouldShowMemberChangeLimitButtons = useShouldShowMemberLimitButtons(planId);

  const maxMembers = shouldShowMemberChangeLimitButtons ? maxMembersShown : maxAccountShares;

  const updateChosenLimitsByPLanAndSendAnalytics = (planId: string, limits: Partial<Limits>): void => {
    const { maxMembers: deltaMaxMembers = 0 } = limits;
    updateChosenLimitsByPLan(planId, limits);

    const { maxMembers: maxMembersUpdated } = getChosenLimitsByPLan(planId);
    const analyticsAction = deltaMaxMembers > 0 ? E_ANALYTICS_ACTIONS.increasedMemberCountOnPricing : E_ANALYTICS_ACTIONS.decreasedMemberCountOnPricing;
    sendActionAnalytics(analyticsAction, { paymentPlan: planName, actionInfo: String(maxMembersUpdated) });
  };

  const getMembersItem = (): JSX.Element|null => {
    if (!(maxMembers && maxAccountShares)) {
      return null;
    }

    return (
      <CardListItem isCustomPlan={isCustomPlan}>
        <IconContainer>
          <IconPlanCheck />
        </IconContainer>
        <DescriptionPlan>
          {shouldShowMemberChangeLimitButtons ? (
            <>
              <ChangeLimitSelector
                maxMembers={maxMembers}
                onPlusClick={(): void => updateChosenLimitsByPLanAndSendAnalytics(planId, { maxMembers: 1 })}
                onMinusClick={(): void => updateChosenLimitsByPLanAndSendAnalytics(planId, { maxMembers: -1 })}
                isCustomPlan={isCustomPlan}
              />
              {' '}
              <div>
                <Trans i18nKey={'pricing.planTable.addTeamMembers'} />
              </div>
            </>
          ) : (
            <span>
              {maxAccountShares}
              {' '}
              <Trans i18nKey={`pricing.${planName.toLowerCase()}Plan.tick4`} />
            </span>
          )}
        </DescriptionPlan>
      </CardListItem>
    );
  };

  return (
    <CardList isCustomPlan={isCustomPlan}>
      <CardListItem isCustomPlan={isCustomPlan}>
        <IconContainer>
          <IconPlanCheck />
        </IconContainer>
        <CardListProfilesItem>
          {getMaxProfilesItem()}
          {' '}
          <DescriptionPlan style={{ marginLeft: 0 }}>
            <Trans i18nKey={`pricing.${planName.toLowerCase()}Plan.tick1`} />
          </DescriptionPlan>
        </CardListProfilesItem>
      </CardListItem>
      {NEW_FEATURES.hideTorAndFreeProxyV2 ? null : (
        <CardListItem isCustomPlan={isCustomPlan}>
          <IconContainer>
            <IconPlanCheck />
          </IconContainer>
          <DescriptionPlan>
            <Trans i18nKey={`pricing.${planName.toLowerCase()}Plan.freeProxiesTick`} />
          </DescriptionPlan>
        </CardListItem>
      )}
      <CardListItem isCustomPlan={isCustomPlan}>
        <IconContainer>
          <IconPlanCheck />
        </IconContainer>
        <DescriptionPlan>
          <Trans i18nKey={`pricing.${planName.toLowerCase()}Plan.tick3`} />
        </DescriptionPlan>
      </CardListItem>
      {getMembersItem()}
      {NEW_FEATURES.pricingGeoProxyTrafficV2 ? (
        <GeoProxyTrafficListItem
          isCustomPlan={isCustomPlan}
          planName={planName}
          geoProxyTraffic={geoProxyTraffic}
        />
      ) : null}
    </CardList>
  );
};
