import { useAtomValue } from 'jotai';
import React, { FC } from 'react';

import { extractNumberBrowserVersion } from '../../common/orbita-browser';
import { ITableCell } from '../interfaces';
import { TextCell } from '../table/styles';

const OrbitaVersionCell: FC<ITableCell> = (props) => {
  const { profileAtom } = props;

  const { navigator } = useAtomValue(profileAtom);

  return (
    <TextCell>
      {extractNumberBrowserVersion(navigator.userAgent)}
    </TextCell>
  );
};

export default OrbitaVersionCell;
