import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { proxyManagerStateAtom } from './proxy-manager-modal-status.atom';
import { PROXY_EDIT_FORM_TABS, ProxyEditFormTab } from '../../features/proxy/proxy-manager/proxy-edit-view/constants';
import {
  readAreStoredGeoProxyAdvancedSettingsVisible,
  readStoredProxyFormTab,
  storeAreGeoProxyAdvancedSettingsVisible,
  storeProxyFormTab,
} from '../../features/proxy/utils/default-proxy-data';

const areGeoProxyAdvancedSettingsVisibleAtom = atom<boolean>(readAreStoredGeoProxyAdvancedSettingsVisible());

const proxyFormUserSelectedTabAtom = atom<ProxyEditFormTab | null>(null);
const proxyFormCurrentTabAtom = atom<ProxyEditFormTab>((get) => {
  const userSelectedTab = get(proxyFormUserSelectedTabAtom);
  const { modalEditingProxyId } = get(proxyManagerStateAtom);

  const storedProxyFormTab = readStoredProxyFormTab();

  switch (true) {
    case !!modalEditingProxyId:
      return PROXY_EDIT_FORM_TABS.customProxy;
    case !!userSelectedTab:
      return userSelectedTab;
    case !!storedProxyFormTab:
      return storedProxyFormTab;
    default:
      return PROXY_EDIT_FORM_TABS.geoProxy;
  }
});

const getAreGeoProxyAdvancedSettingsVisible = (): boolean => getDefaultStore().get(areGeoProxyAdvancedSettingsVisibleAtom);
const setAreGeoProxyAdvancedSettingsVisible = (areVisible: boolean): void => {
  getDefaultStore().set(areGeoProxyAdvancedSettingsVisibleAtom, areVisible);
  storeAreGeoProxyAdvancedSettingsVisible(areVisible);
};

const setProxyFormUserSelectedTab = (newTab: ProxyEditFormTab | null): void => {
  getDefaultStore().set(proxyFormUserSelectedTabAtom, newTab);
  if (newTab) {
    storeProxyFormTab(newTab);
  }
};

export const toggleGeoProxyAdvancedSettingsVisibility = (): void => {
  const areGeoProxyAdvancedSettingsVisible = getAreGeoProxyAdvancedSettingsVisible();
  setAreGeoProxyAdvancedSettingsVisible(!areGeoProxyAdvancedSettingsVisible);
};

export const useAreGeoProxyAdvancedSettingsVisible = (): boolean => useAtomValue(areGeoProxyAdvancedSettingsVisibleAtom);
export const useProxyFormCurrentTab = (): ProxyEditFormTab => useAtomValue(proxyFormCurrentTabAtom);

export const updateProxyFormTab = (newTab: ProxyEditFormTab): void => {
  setProxyFormUserSelectedTab(newTab);
};

export const resetProxyFormTab = (): void => {
  setProxyFormUserSelectedTab(null);
};
