import React, { useContext } from 'react';
import { Trans } from 'react-i18next';

import { itemRowIconProps } from './menu-items';
import { ItemRow } from './styles';
import WorkspaceInfo from './workspace-info';
import switchWorkspace from '../../../features/common/switch-workspace';
import { userContext, workspaceContext } from '../../../state';
import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import { GologinPopoverDivider } from '../../gologin-popover/gologin-popover-divider';
import { IconPlus } from '../icons';

type WorkspacesMenuSectionProps = {
  onClose: () => void;
  openCreateWorkspace: () => void;
}

const WorkspacesMenuSection: React.FC<WorkspacesMenuSectionProps> = (props) => {
  const { onClose, openCreateWorkspace } = props;

  const userCtx = useContext(userContext);
  const workspaceCtx = useContext(workspaceContext);

  const { updateSelectedFolder } = userCtx;
  const { id: workspaceId, availableWorkspaces } = workspaceCtx;

  const onWorkspaceClick = async (clickedWorkspaceId: string): Promise<void> => {
    onClose();
    await switchWorkspace(clickedWorkspaceId, workspaceCtx, userCtx);
    updateSelectedFolder('all');
  };

  return (
    <>
      {availableWorkspaces.map((workspace) => (
        <WorkspaceInfo
          key={workspace.id}
          name={workspace.name}
          profiles={workspace.profilesCount}
          planMaxProfiles={workspace.planProfilesMax}
          planName={workspace.planName}
          onClick={(): Promise<void> => onWorkspaceClick(workspace.id)}
          isSelected={workspace.id === workspaceId}
        />
      ))}
      <ItemRow
        hasPadding={true}
        onClick={(): void => {
          onClose();
          openCreateWorkspace();
        }}
      >
        <IconPlus {...itemRowIconProps} />
        <span style={{ marginLeft: 10, color: 'var(--81818A-header-account-menu)' }}>
          <Trans i18nKey='userMenu.addWorkspace' />
        </span>
      </ItemRow>
      <GologinPopoverDivider />
    </>
  );
};

export default WorkspacesMenuSection;
