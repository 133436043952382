import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { getProfilesList, profilesListAtom, useProfilesList } from './profiles-list.atom';
import { getProfilesTableSelectedIds } from './profiles-table-selected-ids.atom';
import { IProfile } from '../interfaces';
import PerformanceObserverService from '../services/performance-observer/performance-observer.service';

interface IProfilesSettingsState {
  isVisible: boolean;
  isUsingSelectedProfiles: boolean;
  profileIds: string[];
}

const defaultProfilesSettingsState: IProfilesSettingsState = {
  isVisible: false,
  isUsingSelectedProfiles: false,
  profileIds: [],
};

const profilesSettingsStateAtom = atom<IProfilesSettingsState>(defaultProfilesSettingsState);

export const useProfilesSettingsState = (): IProfilesSettingsState => useAtomValue(profilesSettingsStateAtom);

export const useIsProfilesSettingsOpen = (): boolean => useProfilesSettingsState().isVisible;

const profilesSettingsProfileAtom = atom<IProfile | null>((get) => {
  const profilesList = get(profilesListAtom);
  const { profileIds } = get(profilesSettingsStateAtom);
  const [currentProfileId] = profileIds;

  return profilesList.find(profile => profile.id === currentProfileId) || null;
});

export const useProfilesSettingsProfile = (): IProfile | null => useAtomValue(profilesSettingsProfileAtom);

export const getProfilesSettingsState = (): IProfilesSettingsState => getDefaultStore().get(profilesSettingsStateAtom);
const setProfilesSettingsState = (newProfilesSettingsState: IProfilesSettingsState): void => {
  getDefaultStore().set(profilesSettingsStateAtom, newProfilesSettingsState);
};

export const updateProfilesSettingsProfileIds = (newProfileIds: string[]): void => {
  setProfilesSettingsState({ ...getProfilesSettingsState(), profileIds: newProfileIds });
};

export const openProfilesSettings = (profileIds: string[]): void => {
  const performanceObserverService = PerformanceObserverService.getInstance();
  performanceObserverService.handleUserAction({
    userAction: 'open-profile-settings',
    previousProfileId: getProfilesSettingsState().profileIds[0],
  });

  setProfilesSettingsState({
    isVisible: true,
    isUsingSelectedProfiles: false,
    profileIds,
  });
};

export const openProfilesSettingsOfSelectedIds = (): void => {
  const selectedIds = getProfilesTableSelectedIds();
  setProfilesSettingsState({
    isVisible: true,
    isUsingSelectedProfiles: true,
    profileIds: selectedIds,
  });
};

export const closeProfilesSettings = (): void => setProfilesSettingsState({
  isVisible: false,
  isUsingSelectedProfiles: false,
  profileIds: [],
});

export const toggleProfilesTableProfileSettings = (profileId: string): void => {
  const { profileIds } = getProfilesSettingsState();
  if (profileIds.includes(profileId)) {
    closeProfilesSettings();

    return;
  }

  openProfilesSettings([profileId]);
};
