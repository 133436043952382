import { atom, getDefaultStore, useAtomValue } from 'jotai/index';
import { splitAtom } from 'jotai/utils';

import { getProfilesList, IProfileRunStatus } from './profiles-list.atom';
import { isNotNull } from '../../common/typescript/predicates';
import { IProfilePatchData } from '../features/quickProfiles/api';
import { IProfileRunningInWeb } from '../interfaces';

const NULL_PROFILE_STATUS: IProfileRunStatus = { id: '', status: 'profileStatuses.ready' };
const nullProfileStatusAtom = atom<IProfileRunStatus>(NULL_PROFILE_STATUS);
const profileRunStatusesListAtom = atom<IProfileRunStatus[]>([]);

const profileRunStatusesAtom = splitAtom(profileRunStatusesListAtom);

export const useProfileRunStatus = (profileId: string): IProfileRunStatus => {
  const profileRunStatuses = useAtomValue(profileRunStatusesAtom);
  let currentProfileRunStatusAtom = profileRunStatuses.find(profileRunStatusAtom => getDefaultStore().get(profileRunStatusAtom).id === profileId);
  if (!currentProfileRunStatusAtom) {
    currentProfileRunStatusAtom = nullProfileStatusAtom;
  }

  return useAtomValue(currentProfileRunStatusAtom);
};

export const getProfileRunStatus = (profileId: string): IProfileRunStatus => {
  const profileRunStatuses = getDefaultStore().get(profileRunStatusesListAtom);
  const currentProfileRunStatus = profileRunStatuses.find(profileRunStatus => profileRunStatus.id === profileId);
  if (!currentProfileRunStatus) {
    return NULL_PROFILE_STATUS;
  }

  return currentProfileRunStatus;
};

export const setProfileRunStatuses = (newProfileRunStatuses: IProfileRunStatus[]): void =>
  getDefaultStore().set(profileRunStatusesListAtom, newProfileRunStatuses);

export const getFlatProfileRunStatuses = (): IProfilePatchData[] => {
  const profilesList = getProfilesList();
  const profileStatuses = getDefaultStore().get(profileRunStatusesListAtom);

  return profilesList.map(({ id: profileId }) => {
    const profileRunStatus = profileStatuses.find(runStatus => runStatus.id === profileId);

    return { id: profileId, isRunning: profileRunStatus?.status === 'profileStatuses.running' };
  });
};

export const resetProfileRunStatuses = (): void => setProfileRunStatuses([]);

const isRunStatusSame = (curr: IProfileRunStatus, next: IProfileRunStatus): boolean =>
  curr.status === next.status;

export const upsertProfileRunStatuses = (updatedProfileRunStatuses: IProfileRunStatus[]): void => {
  const profileRunStatusAtoms = getDefaultStore().get(profileRunStatusesAtom);
  const profileRunStatuses = getDefaultStore().get(profileRunStatusesListAtom);
  const toAppend = updatedProfileRunStatuses.reduce<IProfileRunStatus[]>((acc, updatedProfileRunStatus) => {
    const { id: profileId } = updatedProfileRunStatus;
    if (!profileId) {
      return acc;
    }

    const currentProfileRunStatusIdx = profileRunStatuses.findIndex(profileRunStatus => profileRunStatus.id === profileId);
    if (currentProfileRunStatusIdx === -1) {
      acc.push(updatedProfileRunStatus);

      return acc;
    }

    const currentProfileRunStatus = profileRunStatuses[currentProfileRunStatusIdx];
    if (!isRunStatusSame(currentProfileRunStatus, updatedProfileRunStatus)) {
      const currentProfileRunStatusAtom = profileRunStatusAtoms[currentProfileRunStatusIdx];
      getDefaultStore().set(currentProfileRunStatusAtom, updatedProfileRunStatus);
    }

    return acc;
  }, []);

  if (toAppend.length) {
    setProfileRunStatuses([...profileRunStatuses, ...toAppend]);
  }
};

export const getRunningProfileCount = (): number => getDefaultStore().get(profileRunStatusesListAtom).reduce<number>((count, profile) => {
  if (profile.status === 'profileStatuses.running') {
    return count + 1;
  }

  return count;
}, 0);

export const updateProfileRunStatusesForWeb = (profilesRunningInWeb: IProfileRunningInWeb[]): void => {
  const profilesList = getProfilesList();
  const profileRunStatusUpdates = profilesList.map<IProfileRunStatus | null>(profile => {
    const prevProfileRunStatus = getProfileRunStatus(profile.id);
    const profileRunningInWeb = profilesRunningInWeb.find(({ id }) => id === profile.id);
    if (!profileRunningInWeb) {
      if (!(profile.isWeb || prevProfileRunStatus.isWeb)) {
        return null;
      }

      return {
        id: profile.id,
        status: 'profileStatuses.ready',
        isWeb: false,
        remoteOrbitaUrl: '',
      };
    }

    const remoteOrbitaUrl = profileRunningInWeb.remoteOrbitaUrl || prevProfileRunStatus.remoteOrbitaUrl;
    if (!remoteOrbitaUrl) {
      return {
        ...prevProfileRunStatus,
        status: 'profileStatuses.downloadingProfile',
      };
    }

    return {
      ...prevProfileRunStatus,
      status: 'profileStatuses.running',
      isWeb: true,
      remoteOrbitaUrl: profileRunningInWeb.remoteOrbitaUrl,
    };
  }).filter(isNotNull);

  upsertProfileRunStatuses(profileRunStatusUpdates);
};
