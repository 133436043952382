import { PaymentMethod } from '../../app/features/pricing/interfaces';

/* eslint-disable no-shadow */
export enum OpenProfileViaLink {
  visited = 'visited open profile via link',
  startedHandling = 'started handling open profile via link',
  startedRedirecting = 'started redirecting open profile via link',
  startedOpening = 'started opening profile via link',
  opened = 'opened profile via link',
  startedFocusing = 'started focusing profile via link',
  focused = 'focused profile via link',
}

export enum ProxyPageAnalyticsEvent {
  visitedProxyPage = 'visited proxy page',
  visitedProxyPageErrors = 'visited proxy page - errors',
  visitedProxyPageArchive = 'visited proxy page - archive',
  clickedBackButton = 'clicked back button',
  searchedProxy = 'searched proxy',

  clickedConnectedProfiles = 'clicked proxy connected profiles',
  visitedProfilesPageFilteredByProxy = 'visited profiles page - filtered by proxy',
  visitedProxyPageFromConnectedProfile = 'visited proxy page - from connected profile',

  massActionsSelect = 'selected proxy via checkbox',
  massActionsCopy = 'copied proxy - bulk',
  massActionsDelete = 'deleted proxy - bulk',
  massActionsArchive = 'archived proxy - bulk',
  massActionsRestored = 'restored proxy - bulk',
  massActionsErased = 'erased proxy - bulk',

  archivedProxy = 'archived proxy',
  restoredProxy = 'restored proxy',
  erasedProxy = 'erased proxy',

  changedProxyNameViaColumn = 'changed proxy name via column',
  createdProxyNote = 'created note - proxy',
  editedProxyNote = 'edited note - proxy',

  visitedCreateProxy = 'visited create proxy',
  visitedEditProxy = 'visited create proxy',
  createdProxy = 'created proxy',
  editedProxy = 'edited proxy',

  visitedImportProxies = 'visited import proxies',
  importedProxies = 'imported proxies',
}

export const PROXY_PAGE_ANALYTICS_ACTION_INFO_DEFAULT = 'proxies page';

export const GROUPED_PROXY_MANAGER_ANALYTICS_EVENTS = <const>{
  createdLocationViaBuiltInProxyClickedOnCountry: 'created location via built-in proxy - clicked on country',
  createdLocationViaBuiltInProxyClickedOnAddServerInExpandedGroup: 'created location via built-in proxy - clicked on add server in expanded group',
  createdLocationViaBuiltInProxyClickedOnServerTypeInExpandedGroup: 'created location via built-in proxy - clicked on server type in expanded group',

  choseLocationViaBuiltInProxyClickedOnCountry: 'chose location via built-in proxy - clicked on country',
  choseLocationViaBuiltInProxyClickedOnServerTypeInExpandedGroup: 'chose location via built-in proxy - clicked on server in expanded group',

  expandedProxiesGroup: 'expanded country group',
  collapsedProxiesGroup: 'collapsed country group',

  visitedSelectGeoProxyType: 'visited choose default server type in country group',
};

export enum E_NEW_BROWSER_PROFILE_ANALYTICS_EVENT {
  visitedNewBrowserProfile = 'visited new browser profile',
  createdProfileViaNewBrowserProfile = 'created profile via new browser profile',
}

export enum E_EDIT_BROWSER_PROFILE_ANALYTICS_EVENT {
  visitedEditBrowserProfile = 'visited edit browser profile',
  clickedUnlinkProxyViaClearFormInEditBrowserProfile = 'clicked unlink proxy via clear form in edit browser profile',
  clickedUnlinkProxyViaChooseProxyInEditBrowserProfile = 'clicked unlink proxy via choose proxy in edit browser profile',
  savedProfileViaEditBrowserProfile = 'saved profile via edit browser profile',
}

export enum E_ANALYTICS_ACTIONS {
  launchedProfile = 'launched profile',
  loadedProfile = 'loaded profile',
  addedFolder = 'added folder',
  createdProfile = 'created profile',
  changedProfileNameViaSettings = 'changed profile name via settings',
  formattedNote = 'formatted note',
  changedProfileNameViaColumn = 'changed profile name via column',
  createdNote = 'created note',
  addedTag = 'added tag',
  changedTagColorAfterCreation = 'changed tag color after creation',
  changedTagNameAfterCreation = 'changed tag name after creation',
  filteredByTag = 'filtered by tag',
  pinnedProfile = 'pinned profile',
  searched = 'searched',
  clonedProfile = 'cloned profile',
  createdApiToken = 'created api token',
  added2fa = 'added 2fa',
  visitedAffiliatePage = 'visited affiliate page',
  uploadedExtension = 'uploaded extension',
  sharedProfileViaMassOperations = 'shared profile via mass operations',
  addedTagViaMassOperations = 'added tag via mass operations',
  launchedProfileInCloudFromApp = 'launched profile in cloud from app',
  addedMember = 'added member',
  sharedFolder = 'shared folder',
  sharedProfile = 'shared profile',
  sharedProfileViaLink = 'shared profile via link',
  createShareLink = 'create share link',
  changedFolderPlace = 'changed folder place',
  visitedAddMember = 'visited add member',
  openedProxyManager = 'opened proxy manager',
  openedProxyManagerInProfilesTableClickOnCell = 'opened proxy manager in profiles table - click on cell',
  pastedProxyFromBuffer = 'pasted proxy from buffer',
  importedCookies = 'imported cookies',
  exportedCookies = 'exported cookies',
  addedExtensionToAllNewProfiles = 'added extension to all new profiles',
  increasedWindowSize = 'increased window size',
  decreasedWindowSize = 'decreased window size',
  launchedProfileViaMassOperations = 'launched profile via mass operations',
  openedOrbitaViaViewFromApp = 'opened orbita via View from app',
  addedExtensionViaMassOperations = 'added extension via mass operations',
  browserOpened = 'browser opened',
  loadedProfileTable = 'loaded profile table',
  deletedFolder = 'deleted folder',
  visitedShareProfile = 'visited share profile',
  sharedProfileCanRun = 'shared profile - can run',
  sharedProfileCanEdit = 'shared profile - can edit',
  sharedProfileFullAccess = 'shared profile - full access',
  visitedMembersPage = 'visited members page',
  changeMemberPermissions = 'change member permissions',
  removedMember = 'removed member',
  addedMemberToWorkspaceCanRun = 'added member to workspace - can run',
  addedMemberToWorkspaceCanEdit = 'added member to workspace - can edit',
  addedMemberToWorkspaceFullAccess = 'added member to workspace - full access',
  addedMemberToFolderFullAccess = 'added member to folder - full access',
  addedMemberToFolderCanRun = 'added member to folder - can run',
  addedMemberToFolderCanEdit = 'added member to folder - can edit',
  addedMemberToFolderMixed = 'added member to folder - mixed',
  visitedTransferProfile = 'visited transfer profile',
  tranferedProfileOwnWorkspace = 'tranfered profile - own workspace',
  tranferedProfileEmail = 'tranfered profile - email',
  deletedTag = 'deleted tag',
  unpinnedProfile = 'unpinned profile',
  addedProxy = 'added proxy',
  choseProxyViaProxyManager = 'chose proxy via proxy manager',
  deletedProxy = 'deleted proxy',
  importedProfile = 'imported profile',
  exportedProfile = 'exported profile',
  visitedProfileHistory = 'visited profile history',
  restoredProfile = 'restored profile',
  importedProxy = 'imported proxy',
  exportedProxy = 'exported proxy',
  clickedBuyProxy = 'clicked buy proxy',
  changedColumnSize = 'changed column size',
  openedEditColumns = 'opened edit columns',
  addedColumn = 'added column',
  removedColumn = 'removed column',
  movedColumn = 'moved column',
  openedIntercom = 'opened intercom',
  wroteToIntercom = 'wrote to intercom',
  updatedApp = 'updated app',
  visitedPaymentMethodsPage = 'visited payment methods page',
  vaultPopupOpened = 'vault-popup-opened',
  updatedOrbita = 'updated orbita',
  clickedPayNow = 'clicked pay now',
  vaultPassPasted = 'vault-pass-pasted',
  vaultPassAdded = 'vault-pass-added',
  createdProfileViaNameColumn = 'created profile via name column',
  taggedProfile = 'tagged profile',
  deletedProfile = 'deleted profile',
  clickedDeleteProfileProfileMenu = 'clicked delete profile via profile menu',
  clickedDeleteProfilesMassOperations = 'clicked delete profile via mass operations',
  clickedConfirmDeleteProfileViaMassOperations = 'clicked confirm delete profile via mass operations',
  clickedConfirmDeleteProfile = 'clicked confirm delete profile',
  visitedFolder = 'visited folder',
  transferedProfileOwnWorkspace = 'transfered profile - own workspace',
  transferedProfileEmail = 'transfered profile - email',
  leftWorkspace = 'left workspace',
  syncedUnsuccessfully = 'synced unsuccessfully',
  syncedSuccessfully = 'synced successfully',
  registeredClientId = 'registered clientId',
  registeredUserId = 'registered userId',
  completedQuiz = 'completed quiz',
  clickedRunProfile = 'clicked run profile',
  clickedStopProfile = 'clicked stop profile',
  activatedPromoSuccessfuly = 'activated promo successfuly',
  activatedPromoUnsuccessfuly = 'activated promo unsuccessfuly',
  uploadedProfile = 'uploaded profile',
  madePayment = 'made payment',
  clickedBuyPlan = 'clicked buy plan',
  redirectedWithTemporaryToken = 'redirected with temporary token',
  loggedInWithToken = 'logged in with token',
  gotErrorPayment = 'got error - payment',
  changedEmail = 'changed email',
  visitedPaymentTab = 'visited payment tab',
  visitedCheckout = 'visited checkout',
  clickedToCardForm = 'clicked to card form',
  upgradedPlan = 'upgraded plan',
  visitedOpenProfileViaLink = 'visited open profile via link',
  startedHandlingOpenProfileViaLink = 'started handling open profile via link',
  startedRedirectingOpenProfileViaLink = 'started redirecting open profile via link',
  startedOpeningProfileViaLink = 'started opening profile via link',
  openedProfileViaLink = 'opened profile via link',
  startedFocusingProfileViaLink = 'started focusing profile via link',
  focusedProfileViaLink = 'focused profile via link',
  createdShareProfileLink = 'created share profile link',
  copiedShareProfileLink = 'copied share profile link',
  visitedAppViaShareProfileLink = 'visited app via share profile link',
  gotProfileSharedViaLink = 'got profile shared via link',
  visitedSignUpViaShareProfileLink = 'visited sign up via share profile link',
  registeredViaShareProfileLink = 'registered via share profile link',
  deletedShareProfileLink = 'deleted share profile link',
  editedShareProfileLinkRole = 'edited share profile link role',
  pausedShareProfileLink = 'paused share profile link',
  resumedShareProfileLink = 'resumed share profile link',
  visitedPaymentMethodsPageUpgrade = 'visited payment methods page - upgrade',
  loggedInAppViaGmail = 'logged in app via gmail',
  loggedInAppViaEmail = 'logged in app via email',
  droppedProfile = 'dropped profile',
  visitedPricing = 'visited pricing',
  openedProfileTableSettings = 'opened profile table settings',
  clickedEnableProfilesGroupByStatus = 'clicked enable profiles group by status',
  clickedEnableProfilesGroupByFolder = 'clicked enable profiles group by folder',
  clickedDisableProfilesGroup = 'clicked disable profiles group',
  selectedProfilesViaProfilesGroupCounter = 'selected profiles via profiles group counter',
  selectedProfilesViaProfilesGroupMenu = 'selected profiles via profiles group menu',
  collapsedProfilesGroup = 'collapsed profiles group',
  expandedProfilesGroup = 'expanded profiles group',
  finishedCreatingDefaultProfiles = 'finished creating default profiles',
  visitedGologinProxyTabInNewBrowserProfile = 'visited gologin proxy tab in new browser profile',
  visitedBuyProxyDataModalViaCheckTooltip = 'visited buy proxy data modal via check tooltip',
  visitedBuyProxyDataModalViaLocationData = 'visited buy proxy data modal via location data',
  increasedMemberCountOnPricing = 'increased member count on pricing',
  decreasedMemberCountOnPricing = 'decreased member count on pricing',
  clickedUnlinkProxyViaCheckProxyTooltip = 'clicked unlink proxy via check proxy tooltip',
  clickedUnlinkProxyViaProxyManager = 'clicked unlink proxy via proxy manager',
  restoredLocalStorageFromBackupFileSuccess = 'restored local storage from backup file - success',
  restoredLocalStorageFromBackupFileError = 'restored local storage from backup file - error',
}

export enum E_ACTION_INFO {
  UPGRADE = 'upgrade',
  EMPTY = '',
}

export const ACTION_INFO = Object.values(E_ACTION_INFO);
export type TActionInfo = (typeof ACTION_INFO)[number];

export type ExtraActionInfo = Partial<{
  actionInfo: string;
  paymentMethod: PaymentMethod;
  appVersion: string;
  profile: string;
  proxyId: string;
  proxyMode: string;
  proxyCountry: string;
  mode: string;
  paymentPlan: string;
}>;

export enum E_ANALYTICS_ACTIONS_INFO {
  checkLaunchesCount = 'checkLaunchesCount',
  closedPaymentMethods = 'closed payment methods',
  createMultipleProfiles = 'create multiple profiles',
  importCookies = 'import cookies',
  restoreProfiles = 'restore profiles',
}

export enum E_PROFILE_SETTINGS_PAGE_ANALYTICS_EVENT {
  openedProxyManagerInProfileSettingsPage = 'opened proxy manager - profile settings page',
  selectedSavedProxy = 'selected saved proxy - profile settings page'
}
