import { message } from 'antd';
import moment from 'moment';
import React from 'react';
import { Trans } from 'react-i18next';

import { determineIsProxyTruthy } from '../../../../../../common/constants/types';
import { IProxy } from '../../../../../interfaces';
import { NEW_FEATURES } from '../../../../../state/feature-toggle/new-features';
import { mapAndSetProfilesList } from '../../../../../state/profiles-list.atom';
import { switchConfirmBlockVisible } from '../../../../../state/proxy-select-menu.atom';
import { handleProxyFormSubmit, useProxyForm } from '../../../../../state/proxy/custom-proxy-form-data.atom';
import { updateGeoProxyCustomNameCounters } from '../../../../../state/proxy/default-geoproxy.atom';
import { useGeoProxyCountries } from '../../../../../state/proxy/geoproxy-countries.atom';
import { GeoProxyFormProxy, updateGeoProxyForm, useGeoProxyForm } from '../../../../../state/proxy/geoproxy-form-data.atom';
import { PROXY_CHECK_TOOLTIP_LOCATIONS } from '../../../../../state/proxy/proxy-check/constants';
import { showProxyCheckTooltip } from '../../../../../state/proxy/proxy-check/proxy-check-tooltip.atom';
import { addProxyStatuses, removeProxyStatuses, updateProxyStatuses } from '../../../../../state/proxy/proxy-check/proxy-statuses.atom';
import { useProxyFormCurrentTab } from '../../../../../state/proxy/proxy-form-tabs.atom';
import { getProxyListProxyById, pushManyProxies, updateProxyInListById, useProxyList } from '../../../../../state/proxy/proxy-list.atom';
import { closeProxyManager, openProxyManagerListView, useProxyManagerState } from '../../../../../state/proxy/proxy-manager-modal-status.atom';
import {
  ArtificialGeoProxyParams,
  checkGeoProxy,
  evaluateSelectableGeoProxyConnectionType,
  fetchGeoProxyWithCredentials,
  GeoProxyWithCredentialsParams,
  handleGeoProxyWithCredentialsError,
} from '../../../../../state/proxy/proxy-operations/create-geoproxy.operations';
import {
  decrementProfileLinkedProxyProfilesCounter,
  linkProfileProxy,
  linkProfileProxyInState,
} from '../../../../../state/proxy/proxy-operations/link-proxy.operations';
import { pasteProxyToForm } from '../../../../../state/proxy/proxy-operations/paste-proxies-operations';
import { updateSelectedProxies } from '../../../../../state/proxy/selected-proxies.atom';
import { useTrafficData } from '../../../../../state/proxy/traffic-data.atom';
import { copyProxies, makeGeoProxyCustomName } from '../../../proxy-helpers';
import { determineAreProxyCheckSensitiveFieldsEqual } from '../../../utils/find-proxy-in-list';
import { generateArtificialGeoProxyId, generateProxyGroupId, getNoIdProxyId } from '../../../utils/proxy-id';
import { PROXY_EDIT_FORM_TABS } from '../constants';
import CustomProxyEditFormFields from '../custom-proxy-edit-form-fields';
import GeoProxyEditFormFields from '../geoproxy-edit-form-fields';
import ProxyEditFormFields from '../proxy-edit-form-fields';
import { DeleteProxyBtn, FormButtonsContainer, ProxyFormContent, ProxyFormLeft, ProxyFormRightOld, ProxyFormRowElOld } from '../styles';
import ToggleableProxyEditFormFooter from '../toggleable-proxy-edit-form-footer';
import ProxyEditFormFooter from './proxy-edit-form-footer';
import { evaluateGeoProxyCustomNameCounter } from '../../../utils/default-proxy-data';

type ExtendedArtificialGeoProxyParams = ArtificialGeoProxyParams & Pick<GeoProxyFormProxy, 'city' | 'customName'>;

type ProxyManagerFormProps = {
  proxyManagerTargetRef: React.RefObject<HTMLElement>;
};
const ProxyManagerForm: React.FC<ProxyManagerFormProps> = ({ proxyManagerTargetRef }) => {
  const formValues = useProxyForm();
  const { currentProfileId, currentProxy, modalEditingProxyId, modalView, handleProxySelect } = useProxyManagerState();

  const proxyList = useProxyList();
  const proxyFormCurrentTab = useProxyFormCurrentTab();
  const geoProxyFormValues = useGeoProxyForm();
  const geoProxyCountries = useGeoProxyCountries();

  const trafficData = useTrafficData();

  const handlePasteProxy = async (): Promise<void | true> => {
    const clipboardText = await navigator.clipboard.readText();
    await pasteProxyToForm(formValues, clipboardText);
  };

  const deleteProxy = (event: React.MouseEvent): void => {
    event.stopPropagation();
    event.preventDefault();

    const proxyToDelete = proxyList.find(listedProxy => listedProxy.id === modalEditingProxyId);
    updateSelectedProxies({ selectedProxy: proxyToDelete || null });
    openProxyManagerListView();
    switchConfirmBlockVisible(true);
  };

  const copyProxy = (): void => {
    copyProxies([formValues]);
    message.success(<Trans i18nKey="base.copiedText" />);
  };

  const handleSubmitCustomProxy = async (): Promise<void> => {
    const currentProxyId = currentProxy?.id;
    const proxyBeforeUpdate = currentProxyId ? getProxyListProxyById(currentProxyId) : null;
    const isProxyCurrent = modalEditingProxyId === currentProxyId;
    const newProxy = await handleProxyFormSubmit({
      proxyId: modalEditingProxyId,
      profileId: isProxyCurrent ? currentProfileId : null,
      isProxyPage: false,
      checkDate: currentProxy?.checkDate,
    });

    if (!newProxy) {
      return;
    }

    closeProxyManager();

    const [checkedProxy] = await Promise.all([
      updateProxyStatuses({
        proxies: [newProxy],
        profileId: currentProfileId,
        isSharedProxy: false,
      }),
      linkProfileProxy({
        profileId: currentProfileId,
        proxy: newProxy,
      }),
    ]);

    const fullCheckedProxy: IProxy = { ...checkedProxy, checkDate: moment().toDate() };
    showProxyCheckTooltip({
      profileIds: [currentProfileId],
      proxies: [fullCheckedProxy],
      view: PROXY_CHECK_TOOLTIP_LOCATIONS.selectorProfileTable,
      timeout: 2000,
    });

    if (isProxyCurrent) {
      mapAndSetProfilesList(prevProfiles =>
        prevProfiles.map(prevProfile => {
          if (prevProfile.id !== currentProfileId) {
            return prevProfile;
          }

          const proxyUpdated: IProxy = { ...prevProfile.proxy, ...newProxy };

          return { ...prevProfile, proxy: proxyUpdated };
        }),
      );
    }

    const areProxyCheckSensitiveFieldsEqual = proxyBeforeUpdate && determineAreProxyCheckSensitiveFieldsEqual(proxyBeforeUpdate, newProxy);
    if (modalView === 'proxy-add' || (modalView === 'proxy-edit' && !areProxyCheckSensitiveFieldsEqual)) {
      const checkedProxy = await updateProxyStatuses({
        proxies: [newProxy],
        view: PROXY_CHECK_TOOLTIP_LOCATIONS.proxyUngroupedListItem,
      });

      const fullCheckedProxy: IProxy = { ...newProxy, ...checkedProxy, checkDate: moment().toDate() };
      showProxyCheckTooltip({
        profileIds: [currentProfileId || ''],
        proxies: [fullCheckedProxy],
        view: PROXY_CHECK_TOOLTIP_LOCATIONS.proxyUngroupedListItem,
        timeout: 2000,
      });
    }
  };

  const handleClickSubmit = async (): Promise<void> => {
    const currentProxyId = currentProxy?.id;
    const proxyBeforeUpdate = currentProxyId ? getProxyListProxyById(currentProxyId) : null;
    const isProxyCurrent = modalEditingProxyId === currentProxyId;
    const newProxy = await handleProxyFormSubmit({
      proxyId: modalEditingProxyId,
      profileId: isProxyCurrent ? currentProfileId : null,
      isProxyPage: false,
      checkDate: currentProxy?.checkDate,
    });

    if (!newProxy) {
      return;
    }

    if (isProxyCurrent) {
      mapAndSetProfilesList(prevProfiles =>
        prevProfiles.map(prevProfile => {
          if (prevProfile.id !== currentProfileId) {
            return prevProfile;
          }

          const proxyUpdated: IProxy = { ...prevProfile.proxy, ...newProxy };

          return { ...prevProfile, proxy: proxyUpdated };
        }),
      );
    }

    const areProxyCheckSensitiveFieldsEqual = proxyBeforeUpdate && determineAreProxyCheckSensitiveFieldsEqual(proxyBeforeUpdate, newProxy);
    if (modalView === 'proxy-add' || (modalView === 'proxy-edit' && !areProxyCheckSensitiveFieldsEqual)) {
      const checkedProxy = await updateProxyStatuses({
        proxies: [newProxy],
        view: PROXY_CHECK_TOOLTIP_LOCATIONS.proxyUngroupedListItem,
      });

      const fullCheckedProxy: IProxy = { ...newProxy, ...checkedProxy, checkDate: moment().toDate() };
      showProxyCheckTooltip({
        profileIds: [currentProfileId || ''],
        proxies: [fullCheckedProxy],
        view: PROXY_CHECK_TOOLTIP_LOCATIONS.proxyUngroupedListItem,
        timeout: 2000,
      });
    }
  };

  const makeArtificialGeoProxy = ({
    country,
    city,
    connectionType,
    customName,
    profilesCount,
    groupId,
  }: ExtendedArtificialGeoProxyParams): IProxy => {
    const proxyId = generateArtificialGeoProxyId();
    updateGeoProxyCustomNameCounters(country);

    return {
      id: proxyId,
      groupId,
      host: '',
      port: 80,
      mode: 'geolocation',
      country,
      city,
      connectionType,
      customName,
      profilesCount,
      customSortingInGroup: 'end',
      createdAt: new Date(),
      selectionDate: profilesCount ? Date.now() : 0,
    };
  };

  const handleSubmitGeoProxy = async (): Promise<void> => {
    if (proxyFormCurrentTab === PROXY_EDIT_FORM_TABS.customProxy || !currentProfileId) {
      return;
    }

    const { country, city, connectionType, customName } = geoProxyFormValues;

    const groupId = generateProxyGroupId('geolocation', country);
    const currentCountryWithTypes = geoProxyCountries.find(({ countryCode }) => countryCode.toLowerCase() === country.toLowerCase());
    const availableTypes = currentCountryWithTypes ? currentCountryWithTypes.types : [];
    const selectableConnectionType = evaluateSelectableGeoProxyConnectionType({
      country,
      groupId,
      selectedConnectionType: connectionType,
      availableConnectionTypes: availableTypes,
      trafficData,
    });

    if (!selectableConnectionType) {
      return;
    }

    let generatedName = customName;

    if (!customName) {
      const { counter } = evaluateGeoProxyCustomNameCounter(country);
      generatedName = makeGeoProxyCustomName({ countryCode: country, city: city, counter });
      updateGeoProxyForm({ customName });
    }

    const profilesCount = currentProfileId && !handleProxySelect ? 1 : 0;
    const artificialGeoProxy = makeArtificialGeoProxy({
      country,
      city,
      connectionType: selectableConnectionType,
      customName: generatedName,
      groupId,
      profilesCount,
    });

    const geoProxyWithCredentialsParams: GeoProxyWithCredentialsParams = {
      country,
      city,
      connectionType: selectableConnectionType,
      profileId: '',
      customName: artificialGeoProxy.customName,
    };

    closeProxyManager();

    addProxyStatuses([artificialGeoProxy], [currentProfileId]);
    pushManyProxies([artificialGeoProxy]);
    if (handleProxySelect) {
      let proxyId = artificialGeoProxy.id;
      if (!proxyId && determineIsProxyTruthy(artificialGeoProxy)) {
        proxyId = getNoIdProxyId(artificialGeoProxy);
      }

      handleProxySelect(proxyId);
    } else {
      decrementProfileLinkedProxyProfilesCounter(currentProfileId);
      linkProfileProxyInState({
        profileId: currentProfileId,
        proxy: artificialGeoProxy,
        shouldUpdateProxyInList: false,
      });

      geoProxyWithCredentialsParams.profileIdToLink = currentProfileId;
    }

    const geoProxyWithCredentialsResponse = await fetchGeoProxyWithCredentials(geoProxyWithCredentialsParams).catch(error => {
      handleGeoProxyWithCredentialsError(artificialGeoProxy, currentProfileId);
      let errorMessage = 'tableProfiles.notification.trafficLimit';
      if (typeof error?.body?.message === 'string') {
        errorMessage = error.body.message;
      }

      return errorMessage;
    });

    if (typeof geoProxyWithCredentialsResponse === 'string') {
      return message.error(<Trans i18nKey={geoProxyWithCredentialsResponse} />);
    }

    const proxyWithCredentials = geoProxyWithCredentialsResponse;
    const fullProxy: IProxy = {
      ...artificialGeoProxy,
      ...proxyWithCredentials,
      mode: 'geolocation',
    };

    const checkResult = await checkGeoProxy(fullProxy, currentProfileId, PROXY_CHECK_TOOLTIP_LOCATIONS.proxyManagerEditForm);

    if (typeof checkResult === 'string') {
      handleGeoProxyWithCredentialsError(artificialGeoProxy, currentProfileId);

      return message.error(<Trans i18nKey={checkResult} />);
    }

    const checkedProxy = checkResult;
    updateProxyInListById(artificialGeoProxy.id, checkedProxy);
    removeProxyStatuses([artificialGeoProxy], [currentProfileId]);

    const fullCheckedProxy: IProxy = { ...checkedProxy, checkDate: moment().toDate() };
    showProxyCheckTooltip({
      profileIds: [currentProfileId],
      proxies: [fullCheckedProxy],
      view: PROXY_CHECK_TOOLTIP_LOCATIONS.selectorProfileTable,
      timeout: 2000,
    });
    
    if (handleProxySelect) {
      let proxyId = checkedProxy.id;
      if (!proxyId && determineIsProxyTruthy(checkedProxy)) {
        proxyId = getNoIdProxyId(checkedProxy);
      }

      handleProxySelect(proxyId);
    } else {
      linkProfileProxyInState({
        profileId: currentProfileId,
        proxy: checkedProxy,
        shouldUpdateProxyInList: false,
      });
    }

    const createdGeoProxy: IProxy = { ...proxyWithCredentials, ...checkedProxy, profilesCount };
    // TODO: add analytics in the upcoming MR
  };

  const renderDeleteButton = (): JSX.Element | null => {
    if (!modalEditingProxyId) {
      return null;
    }

    return (
      <FormButtonsContainer>
        <DeleteProxyBtn onClick={deleteProxy} type="button">
          <Trans i18nKey="proxies.deleteProxy" />
        </DeleteProxyBtn>
      </FormButtonsContainer>
    );
  };

  const renderFormFields = (): JSX.Element => {
    switch (true) {
      case !NEW_FEATURES.proxyEditForm:
        return (
          <>
            <ProxyEditFormFields
              formValues={formValues}
              currentProfileId={currentProfileId}
              Row={ProxyFormRowElOld}
              RowKey={ProxyFormLeft}
              RowValue={ProxyFormRightOld}
            />
            {renderDeleteButton()}
          </>
        );
      case proxyFormCurrentTab === PROXY_EDIT_FORM_TABS.customProxy || !currentProfileId:
        return <CustomProxyEditFormFields />;
      default:
        return <GeoProxyEditFormFields />;
    }
  };

  const renderFooter = (): JSX.Element => {
    if (NEW_FEATURES.proxyEditForm) {
      return (
        <ToggleableProxyEditFormFooter
          proxyManagerTargetRef={proxyManagerTargetRef}
          onGeoProxyClickSubmit={handleSubmitGeoProxy}
          onCustomProxyClickSubmit={handleSubmitCustomProxy}
        />
      );
    }

    return <ProxyEditFormFooter copyProxy={copyProxy} handlePasteProxy={handlePasteProxy} handleClickSubmit={handleClickSubmit} />;
  };

  return (
    <>
      <ProxyFormContent newDesign={!!NEW_FEATURES.proxyEditForm}>{renderFormFields()}</ProxyFormContent>
      {renderFooter()}
    </>
  );
};

export default React.memo(ProxyManagerForm);
