import styled from '@emotion/styled';

export const ChangeLimitsContainer = styled.div<{ isCustomPlan: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin-right: 8px;
  width: 89px;
  height: 26px;
  padding: 4px 8px;

  background-color: ${({ isCustomPlan }): string => isCustomPlan ? 'var(--F9F9F9-new-pricing-limits-select-custom)': 'var(--F9F9F9-new-pricing-limits-select)'};
  color: ${({ isCustomPlan }): string => isCustomPlan ? 'var(--222222-new-pricing-limits-select-custom)': 'var(--222222-new-pricing-limits-select)'};
`;
