import { DefaultTFuncReturn } from 'i18next';
import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { RenderTableCellFn } from '../features/quickProfiles/interfaces';

const PROFILE_TABLE_COLUMNS: string[] = [
  'name',
  'status',
  'notes',
  'tags',
  'customStatus',
  'lastUpdate',
  'lastLaunch',
  'createdAt',
  'sharing',
  'proxyType',
  'proxyPort',
  'proxyUsername',
  'proxyPassword',
  'proxy',
  'os',
  'orbitaVersion',
] as const;

type ProfileColumnSettings = {
  title?: string | JSX.Element;
  label?: DefaultTFuncReturn | string;
  labelTooltip?: string;
  visible: boolean;
  width: number;
  minWidth?: number;
  headerWidth?: number;
  dataIndex?: string;
  sortField?: string;
  className?: string;
  onCellClick?: (_: unknown, event: React.MouseEvent) => void;
  originalColumnIndex?: number;
  actionsSlot?: JSX.Element;
  leftPadding?: number;
};

type EnhancedProfileColumnSettings<ColumnName, RenderCallback> = ProfileColumnSettings & {
  colName?: ColumnName;
  render?: RenderCallback;
};

export type SingleProfileColumnSetting = EnhancedProfileColumnSettings<typeof PROFILE_TABLE_COLUMNS[number], RenderTableCellFn>;

export type ColumnsOpts = {
  [key: typeof PROFILE_TABLE_COLUMNS[number]]: SingleProfileColumnSetting;
};

const defaultColumnsSettings: ColumnsOpts = {
  name: {
    visible: true,
    width: 335,
  },
  status: {
    visible: Boolean(localStorage.getItem('ColumnTableStatus')),
    width: 114,
  },
  notes: {
    visible: true,
    width: 180,
  },
  tags: {
    visible: false,
    width: 185,
  },
  customStatus: {
    visible: true,
    width: 185,
  },
  lastUpdate: {
    visible: Boolean(localStorage.getItem('ColumnTable')),
    width: 120,
  },
  lastLaunch: {
    visible: Boolean(localStorage.getItem('ColumnTableLastLaunch')),
    width: 130,
  },
  createdAt: {
    visible: Boolean(localStorage.getItem('ColumnTableCreatedAt')),
    width: 100,
  },
  sharing: {
    visible: Boolean(localStorage.getItem('ColumnTableSharing')),
    width: 100,
  },
  proxyType: {
    visible: Boolean(localStorage.getItem('ColumnProxyType')),
    width: 120,
  },
  proxy: {
    visible: Boolean(localStorage.getItem('ColumnProxy')),
    width: 150,
  },
  proxyPort: {
    visible: Boolean(localStorage.getItem('ColumnProxyPort')),
    width: 100,
  },
  proxyUsername: {
    visible: Boolean(localStorage.getItem('ColumnProxyUsername')),
    width: 100,
  },
  proxyPassword: {
    visible: Boolean(localStorage.getItem('ColumnProxyPassword')),
    width: 100,
  },
  os: {
    visible: Boolean(localStorage.getItem('ColumnOs')),
    width: 100,
  },
  orbitaVersion: {
    visible: Boolean(localStorage.getItem('ColumnOrbitaVersion')),
    width: 100,
  },
};

const columnSettingsAtom = atom<ColumnsOpts>(defaultColumnsSettings);

export const useColumnSettings = (): ColumnsOpts => useAtomValue(columnSettingsAtom);

export const getColumnSettings = (): ColumnsOpts => getDefaultStore().get(columnSettingsAtom);

export const setColumnSettings = (newColumnsOpts: ColumnsOpts): void => getDefaultStore().set(columnSettingsAtom, newColumnsOpts);

export const convertColumnsSettingsInArray = (columnsSettings: ColumnsOpts): SingleProfileColumnSetting[] =>
  Object.entries(columnsSettings).map(([colName, props]) => ({
    colName,
    ...props,
  }));

export const setArrayInObjectColumnSettings = (newArrayColumns: SingleProfileColumnSetting[]): void => {
  const newColumns = newArrayColumns.reduce((acc, { colName, ...props }) => {
    acc[colName] = props;

    return acc;
  }, {});

  getDefaultStore().set(columnSettingsAtom, newColumns);
};

export const getDefaultColumnsSettings = (): ColumnsOpts  => {
  const columnsSettings = defaultColumnsSettings;
  let showNotes = localStorage.getItem('ColumnNotes') === null;
  if (localStorage.getItem('ColumnNotes')) {
    showNotes = localStorage.getItem('ColumnNotes') === 'true';
  }

  columnsSettings.notes.visible = showNotes;
  let showStatus = localStorage.getItem('ColumnTableStatus') === null;
  if (localStorage.getItem('ColumnTableStatus')) {
    showStatus = localStorage.getItem('ColumnTableStatus') === 'true';
  }

  columnsSettings.status.visible = showStatus;

  let showProxy = localStorage.getItem('ColumnProxy') === null;
  if (localStorage.getItem('ColumnProxy')) {
    showProxy = localStorage.getItem('ColumnProxy') === 'true';
  }

  columnsSettings.proxy.visible = showProxy;

  return columnsSettings;
};
