import React, { ChangeEvent, ClipboardEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Row, SearchIconContainer, SearchInput, SearchInputContainer, SearchInputWrapper } from './styles';
import { clearSearch, commitSearch, openSearch, updateSearchInputText, useSearchState } from '../../../state';
import { getOSReleaseVersionForWeb } from '../../../utils/user-os';
import { IconCross, IconSearch, IconFillArrowDown } from '../icons';
import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';

interface IInputInactive {
  onClick?: () => void;
  isSearchPinned: boolean;
  onOpenSearchHistory: () => void;
  onOpenSearchMenu: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onClickCross: (event: MouseEvent) => void;
  inputRef: React.RefObject<HTMLInputElement>;
  onKeyPress: (event: React.KeyboardEvent) => void;
  onPaste: (event: ClipboardEvent<HTMLInputElement>) => void;
}

export const InputInactive: FC<IInputInactive> = (props): JSX.Element => {
  const {
    onClick, isSearchPinned, onOpenSearchHistory, onOpenSearchMenu, onClickCross, inputRef, onKeyPress, onPaste,
  } = props;

  const { t: translation } = useTranslation();
  const { searchInputText, isSearchOpen } = useSearchState();

  const onFocus = (): void => {
    openSearch();
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (!event.target.value) {
      clearSearch();
    }

    if (!NEW_FEATURES.objectPool) {
      updateSearchInputText(event.target.value);
    } else {
      const newSearchText = event.target.value;
      // FIXME: debounce into search history
      commitSearch(newSearchText);
    }
  };

  const renderInput = (): JSX.Element => {
    if (!isSearchOpen) {
      const { os } = getOSReleaseVersionForWeb();

      return (
        <div style={{ color: 'var(--B5B5BA-header-new-search)' }}>
          {translation('header.searchProfile.searchHint', { count: os === 'mac' ? 0 : 1 })}
        </div>
      );
    }

    return (
      <SearchInput
        ref={inputRef}
        value={searchInputText}
        placeholder={translation('header.searchProfile.placeholder') || ''}
        onChange={onChange}
        onKeyPress={onKeyPress}
        onClick={onClick}
        onFocus={onFocus}
        autoFocus={true}
        onPaste={onPaste}
      />
    );
  };

  return (
    <SearchInputWrapper
      isSearchPinned={isSearchPinned}
      isSearchOpen={isSearchOpen}
      onClick={onOpenSearchHistory}
    >
      <SearchInputContainer isSearchPinned={isSearchPinned} isSearchOpen={isSearchOpen}>
        <Row>
          <SearchIconContainer onClick={onOpenSearchMenu}>
            <IconSearch
              padding={0}
              margin={isSearchOpen ? '0 3px 0 0' : '0 10px 0 0'}
              iconColor={isSearchOpen ? 'var(--2B2B31-header-new-search)' : 'var(--B5B5BA-header-new-search)'}
            />
            {isSearchOpen ? (
              <IconFillArrowDown
                padding={0}
                margin='0 10px 0 0'
                styleType='darkGray'
                iconColor='var(--2B2B31-header-new-search)'
              />
            ) : null}
          </SearchIconContainer>
          {renderInput()}
          {(isSearchOpen) ? (
            <IconCross
              iconColor='var(--98989F-header-new-search)'
              iconHoveredColor='var(--767676-header-new-search)'
              styleType='activeGray'
              margin='0 0 0 10px'
              onClick={onClickCross}
            />
          ) : null}
        </Row>
      </SearchInputContainer>
    </SearchInputWrapper>
  );
};
