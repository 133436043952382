import { css } from '@emotion/core';
import { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled';
import React, { useEffect, useRef, useState } from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

const IconContainer = styled('svg')<{ isSpinning: boolean }>`
  ${(props): SerializedStyles | null =>
    props.isSpinning
      ? css`
          animation: spin 1000ms linear infinite;
        `
      : css`
          transition: transform 0.5s linear;
          transform: rotate(0deg);
        `};

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

interface IIconSpin extends IIconWrapper {
  hasSpin: boolean;
}

export const IconSpin: React.FC<IIconSpin> = (props) => {
  const { hasSpin, size = '16' } = props;
  const [isSpinning, setIsSpinning] = useState(hasSpin);
  const containerRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    if (hasSpin) {
      setIsSpinning(true);

      return;
    }

    const checkRotation = (): void => {
      if (!containerRef.current) {
        return;
      }

      const { transform } = getComputedStyle(containerRef.current);
      if (transform === 'none') {
        setIsSpinning(false);

        return;
      }

      const values = transform
        .split('(')[1]
        .split(')')[0]
        .split(',');

      const cosAngle = parseFloat(values[0]);
      const sinAngle = parseFloat(values[1]);
      const angle = Math.round(Math.atan2(sinAngle, cosAngle) * (180 / Math.PI));

      const threshold = 5;
      if (Math.abs(angle) <= threshold || Math.abs(angle - 360) <= threshold) {
        setIsSpinning(false);

        return;
      }

      requestAnimationFrame(checkRotation);
    };

    requestAnimationFrame(checkRotation);
  }, [hasSpin]);

  return (
    <IconWrapper {...props}>
      <IconContainer
        ref={containerRef}
        isSpinning={isSpinning}
        width={size}
        height={size}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.5 8.00121C1.49974 6.59515 1.95542 5.22691 2.79867 4.10177C3.64193 2.97663 4.82729 2.15526 6.17692 1.7609C7.52655 1.36654 8.96766 1.42044 10.2841 1.91452C11.6005 2.4086 12.7212 3.31622 13.478 4.50121"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M13.5 0.5V4.5H9.5" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M14.5005 8C14.5007 9.40606 14.045 10.7743 13.2018 11.8994C12.3585 13.0246 11.1732 13.8459 9.82354 14.2403C8.47391 14.6347 7.0328 14.5808 5.7164 14.0867C4.4 13.5926 3.27931 12.685 2.52246 11.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M2.5 15.5L2.5 11.5H6.5" strokeLinecap="round" strokeLinejoin="round" />
      </IconContainer>
    </IconWrapper>
  );
};
