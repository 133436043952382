import Popper, { PopperProps } from '@material-ui/core/Popper';
import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { IProxy } from '../../../interfaces';
import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import { PROXY_CHECK_TOOLTIP_LOCATIONS } from '../../../state/proxy/proxy-check/constants';
import {
  useIsProxyCheckTooltipVisible,
  useProxyCheckTooltipProxies,
} from '../../../state/proxy/proxy-check/proxy-check-tooltip.atom';
import { ProxyCheckTooltipLocation } from '../../../state/proxy/proxy-check/types/proxy-check-tooltip-location.type';
import { useProxyManagerState } from '../../../state/proxy/proxy-manager-modal-status.atom';
import { useCurrentTheme } from '../../../state/theme.atom';
import getSelectedThemeColor from '../../../utils/get-selected-theme-color';
import ProxyInfo from './proxy-info';
import ProxyDataAvailability from './proxy-info/proxy-data-availability';
import { CheckProxyTooltipContent, CheckProxyTooltipWrapper } from './styles';

const DEFAULT_PROXY_SELECTOR_LOCATIONS: string[] = [
  PROXY_CHECK_TOOLTIP_LOCATIONS.selectorProfileTable,
  PROXY_CHECK_TOOLTIP_LOCATIONS.selectorProfileSettingsFavourite,
  PROXY_CHECK_TOOLTIP_LOCATIONS.selectorProfileSettingsProxy,
];

const TOP_PROXY_SELECTOR_LOCATIONS: ProxyCheckTooltipLocation[] = [
  PROXY_CHECK_TOOLTIP_LOCATIONS.profileSettingsPage,
  PROXY_CHECK_TOOLTIP_LOCATIONS.proxyPageTable,
];

type CheckProxyTooltipProps = {
  proxy: IProxy;
  profileId?: string;
  proxyTooltipView: ProxyCheckTooltipLocation;
  tooltipRef: React.RefObject<HTMLDivElement>;
  selectorContainerElement?: React.RefObject<HTMLDivElement>;
};

const CheckProxyTooltip: React.FC<CheckProxyTooltipProps> = (props) => {
  const { proxy, profileId = '', proxyTooltipView, tooltipRef, selectorContainerElement } = props;

  const isTooltipVisible = useIsProxyCheckTooltipVisible(proxy, profileId, proxyTooltipView);

  const proxies = useProxyCheckTooltipProxies();
  const { containerElement: selectorContainerElementFromState, handleProxySelect } = useProxyManagerState();

  const theme = useCurrentTheme();

  const isProfilesSettingsProxyManager = !!handleProxySelect;
  const isDarkTheme = getSelectedThemeColor(theme) === 'dark';
  const [placement, setPlacement] = useState<PopperProps['placement']>('left-start');
  // we need selectorContainerElement if proxy-manager is not opened at all
  const refElement = selectorContainerElement ? selectorContainerElement.current : selectorContainerElementFromState;
  useEffect(() => {
    if (isTooltipVisible && tooltipRef.current && refElement) {
      const tooltipRect = tooltipRef.current.getBoundingClientRect();
      const distanceFromBottom = window.innerHeight - (tooltipRect.bottom + 130);
      if (tooltipRect.left < 0) {
        setPlacement('right-start');
      } else if (distanceFromBottom < 0) {
        setPlacement('left-end');
      } else {
        setPlacement('left-start');
      }
    }
  }, [isTooltipVisible, tooltipRef, refElement]);
  if (!(proxies.length && isTooltipVisible && tooltipRef.current && refElement)) {
    return null;
  }
  const routeMatch = useRouteMatch('/proxy');
  let padding = '0';
  let [translateLeft, marginTop] = [0, -18];
  let marginLeft = 0;
  let marginRight = 0;
      
  switch (true) {
    case !NEW_FEATURES.proxyGroupsV2 ||
      isProfilesSettingsProxyManager ||
      TOP_PROXY_SELECTOR_LOCATIONS.includes(proxyTooltipView):
      padding = '0';
      marginTop = (routeMatch !== null ? -16 : -18);
      break;
    case proxyTooltipView && DEFAULT_PROXY_SELECTOR_LOCATIONS.includes(proxyTooltipView):
      marginTop = -19;
      break;
    case proxyTooltipView === PROXY_CHECK_TOOLTIP_LOCATIONS.proxyUngroupedListItem:
      translateLeft = -9;
      break;
    case proxyTooltipView === PROXY_CHECK_TOOLTIP_LOCATIONS.proxyGroupedListItem:
      translateLeft = -33;
      break;
    default:
      break;
  }

  const { connectionType } = proxy;

  return (
    <Popper
      open={isTooltipVisible}
      anchorEl={tooltipRef.current}
      style={{ zIndex: 1070, padding: '8px', paddingTop: '0', marginLeft: `${marginLeft}px`, marginRight: `${marginRight}px`, paddingBottom: '0' }}
      placement={placement}
    >
      <CheckProxyTooltipWrapper
        style={{ width: !NEW_FEATURES.proxyGroupsV2 ? '306px' : '300px' }}
        padding={padding}
        marginTop={marginTop}
        translateLeft={translateLeft}
      >
        <CheckProxyTooltipContent isProxyManagerWithGroups={!!NEW_FEATURES.proxyGroupsV2} isDarkTheme={isDarkTheme}>
          <ProxyInfo
            proxy={proxy}
            profileId={profileId}
            proxyTooltipView={proxyTooltipView}
            selectorContainerElement={refElement}
          />
          {connectionType ? <ProxyDataAvailability geoProxyType={connectionType}></ProxyDataAvailability> : null}
        </CheckProxyTooltipContent>
      </CheckProxyTooltipWrapper>
    </Popper>
  );
};

export default CheckProxyTooltip;
