import React from 'react';

import { MenuItemsProps } from './interfaces';
import { MassActionsButtonLine } from './styles';
import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import {
  getActionsButtonStyles,
  MassActionsButtonText,
  MassActionsRow,
} from '../../common/custom-table/components/mass-actions-menu/styles';

export const MenuItem = (props: MenuItemsProps): JSX.Element|null => {
  const { Icon, text, onClick, isVisible = true, isDisabled, hasTooltip, setAnchorElTooltip } = props;

  if (!isVisible) {
    return null;
  }

  const showTooltip = (event: React.MouseEvent<HTMLSpanElement>): void => {
    if (!hasTooltip || !setAnchorElTooltip) {
      return;
    }

    setAnchorElTooltip(event.currentTarget);
  };

  const closeTooltip = (): void => {
    if (!hasTooltip || !setAnchorElTooltip) {
      return;
    }

    setAnchorElTooltip(null);
  };

  const onClickItem = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    if (isDisabled || hasTooltip) {
      return;
    }

    onClick(event);
  };

  return (
    <MassActionsButtonLine
      onMouseEnter={showTooltip}
      onMouseLeave={closeTooltip}
      {...getActionsButtonStyles(isDisabled || hasTooltip)}
      iconHoveredColor={NEW_FEATURES.header ? 'var(--36363D)' : 'var(--00B86D)'}
      onClick={onClickItem}
      oldStyle={!NEW_FEATURES.header}
    >
      <MassActionsRow>
        <Icon size={12} padding={0} />
        <MassActionsButtonText>
          {text}
        </MassActionsButtonText>
      </MassActionsRow>
    </MassActionsButtonLine>
  );
};
