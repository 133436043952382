import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconCity: React.FC<IIconWrapper> = props => (
  <IconWrapper {...props}>
    <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.5 6C11.5 10.5 6 15.5 6 15.5C6 15.5 0.5 10.5 0.5 6C0.5 4.54131 1.07946 3.14236 2.11091 2.11091C3.14236 1.07946 4.54131 0.5 6 0.5C7.45869 0.5 8.85764 1.07946 9.88909 2.11091C10.9205 3.14236 11.5 4.54131 11.5 6Z"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6 8.5C7.38071 8.5 8.5 7.38071 8.5 6C8.5 4.61929 7.38071 3.5 6 3.5C4.61929 3.5 3.5 4.61929 3.5 6C3.5 7.38071 4.61929 8.5 6 8.5Z"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </IconWrapper>
);
