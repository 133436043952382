import { SerializedStyles } from '@emotion/core';
import css from '@emotion/css';
import styled from '@emotion/styled';
import { Badge, Dropdown, Menu, Icon } from 'antd';
import { format } from 'date-fns';
import React, { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { requestGetSharedAccounts } from './api';
import { NEW_FEATURES } from '../../state/feature-toggle/new-features';
import switchWorkspace from '../../features/common/switch-workspace';
import CreateWorkspaceModal from '../../features/modalsComponents/components/create-workspace';
import InviteMembersModal from '../../features/modalsComponents/components/invite-members';
import { LogoutNotificationModal } from '../../features/modalsComponents/components/logout-notification/index.js';
import { userContext, workspaceContext } from '../../state';
import { checkAndLogout } from '../../utils/logout';
import { itemRowIconProps } from '../gologin-header/account-menu/menu-items';
import { ItemRow } from '../gologin-header/account-menu/styles';
import WorkspaceInfo from '../gologin-header/account-menu/workspace-info';
import { IconPlus } from '../gologin-header/icons';
import IconAddMembers from '../icons/IconAddMembers';
import IconArrowDown from '../icons/IconArrowDown';
import IconLogout from '../icons/IconLogout';
import IconProfile from '../icons/IconProfile';
import IconSetting from '../icons/IconSetting';
import IconSupport from '../icons/IconSupport';
import IconSwitch from '../icons/IconSwitch';
import { LOCAL_STORAGE_SELECTED_FOLDER } from '../../../common/constants/local-storage';

const Row = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const EmailTxt = styled.p`
  font-family: Roboto, serif;
  color: var(--000000-old-header-account-menu-email);
  font-size: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin: 0;
`;

const ContainerMenuDropdawn = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`;

const PlanText = styled('div')`
  font-family: Roboto, serif;
  font-size: 14px;
  color: var(--000000-old-header-account-menu-plan);
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SettingText = styled('div')`
  font-family: Roboto, serif;
  font-size: 14px;
  line-height: 12px;
  color: var(--000000-old-header-account-menu-settings);
  margin-left: 4px;
`;

const MenuItem = styled(Menu.Item)`
  display: none;
  @media screen and (max-width: 500px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
 }
`;

const ContainerProfile = styled('div')<{  isQuickSettingsEnabled?: boolean; accountMenuPage: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: -1px;
  cursor: pointer;
  ${(props): SerializedStyles =>
    css`
      margin-left: ${props.isQuickSettingsEnabled && props.accountMenuPage ? '0px' : '60px'};
    `}
`;

const ContainerWorkspaceName = styled('span')`
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 20px;
`;

const IconArrow = styled(IconArrowDown)`
  margin-left: 4px;
  stroke: var(--4E4E4E);
`;

const MenuMobileText = styled('a')`
  font-size: 12px;
  line-height: 12px;
  color: var(--000000);
  opacity: 0.6;
  margin-top: 4px;
  margin-bottom: 3px;
  margin-left: 8px;
  :hover {
    color: var(--000000);
  }
`;

const DropdownMenu = styled(Menu)<{ isWorkspaceUser: boolean }>`
  width: ${(props): string => props.isWorkspaceUser ? '230px' : '206px'};
  max-height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
  top: 19px;
  left: 41px;
`;

declare interface IAccountMenu {
  windowWidth: number;
  isEmailConfirmed?: boolean;
  userEmail: string;
  userProfiles: number;
  planMaxProfiles: number;
  namePlan: string;
  sharedAccountMenuDisplay: string;
  addMembersMenuDisplay: string;
  iconColor?: string;
  toggleAccountModal?: (state: boolean) => void;
  toggleShareAccountModal?: (options: any) => void;
  isQuickSettingsEnabled?: boolean;
  accountMenuPage: boolean;
  setSharedAccounts?: any;
}

const AccountMenu: FC<IAccountMenu> = (props) => {
  const [switchAccountMenuDisplay, setSwitchAccountMenuDisplay] = useState<'block'|'none'>('none');
  const [isLogoutModalVisible, setIsLogoutModalVisible] = useState<boolean>(false);
  const [createWorkspaceModalVisible, setCreateWorkspaceModalVisible] = useState<boolean>(false);
  const [inviteMembersModalVisible, setInviteMembersModalVisible] = useState<boolean>(false);

  const workspaceCtx = useContext(workspaceContext);
  const user = useContext(userContext);

  const { t: translation } = useTranslation();

  const { availableWorkspaces, id: workspaceId, folders } = workspaceCtx;
  const { email, isEmailConfirmed, profiles, updateSelectedFolder } = user;

  const localStorageFolderName = localStorage.getItem(LOCAL_STORAGE_SELECTED_FOLDER);
  const selectedFolder = folders.find((folder) => folder.name === localStorageFolderName);
  const selectedFolderId = selectedFolder?.id;

  const {
    windowWidth, planMaxProfiles, namePlan, addMembersMenuDisplay, iconColor, setSharedAccounts,
    toggleAccountModal, toggleShareAccountModal, isQuickSettingsEnabled, accountMenuPage,
  } = props;

  const showMenuDivider = (): null|JSX.Element => {
    if (windowWidth >= 500) {
      return null;
    }

    return (
      <Menu.Divider style={{ marginRight: 7, marginLeft: 7, display: switchAccountMenuDisplay }} />
    );
  };

  const renderExpirationDate = (): null|JSX.Element => {
    if (user.planExpireDate && user.plan.priceForMonth) {
      return (
        <ContainerMenuDropdawn>
          <PlanText>
            {translation('userMenu.nextPayment')}
            {' '}
            {format(new Date(user.planExpireDate), 'dd MMM yyyy')}
          </PlanText>
        </ContainerMenuDropdawn>
      );
    }

    return null;
  };

  const onWorkspaceClick = async (clickedWorkspaceId: string): Promise<void> => {
    await switchWorkspace(clickedWorkspaceId, workspaceCtx, user);
    updateSelectedFolder('all');
  };

  const renderHeaderMenu = (): JSX.Element => {
    if (NEW_FEATURES.workspaces) {
      return (
        <EmailTxt style={{ color: 'var(--767676)' }}>
          {email}
        </EmailTxt>
      );
    }

    return (
      <>
        <EmailTxt>
          {email}
        </EmailTxt>
        <ContainerMenuDropdawn>
          <PlanText>
            {namePlan}
            {' '}
            (
            {profiles}
            /
            {planMaxProfiles}
            )
          </PlanText>
        </ContainerMenuDropdawn>
      </>
    );
  };

  const menu = (): JSX.Element => {
    if (windowWidth >= 500) {
      return (
        <DropdownMenu isWorkspaceUser={!!NEW_FEATURES.workspaces}>
          <Menu.Item
            style={{ cursor: 'default', background: '000000' }}
          >
            {renderHeaderMenu()}
            {renderExpirationDate()}
          </Menu.Item>
          <Menu.Divider
            style={{ marginRight: 7, marginLeft: 7 }}
          />
          {/* 3 блока на проверку фича тогла 'workspaces' */}
          {/* так как если Menu.Item обернуть в внешний блок, будут ошибки при обработке антовского события onItemHover */}
          {/* Учитывая, что это ант + хотим уходить от старого дизайна, посчитал, что проще продублировать блоки, чем делать обработку на уже существующих */}
          {availableWorkspaces.map(workspace => (
            <Menu.Item key={workspace.id}>
              <WorkspaceInfo
                key={workspace.id}
                name={workspace.name}
                profiles={workspace.profilesCount}
                planMaxProfiles={workspace.planProfilesMax}
                planName={workspace.planName}
                onClick={(): Promise<void> => onWorkspaceClick(workspace.id)}
                isSelected={workspace.id === workspaceId}
                isFromOldHeader={true}
              />
            </Menu.Item>
          ))}
          <Menu.Item onClick={(): void => setCreateWorkspaceModalVisible(true)}>
            <ItemRow>
              <IconPlus {...itemRowIconProps} />
              <span style={{ marginLeft: 10, color: 'var(--81818A-header-account-menu)' }}>
                {translation('userMenu.addWorkspace')}
              </span>
            </ItemRow>
          </Menu.Item>
          <Menu.Divider style={{ marginRight: 7, marginLeft: 7 }} />
          <Menu.Item>
            <Link to='/personalArea/Billing'>
              <Badge count={isEmailConfirmed ? 0 : 1} dot={true}>
                <ContainerMenuDropdawn>
                  <IconSetting />
                  <SettingText
                    style={{ marginTop: 3 }}
                  >
                    {translation('userMenu.settings')}
                  </SettingText>
                </ContainerMenuDropdawn>
              </Badge>
            </Link>
          </Menu.Item>
          <Menu.Divider style={{ marginRight: 7, marginLeft: 7 }} />
          <Menu.Item
            style={{ display: addMembersMenuDisplay }}
            onClick={(): void => {
              if (NEW_FEATURES.workspaces) {
                setInviteMembersModalVisible(true);

                return;
              }

              toggleShareAccountModal && toggleShareAccountModal({ status: true });
            }}
          >
            <ContainerMenuDropdawn>
              <IconAddMembers />
              <SettingText
                style={{ marginTop: 3, marginLeft: 5 }}
              >
                {translation('userMenu.addMembers')}
              </SettingText>
            </ContainerMenuDropdawn>
          </Menu.Item>
          <Menu.Divider
            style={{ marginRight: 7, marginLeft: 7, display: addMembersMenuDisplay }}
          />
          <Menu.Item
            style={{ display: switchAccountMenuDisplay }}
            onClick={(): void => toggleAccountModal && toggleAccountModal(true)}
          >
            <ContainerMenuDropdawn>
              <IconSwitch />
              <SettingText
                style={{ marginBottom: 3, marginTop: 4, marginLeft: 5 }}
              >
                {translation('userMenu.switchAccount')}
              </SettingText>
            </ContainerMenuDropdawn>
          </Menu.Item>
          <Menu.Divider
            style={{ marginRight: 7, marginLeft: 7, display: switchAccountMenuDisplay }}
          />
          <Menu.Item
            onClick={() => checkAndLogout(workspaceCtx, setIsLogoutModalVisible)}
          >
            <ContainerMenuDropdawn>
              <IconLogout />
              <SettingText
                style={{
                  marginTop: 3,
                  marginLeft: 5,
                }}
              >
                {translation('userMenu.logout')}
              </SettingText>
            </ContainerMenuDropdawn>
          </Menu.Item>
        </DropdownMenu>
      );
    }

    return (
      <Menu style={{ marginTop: 15, width: 180 }}>
        <Menu.Item
          style={{ cursor: 'default' , background: '000000' }}
        >
          <EmailTxt>
            {email}
          </EmailTxt>
          <ContainerMenuDropdawn>
            <PlanText>
              {namePlan}
              {' '}
              (
              {profiles}
              /
              {planMaxProfiles}
              )
            </PlanText>
          </ContainerMenuDropdawn>

          {renderExpirationDate()}

        </Menu.Item>
        <Menu.Divider
          style={{ marginRight: 7, marginLeft: 7 }}
        />
        <MenuItem>
          <Link to='/'>
            <Row>
              <Icon
                type='profile'
                style={{ color: 'var(--888888)', fontSize: 16, marginTop: 2, marginLeft: 2 }}
              />
              <MenuMobileText
                style={{ marginLeft: 6 }}
              >
                {translation('userMenu.profiles')}
              </MenuMobileText>
            </Row>
          </Link>
        </MenuItem>
        {showMenuDivider()}
        <Menu.Item>
          <Link to='/personalArea/billing'>
            <ContainerMenuDropdawn>
              <IconSetting />
              <SettingText
                style={{ marginTop: 3 }}
              >
                {translation('userMenu.settings')}
              </SettingText>
            </ContainerMenuDropdawn>
          </Link>
        </Menu.Item>
        <Menu.Divider
          style={{ marginRight: 7, marginLeft: 7 }}
        />
        <Menu.Item
          style={{ display: addMembersMenuDisplay }}
          onClick={(): void => toggleShareAccountModal && toggleShareAccountModal({ status: true })}
        >
          <ContainerMenuDropdawn>
            <IconAddMembers />
            <SettingText
              style={{ marginTop: 3, marginLeft: 5 }}
            >
              {translation('userMenu.addMembers')}
            </SettingText>
          </ContainerMenuDropdawn>
        </Menu.Item>
        <Menu.Divider
          style={{ marginRight: 7, marginLeft: 7, display: addMembersMenuDisplay }}
        />
        <Menu.Item
          style={{ display: switchAccountMenuDisplay }}
          onClick={(): void => toggleAccountModal && toggleAccountModal(true)}
        >
          <ContainerMenuDropdawn>
            <IconSwitch />
            <SettingText
              style={{ marginBottom: 3, marginTop: 4, marginLeft: 5 }}
            >
              {translation('userMenu.switchAccount')}
            </SettingText>
          </ContainerMenuDropdawn>
        </Menu.Item>
        <Menu.Divider
          style={{ marginRight: 7, marginLeft: 7, display: switchAccountMenuDisplay }}
        />
        <MenuItem>
          <ContainerMenuDropdawn>
            <IconSupport />
            <MenuMobileText
              href='https://support.gologin.com/en'
              target='_blank'
              style={{ marginLeft: 7, marginTop: 2 }}
            >
              {translation('userMenu.support')}
            </MenuMobileText>
          </ContainerMenuDropdawn>
        </MenuItem>
        {showMenuDivider()}
        <Menu.Item
          onClick={() => checkAndLogout(workspaceCtx, setIsLogoutModalVisible)}
        >
          <ContainerMenuDropdawn>
            <IconLogout />
            <SettingText
              style={{ marginTop: 3, marginLeft: 5 }}
            >
              {translation('userMenu.logout')}
            </SettingText>
          </ContainerMenuDropdawn>
        </Menu.Item>
      </Menu>
    );
  };

  const getShareInfo = async (): Promise<void> => {
    const sharedAccounts = await requestGetSharedAccounts().catch(() => []);
    setSharedAccounts(sharedAccounts);
    setSwitchAccountMenuDisplay(sharedAccounts.length ? 'block' : 'none');
  };

  return (
    <>
      <Dropdown overlay={menu} trigger={['click']} onVisibleChange={getShareInfo}>
        <ContainerProfile
          style={{ color: iconColor }}
          isQuickSettingsEnabled={isQuickSettingsEnabled}
          accountMenuPage={accountMenuPage}
        >
          {NEW_FEATURES.workspaces ? (
            <ContainerWorkspaceName>
              {workspaceCtx.name}
            </ContainerWorkspaceName>
          ) : (
            <Badge count={isEmailConfirmed ? 0 : 1} dot={true}>
              <IconProfile color={iconColor} />
            </Badge>
          )}
          <IconArrow color={iconColor} />
        </ContainerProfile>
      </Dropdown>
      <CreateWorkspaceModal
        visible={createWorkspaceModalVisible}
        onClose={(): void => setCreateWorkspaceModalVisible(false)}
      />
      <InviteMembersModal
        visible={inviteMembersModalVisible}
        onClose={(): void => setInviteMembersModalVisible(false)}
        initWithFolderId={selectedFolderId}
      />
      <LogoutNotificationModal isLogoutModalVisible={isLogoutModalVisible} setIsLogoutModalVisible={setIsLogoutModalVisible} />
    </>
  );
};

export default AccountMenu;
