import { Button } from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { templatesContext } from '../../../state';
import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import EmptyTablePlaceholder from '../../common/custom-table/components/empty-table-placeholder';
import { quickCreateProfile } from '../quick-create-profile/create-quick-profile.action';

const NoProfilesPlaceholder = (): JSX.Element => {
  const templateCtx = useContext(templatesContext);

  const { t: translation } = useTranslation();

  if (!NEW_FEATURES.header) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Link to='/newProfile'>
          <Button type='primary'>
            {translation('newProfile.saveProfileBtnText')}
          </Button>
        </Link>
      </div>
    );
  }

  return (
    <EmptyTablePlaceholder
      entityType='profile'
      handleCreateFirstEntity={(): Promise<void> => quickCreateProfile(templateCtx)}
    />
  );
};

export default NoProfilesPlaceholder;
