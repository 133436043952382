import React from 'react';

import { IconWrapper, IIconWrapper } from '../gologin-header/icons/wrapper';

export const IconMinus: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.5 8.5H13.5" strokeMiterlimit='10' strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  </IconWrapper>
);
