import { createContext } from 'react';

import { zeroProfileBookmarks } from '../../electron/constants/zero-profile-bookmarks';
import { IBookmarkFoldersObj } from '../../electron/interfaces/bookmarks-utils.interfaces';
import { RESOLUTIONS } from '../features/common/all-resolutions';
import { MajorVersionOption } from '../features/common/orbita-browser';
import { OS } from '../utils/user-os';

export interface IBrowserStorage {
  local: boolean;
  indexedDb: boolean;
  extensions: boolean;
  bookmarks: boolean;
  history: boolean;
  passwords: boolean;
  session: boolean;
  enableExternalExtensions?: boolean;
}

export interface IProfileName {
  dropFiles: string;
  rename?: string;
  singleProfile?: string;
  singleProfileQuick?: string;
}

export interface IBrowserParamsFromTemplates {
  os: OS | 'deviceOS' | 'macM1';
  osSpec?: string;
  minResolution: typeof RESOLUTIONS[number];
  maxResolution: typeof RESOLUTIONS[number];
  uaUserVersion: MajorVersionOption;
  language: string;
  storage: IBrowserStorage;
  dns: string;
  startUrl: string;
  bookmarks: IBookmarkFoldersObj;
  launchArguments: string;
  autoLang: boolean;
  email?: string;
}

export const BROWSER_TEMPLATE_VALUE: IBrowserParamsFromTemplates = {
  os: 'deviceOS',
  minResolution: RESOLUTIONS[0],
  maxResolution: RESOLUTIONS[RESOLUTIONS.length - 1],
  uaUserVersion: 'unset',
  language: '',
  storage: {
    local: true,
    extensions: true,
    bookmarks: true,
    history: true,
    passwords: true,
    session: true,
    indexedDb: false,
    enableExternalExtensions: false,
  },
  dns: '',
  startUrl: '',
  bookmarks: zeroProfileBookmarks.roots,
  launchArguments: '',
  autoLang: true,
};

export interface ITemplate {
  id: string|null;
  name: string|null;
  user: string|null;
  profileName: IProfileName;
  browser: IBrowserParamsFromTemplates;
}

export const DEFAULT_PROFILE_NAME = 'profile';
export const TEMPLATE_DEFAULT_VALUE: ITemplate = {
  id: null,
  name: null,
  user: null,
  profileName: {
    dropFiles: 'profile {number from 1}',
  },
  browser: BROWSER_TEMPLATE_VALUE,
};

export interface ITemplates {
  selectedTemplate: ITemplate;
  availableTemplates: ITemplate[];
}

export interface ITemplatesCtx extends ITemplates {
  updateSelectedTemplate: (template: ITemplate) => void;
  updateAvailableTemplates: (templates: ITemplate[], userScreenResolution: typeof RESOLUTIONS[number] | '') => void;
}

export const TEMPLATES_DEFAULT_VALUE: ITemplatesCtx = {
  selectedTemplate: TEMPLATE_DEFAULT_VALUE,
  updateSelectedTemplate: () => {},
  availableTemplates: [TEMPLATE_DEFAULT_VALUE],
  updateAvailableTemplates: () => {},
};

export const templatesContext = createContext<ITemplatesCtx>(TEMPLATES_DEFAULT_VALUE);

