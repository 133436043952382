import css from '@emotion/css';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';

import { E_ANALYTICS_ACTIONS } from '../../../../common/constants/analytics';
import { GeoProxyType } from '../../../../common/constants/types';
import { stringifyRemainingTrafficAmount } from '../../../../common/proxy/traffic/utils';
import { sendActionAnalytics } from '../../../features/common/api';
import {
  GEO_PROXY_TYPE_HINTS,
  GeoProxyTypeHint,
} from '../../../features/proxy/proxy-manager/proxy-list/proxy-group-add-button/proxy-group-add-button-menu/constants';
import {
  POPOVER_DEFAULT_PADDING,
} from '../../../features/proxy/proxy-manager/geoproxy-type-popover';
import {
  AddButtonPopoverRowContent,
  AddButtonPopoverRowRightControls,
  AddButtonPopoverRowTitle,
} from '../../../features/proxy/proxy-manager/proxy-list/proxy-group-add-button/proxy-group-add-button-menu/styles';
import { IMenuItem } from '../../../features/quickProfiles/profile-dropdown-menu/interfaces';
import { updateProxyTypeVisibility, useProxyTypeVisibility } from '../../../state/proxy/proxy-type-visibility.atom';
import { useTrafficData } from '../../../state/proxy/traffic-data.atom';
import GologinPopover from '../../gologin-popover';
import { itemRowIconProps } from '../account-menu/menu-items';
import ToggleableEye from '../icons/icon-eyes';
import { IconHome } from '../icons/icon-home';
import { IconProxyDataCenterSmall } from '../icons/icon-proxy-datacenter-small';
import { IconProxyMobile } from '../icons/icon-proxy-mobile';
import PriceList from './price-list';
import { ProxyTrafficPopoverProxyTypeHintWrapper, ProxyTrafficPopoverRow, ProxyTrafficPopoverTitle, VolumeProxyContainer } from './styles';

type GeoProxyTypeItem = Omit<IMenuItem, 'name' | 'onAction'> & {
  name: GeoProxyType;
  hint: {
    translationKey: string;
  };
};

type ProxyTrafficPopoverProps = {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  isTrial: boolean;
};

const ProxyTrafficPopover: React.FC<ProxyTrafficPopoverProps> = (props) => {
  const { anchorEl, onClose } = props;

  const trafficData = useTrafficData();
  const proxyVisibility = useProxyTypeVisibility();

  const [anchorElPriceList, setAnchorElPriceList] = useState<HTMLElement | null>(null);
  const [selectedType, setSelectedType] = useState<GeoProxyType>(GeoProxyType.Mobile);
  const [activeGeoProxyHint, setActiveGeoProxyHint] = useState<GeoProxyTypeHint | null>(null);
  const handleToggleVisibility = (type: GeoProxyType) => {
    updateProxyTypeVisibility({ [type]: !proxyVisibility[type] });
  };

  const geoProxyTypeItems: GeoProxyTypeItem[] = [
    {
      name: GeoProxyType.Resident,
      translationKey: `proxyTypes.${GeoProxyType.Resident}`,
      icon: <IconHome {...itemRowIconProps} />,
      hint: { translationKey: GEO_PROXY_TYPE_HINTS[GeoProxyType.Resident] },
    },
    {
      name: GeoProxyType.Mobile,
      translationKey: `proxyTypes.${GeoProxyType.Mobile}`,
      icon: <IconProxyMobile {...itemRowIconProps} />,
      hint: { translationKey: GEO_PROXY_TYPE_HINTS[GeoProxyType.Mobile] },
    },
    {
      name: GeoProxyType.DataCenter,
      translationKey: `proxyTypes.${GeoProxyType.DataCenter}`,
      icon: <IconProxyDataCenterSmall {...itemRowIconProps} />,
      hint: { translationKey: GEO_PROXY_TYPE_HINTS[GeoProxyType.DataCenter] },
    },
  ];

  const openPriceList = (type: GeoProxyType, currentTarget: HTMLDivElement): void => {
    setSelectedType(type);
    setAnchorElPriceList(currentTarget);
    sendActionAnalytics(E_ANALYTICS_ACTIONS.visitedBuyProxyDataModalViaLocationData);
  };

  const renderMenuItemRightControls = (geoProxyType: GeoProxyType): JSX.Element => {
    const stringifiedRemainingTrafficAmount = stringifyRemainingTrafficAmount(trafficData, geoProxyType, true);

    return (
      <>
        <VolumeProxyContainer>
          <Trans i18nKey="proxyManager.trafficRemainingLimit" values={{ limit: stringifiedRemainingTrafficAmount }} />
        </VolumeProxyContainer>
        <ToggleableEye state={proxyVisibility[geoProxyType] ? 'visible' : 'hidden'} toggle={() => handleToggleVisibility(geoProxyType)} />
      </>
    );
  };

  const renderMenuItem = (menuItem: GeoProxyTypeItem): JSX.Element => {
    const { name, translationKey, icon } = menuItem;

    const handleMenuItemClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
      event.stopPropagation();
      handleToggleVisibility(name);
    };

    return (
      <ProxyTrafficPopoverRow
        key={name}
        style={{
          height: 32,
          padding: '8px 12px',
          paddingRight: '4px',
          marginBottom: '1px',
        }}
        onClick={handleMenuItemClick}
        onMouseOver={(): void => {
          setActiveGeoProxyHint(GEO_PROXY_TYPE_HINTS[name]);
        }}
        onMouseLeave={(): void => {
          setActiveGeoProxyHint(null);
        }}
      >
        {icon}
        <AddButtonPopoverRowContent>
          <AddButtonPopoverRowTitle>
            <Trans i18nKey={translationKey} />
          </AddButtonPopoverRowTitle>
          <AddButtonPopoverRowRightControls>{renderMenuItemRightControls(name)}</AddButtonPopoverRowRightControls>
        </AddButtonPopoverRowContent>
      </ProxyTrafficPopoverRow>
    );
  };

  const renderPopoverContent = (): JSX.Element => (
    <>
      <ProxyTrafficPopoverTitle>
        <Trans i18nKey="proxyManager.proxyGroupAddButtonPopoverTitle" />
      </ProxyTrafficPopoverTitle>
      {geoProxyTypeItems.map(renderMenuItem)}
      <ProxyTrafficPopoverProxyTypeHintWrapper onClick={(event): void => event.stopPropagation()}>
        <Trans i18nKey={activeGeoProxyHint || GEO_PROXY_TYPE_HINTS.default} />
      </ProxyTrafficPopoverProxyTypeHintWrapper>
    </>
  );

  return (
    <>
      <GologinPopover
        anchorEl={anchorEl}
        onClose={(event): void => {
          event.stopPropagation();
          onClose();
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        extraTranslate={{ left: 9, bottom: 4 }}
        width={261}
        popoverStyle={css`
          padding: 16px 4px 4px;
          overflow: visible;
          gap: 0;
        `}
        zIndex={1100}
      >
        {renderPopoverContent()}
      </GologinPopover>
      <PriceList
        anchorEl={anchorElPriceList}
        onClose={(): void => {
          setAnchorElPriceList(null);
        }}
        price={trafficData.prices}
        geoProxyType={selectedType}
        availableForPurchase={trafficData.availableForPurchase}
        bundle={trafficData.bundlePrices}
      />
    </>
  );
};

export default ProxyTrafficPopover;
