import React, { FC, useContext } from 'react';
import { useLocation } from 'react-router-dom';

import { quickCreateProfile } from './create-quick-profile.action';
import LoadableIconButton from './loadable-icon-button';
import { PROFILE_LIST_PAGE } from '../../../../common/constants/routes';
import { templatesContext } from '../../../state';
import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import { openProxySettingsModal } from '../../../state/proxy/proxy-settings-modal.atom';
import { IconPlus } from '../../../ui/gologin-header/icons';
import IconPlusInCircle from '../../../ui/icons/IconPlusInCircle';

interface IQuickCreateProfile {
  tooltipContent: JSX.Element;
}

const ButtonQuickCreateProfile: FC<IQuickCreateProfile> = (props) => {
  const { tooltipContent } = props;

  const templateCtx = useContext(templatesContext);

  const location = useLocation();
  const isProfilesPage = location.pathname === PROFILE_LIST_PAGE;

  const renderIcon = (): JSX.Element => {
    if (NEW_FEATURES.header) {
      return (
        <IconPlus
          margin='-4px 0 0 0'
          styleType='activeGrayWithBackground'
          iconColor='var(--98989F-profile-table-create-quick-profile)'
          iconHoveredColor='var(--2B2B31-profile-table-create-quick-profile)'
        />
      );
    }

    return <IconPlusInCircle />;
  };

  const handleClick = async (): Promise<void> => {
    if (isProfilesPage) {
      return quickCreateProfile(templateCtx);
    }

    openProxySettingsModal({ modalView: 'proxy-add', currentProxy: null });
  };

  return (
    <LoadableIconButton
      onClick={handleClick}
      icon={renderIcon()}
      tooltipContent={tooltipContent}
      style={{
        margin: NEW_FEATURES.header ? '-4px 5px 0 0' : '-4px 5px 0 11px',
        height: 21,
      }}
    />
  );
};

export default ButtonQuickCreateProfile;
