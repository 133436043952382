import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { makeAutoLogin } from './auto-login';
import LoadingPage from './loading-page';
import { PROFILE_LIST_PAGE } from '../../../common/constants/routes';
import { isUserAuthenticated } from '../../../common/utils';
import { useQuery } from '../../hooks';
import { AppStartTransactionSpan } from '../../interfaces/sentry/app-start.transaction';
import { history } from '../../services';
import { createAppStartTransactionSpan } from '../../utils/app-start.transaction.utils';

const RootRouteHandler: FC = () => {
  const query = useQuery();

  const { i18n } = useTranslation();

  useEffect(() => {
    makeAutoLogin({ query, i18n }).finally(() => {
      const doesTokenExist = isUserAuthenticated();
      const transactionName: AppStartTransactionSpan = doesTokenExist
        ? 'from-end-load-root-route-to-first-effect-in-private-route'
        : 'from-load-root-route-to-load-sign-up-page';

      createAppStartTransactionSpan(transactionName);
      history.replace(doesTokenExist ? PROFILE_LIST_PAGE : '/sign_up');
    });
  }, []);

  return <LoadingPage />;
};

export default RootRouteHandler;
