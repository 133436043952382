import { message } from 'antd';
import moment from 'moment';
import React from 'react';
import { Trans } from 'react-i18next';

import { determineIsProxyTruthy, GeoProxyType } from '../../../../common/constants/types';
import { calculateSelectableConnectionTypes } from '../../../../common/proxy/traffic/utils';
import { getVpnUfoProxy } from '../../../features/profileSettingsComponents/proxyTab/api';
import { VpnUfoProxyOptions } from '../../../features/profileSettingsComponents/proxyTab/vpn-ufo.types';
import {
  GEOPROXY_CHECK_FAILED_WHEN_CREATING_ERROR,
  GEOPROXY_NOT_FOUND_FOR_COUNTRY_AND_TYPE_ERROR,
  GEOPROXY_TYPE_TAG,
  PROXY_CHECK_ERROR_TAG,
  PROXY_COUNTRY_TAG,
  PROXY_GROUP_ID_TAG,
  PROXY_ID_TAG,
} from '../../../features/proxy/constants';
import { generateGeoProxyCustomName } from '../../../features/proxy/proxy-helpers';
import { generateArtificialGeoProxyId, getNoIdProxyId } from '../../../features/proxy/utils/proxy-id';
import { IGeolocationProxyFullData, IProxy } from '../../../interfaces';
import { GeoProxyWithTraffic } from '../../../interfaces/geoproxy-with-traffic.type';
import { sendReactErrorToSentry } from '../../../utils/sentry.helper';
import { updateDefaultGeoProxy } from '../default-geoproxy.atom';
import { showProxyCheckTooltip } from '../proxy-check/proxy-check-tooltip.atom';
import { addProxyStatuses, removeProxyStatuses, updateProxyStatuses } from '../proxy-check/proxy-statuses.atom';
import { ProxyCheckTooltipLocation } from '../proxy-check/types/proxy-check-tooltip-location.type';
import { getProxyList, pushManyProxies, updateProxyInListById } from '../proxy-list.atom';
import { getIsProxyManagerVisible, IProxyManagerState } from '../proxy-manager-modal-status.atom';
import { decrementProfileLinkedProxyProfilesCounter, linkProfileProxyInState } from './link-proxy.operations';

export type GeoProxyWithCredentialsParams = Pick<VpnUfoProxyOptions, 'customName' | 'profileIdToLink' | 'city'> & {
  country: string;
  connectionType: GeoProxyType;
  profileId: string;
};

export const fetchGeoProxyWithCredentials = async ({
  country,
  city,
  profileId,
  connectionType,
  customName,
  profileIdToLink,
}: GeoProxyWithCredentialsParams): Promise<GeoProxyWithTraffic> => {
  const parameters: VpnUfoProxyOptions = {
    countryCode: country,
    city,
    browserId: profileId,
    isMobile: connectionType === GeoProxyType.Mobile,
    isDC: connectionType === GeoProxyType.DataCenter,
    customName,
  };

  if (profileIdToLink) {
    parameters.profileIdToLink = profileIdToLink;
  }

  return getVpnUfoProxy(parameters);
};

export type GeoProxySubmitParams = Pick<VpnUfoProxyOptions, 'city'> & {
  groupId: string;
  profileId: string;
  country: string;
  selectedConnectionType: GeoProxyType;
  availableConnectionTypes: GeoProxyType[];
  trafficData: IGeolocationProxyFullData;
  checkTooltipView: ProxyCheckTooltipLocation;
  handleProxySelect?: IProxyManagerState['handleProxySelect'];
  isRetry?: boolean;
};

export type ArtificialGeoProxyParams = {
  country: string;
  connectionType: GeoProxyType;
  profilesCount: number;
  groupId: string;
};

const makeArtificialGeoProxy = ({ country, connectionType, profilesCount, groupId }: ArtificialGeoProxyParams): IProxy => {
  const proxyId = generateArtificialGeoProxyId();
  const customName = generateGeoProxyCustomName(country);

  const result: IProxy = {
    id: proxyId,
    groupId,
    host: '',
    port: 80,
    connectionType,
    mode: 'geolocation',
    customName,
    country,
    profilesCount,
    createdAt: new Date(),
    selectionDate: profilesCount ? Date.now() : 0,
  };

  const isProxyManagerVisible = getIsProxyManagerVisible();
  if (isProxyManagerVisible) {
    result.customSortingInGroup = 'end';
  }

  return result;
};

export const handleGeoProxyWithCredentialsError = (proxy: IProxy, profileId: string): void => {
  removeProxyStatuses([proxy], [profileId]);
  updateProxyInListById(proxy.id, {
    status: false,
    checkDate: new Date(),
  });
};

export const checkGeoProxy = async (proxy: IProxy, profileId: string, checkTooltipView: ProxyCheckTooltipLocation): Promise<IProxy | string> => {
  const checkedProxy = await updateProxyStatuses({
    proxies: [proxy],
    profileId,
    isEditable: true,
    shouldUpdateProxyInList: false,
  }).catch(() => ({ status: false, error: '' }));

  const checkedFullProxy: IProxy = { ...proxy, ...checkedProxy, checkDate: moment().toDate() };
  if (!(checkedProxy && checkedProxy.status)) {
    sendReactErrorToSentry({
      transactionName: GEOPROXY_CHECK_FAILED_WHEN_CREATING_ERROR,
      message: GEOPROXY_CHECK_FAILED_WHEN_CREATING_ERROR.replaceAll('-', ' '),
      tags: [
        [PROXY_ID_TAG, proxy.id],
        [GEOPROXY_TYPE_TAG, proxy.connectionType],
        [PROXY_COUNTRY_TAG, proxy.country],
        [PROXY_CHECK_ERROR_TAG, checkedProxy?.error || ''],
      ],
    });

    return 'tableProfiles.notification.proxyCheckFailed';
  }

  showProxyCheckTooltip({
    profileIds: [profileId || ''],
    proxies: [checkedFullProxy],
    view: checkTooltipView,
    timeout: 2000,
  });

  return checkedFullProxy;
};

type SelectableGeoProxyConnectionTypeEvaluateParams = Pick<
  GeoProxySubmitParams,
  'country' | 'groupId' | 'selectedConnectionType' | 'availableConnectionTypes' | 'trafficData'
>;

export const evaluateSelectableGeoProxyConnectionType = ({
  country,
  groupId,
  selectedConnectionType,
  availableConnectionTypes,
  trafficData,
}: SelectableGeoProxyConnectionTypeEvaluateParams): GeoProxyType | null => {
  let selectableConnectionType = selectedConnectionType;
  if (!availableConnectionTypes.includes(selectedConnectionType)) {
    return selectableConnectionType;
  }

  const selectableConnectionTypes = calculateSelectableConnectionTypes(trafficData);
  if (!selectableConnectionTypes.length) {
    message.error(<Trans i18nKey="tableProfiles.notification.proxyNotFoundForCountryAndType" />);
    sendReactErrorToSentry({
      transactionName: GEOPROXY_NOT_FOUND_FOR_COUNTRY_AND_TYPE_ERROR,
      message: GEOPROXY_CHECK_FAILED_WHEN_CREATING_ERROR.replaceAll('-', ' '),
      tags: [
        [PROXY_GROUP_ID_TAG, groupId],
        [GEOPROXY_TYPE_TAG, selectedConnectionType],
        [PROXY_COUNTRY_TAG, country],
      ],
    });

    return null;
  }

  if (selectableConnectionTypes.includes(selectedConnectionType)) {
    selectableConnectionType = selectedConnectionType;
  } else {
    [selectableConnectionType] = selectableConnectionTypes;
  }
  
  updateDefaultGeoProxy({ connectionType: selectableConnectionType });

  return selectableConnectionType;
};

type TranslationKey = string;

export const createGeoProxy = async (opts: GeoProxySubmitParams): Promise<IProxy | TranslationKey | null> => {
  const {
    groupId,
    profileId,
    country,
    selectedConnectionType,
    availableConnectionTypes,
    trafficData,
    checkTooltipView,
    handleProxySelect = null,
    isRetry = false,
  } = opts;

  const selectableConnectionType = evaluateSelectableGeoProxyConnectionType({
    country,
    groupId,
    selectedConnectionType,
    availableConnectionTypes,
    trafficData,
  });

  if (!selectableConnectionType) {
    return null;
  }

  const profilesCount = profileId && !handleProxySelect ? 1 : 0;
  const artificialGeoProxy = makeArtificialGeoProxy({
    country,
    connectionType: selectableConnectionType,
    groupId,
    profilesCount,
  });

  const geoProxyWithCredentialsParams: GeoProxyWithCredentialsParams = {
    country,
    connectionType: selectableConnectionType,
    profileId,
    customName: artificialGeoProxy.customName,
  };

  addProxyStatuses([artificialGeoProxy], [profileId]);
  pushManyProxies([artificialGeoProxy]);
  if (handleProxySelect) {
    let proxyId = artificialGeoProxy.id;
    if (!proxyId && determineIsProxyTruthy(artificialGeoProxy)) {
      proxyId = getNoIdProxyId(artificialGeoProxy);
    }

    handleProxySelect(proxyId);
  } else if (profileId) {
    decrementProfileLinkedProxyProfilesCounter(profileId);
    linkProfileProxyInState({
      profileId,
      proxy: artificialGeoProxy,
      shouldUpdateProxyInList: false,
    });

    geoProxyWithCredentialsParams.profileIdToLink = profileId;
  }

  const geoProxyWithCredentialsResponse = await fetchGeoProxyWithCredentials(geoProxyWithCredentialsParams).catch(error => {
    handleGeoProxyWithCredentialsError(artificialGeoProxy, profileId);
    let errorMessage = 'tableProfiles.notification.trafficLimit';
    if (typeof error?.body?.message === 'string') {
      errorMessage = error.body.message;
    }

    return errorMessage;
  });

  if (typeof geoProxyWithCredentialsResponse === 'string') {
    return geoProxyWithCredentialsResponse;
  }

  const proxyWithCredentials = geoProxyWithCredentialsResponse;
  const fullProxy: IProxy = {
    ...artificialGeoProxy,
    ...proxyWithCredentials,
    mode: 'geolocation',
  };

  updateProxyInListById(artificialGeoProxy.id, fullProxy);
  if (handleProxySelect) {
    let proxyId = fullProxy.id;
    if (!proxyId && determineIsProxyTruthy(fullProxy)) {
      proxyId = getNoIdProxyId(fullProxy);
    }

    handleProxySelect(proxyId);
  } else if (profileId) {
    linkProfileProxyInState({
      profileId,
      proxy: fullProxy,
      shouldUpdateProxyInList: false,
    });
  }

  const checkResult = await checkGeoProxy(fullProxy, profileId, checkTooltipView);
  if (typeof checkResult === 'string') {
    let shouldRetry = !isRetry;
    if (shouldRetry) {
      const proxyList = getProxyList();
      const hadSuccessfulProxy = !!proxyList.find(
        proxy => proxy.mode === 'geolocation' && proxy.connectionType === selectableConnectionType && proxy.country === country && proxy.status,
      );

      shouldRetry = !!hadSuccessfulProxy;
    }

    if (!shouldRetry) {
      return checkResult;
    }

    return createGeoProxy({ ...opts, isRetry: true });
  }

  updateProxyInListById(fullProxy.id, checkResult);
  removeProxyStatuses([artificialGeoProxy], [profileId]);

  return { ...proxyWithCredentials, ...checkResult, profilesCount };
};
