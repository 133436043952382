import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { NEW_FEATURES } from './feature-toggle/new-features';
import {  resetProfilesList } from './profiles-list.atom';
import { ISearchHistoryParams, addSearchToHistory } from './search-history';
import { getSearchQuery, resetToDefaultSearchQuery, updateSearchQuery } from './search-query.atom';
import { PROXY_PAGE_ANALYTICS_ACTION_INFO_DEFAULT, ProxyPageAnalyticsEvent } from '../../common/constants/analytics';
import { sendActionAnalytics } from '../features/common/api';

export interface ISearchState {
  searchInputText: string;
  isSearchOpen: boolean;
}

const DEFAULT_SEARCH_STATE: ISearchState = {
  searchInputText: '',
  isSearchOpen: false,
};

const searchStateAtom = atom<ISearchState>(DEFAULT_SEARCH_STATE);

export const useSearchState = (): ISearchState => useAtomValue(searchStateAtom);

const updateSearchState = (newSearchState: Partial<ISearchState>): void => {
  const oldSearchState = getDefaultStore().get(searchStateAtom);
  const updatedSearchState = { ...oldSearchState, ...newSearchState };
  getDefaultStore().set(searchStateAtom, updatedSearchState);
};

export const updateSearchInputText = (newSearchInputText: string): void => updateSearchState({ searchInputText: newSearchInputText });

export const closeSearch = (): void => updateSearchState({ isSearchOpen: false });

export const openSearch = (): void => updateSearchState({ isSearchOpen: true });

export const commitSearch = (newSearch: string): void => {
  if (!NEW_FEATURES.objectPool) {
    const prevSearchQuery = getSearchQuery();
    if ((prevSearchQuery ?? '') !== (newSearch ?? '')) {
      resetProfilesList();
    }
  }

  if (newSearch) {
    openSearch();
  }

  updateSearchInputText(newSearch);
  updateSearchQuery(newSearch);
};

export const doSearch = ({ newSearch, userId, type, workspaceId }: ISearchHistoryParams): void => {
  if (type === 'proxies') {
    sendActionAnalytics(ProxyPageAnalyticsEvent.searchedProxy, { actionInfo: PROXY_PAGE_ANALYTICS_ACTION_INFO_DEFAULT });
  }

  commitSearch(newSearch);
  addSearchToHistory({ newSearch, userId, type, workspaceId });
};

export const resetToDefaultSearchState = (): void => {
  updateSearchState(DEFAULT_SEARCH_STATE);
  resetToDefaultSearchQuery();
};

export const clearSearch = (): void => {
  commitSearch('');
};
