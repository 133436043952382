import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconTrialTrafficGift: React.FC<IIconWrapper> = (props) => (
  <IconWrapper {...props}>
    <svg width="57" height="46" viewBox="0 0 57 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M46.9365 34.3359L54.4365 37.8359" stroke="#00A987" />
      <path d="M48.5479 27.1016L56.8243 27.0778" stroke="#00A987" />
      <path d="M46.3135 20.2344L53.8357 16.7824" stroke="#00A987" />
      <path d="M42.7812 13.2656L48.6143 7.39404" stroke="#00A987" />
      <path d="M36.208 9.25L39.499 1.65596" stroke="#00A987" />
      <path d="M28.8691 8.27344L28.7106 -0.00151585" stroke="#00A987" />
      <path d="M9.8877 34.3359L2.3877 37.8359" stroke="#00A987" />
      <path d="M8.27637 27.1016L-7.16448e-05 27.0778" stroke="#00A987" />
      <path d="M10.5107 20.2344L2.98854 16.7824" stroke="#00A987" />
      <path d="M14.043 13.2656L8.20992 7.39404" stroke="#00A987" />
      <path d="M20.6162 9.25L17.3252 1.65596" stroke="#00A987" />
      <g clipPath="url(#clip0_27135_33765)">
        <path
          d="M40.0615 30.8047V40.4922C40.0615 41.263 39.7553 42.0022 39.2103 42.5472C38.6653 43.0922 37.9261 43.3984 37.1553 43.3984H19.7178C18.947 43.3984 18.2078 43.0922 17.6627 42.5472C17.1177 42.0022 16.8115 41.263 16.8115 40.4922V30.8047"
          stroke="#00A987"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path d="M42.9678 22.0859H13.9053V26.9297H42.9678V22.0859Z" stroke="#00A987" strokeMiterlimit="10" strokeLinecap="square" />
        <path
          d="M19.7178 19.1797C19.7178 18.4089 20.024 17.6697 20.569 17.1247C21.114 16.5796 21.8532 16.2734 22.624 16.2734C26.9233 16.2734 28.4365 22.0859 28.4365 22.0859H22.624C21.8532 22.0859 21.114 21.7797 20.569 21.2347C20.024 20.6897 19.7178 19.9505 19.7178 19.1797Z"
          stroke="#00A987"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M37.1553 19.1797C37.1553 18.4089 36.8491 17.6697 36.3041 17.1247C35.759 16.5796 35.0198 16.2734 34.249 16.2734C29.9497 16.2734 28.4365 22.0859 28.4365 22.0859H34.249C35.0198 22.0859 35.759 21.7797 36.3041 21.2347C36.8491 20.6897 37.1553 19.9505 37.1553 19.1797Z"
          stroke="#00A987"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path d="M31.3428 22.0859H25.5303V43.3984H31.3428V22.0859Z" stroke="#00A987" strokeMiterlimit="10" strokeLinecap="square" />
      </g>
      <defs>
        <clipPath id="clip0_27135_33765">
          <rect width="31" height="31" fill="white" transform="translate(12.9365 14.3359)" />
        </clipPath>
      </defs>
    </svg>
  </IconWrapper>
);
