import { css } from '@emotion/core';
import styled from '@emotion/styled/macro';

import { getDefaultColumnsSettings } from '../../../state/columns-settings.atom';

export const ProfileStatusRow = styled('div')`
  display: flex;
  height: 20px;

  align-items: center;
  display: flex;
`;

export const StatusWidthLimitterEl = styled('span')<{ width?: number; newStyle: boolean }>`
  padding: 0;
  height: 100%;
  overflow: hidden;
  position: relative;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${props => css`width: ${props.width ? props.width - 16 : getDefaultColumnsSettings().status - 16}px;`};
  z-index: ${(props): string => props.newStyle ? '100' : ''};

  :hover {
    padding-right: 16px;
    z-index: ${(props): string => props.newStyle ? '104' : '59'};
    overflow: visible;
    width: 180px;
    position: relative;
    background-color: var(--F9F9F9-profile-table-status);
`;

export const ErrorTooltip = styled('div')`
  display: flex;
`;

export const TooltipTextError = styled('div')`
  margin-left: 3px;
`;

export const ResyncButton = styled('div')`
  margin-left: 16px;
  margin-right: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  :hover path {
    stroke: var(--01A886-profile-table-status);
  }
`;
