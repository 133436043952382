import styled from '@emotion/styled';
import { Button, Icon, Table } from 'antd';
import { format } from 'date-fns';
import React, { memo, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { cloneS3Profile, requestProfileHistory } from './api';
import {
  closeProfileTableModal,
  PROFILE_HISTORY_MODAL_NAME,
  useProfilesTableModalProfiles,
  useProfileTableModalIsVisible,
} from '../../../../state/profiles-table-modal.atom';
import GologinModal from '../../../../ui/gologin-modal';

let ipcRenderer: Electron.IpcRenderer;
const isElectron = !!window.require;

if (isElectron) {
  ipcRenderer = window.require('electron').ipcRenderer;
}

const ContainerLoader = styled('div')`
  font-size: 32px;
  text-align: center;
`;

const DescriptionModalTxt = styled('div')`
  padding: 4px;
  margin-bottom: 10px;
  font-size: 14px;
  color: var(--000000A6-antd);
`;

const HistoryModal = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true);
  const [profileUploads, setProfileUploads] = useState<any[]>([]);

  const isModalVisible = useProfileTableModalIsVisible(PROFILE_HISTORY_MODAL_NAME);
  const [modalProfile = { id: '' }] = useProfilesTableModalProfiles();

  const { t: translation } = useTranslation();

  const profileId = modalProfile.id;

  const updateProfileHistory = async (): Promise<void> => {
    setIsLoading(true);
    const profileHistory = await requestProfileHistory(profileId);

    setProfileUploads(profileHistory);
    setIsLoading(false);
  };

  const cloneProfile = async (key: string) => {
    const clonedProfile = await cloneS3Profile(profileId, key);
    if (clonedProfile.id) {
      ipcRenderer && ipcRenderer.invoke('clear-profile-cache', profileId);
    }

    setProfileUploads([clonedProfile, ...profileUploads]);
  };

  useEffect(() => {
    if (profileId) {
      updateProfileHistory();
    }
  }, [profileId]);

  return (
    <GologinModal
      isVisible={isModalVisible}
      onClose={closeProfileTableModal}
      width='520px'
      title={translation('historyModal.title')}
    >
      {isLoading ? (
        <ContainerLoader>
          <Icon type='loading'/>
        </ContainerLoader>
      ) : (
        <>
          <DescriptionModalTxt>
            <Trans i18nKey='historyModal.help' t={translation}>
              Continue work from any of the listed sessions. To do that, <b>restore</b> the session needed and run the profile. The <b>current</b> session will be erased.
            </Trans>
          </DescriptionModalTxt>

          <Table dataSource={profileUploads} pagination={false} rowKey={record => record.id} locale={{ emptyText: 'No Data' }}>
            <Table.Column
              title={translation('historyModal.table.columns.date')}
              dataIndex='lastModified'
              render={(v: string) => format(new Date(v), 'MMM dd, h:mm a')}
            />
            <Table.Column
              title={translation('historyModal.table.columns.size')}
              dataIndex='size'
              render={(v: number) => `${(v / (1024 ** 2)).toFixed(2)}MB`}
            />
            <Table.Column
              dataIndex='id'
              render={(v: string, record: any, index: number) => index === 0 ? translation('historyModal.currentSessionLabel') : <Button onClick={() => cloneProfile(v)}>{translation('historyModal.restoreBtn.label')}</Button>}
            />
          </Table>
        </>
      )}
    </GologinModal>
  )
}

export default memo(HistoryModal);
