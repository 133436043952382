import { getDefaultStore, useAtomValue } from 'jotai';

import { atomWithPersistence } from '../local-storage.atom';

type ProxyTypeVisibility = {
  resident: boolean;
  mobile: boolean;
  dataCenter: boolean;
};

export const trialTrafficModalHintClosedAtom = atomWithPersistence<ProxyTypeVisibility>('TrafficModalTrialHintClosed', false);
export const proxyTypeVisibilityAtom = atomWithPersistence('proxyTypeVisibility', { resident: true, mobile: false, dataCenter: false });

export const useProxyTypeVisibility = () => useAtomValue(proxyTypeVisibilityAtom);
export const useIsTrafficModalTrialHintClosed = () => useAtomValue(trialTrafficModalHintClosedAtom);

export const getTrafficModalTrialHintClosed = () => getDefaultStore().get(trialTrafficModalHintClosedAtom);
export const getProxyTypeVisibility = () => getDefaultStore().get(proxyTypeVisibilityAtom);
export const setProxyTypeVisibility = (newVisibility: { resident: boolean; mobile: boolean; dataCenter: boolean }) => {
  getDefaultStore().set(proxyTypeVisibilityAtom, newVisibility);
};
export const setTrafficModalTrialHintClosed = (newStatus: boolean) => getDefaultStore().set(trialTrafficModalHintClosedAtom, newStatus);

export const updateProxyTypeVisibility = (partialVisibility: Partial<{ resident: boolean; mobile: boolean; dataCenter: boolean }>): void => {
  const currentVisibility = getProxyTypeVisibility();
  setProxyTypeVisibility({ ...currentVisibility, ...partialVisibility });
};
