import styled from '@emotion/styled';

export const GeoProxyCustomNameInputElement = styled.input`
  padding: 0;
  outline: none;
  border: none;
  max-width: 100%;
  width: 100%;
  background: none;

  color: var(--222222);
  font-size: 14px;
  letter-spacing: 0.25px;
`;
