import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { GeoProxyFormProxy } from './geoproxy-form-data.atom';
import { GEOPROXY_COUNTRY_KEY_NAME, GEOPROXY_TYPE_ADDED_LAST_KEY_NAME } from '../../../common/constants/local-storage';
import { determineIsGeoProxyType, GeoProxyType } from '../../../common/constants/types';
import { DEFAULT_SELECTED_GEOPROXY_COUNTRY, DEFAULT_SELECTED_GEOPROXY_TYPE } from '../../features/proxy/constants';
import {
  determineIsCityAvailableInCountry,
  evaluateGeoProxyCustomNameCounter,
  readStoredGeoProxyCity,
  removeGeoProxyCityFromStore,
  storeGeoProxyCity,
  storeGeoProxyCustomNameCounter,
} from '../../features/proxy/utils/default-proxy-data';
import { updateRecentSelectedCountriesAtom } from './recent-selected-proxies.atom';

type DefaultGeoProxy = Pick<GeoProxyFormProxy, 'country' | 'city' | 'connectionType'>;

type CountryCode = string;
type GeoProxyCustomNameCounters = Record<CountryCode, number>;

const DEFAULT_GEOPROXY_FIELD_STORAGE_KEYS: Record<string, string> = {
  country: GEOPROXY_COUNTRY_KEY_NAME,
  connectionType: GEOPROXY_TYPE_ADDED_LAST_KEY_NAME,
};

const initDefaultGeoProxyType = (): GeoProxyType => {
  let geoProxyLastSelectedType: GeoProxyType | null = null;
  const geoProxyLastSelectedTypeInStorage = localStorage.getItem(GEOPROXY_TYPE_ADDED_LAST_KEY_NAME);
  if (geoProxyLastSelectedTypeInStorage && determineIsGeoProxyType(geoProxyLastSelectedTypeInStorage)) {
    geoProxyLastSelectedType = geoProxyLastSelectedTypeInStorage;
  }

  return geoProxyLastSelectedType ?? DEFAULT_SELECTED_GEOPROXY_TYPE;
};

const userSelectedGeoProxyAtom = atom<Partial<DefaultGeoProxy>>({});

export const geoProxyCustomNameCountersAtom = atom<GeoProxyCustomNameCounters>({});

export const defaultGeoProxyAtom = atom((get) => {
  const userSelectedGeoProxy = get(userSelectedGeoProxyAtom);

  const defaultGeoProxyCountry = localStorage.getItem(GEOPROXY_COUNTRY_KEY_NAME) ?? DEFAULT_SELECTED_GEOPROXY_COUNTRY;
  const country = userSelectedGeoProxy.country || defaultGeoProxyCountry;

  const defaultGeoProxyCity = readStoredGeoProxyCity(country);
  const city = userSelectedGeoProxy.city || defaultGeoProxyCity;

  const defaultGeoProxyType = initDefaultGeoProxyType();

  return {
    country,
    city,
    connectionType: userSelectedGeoProxy.connectionType || defaultGeoProxyType,
  };
});

const setUserSelectedGeoProxy = (partialDefaultGeoProxy: Partial<DefaultGeoProxy>): void => {
  getDefaultStore().set(userSelectedGeoProxyAtom, partialDefaultGeoProxy);
  storeDefaultGeoProxy(partialDefaultGeoProxy);
};

const getDefaultGeoProxy = (): DefaultGeoProxy => getDefaultStore().get(defaultGeoProxyAtom);
const getGeoProxyCustomNameCounters = (): GeoProxyCustomNameCounters => getDefaultStore().get(geoProxyCustomNameCountersAtom);
const setGeoProxyCustomNameCounters = (newCounters: GeoProxyCustomNameCounters): void =>
  getDefaultStore().set(geoProxyCustomNameCountersAtom, newCounters);

const storeDefaultGeoProxy = (partialDefaultGeoProxy: Partial<DefaultGeoProxy>): void => {
  const defaultGeoProxy = getDefaultGeoProxy();
  const updatedGeoProxy: DefaultGeoProxy = { ...defaultGeoProxy, ...partialDefaultGeoProxy };
  const geoProxyFieldEntries = Object.entries(updatedGeoProxy);
  geoProxyFieldEntries.forEach(([fieldKey, fieldValue]) => {
    const storageKey = DEFAULT_GEOPROXY_FIELD_STORAGE_KEYS[fieldKey];
    if (!fieldValue) {
      return;
    }

    if (fieldKey !== 'city') {
      if (storageKey) {
        localStorage.setItem(storageKey, fieldValue);
      }

      return;
    }

    const { country: defaultCountry } = updatedGeoProxy;
    const newDefaultCity = fieldValue;
    const isCityAvailableInCountry = determineIsCityAvailableInCountry(defaultCountry, newDefaultCity);
    if (isCityAvailableInCountry) {
      storeGeoProxyCity(defaultCountry, fieldValue);
    }

    if (fieldValue === 'The fastest server') {
      storeGeoProxyCity(defaultCountry, 'auto');
    }
  });
};

export const useDefaultGeoProxy = (): DefaultGeoProxy => useAtomValue(defaultGeoProxyAtom);

export const updateDefaultGeoProxy = (partialDefaultGeoProxy: Partial<DefaultGeoProxy>): void => {
  setUserSelectedGeoProxy(partialDefaultGeoProxy);
  
  if (partialDefaultGeoProxy.country) {
    updateRecentSelectedCountriesAtom({
      countryCode: partialDefaultGeoProxy.country,
    });
  }
};

export const updateGeoProxyCustomNameCounters = (countryCode: CountryCode): number => {
  const { counter, storageKey } = evaluateGeoProxyCustomNameCounter(countryCode);
  storeGeoProxyCustomNameCounter({ counter, storageKey });

  const currentCounters = getGeoProxyCustomNameCounters();
  setGeoProxyCustomNameCounters({
    ...currentCounters,
    [countryCode.toUpperCase()]: counter,
  });

  return counter;
};
