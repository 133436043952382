import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { getAllProfilesFolderId } from './folders/all-profiles-folder-id.atom';

export const selectedFolderIdAtom = atom<string|null>(null);

export const getSelectedFolderId = (): string|null => getDefaultStore().get(selectedFolderIdAtom);
export const useSelectedFolderId = (): string|null => useAtomValue(selectedFolderIdAtom);
export const setSelectedFolderId = (newSelectedFolderId: string|null): void => getDefaultStore().set(selectedFolderIdAtom, newSelectedFolderId);

export const getHasSelectedFolder = (): boolean => {
  const selectedFolderId = getSelectedFolderId();
  const allProfilesFolderId = getAllProfilesFolderId();
  let hasSelectedFolder = !!selectedFolderId;
  if (hasSelectedFolder && allProfilesFolderId) {
    hasSelectedFolder = selectedFolderId !== allProfilesFolderId;
  }

  return hasSelectedFolder;
};

export const resetSelectedFolderId = (): void => {
  const allProfilesFolderId = getAllProfilesFolderId();
  if (allProfilesFolderId) {
    setSelectedFolderId(allProfilesFolderId);
  } else {
    setSelectedFolderId(null);
  }
};
