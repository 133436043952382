import styled from '@emotion/styled/macro';

import { textFont } from '../../../../../../ui/style-templates';
import { ADD_BUTTON_ARROW_ACTIVE_COLOR } from '../../../proxy-list/proxy-group-add-button/proxy-group-add-button-menu/styles';

export const GeoProxyTypeSelectTitle = styled.span`
  ${textFont()}
`;

export const GeoProxyTypeSelectArrowIconWrapper = styled.div``;

export const GeoProxyTypeSelectWrapper = styled.div`
  display: flex;
  align-items: center;

  cursor: pointer;

  ${GeoProxyTypeSelectArrowIconWrapper} {
    margin-left: 4px;
  }

  :hover {
    ${GeoProxyTypeSelectArrowIconWrapper} {
      svg path {
        stroke: ${ADD_BUTTON_ARROW_ACTIVE_COLOR};
      }
    }
  }
`;
