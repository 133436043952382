import React, { useRef, useState } from 'react';

import { updateGeoProxyForm, useGeoProxyForm } from '../../../../../../../state/proxy/geoproxy-form-data.atom';
import { GeoProxyCustomNameInputElement } from './styles';
import { NEW_FEATURES } from '../../../../../../../state/feature-toggle/new-features';

const GeoProxyCustomNameInput: React.FC = () => {
  const [isEditing, setIsEditing] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const geoProxyForm = useGeoProxyForm();

  const handleChangeCustomName: React.ChangeEventHandler<HTMLInputElement> = event => {
    event.preventDefault();
    const rawCustomName = event?.target?.value || '';
    updateGeoProxyForm({ customName: rawCustomName });
  };

  const handleBlur = () => {
    setIsEditing(false);
  };

  const handleClick = () => {
    setIsEditing(true);
  };

  const getDisplayValue = () => {
    if (isEditing || !geoProxyForm.customName) return geoProxyForm.customName;

    const parts = geoProxyForm.customName.split('-');
    if (parts.length < 3) return geoProxyForm.customName;

    const [part1, part2, part3] = parts;
    const inputWidth = inputRef.current?.offsetWidth || 200;

    let adjustedPart2 = part2.trim();
    const maxWidth = inputWidth - measureTextWidth(`${part1} - - -  ${part3}`, '14px Roboto');

    while (measureTextWidth(adjustedPart2 + '...', '14px Roboto') > maxWidth && adjustedPart2.length > 0) {
      adjustedPart2 = adjustedPart2.slice(0, -1);
    }

    return `${part1}- ${adjustedPart2.length < part2.trim().length ? adjustedPart2 + '...' : adjustedPart2} -${part3}`;
  };

  return (
    <GeoProxyCustomNameInputElement
      ref={inputRef}
      value={!!NEW_FEATURES.proxyEditFormCities ? getDisplayValue() : geoProxyForm.customName}
      onChange={handleChangeCustomName}
      onClick={handleClick}
      onBlur={handleBlur}
    />
  );
};

const measureTextWidth = (text: string, font: string = '14px Roboto') => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  if (context) {
    context.font = font;
    return context.measureText(text).width;
  }
  return 0;
};

export default GeoProxyCustomNameInput;
