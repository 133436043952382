import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { IProxy, MouseEnteredState } from '../../../interfaces';
import { PROXY_CHECK_TOOLTIP_LOCATIONS } from './constants';
import { ProxyCheckTooltipLocation } from './types/proxy-check-tooltip-location.type';
import { ProxyCheckTooltipParams } from './types/proxy-check-tooltip-params.type';

const proxyCheckTooltipProfileIdsAtom = atom<string[]>([]);
const proxyCheckTooltipProxiesAtom = atom<IProxy[]>([]);
const proxyCheckTooltipViewAtom = atom<ProxyCheckTooltipLocation | null>(null);
const proxyCheckTooltipTimeoutIdsAtom = atom<Record<string, NodeJS.Timeout>>({});
export const isProxyCheckTooltipMouseEnteredAtom = atom<MouseEnteredState>({});

const getProxyCheckTooltipProfileIds = (): string[] => getDefaultStore().get(proxyCheckTooltipProfileIdsAtom);
const getProxyCheckTooltipProxies = (): IProxy[] => getDefaultStore().get(proxyCheckTooltipProxiesAtom);

const setProxyCheckTooltipProfileIds = (profileIds: string[]): void => getDefaultStore().set(proxyCheckTooltipProfileIdsAtom, profileIds);
const setProxyCheckTooltipProxies = (proxies: IProxy[]): void => getDefaultStore().set(proxyCheckTooltipProxiesAtom, proxies);
const setProxyCheckTooltipView = (view: ProxyCheckTooltipLocation): void => getDefaultStore().set(proxyCheckTooltipViewAtom, view);
export const setIsProxyCheckTooltipMouseEnteredAtom = (profileId: string, value: boolean) => {
  const currentState = getDefaultStore().get(isProxyCheckTooltipMouseEnteredAtom);
  getDefaultStore().set(isProxyCheckTooltipMouseEnteredAtom, { ...currentState, [profileId]: value });
};

const useProxyCheckTooltipProfileIds = (): string[] => useAtomValue(proxyCheckTooltipProfileIdsAtom);
export const useProxyCheckTooltipProxies = (): IProxy[] => useAtomValue(proxyCheckTooltipProxiesAtom);
const useProxyCheckTooltipView = (): ProxyCheckTooltipLocation => useAtomValue(proxyCheckTooltipViewAtom);
export const useIsProxyCheckTooltipMouseEnteredAtom = () => useAtomValue(isProxyCheckTooltipMouseEnteredAtom);

export const useIsProxyCheckTooltipVisible = (proxy: IProxy, profileId = '', view: ProxyCheckTooltipLocation): boolean => {
  const tooltipCurrentProxies = useProxyCheckTooltipProxies();
  const tooltipCurrentView = useProxyCheckTooltipView();
  const tooltipCurrentProfilesIds = useProxyCheckTooltipProfileIds();

  const isProxyInProxySelector = tooltipCurrentView === PROXY_CHECK_TOOLTIP_LOCATIONS.selectorProfileTable && !!profileId;

  return (
    !!view &&
    tooltipCurrentView === view &&
    (isProxyInProxySelector ? tooltipCurrentProfilesIds.includes(profileId) : true) &&
    tooltipCurrentProxies.some((tooltipCurrentProxy) => tooltipCurrentProxy.id === proxy.id)
  );
};

export const showProxyCheckTooltip = ({ profileIds, proxies, view, timeout }: ProxyCheckTooltipParams): void => {
  const tooltipProfileIds = getProxyCheckTooltipProfileIds();
  const tooltipProxies = getProxyCheckTooltipProxies();

  const newTooltipProfileIds = [...tooltipProfileIds, ...profileIds];
  const newTooltipProxies = [...tooltipProxies, ...proxies];

  const timeoutIds = getDefaultStore().get(proxyCheckTooltipTimeoutIdsAtom);

  setProxyCheckTooltipProfileIds(newTooltipProfileIds);
  setProxyCheckTooltipProxies(newTooltipProxies);
  setProxyCheckTooltipView(view);

  if (timeout) {
    if (view === PROXY_CHECK_TOOLTIP_LOCATIONS.proxyPageTable) {
      const timeoutId = setTimeout(() => {
        hideProxyCheckTooltip(proxies, []);
      }, timeout);

      getDefaultStore().set(proxyCheckTooltipTimeoutIdsAtom, { ...timeoutIds, proxyPage: timeoutId });
    } else {
      profileIds.forEach((profileId) => {
        const timeoutId = setTimeout(() => {
          const isMouseEntered = getDefaultStore().get(isProxyCheckTooltipMouseEnteredAtom)[profileId];
          if (!isMouseEntered) {
            hideProxyCheckTooltip(proxies, [profileId]);
          }
        }, timeout);

        getDefaultStore().set(proxyCheckTooltipTimeoutIdsAtom, { ...timeoutIds, [profileId]: timeoutId });
      });
    }
  }
};

export const clearTooltipTimeout = (profileId: string) => {
  const timeoutIds = getDefaultStore().get(proxyCheckTooltipTimeoutIdsAtom);
  if (timeoutIds[profileId]) {
    clearTimeout(timeoutIds[profileId]);
    const updatedTimeoutIds = { ...timeoutIds };
    delete updatedTimeoutIds[profileId];
    getDefaultStore().set(proxyCheckTooltipTimeoutIdsAtom, updatedTimeoutIds);
  }
};
export const hideProxyCheckTooltip = (proxies: IProxy[] = [], profilesIds: string[] = []): void => {
  let newTooltipProfileIds: string[] = [];
  let newTooltipProxies: IProxy[] = [];
  if (!(proxies.length || profilesIds.length)) {
    setProxyCheckTooltipProfileIds(newTooltipProfileIds);
    setProxyCheckTooltipProxies(newTooltipProxies);
    setProxyCheckTooltipView(null);

    return;
  }

  const tooltipProfileIds = getProxyCheckTooltipProfileIds();
  const tooltipProxies = getProxyCheckTooltipProxies();

  if (proxies.length) {
    newTooltipProxies = tooltipProxies.filter((proxyCurrent) => !proxies.some((proxy) => proxy.id === proxyCurrent.id));
  }

  if (profilesIds.length) {
    newTooltipProfileIds = tooltipProfileIds.filter((profileIdCurrent) => !profilesIds.includes(profileIdCurrent));
    setProxyCheckTooltipProfileIds(newTooltipProfileIds);
  }

  setProxyCheckTooltipProxies(newTooltipProxies);
  if (!newTooltipProxies.length) {
    setProxyCheckTooltipView(null);
  }
};
