import React from 'react';
import { Trans } from 'react-i18next';

import { useProxyFormCurrentTab } from '../../../../../state/proxy/proxy-form-tabs.atom';
import { closeProxyManager, openProxyManager, useProxyManagerState } from '../../../../../state/proxy/proxy-manager-modal-status.atom';
import { PROXY_EDIT_FORM_TABS_LIST, PROXY_FORM_TAB_INDEX_MAP } from '../constants';
import ProxyEditFormFooterTab from './proxy-edit-form-footer-tab';
import {
  MovingBackground,
  ProxyEditFormFooterTabsWrapper,
  ToggleableProxyEditFormFooterButton,
  ToggleableProxyEditFormFooterWrapper,
} from './styles';

type ToggleableProxyEditFormFooterProps = {
  onGeoProxyClickSubmit: () => void;
  onCustomProxyClickSubmit: () => void;
  disabledToggleButtons?: boolean;
  proxyManagerTargetRef: React.RefObject<HTMLElement>;
};

const ToggleableProxyEditFormFooter: React.FC<ToggleableProxyEditFormFooterProps> = props => {
  const { onGeoProxyClickSubmit, onCustomProxyClickSubmit, proxyManagerTargetRef, disabledToggleButtons = false } = props;

  const proxyFormCurrentTab = useProxyFormCurrentTab();
  const { currentProfileId, currentProxy, modalEditingProxyId, modalView, containerElement, handleProxySelect } = useProxyManagerState();

  const handleClickCancel: React.MouseEventHandler<HTMLButtonElement> = event => {
    event.preventDefault();

    if (modalView === 'proxy-edit') {
      openProxyManager({
        containerElement: proxyManagerTargetRef?.current,
        modalView: 'proxy-list',
        currentProxy: null,
        modalEditingProxyId: null,
        currentProfileId: currentProfileId,
        proxySelectorLocation: 'no-location',
      });
      return;
    }

    closeProxyManager();
  };

  const handleClickSubmit: React.MouseEventHandler<HTMLButtonElement> = event => {
    event.preventDefault();
    switch (proxyFormCurrentTab) {
      case 'geolocation':
        onGeoProxyClickSubmit();
        break;
      case 'custom':
        onCustomProxyClickSubmit();
        break;
    }
  };

  const renderToggleButton = () => {
    if (modalView === 'proxy-edit') {
      return null;
    }

    return (
      <>
        <ProxyEditFormFooterTabsWrapper>
          <MovingBackground activeIndex={PROXY_FORM_TAB_INDEX_MAP[proxyFormCurrentTab]} />
          {PROXY_EDIT_FORM_TABS_LIST.map(tabName => (
            <ProxyEditFormFooterTab key={tabName} tabName={tabName} />
          ))}
        </ProxyEditFormFooterTabsWrapper>
      </>
    );
  };

  return (
    <ToggleableProxyEditFormFooterWrapper>
      <ToggleableProxyEditFormFooterButton alignment="flex-start" onClick={handleClickCancel}>
        <Trans i18nKey="proxyManager.proxyForm.back" />
      </ToggleableProxyEditFormFooterButton>
      {renderToggleButton()}
      <ToggleableProxyEditFormFooterButton alignment="flex-end" onClick={handleClickSubmit}>
        <Trans i18nKey="proxyManager.proxyForm.connect" />
      </ToggleableProxyEditFormFooterButton>
    </ToggleableProxyEditFormFooterWrapper>
  );
};

export default ToggleableProxyEditFormFooter;
