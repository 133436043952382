import { SetStateAction } from 'jotai';
import { Dispatch } from 'react';

import { getSelectedTagId, setSelectedTagId } from './selected-tag.atom';
import { closeTagManager } from './tag-manager-state.atom';
import { addNewTagToTagsList, getTagById, getTagsList, setTagsList } from './tags-list.atom';
import {
  addTagToProfiles,
  findProfilesWithTag,
  IAddTagToProfiles,
  removeCustomStatusesFromProfiles,
  removeTagFromProfiles,
  updateTagInProfiles,
} from '../../features/quickProfiles/components/manage-profile-tags';
import { addTagRequest, deleteProfilesTag, deleteTag, updateTagRequest } from '../../features/tags/api';
import { IAddTag, IAddTagMethod, ITag, ITagBase } from '../../features/tags/interfaces/tag.interface';
import { IProfile } from '../../interfaces';
import { getCurrentWorkspaceId } from '../current-workspace-id.atom';
import { getProfilesList, mapAndSetProfilesList, setProfilesList } from '../profiles-list.atom';
import { getProfilesTableGroupField } from '../profiles-table/profiles-table-group-field.atom';
import { callProfilesTableRowMeasurer } from '../profiles-table-row-measurers.atom';

const doUpdateTagRequest = async (newTag: ITag): Promise<void> => {
  const workspaceId = getCurrentWorkspaceId();
  const selectedTagId = getSelectedTagId();
  const selectedTag = getTagById(selectedTagId);
  const profiles = getProfilesList();
  const tagsList = getTagsList();

  const tagsBackup = [...tagsList];
  const profilesBackup: IProfile[] = [...profiles];
  let newSelectedTagBackup: ITag|null = null;

  if (selectedTag && newTag.id === selectedTagId) {
    newSelectedTagBackup = {
      ...selectedTag,
    };
  }

  updateTagRequest(workspaceId, newTag)
    .catch(() => {
      setProfilesList(profilesBackup);
      setTagsList(tagsBackup);

      if (selectedTag && selectedTagId === newTag.id) {
        setSelectedTagId(newSelectedTagBackup?.id || null);
      }
    });
};

export const updateTag = (tag: ITagBase, newAddedTag: ITag | null, setNewAddedTag: Dispatch<SetStateAction<ITag | null>>): Promise<void> => {
  const selectedTagId = getSelectedTagId();
  const selectedTag = getTagById(selectedTagId);
  const tagsList = getTagsList();
  const profiles = getProfilesList();

  mapAndSetProfilesList(prevProfiles => {
    const { newProfiles, updateRequired } = updateTagInProfiles({ profiles, tag });

    return updateRequired ? newProfiles : prevProfiles;
  });

  profiles.forEach(profile => callProfilesTableRowMeasurer(profile.id));

  if (selectedTag && tag.id === selectedTagId) {
    setSelectedTagId(tag.id);
  }

  const updateTags = tagsList.map(currentTag => {
    if (currentTag.id !== tag.id) {
      return currentTag;
    }

    return {
      ...currentTag,
      ...tag,
    };
  });

  setTagsList(updateTags);

  const isUpdatingNewAddedTag = newAddedTag?.id === tag.id && newAddedTag.hasLocalId;
  if (!isUpdatingNewAddedTag) {
    return doUpdateTagRequest(tag);
  }

  setNewAddedTag(prev => ({
    ...tag,
    hasLocalId: prev?.hasLocalId,
    field: prev?.field || 'tags',
  }));

  return Promise.resolve();
};

export const addTag = async (params: IAddTag, setNewAddedTag: Dispatch<SetStateAction<ITag | null>>): Promise<void> => {
  const tagsList = getTagsList();
  const workspaceId = getCurrentWorkspaceId();
  const profiles = getProfilesList();

  const {
    profileIds,
    title,
    color,
    tagId,
    isNewAdded,
    field,
  } = params;

  const preparedTitle = title.trim();
  const tempTag: ITag = {
    id: `${new Date().getTime()}`,
    title: preparedTitle,
    color,
    hasLocalId: true,
    field,
  };

  if (!tagId) {
    const tagInList = tagsList.find(tagItem => tagItem.title === preparedTitle && tagItem.field === field);
    if (tagInList) {
      tempTag.color = tagInList.color;
    } else {
      setNewAddedTag({
        ...tempTag,
        isNewAdded: true,
      });
    }

    const groupField = getProfilesTableGroupField();
    if (groupField === field) {
      closeTagManager();
    }
  }

  const shouldRemoveCustomStatuses = field === 'custom-status' && !isNewAdded;
  const profilesBackup = [...profiles];
  const tagsBackup = [...tagsList];
  mapAndSetProfilesList(prevProfiles => {
    let newProfiles: IProfile[];
    let updateRequired: boolean;
    let profilesToChange = prevProfiles;

    if (shouldRemoveCustomStatuses) {
      profilesToChange = removeCustomStatusesFromProfiles(prevProfiles, workspaceId, profileIds);
    }

    const tagChange: IAddTagToProfiles = {
      profiles: profilesToChange,
      targetProfileIds: profileIds,
      tag: tempTag,
      compareBy: 'title',
    };

    if (tagId) {
      const newTag: ITag = { id: tagId, title, color, isNewAdded, field };
      ({ newProfiles, updateRequired } = updateTagInProfiles({
        ...tagChange,
        tag: newTag,
        appendIfNotFound: !isNewAdded,
        compareBy: isNewAdded ? 'title' : 'id',
      }));
    } else {
      ({ newProfiles, updateRequired } = addTagToProfiles(tagChange));
    }

    return updateRequired ? newProfiles : profilesToChange;
  });

  profileIds.forEach(profileId => callProfilesTableRowMeasurer(profileId));

  if (tagId && isNewAdded) {
    const newTag: ITag = {
      id: tagId,
      title: preparedTitle,
      color,
      field,
    };

    addNewTagToTagsList(newTag);

    return;
  }

  const requestParams: IAddTagMethod = {
    browserIds: profileIds,
    title,
    color,
    field,
  };

  if (workspaceId) {
    requestParams.workspace = workspaceId;
  }

  const addTagResponse = await addTagRequest(requestParams).catch<{ success: false }>(() => {
    setProfilesList(profilesBackup);
    setTagsList(tagsBackup);

    return { success: false };
  });

  if (!addTagResponse.success || tagId) {
    return;
  }

  const { id: apiTagId, isNewAdded: isTagCreated } = addTagResponse;
  let { title: tagTitle, color: tagColor, field: tagField } = addTagResponse;
  if (isTagCreated) {
    if (!tagId) {
      ({ title: tagTitle, color: tagColor, field: tagField } = params);
      doUpdateTagRequest({
        id: apiTagId,
        title: tagTitle,
        color: tagColor,
        field: tagField,
      });
    }

    setNewAddedTag({
      id: apiTagId,
      title: tagTitle,
      color: tagColor,
      hasLocalId: false,
      field: tagField,
    });
  }

  await addTag({
    profileIds,
    title: tagTitle,
    color: tagColor,
    field: tagField,
    tagId: apiTagId,
    isNewAdded: isTagCreated,
    lastTempId: tempTag.id,
  }, setNewAddedTag).catch(() => {
    setProfilesList(profilesBackup);
    setTagsList(tagsBackup);
  });
};

export const removeTag = async (tagId: string): Promise<void> => {
  const selectedTagId = getSelectedTagId();
  const selectedTag = getTagById(selectedTagId);
  const workspaceId = getCurrentWorkspaceId();
  const tagsList = getTagsList();
  const profiles = getProfilesList();

  if (selectedTag && selectedTag.id === tagId) {
    setSelectedTagId(null);
    closeTagManager();
  }

  const tagsBackup = [...tagsList];
  let profilesBackup: IProfile[];
  mapAndSetProfilesList(prevProfiles => {
    profilesBackup = [...prevProfiles];

    return removeTagFromProfiles(profiles, tagId);
  });

  profiles.forEach(profile => callProfilesTableRowMeasurer(profile.id));

  const newTagsList = tagsList.filter(tag => tag.id !== tagId);

  setTagsList(newTagsList);

  await deleteTag(tagId, workspaceId).catch(() => {
    setProfilesList(profilesBackup);
    setTagsList(tagsBackup);
  });
};

export const removeProfileTag = async (profileIds: string[], tagId: string): Promise<void> => {
  const workspaceId = getCurrentWorkspaceId();
  const selectedTagId = getSelectedTagId();
  const selectedTag = getTagById(selectedTagId);

  let profilesBackup: IProfile[];
  mapAndSetProfilesList(prevProfiles => {
    profilesBackup = [...prevProfiles];
    let newProfiles = removeTagFromProfiles(prevProfiles, tagId, profileIds);
    let skipUpdateProfilesList = false;
    if (selectedTag) {
      newProfiles = findProfilesWithTag(newProfiles, selectedTag.id);
      if (!newProfiles.length) {
        skipUpdateProfilesList = true;
        setSelectedTagId(null);
        closeTagManager();
      }
    }

    return skipUpdateProfilesList ? prevProfiles : newProfiles;
  });

  profileIds.forEach(profileId => callProfilesTableRowMeasurer(profileId));

  await deleteProfilesTag({ profileIds, tagId, workspace: workspaceId }).catch(() => {
    setProfilesList(profilesBackup);
  });
};
