import React, { useContext, useEffect, useState } from 'react';

import { ContainerButtons } from './styles';
import { IProfile } from '../../../interfaces';
import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import { useProfileRunStatus } from '../../../state/profile-run-statuses.atom';
import { IProfileRunStatus } from '../../../state/profiles-list.atom';
import { ILaunchProfileOrbita } from '../components/interfaces/launch-profile-orbita.interface';
import RunProfileButton from '../run-button';

const stopPropagation: React.MouseEventHandler<HTMLDivElement> = (event) => event.stopPropagation();

interface IRunButtonCell {
  profile: IProfile;
  // TODO create child components
  // instead of this `renderDropDownMenu` method from profiles table
  renderDropDownMenu: (profile: IProfile & IProfileRunStatus) => JSX.Element | null;
  launchProfileOrbita: (opts: ILaunchProfileOrbita) => void;
}

const RunButtonCell: React.FC<IRunButtonCell> = (props) => {
  const { profile, renderDropDownMenu, launchProfileOrbita } = props;

  const [shouldMountDropDown, setShouldMountDropDown] = useState<boolean>(!NEW_FEATURES.header);

  const profileRunStatus = useProfileRunStatus(profile.id);

  useEffect(() => {
    if (shouldMountDropDown) {
      return;
    }

    window.requestIdleCallback(
      () => setShouldMountDropDown(true),
      { timeout: 400 },
    );
  }, [shouldMountDropDown]);

  const profileWithRunStatus: IProfile & IProfileRunStatus = {
    ...profileRunStatus,
    ...profile,
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', backgroundColor: 'inherit' }}>
      <ContainerButtons
        newStyle={NEW_FEATURES.header}
        onClick={stopPropagation}
      >
        <RunProfileButton
          profile={profileWithRunStatus}
          launchProfileOrbita={launchProfileOrbita}
        />
      </ContainerButtons>
      {shouldMountDropDown ? renderDropDownMenu(profileWithRunStatus) : <div style={{ width: 20, height: 20 }} />}
    </div>
  );
};

export default React.memo(RunButtonCell);
