import { atom, getDefaultStore } from 'jotai';

import { setLastSyncDate } from './delta-sync/last-sync-date.atom';
import type { ObjectPoolEntity, ObjectPoolEntityType, ObjectPoolFolderProfile, ObjectPoolProfile } from './types';

const objectPoolAtom = atom<ObjectPoolEntity[]>([]);

export const getObjectPool = (): ObjectPoolEntity[] => getDefaultStore().get(objectPoolAtom);
export const setObjectPool = (newObjectPool: ObjectPoolEntity[]): void => getDefaultStore().set(objectPoolAtom, newObjectPool);

export const putIntoObjectPool = (objectPoolEntities: ObjectPoolEntity[]): void => {
  const objectPool = getObjectPool();

  // TODO: convert to map to improve performance
  const newObjectPool = [...objectPool];
  objectPoolEntities.forEach((objPoolEntity) => {
    const index = newObjectPool.findIndex(ent => ent.id === objPoolEntity.id && ent.objectPoolEntityType === objPoolEntity.objectPoolEntityType);

    if (index > -1) {
      newObjectPool[index] = objPoolEntity;
    } else {
      newObjectPool.push(objPoolEntity);
    }
  });

  setObjectPool(newObjectPool);
};

export const removeFromObjectPoolByIds = (toDeleteIds: Array<ObjectPoolEntity['id']>): void => {
  setObjectPool(getObjectPool().filter(({ id }) => !toDeleteIds.includes(id)));
};

const filterByObjectPoolType = <T extends ObjectPoolEntityType>(
  array: ObjectPoolEntity[],
  type: T,
): Extract<ObjectPoolEntity, { objectPoolEntityType: T }>[] => array.filter(
    (item): item is Extract<ObjectPoolEntity, { objectPoolEntityType: T }> => item.objectPoolEntityType === type,
  );

export const getObjectPoolEntitiesByType = <T extends ObjectPoolEntityType>(type: T): Extract<ObjectPoolEntity, { objectPoolEntityType: T }>[] =>
  filterByObjectPoolType(getObjectPool(), type);

export const resetObjectPool = (): void => {
  setObjectPool([]);
  setLastSyncDate(null);
};

const isFolderProfile = (entity: ObjectPoolEntity): entity is ObjectPoolFolderProfile => entity.objectPoolEntityType === 'folder-profile';

export const findObjectPoolFolderProfile = (folderId: string, profileId: string): ObjectPoolFolderProfile | null => {
  const objectPool = getObjectPool();

  // `as` here works because for other types TS correctly shows an error
  // but at the same time, TS can't infer the type automatically too :(
  return objectPool.find((entity) =>
    isFolderProfile(entity)
    && entity.folder === folderId
    && entity.profile === profileId,
  ) as ObjectPoolFolderProfile || null;
};
