import { message } from 'antd';
import React, { useState, MouseEvent, DragEvent, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import {
  DEFAULT_COOKIES_BORDER_COLOR,
  JsonTitleTxt,
  DrugAndDropCookiesContainer,
  CookiesTextArea,
} from './styles/styles';
import {
  closeProfileTableModal,
  openProfilesTableModal,
  PROFILE_COOKIES_MANAGER_MODAL_NAME,
  PROFILE_COOKIES_MODAL_NAME,
  useProfilesTableModalProfiles,
  useProfileTableModalIsVisible,
} from '../../../../state/profiles-table-modal.atom';
import IconCookiesDrop from '../../../../ui/icons/IconCookiesDrop';
import { getFormattedCookies } from '../../../../utils/cookies-parser';
import { sendActionAnalytics } from '../../../common/api';
import { updateProfileCookies } from '../../../quickProfiles/api';
import CookiesManagerModal from '../../components/cookies-manager-modal';

const CookiesManager = (): JSX.Element => {
  const { t: translation } = useTranslation();

  const [cookiesTxt, setCookiesTxt] = useState<string>('');
  const [cookiesBorderColor, setCookiesBorderColor] = useState<string>(DEFAULT_COOKIES_BORDER_COLOR);
  const [placeholder, setPlaceholder] = useState<string>(translation('cookieManagerModal.pastCookiesTextField.placeholder.default') || '');
  const [cookiesButtonDisabled, setCookiesButtonDisabled] = useState<boolean>(true);

  const isModalVisible = useProfileTableModalIsVisible(PROFILE_COOKIES_MANAGER_MODAL_NAME);
  const [modalProfile = { id: '', name: '' } ] = useProfilesTableModalProfiles();

  const { id: profileId, name: profileName } = modalProfile;

  const onDragOver = (event: MouseEvent): void => {
    event.stopPropagation();
    event.preventDefault();
  };

  const handleDrop = (event: DragEvent): void => {
    event.preventDefault();
    event.stopPropagation();
    const { files } = event.dataTransfer;
    parseFileList(files);
  };

  const parseFileList = (files: FileList): void => {
    for (const file of files) {
      parseFile(file);
    }
  };

  const parseFile = (file: File): void => {
    const allowedFileType = file.type === 'application/json' || file.type.match(/^text*/);
    if (!allowedFileType) {
      message.error(translation('cookies.importFile.error'));

      return;
    }

    const reader: FileReader = new FileReader();
    reader.readAsText(file);

    reader.onload = (): undefined => {
      let cookiesButtonDisabledToggle = true;
      let cookiesBorderColorToggle = 'var(--FF0000)';
      try {
        getFormattedCookies(reader.result as string);
        cookiesButtonDisabledToggle = false;
        cookiesBorderColorToggle = DEFAULT_COOKIES_BORDER_COLOR;
      } catch (err) {}

      setCookiesTxt(reader.result as string);
      setCookiesButtonDisabled(cookiesButtonDisabledToggle);
      setCookiesBorderColor(cookiesBorderColorToggle);
    };

    reader.onerror = (): void => {
      message.error(reader.error);
    };
  };

  const closeCookiesModal = (): void => {
    setCookiesTxt('');
    setCookiesBorderColor(DEFAULT_COOKIES_BORDER_COLOR);
    setCookiesButtonDisabled(true);
    closeProfileTableModal();
  };

  const saveProfileCookies = async (): Promise<void> => {
    setCookiesButtonDisabled(true);
    sendActionAnalytics('imported cookies');
    let cookiesJson = [];
    try {
      cookiesJson = getFormattedCookies(cookiesTxt);
    } catch (err) {
      setCookiesBorderColor('var(--FF0000)');

      return;
    }

    try {
      await updateProfileCookies(profileId, cookiesJson);
      const cookiesLength = cookiesJson.length;
      let text = `${cookiesLength} ${translation('cookies.updating.success')}`;

      if (cookiesLength === 1) {
        text = `${cookiesLength} ${translation('cookies.updating.success')}`;
      }

      const successMessageDuration = 5;

      message.success(text, successMessageDuration);
      closeCookiesModal();
      openProfilesTableModal(PROFILE_COOKIES_MODAL_NAME, [profileId]);
    } catch (error) {
      message.error(translation('cookies.updating.error'));
      setCookiesButtonDisabled(false);
    }
  };

  const changeCookiesTxt = (event: ChangeEvent<HTMLTextAreaElement>): void => {
    const txt = event.target.value;
    let cookiesButtonDisabledToggle = true;
    let cookiesBorderColorToggle = 'var(--FF0000)';

    try {
      getFormattedCookies(txt);
      cookiesButtonDisabledToggle = false;
      cookiesBorderColorToggle = DEFAULT_COOKIES_BORDER_COLOR;
    } catch (err) {}

    setCookiesTxt(event.target.value);
    setCookiesButtonDisabled(cookiesButtonDisabledToggle);
    setCookiesBorderColor(cookiesBorderColorToggle);
  };

  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  const handleUploadClick = (): void => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.value = '';
    }

    hiddenFileInput && hiddenFileInput.current?.click();
  };

  const handleUploadChange = (event: any): void => {
    const filesUploaded = event.target.files;
    parseFileList(filesUploaded);
  };

  const cookiesSettings = (): JSX.Element => (
    <div>
      <h2 style={{ marginBottom: '20px', marginTop: '-15px', fontSize: 21, fontWeight: 500 }}>
        {profileName}
      </h2>
      <p style={{ fontSize: 14, fontWeight: 300 }}>
        {translation('cookieManagerModal.subtitle')}
        &nbsp;
        <JsonTitleTxt
          onMouseEnter={(): void => setPlaceholder(translation('cookieManagerModal.pastCookiesTextField.placeholder.json') || '')}
          onMouseLeave={(): void => setPlaceholder(translation('cookieManagerModal.pastCookiesTextField.placeholder.default') || '')}
        >
          {translation('base.json')}
        </JsonTitleTxt>
        &nbsp;
        {translation('base.and')}
        &nbsp;
        <JsonTitleTxt
          onMouseEnter={(): void => setPlaceholder(translation('cookieManagerModal.pastCookiesTextField.placeholder.netscape') || '')}
          onMouseLeave={(): void => setPlaceholder(translation('cookieManagerModal.pastCookiesTextField.placeholder.default') || '')}
        >
          {translation('base.netscape')}
        </JsonTitleTxt>
        &nbsp;
        {translation('base.format')}
      </p>
      <DrugAndDropCookiesContainer
        onDrop={handleDrop}
        onDragOver={onDragOver}
        onClick={handleUploadClick}
      >
        <input
          type='file'
          ref={hiddenFileInput}
          onChange={handleUploadChange}
          style={{ display:'none' }}
        />
        <IconCookiesDrop />
      </DrugAndDropCookiesContainer>
      <div style={{ textAlign: 'center' }}>
        <h3 style={{ color: DEFAULT_COOKIES_BORDER_COLOR, fontSize: 16 }}>
          {translation('base.or').toUpperCase()}
        </h3>
      </div>
      <CookiesTextArea
        value={cookiesTxt}
        onChange={(event): void => changeCookiesTxt(event)}
        style={{ borderColor: cookiesBorderColor }}
        placeholder={placeholder}
        onDrop={handleDrop}
      />
    </div>
  );

  return (
    <CookiesManagerModal
      visible={isModalVisible}
      cookiesButtonDisabled={cookiesButtonDisabled}
      saveCookies={saveProfileCookies}
      closeCookiesModal={closeCookiesModal}
      cookiesSettings={cookiesSettings}
    />
  );
};

export default CookiesManager;
