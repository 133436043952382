import { handleAcceptedTransactions } from './handle-accepted-transaction';
import type { ObjectPoolFolderProfile, ObjectPoolTransaction } from '../types';

export type UpdatedFoldersProfilesRes = {
  foldersProfiles: Array<Omit<ObjectPoolFolderProfile, 'objectPoolEntityType'>>;
};

export const handleUpdatedFoldersProfiles = (updatedFoldersProfiles: UpdatedFoldersProfilesRes): Promise<void> => {
  const transactions: Array<ObjectPoolTransaction<ObjectPoolFolderProfile>> = updatedFoldersProfiles.foldersProfiles.map((fp) => ({
    transactionType: 'P',
    entityType: 'folder-profile',
    entity: fp,
  }));

  return handleAcceptedTransactions(transactions);
};
