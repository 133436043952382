import * as Sentry from '@sentry/react';
import { message } from 'antd';
import { TFunction } from 'i18next';

import { E_ANALYTICS_ACTIONS } from '../../../../common/constants/analytics';
import { IProfile } from '../../../interfaces';
import { acceptDeleteProfiles } from '../../../object-pool/transactions/accepters/accept-delete-profiles';
import { getCurrentWorkspaceId } from '../../../state/current-workspace-id.atom';
import { getProfileRunStatus } from '../../../state/profile-run-statuses.atom';
import { filterProfilesByPermission, getProfilesList } from '../../../state/profiles-list.atom';
import { closeProfilesSettings, getProfilesSettingsState } from '../../../state/profiles-settings-atom';
import { reloadProfilesTableProfiles } from '../../../state/profiles-table/profiles-query';
import { openProfilesTableModal, TRANSFER_PROFILE_MODAL_NAME } from '../../../state/profiles-table-modal.atom';
import {
  getProfilesTableSelectedIds,
  getSelectedProfilesByAction,
} from '../../../state/profiles-table-selected-ids.atom';
import { generateBrowserIconsMassOpsPromise } from '../../browser-system-icons';
import { sendActionAnalytics } from '../../common/api';
import { getBrowserVersionCurrent } from '../../common/orbita-browser';
import { stopProfileOrbita, stopProfileWeb } from '../actions';
import { cloneWorkspaceProfiles, removeMultipleInvites, submitProfilesDelete } from '../api';
import { ILaunchProfileOrbita } from '../components/interfaces/launch-profile-orbita.interface';

let ipcRenderer: Electron.IpcRenderer;
const isElectron = !!window.require;
if (isElectron) {
  ({ ipcRenderer } = window.require('electron'));
}

export const stopProfiles = (): void => {
  const selectedProfilesIds = getProfilesTableSelectedIds();
  getProfilesList().forEach((profile) => {
    if (!selectedProfilesIds.includes(profile.id)) {
      return;
    }

    const profileRunStatus = getProfileRunStatus(profile.id);
    if (profileRunStatus.isWeb && profileRunStatus.status === 'profileStatuses.running') {
      stopProfileWeb(profile.id, true);

      return;
    }

    stopProfileOrbita(profile.id);
  });
};

const getBrowserMajorVersionCurrent = (userAgent = ''): string => {
  const version = getBrowserVersionCurrent(userAgent);
  const [majorVersion] = version.split('.');

  return majorVersion;
};

const filterOrbitasVersionsAvailable = async (majorVersions: number[]): Promise<number[]> =>
  ipcRenderer.invoke('check-orbitas-available', majorVersions);

const isProfileVersionLoading = (profile: IProfile, versionLoadingNow?: string): boolean => {
  const chromeVersionMatch = profile.navigator.userAgent.match(/[\s\S\w]+Chrome\/(\d+)/) || [];
  if (!chromeVersionMatch) {
    return true;
  }

  const [_, version = ''] = chromeVersionMatch;

  return Number(versionLoadingNow) === Number(version);
};

const isProfileAvailableToRun = (profile: IProfile): boolean => {
  const selectedProfilesIds = getProfilesTableSelectedIds();
  if (!selectedProfilesIds.includes(profile.id)) {
    return false;
  }

  const profileRunStatus = getProfileRunStatus(profile.id);

  return profile.canBeRunning &&
    !(profileRunStatus.isWeb || profileRunStatus.status === 'profileStatuses.running' || isProfileVersionLoading(profile));
};

const getAvailableToRunProfiles = async (profiles: IProfile[], transaction?: Sentry.Transaction): Promise<IProfile[]> => {
  const span = transaction?.startChild({ op: 'check-browser-version-before-launch-mass-run' });
  let availableToRunProfiles = profiles.filter(isProfileAvailableToRun);
  if (isElectron) {
    const profilesMajorVersions = availableToRunProfiles.map((profile) => +getBrowserMajorVersionCurrent(profile.navigator.userAgent));
    const profilesMajorVersionsUnique = [...new Set(profilesMajorVersions)];
    const orbitasVersionsAvailable = await filterOrbitasVersionsAvailable(profilesMajorVersionsUnique);

    availableToRunProfiles = availableToRunProfiles.filter((profile) => {
      const majorVersion = +getBrowserMajorVersionCurrent(profile.navigator.userAgent);

      return orbitasVersionsAvailable.includes(majorVersion);
    });
  }

  span?.finish();

  return availableToRunProfiles;
};

export const startProfiles = async (launchProfileOrbita: (opts: ILaunchProfileOrbita) => Promise<void>): Promise<void> => {
  const selectedProfilesIds = getProfilesTableSelectedIds();
  const transaction = Sentry.startTransaction({ name: 'prepare-mass-run' });

  const spanUpdateState = transaction.startChild({ op: 'update-state' });
  spanUpdateState.finish();
  sendActionAnalytics('launched profile via mass operations');
  const profilesList = getProfilesList();
  const profilesSelected = profilesList.filter(({ id }) => selectedProfilesIds.includes(id));
  const [availableToRunProfiles, iconsStartDataMassOps] = await Promise.all([
    getAvailableToRunProfiles(profilesList, transaction),
    generateBrowserIconsMassOpsPromise(profilesSelected, transaction),
  ]);

  transaction.finish();

  availableToRunProfiles.forEach((profile) => launchProfileOrbita({
    profile,
    multipleLaunch: true,
    updateStatusToSync: false,
    skipOrbitaVersionCheck: true,
    iconsStartData: iconsStartDataMassOps[profile.id],
  }).catch(() => null));
};

export const toggleModalTransfer = (translation: TFunction): void => {
  const selectedProfileIds = getProfilesTableSelectedIds();
  const canTransferProfiles = filterProfilesByPermission(selectedProfileIds, 'transferProfile');

  if (!canTransferProfiles) {
    message.error(translation('notifications.error.permissionWorkspace'));

    return;
  }

  openProfilesTableModal(TRANSFER_PROFILE_MODAL_NAME, selectedProfileIds);
};

export const cloneProfile = async (translation: TFunction): Promise<void> => {
  const workspaceId = getCurrentWorkspaceId();
  const selectedProfileIds = getProfilesTableSelectedIds();
  sendActionAnalytics('clicked clone profile via mass operations');
  await cloneWorkspaceProfiles(workspaceId, selectedProfileIds);

  message.success(translation('notifications.success.profileCloned'));
};

export const deleteOrLeaveMultipleProfiles = async (translation: TFunction): Promise<void> => {
  const workspaceId = getCurrentWorkspaceId();
  const promises = [];
  const profilesForDelete = getSelectedProfilesByAction('delete');
  const profileIdsToDelete = profilesForDelete.map((profile) => profile.id);
  if (profileIdsToDelete.length) {
    promises.push(
      submitProfilesDelete(workspaceId, profileIdsToDelete)
        .then(() => acceptDeleteProfiles(profileIdsToDelete)),
    );

    if (ipcRenderer) {
      ipcRenderer.invoke('stop-multiple-profiles', profileIdsToDelete);
      ipcRenderer.invoke('del-profiles-ext-folder', profileIdsToDelete);
    }

    sendActionAnalytics(E_ANALYTICS_ACTIONS.clickedConfirmDeleteProfileViaMassOperations, { actionInfo: profileIdsToDelete.length.toString() });
  }

  const profilesForLeave = getSelectedProfilesByAction('leave');
  if (profilesForLeave.length) {
    promises.push(await removeMultipleInvites(profilesForLeave.map((profile: any) => profile.shareId)));
  }

  const removedProfileIds = [...profileIdsToDelete, ...profilesForLeave.map(profile => profile.id)];
  const profileSettingsState = getProfilesSettingsState();
  if (removedProfileIds.find(removedProfileId => profileSettingsState.profileIds.includes(removedProfileId))) {
    closeProfilesSettings();
  }

  await Promise.all(promises);
  if (profileIdsToDelete.length) {
    message.success(translation('tableProfiles.notification.profilesDeleted', { count: profileIdsToDelete.length }));
  }

  reloadProfilesTableProfiles();
};
