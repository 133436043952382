import React, { useContext } from 'react';
import { Trans } from 'react-i18next';

import { importFilesContext } from '../../../state';
import { useIsCreatingFirstSessionProfiles } from '../../../state/first-session-status.atom';
import { IconSpinner } from '../../../ui/gologin-header/icons';
import { SpinnerContainer, SpinnerContainerText } from '../table/styles';

export const ProfilesTableLoadingSpinner: React.FC = () => {
  const isCreatingFirstSessionProfiles = useIsCreatingFirstSessionProfiles();

  const { dropFileStep } = useContext(importFilesContext);

  let loadingTextKey = null;
  if (dropFileStep === 'importing') {
    loadingTextKey = 'profiles.importing.text';
  } else if (dropFileStep === 'multipleCreating') {
    loadingTextKey = 'base.tableProfile.loading';
  } else if (isCreatingFirstSessionProfiles) {
    loadingTextKey = 'base.tableProfile.loadingFirstProfiles';
  }

  return (
    <SpinnerContainer>
      <IconSpinner size={32} padding={0} />
      {loadingTextKey ? (
        <SpinnerContainerText>
          <Trans i18nKey={loadingTextKey} />
        </SpinnerContainerText>
      ): null}
    </SpinnerContainer>
  );
};
