export const PROXY_EDIT_FORM_TABS = <const>{
  geoProxy: 'geolocation',
  customProxy: 'custom',
};

export const PROXY_EDIT_FORM_TABS_LIST = Object.values(PROXY_EDIT_FORM_TABS);

export type ProxyEditFormTab = typeof PROXY_EDIT_FORM_TABS_LIST[number];

export const PROXY_FORM_TAB_INDEX_MAP: Record<ProxyEditFormTab, number> = {
  geolocation: 0,
  custom: 1,
}
