import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { getLimitRangeByPlanAndLimit, usePaymentModalPaymentCountry } from './payment-modal.atom';
import { getPlanObjById, getPlansList, usePlanObjById } from './plans-list.atom';
import { useIsUpgradeDiscountAvaliable } from './upgrade-discount.atom';
import { DISCOUNT_IDS, DiscountIds, PLAN_IDS, PlanId } from '../../features/pricing/constants';
import { E_PAYMENT_COUNTRY } from '../../features/pricing/interfaces';
import { getBaseLimitsFromPlan, isLimitChangeBelowMinimum, mergeLimits, substractLimits } from '../../features/pricing/utils';
import { IPlan } from '../../interfaces/plan';
import { EMPTY_LIMITS, Limits, PricingChosenLimits } from '../../interfaces/workspaces/limits';
import { getWorkspaceLimits, useIsWorkspaceLimitsAvaliable, useWorkspaceLimits } from '../limits/workspace-limits.atom';
import { getWorkspaceSubscription } from '../workspace/workspace-subscription.atom';

const selectedPlanAtom = atom<string>(PLAN_IDS.Professional);
const selectedDiscountAtom = atom<string>(DISCOUNT_IDS.Annual);

const chosenLimitsAtom = atom<PricingChosenLimits[]>([]);

export const setSelectedPlan = (data: string): void => getDefaultStore().set(selectedPlanAtom, data);
export const getSelectedPlan = (): string => getDefaultStore().get(selectedPlanAtom);
export const useSelectedPlan = (): string => useAtomValue(selectedPlanAtom);

export const setSelectedDiscount = (data: string): void => getDefaultStore().set(selectedDiscountAtom, data);
export const getSelectedDiscount = (): string => getDefaultStore().get(selectedDiscountAtom);
export const useSelectedDiscount = (): string => useAtomValue(selectedDiscountAtom);

export const getChosenLimits = (): PricingChosenLimits[] => getDefaultStore().get(chosenLimitsAtom);
export const useChosenLimits  = (): PricingChosenLimits[] => useAtomValue(chosenLimitsAtom);
export const setChosenLimits = (data: PricingChosenLimits[]): void => getDefaultStore().set(chosenLimitsAtom, data);

export const setDefaultChosenLimits = (): void => {
  const plans = getPlansList();
  const { planId: workspacePlanId, paymentDiscount: workspaceDiscountId } = getWorkspaceSubscription();
  const workspaceLimits = getWorkspaceLimits();
  const defaultChosenLimits = plans.reduce((chosenLimits: PricingChosenLimits[], planObj: IPlan) => {
    const { limits: planLimits, id: planId } = planObj;
    const chosenLimitsToPush = DiscountIds.map<PricingChosenLimits>(discountId => {
      const isSamePlanAndDiscount = planId === workspacePlanId && discountId === workspaceDiscountId;
      const limits = isSamePlanAndDiscount ? workspaceLimits : planLimits;

      return {
        planId,
        discountId,
        limits,
      };
    });

    chosenLimits.push(...chosenLimitsToPush);

    return chosenLimits;
  }, [] as PricingChosenLimits[]);

  setChosenLimits(defaultChosenLimits);
};

export const useChosenLimitsByPlan = (planId: string): Limits => {
  const chosenLimits = useChosenLimits();
  const selectedDiscount = useSelectedDiscount();
  const planObj = usePlanObjById(planId);
  const { limits: chosenPlanLimits } = chosenLimits.find(limits => {
    const { planId: chosenPlanId, discountId } = limits || {};

    return planId === chosenPlanId && selectedDiscount === discountId;
  }) || {};

  if (!chosenPlanLimits) {
    const { limits = EMPTY_LIMITS } = planObj || {};

    return limits;
  }

  return chosenPlanLimits;
};

export const getChosenLimitsByPLan = (planId: string): Limits => {
  const chosenLimits = getChosenLimits();
  const selectedDiscount = getSelectedDiscount();
  const { limits: chosenPlanLimits } = chosenLimits.find(limits => {
    const { planId: chosenPlanId, discountId } = limits || {};

    return planId === chosenPlanId && selectedDiscount === discountId;
  }) || {};

  if (!chosenPlanLimits) {
    return EMPTY_LIMITS;
  }

  return chosenPlanLimits;
};

export const updateChosenLimitsByPLan = (planId: string, deltaLimits: Limits): void => {
  const chosenLimits = getChosenLimits();
  const selectedDiscount = getSelectedDiscount();
  const planObj = getPlanObjById(planId);
  const workspaceLimits = getWorkspaceLimits();
  const { planId: workspacePlanId, paymentDiscount: workspaceDiscountId } = getWorkspaceSubscription();
  const { limits: planLimits = EMPTY_LIMITS } = planObj || {};
  const { limits: chosenPlanLimits = planLimits } = chosenLimits.find(limits => {
    const { planId: chosenPlanId, discountId } = limits || {};

    return planId === chosenPlanId && selectedDiscount === discountId;
  }) || {};

  const isSamePlanAndDiscount = planId === workspacePlanId && selectedDiscount === workspaceDiscountId;
  const limitsToCheck = isSamePlanAndDiscount ? workspaceLimits : planLimits;
  const newLimits = mergeLimits(chosenPlanLimits, deltaLimits);
  const isBelowMinimum = isLimitChangeBelowMinimum(limitsToCheck, newLimits);
  if (isBelowMinimum) {
    return;
  }

  const updatedChosenLimits = chosenLimits.map(chosen => {
    const { planId: chosenLimitsPLanId, discountId: chosenLimitsDiscountId } = chosen;
    if (planId === chosenLimitsPLanId && selectedDiscount === chosenLimitsDiscountId) {
      return {
        ...chosen,
        limits: newLimits,
      };
    }

    return chosen;
  });

  setChosenLimits(updatedChosenLimits);
};

export const useIsAtLeastOneLimitsChoosedByUser = (planId: string): boolean => {
  const addonLimits = useAddonLimitsChosen(planId);

  return Object.values(addonLimits).some((limit) => limit > 0);
};

export const useAddonLimitsChosen = (planId: string): Limits => {
  const planObj = usePlanObjById(planId);
  const basePlanLimits = getBaseLimitsFromPlan(planObj);
  const workspaceLimits = useWorkspaceLimits();
  const selectedDiscount = useSelectedDiscount();
  const chosenLimits = useChosenLimitsByPlan(planId);
  const { planId: workspacePlanId, paymentDiscount: workspaceDiscountId } = getWorkspaceSubscription();
  const isSamePlanAndDiscount = planId === workspacePlanId && selectedDiscount === workspaceDiscountId;
  const baseLimits = isSamePlanAndDiscount ? workspaceLimits : basePlanLimits;

  return substractLimits(chosenLimits, baseLimits);
};

export const useHasAddonLimitsChosen = (workspacePlanId: string): boolean => {
  const workspaceAddonLimits = useAddonLimitsChosen(workspacePlanId);

  return Object.values(workspaceAddonLimits).some((limit: number) => limit > 0);
};

export const useIsSamePlan = (workspacePlanId: string, workspaceDiscount: string): boolean => {
  const selectedPlan = useSelectedPlan();
  const selectedDiscount = useSelectedDiscount();

  return selectedPlan === workspacePlanId && selectedDiscount === workspaceDiscount;
};

export const useIsLimitsPurchase = (workspacePlanId: string, workspaceDiscount: string): boolean => {
  const isWorkspaceLimitsAvaliable = useIsWorkspaceLimitsAvaliable();
  const isAtLeastOneLimitsChoosedByUser = useHasAddonLimitsChosen(workspacePlanId);
  const isUpgrade = useIsUpgradeDiscountAvaliable();
  const isSamePlan = useIsSamePlan(workspacePlanId, workspaceDiscount);

  return isWorkspaceLimitsAvaliable && isAtLeastOneLimitsChoosedByUser && isUpgrade && isSamePlan;
};

export const useShouldShowMemberLimitButtons = (planId: string): boolean => {
  const isWorkspaceLimitsAvaliable = useIsWorkspaceLimitsAvaliable();
  const paymentModalCountry = usePaymentModalPaymentCountry();
  const membersLimitsSettings = getLimitRangeByPlanAndLimit(planId, 'maxMembers');
  const { maxPurchasable: membersLimitsMaxPurchasable = 10 } = membersLimitsSettings || {};

  if (membersLimitsMaxPurchasable === 0) {
    return false;
  }

  return isWorkspaceLimitsAvaliable && paymentModalCountry !== E_PAYMENT_COUNTRY.RU;
};

export const useMaxMembersShown = (planId: PlanId): number => {
  const { maxMembers: membersUserHas = 0 } = useWorkspaceLimits();
  const { maxMembers: maxMembersChoosedByUser = 0 } = useChosenLimitsByPlan(planId);

  return membersUserHas + maxMembersChoosedByUser;
};

export const useCanAddMember = (planId: PlanId): boolean => {
  const planObj = usePlanObjById(planId);
  const { maxMembers: maxAccountShares = 0 } = getBaseLimitsFromPlan(planObj);
  const membersLimitsSettings = getLimitRangeByPlanAndLimit(planId, 'maxMembers');
  const { maxPurchasable: membersLimitsMaxPurchasable = 0 } = membersLimitsSettings || {};
  const maxMembersShown = useMaxMembersShown(planId);
  const maxMembersUserCanHave = membersLimitsMaxPurchasable + maxAccountShares;

  return maxMembersShown <= maxMembersUserCanHave;
};
