import React from 'react';

export interface IIconArrowDown {
  width?: number;
  height?: number;
  color?: string;
  className?: string;
  style?: any;
}

const IconArrowDown3: React.FC<IIconArrowDown> = ({
  width = 10,
  height = 6,
  className,
  color = 'var(--FFFFFF)',
  style,
}) => (
  <div>
    <svg
      width={width}
      height={height}
      className={className}
      style={style}
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.5 0.5L5 5L9.5 0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);

export default IconArrowDown3;
