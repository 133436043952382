import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { PaymentMethod } from '../../features/pricing/interfaces';
import { IUpdateWorkspaceContextData } from '../workspace.context';

export type WorkspaceSubscription = {
  planId: string;
  planExpiresAt: string;
  paymentMethod: PaymentMethod | '';
  paymentDiscount: string;
  isPlanUnlimited?: boolean;
  paymentIsTrial?: boolean;
  isUnpaid?: boolean;
};

const emptyWorkspaceSubscription: WorkspaceSubscription = {
  planId: '',
  planExpiresAt: '',
  paymentMethod: '',
  paymentDiscount: '',
  isPlanUnlimited: false,
  paymentIsTrial: false,
  isUnpaid: true,
};

export const workspaceSubscriptionAtom = atom<WorkspaceSubscription>(emptyWorkspaceSubscription);

export const getWorkspaceSubscription = (): WorkspaceSubscription => getDefaultStore().get(workspaceSubscriptionAtom);
export const useWorkspaceSubscription = (): WorkspaceSubscription => useAtomValue(workspaceSubscriptionAtom);
export const setWorkspaceSubscription = (partial: Partial<IUpdateWorkspaceContextData>): void => {
  const {
    planId = '',
    planExpiresAt = '',
    paymentMethod = '',
    paymentDiscount = '',
    isPlanUnlimited,
    paymentIsTrial,
    isUnpaid,
  } = partial || {};

  const workspaceSubscription = {
    planId,
    planExpiresAt,
    paymentMethod,
    paymentDiscount,
    isPlanUnlimited: !!isPlanUnlimited,
    isTrial: !!paymentIsTrial,
    isUnpaid: !!isUnpaid,
  };

  getDefaultStore().set(workspaceSubscriptionAtom, workspaceSubscription);

};
