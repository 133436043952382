import { css, SerializedStyles } from '@emotion/core';
import styled from '@emotion/styled';
import { Popover } from '@material-ui/core';

import { gologinPopoverElementBaseStyles } from '../../gologin-popover';
import { GologinPopoverHint } from '../../gologin-popover/gologin-popover-hint';
import { GologinPopoverItemRow } from '../../gologin-popover/gologin-popover-item-row';
import { textFont } from '../../style-templates';
import { IconWrapperWithDescription } from '../icons/wrapper';

const STANDARD_TEXT_LINE_HEIGHT = 20;
const HINT_MAXIMUM_LINES = 4;

// the element height must be adjusted by appropriate translation texts
// so the need in ellipsis is not occurred
const unsafeVerticalEllipsis = css`
  display: -webkit-box;
  -webkit-line-clamp: ${HINT_MAXIMUM_LINES};
  -webkit-box-orient: vertical;

  text-overflow: ellipsis;
  overflow-y: hidden;
`;

export const ProxyTrafficPopoverProxyTypeHintWrapper = styled(GologinPopoverHint)`
  width: 100%;
  padding: 0 12px 6px 12px;
  margin: 7px 0 0 0;

  ${unsafeVerticalEllipsis}
`;

export const ProxyTrafficPopoverTitle = styled.p`
  padding: 0 10px;
  margin: 0;
  margin-bottom: 4px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.2px;

  color: var(--767676);
`;
export const ProxyTrafficPopoverTrialHintWrapper = styled.div`
  ${gologinPopoverElementBaseStyles}

  width: 271px;
  padding: 2px 4px;
  margin-left: -4px;
  position: absolute;
  top: ${132 + STANDARD_TEXT_LINE_HEIGHT * HINT_MAXIMUM_LINES}px;
`;

export const ProxyTrafficPopoverLoaderWrapper = styled.div`
  height: 183px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 24px;
`;

export const ProxyTrafficPopoverRow = styled(GologinPopoverItemRow)`
  cursor: default;

  :hover {
    background: var(--F2F2F2);
    cursor: pointer;
  }
`;

export const BuyProxyPopoverCustom = styled(Popover)<{ isDarkTheme: boolean }>`
  .MuiPopover-paper {
    color: var(--000000DE-header-buy-proxy);
    margin-left: -24px;
    margin-top: 4px;
    padding: 16px;
    width: 271px;
    background: var(--FFFFFF-header-buy-proxy);

    border-radius: 4px;
    line-height: 0;

    ${(props): SerializedStyles | null =>
      !props.isDarkTheme
        ? css`
            box-shadow: 0 3px 6px var(--0000001A-header-buy-proxy), 0 4px 8px var(--00000014-header-buy-proxy),
              0 1px 12px var(--0000000A-header-buy-proxy);
          `
        : null}
  }
`;

export const ItemRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  line-height: 20px;

  color: var(--2B2B31-header-buy-proxy);
`;

export const VolumeProxyContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  ${textFont};
  width: max-content;
  color: var(--767676);
  margin-right: 4px;
`;

export const IconContainerWithDescription = styled(IconWrapperWithDescription)`
  ${textFont};
  margin-right: 8px;
  padding-right: 8px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  white-space: nowrap;
`;
