import { atom, getDefaultStore } from 'jotai';

import { DBMeta, DBMetaParams, loadOrInitDBMeta } from './meta-db';

// TODO: think how to remove null - init must in some way always be done before read
const cacheDBNameAtom = atom<string | null>(null);

export const getCacheDBName = (): string | null => getDefaultStore().get(cacheDBNameAtom);
export const initCacheDBName = async (dbMetaParams: DBMetaParams): Promise<DBMeta> => {
  const dbMeta = await loadOrInitDBMeta(dbMetaParams);
  getDefaultStore().set(cacheDBNameAtom, dbMeta.name);

  return dbMeta;
};
