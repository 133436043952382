import React, { useState } from 'react';
import { Trans } from 'react-i18next';

import { ProxiesErrorLocalisationKeysMap } from '../../../../../../common/proxy/utils';
import { IProxy } from '../../../../../interfaces';
import { ProxyInfoRowWrapper } from '../styles';
import {
  ErrorDetails,
  ErrorDetailsList,
  ErrorDetailsListItem,
  ErrorReasonText,
  IconArrow,
  ReadMoreButton,
} from './styles';

type ProxyInfoProps = {
  proxy: IProxy;
};

type GroupTranslationData = Record<string, { reason: string; solutions: string[] }>;

const groupedErrorTranslationData: GroupTranslationData = {
  [ProxiesErrorLocalisationKeysMap.proxyNonfunctional]: {
    reason: 'proxies.checkErrorNonfunctional.reason',
    solutions: [
      'proxies.checkErrorNonfunctional.howToFixListItemOne',
      'proxies.checkErrorNonfunctional.howToFixListItemTwo',
      'proxies.checkErrorNonfunctional.howToFixListItemThree',
    ],
  },
  [ProxiesErrorLocalisationKeysMap.proxyAuthError]: {
    reason: 'proxies.checkErrorAuth.reason',
    solutions: [
      'proxies.checkErrorAuth.howToFixListItemOne',
      'proxies.checkErrorAuth.howToFixListItemTwo',
      'proxies.checkErrorAuth.howToFixListItemThree',
    ],
  },
  [ProxiesErrorLocalisationKeysMap.proxyFailsInUserEnv]: {
    reason: 'proxies.checkErrorFailsInUserEnv.reason',
    solutions: [
      'proxies.checkErrorFailsInUserEnv.howToFixListItemOne',
      'proxies.checkErrorFailsInUserEnv.howToFixListItemTwo',
      'proxies.checkErrorFailsInUserEnv.howToFixListItemThree',
    ],
  },
  [ProxiesErrorLocalisationKeysMap.proxyWrongProtocol]: {
    reason: 'proxies.checkErrorWrongProtocol.reason',
    solutions: [
      'proxies.checkErrorWrongProtocol.howToFixListItemOne',
      'proxies.checkErrorWrongProtocol.howToFixListItemTwo',
      'proxies.checkErrorWrongProtocol.howToFixListItemThree',
    ],
  },
  [ProxiesErrorLocalisationKeysMap.proxyTrafficIsOver]: {
    reason: 'proxies.checkErrorNonfunctional.reason',
    solutions: [
      'proxies.checkErrorNonfunctional.howToFixListItemOne',
      'proxies.checkErrorNonfunctional.howToFixListItemTwo',
      'proxies.checkErrorNonfunctional.howToFixListItemThree',
    ],
  },
};

const ProxyInfoError: React.FC<ProxyInfoProps> = (props) => {
  const { proxy } = props;

  const [isReadMoreVisible, setIsReadMoreVisible] = useState<boolean>(false);

  const handleReadMoreClick = (): void => setIsReadMoreVisible((isVisible) => !isVisible);

  let errorData = groupedErrorTranslationData[ProxiesErrorLocalisationKeysMap.proxyNonfunctional];
  if (proxy?.error) {
    errorData = groupedErrorTranslationData[proxy.error] || errorData;
  }

  return (
    <>
      <div>
        <ErrorReasonText>
          <Trans i18nKey={errorData.reason} />
        </ErrorReasonText>
        {isReadMoreVisible && (
          <ErrorDetails>
            <Trans i18nKey="proxies.error.howToFixTitle" />
            <ErrorDetailsList>
              {errorData.solutions.map((key) => (
                <ErrorDetailsListItem key={key}>
                  <Trans i18nKey={key} />
                </ErrorDetailsListItem>
              ))}
            </ErrorDetailsList>
          </ErrorDetails>
        )}
      </div>
      {isReadMoreVisible ? (
        <ProxyInfoRowWrapper>
          <ReadMoreButton onClick={handleReadMoreClick}>
            <Trans i18nKey="proxies.readLess" />
            <IconArrow color="var(--767676-proxy-manager-info-error)" style={{ transform: 'scaleY(-1)' }} />
          </ReadMoreButton>
        </ProxyInfoRowWrapper>
      ) : (
        <ProxyInfoRowWrapper>
          <ReadMoreButton onClick={handleReadMoreClick}>
            <Trans i18nKey="proxies.readMore" />
            <IconArrow color="var(--767676-proxy-manager-info-error)" />
          </ReadMoreButton>
        </ProxyInfoRowWrapper>
      )}
    </>
  );
};

export default ProxyInfoError;
