/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-underscore-dangle */
import css, { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled';
import { Button } from 'antd';

import { IActionsButton } from '../../../../quickProfiles/components/interfaces/actions-button.interface';
export const MassActionsBlockLineWrapper = styled.div<{
  isProfilePage?: boolean;
  hasBorderRadius?: boolean;
  isProxyManagerVisible?: boolean;
  isNewDesign?: boolean;
}>`
   
  z-index: ${(props): string => (props.isProxyManagerVisible ? '140' : '151')};

  ${(props) =>
    props.hasBorderRadius
      ? `
    border-radius: 8px 8px 0 0;
  `
      : ''}
`;

export const MassActionsBlockLine = styled.div<{
  isVisible?: boolean;
  hasBorderRadius?: boolean;
  isProxyManagerVisible?: boolean;
  isProfilePage?: boolean;
  oldStyle?: boolean;
}>`
  width: 100%;
  display: ${(props): string => (props.isVisible ? 'flex' : 'none')};
  flex-direction: row;
  position: sticky;
  top: ${({ isProfilePage, oldStyle }): string => (isProfilePage && !oldStyle ? '73px' : '0')};
  left: 0;
  background-color: ${({ oldStyle }): string => oldStyle ? 'var(--F9F9F9)' : 'var(--FFFFFF)'};
  z-index: ${(props): string => (props.isProxyManagerVisible ? '140' : '151')};

  ${(props): SerializedStyles => props.oldStyle ? css`
    border-bottom: 0.5px solid var(--E5E5E6-profile-table);
  ` : css`
    outline: 1px solid var(--EBEBEB);
    background-color: ${({ oldStyle }): string => (oldStyle ? 'var(--F9F9F9)' : 'var(--FFFFFF)')};
  `}

  ${(props) =>
    props.hasBorderRadius
      ? `
    border-radius: 8px 8px 0 0;
    margin-top: -1px;
  `
      : ''}

  .ant-btn-primary {
    color: var(--FFFFFF-antd);
    background-color: var(--00B86D-antd);
    border-color: var(--00B86D-antd);
  }

  .ant-btn-primary:hover,
  .ant-btn-primary:focus {
    color: var(--FFFFFF-antd);
    background-color: var(--1FC47D-antd);
    border-color: var(--1FC47D-antd);
  }
`;

export const MassActionsBlockLineCorners = styled.div<{ oldStyle: boolean; hasBorderRadius?: boolean }>`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  border-radius: 8px 8px 0 0;
  background-color: ${({ oldStyle }): string => (oldStyle ? 'var(--F9F9F9)' : 'var(--FFFFFF)')};

  ${(props) =>
    props.hasBorderRadius && !props.oldStyle
      ? `
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    width: 14px;
    height: 8px;
    background-color: var(--F9F9F9-header-container);
    z-index: -5;
  }

  &::before {
    left: -8px;
    top: -2px;
  }

  &::after {
    right: -8px;
    top: -2px;
  }
  `
      : ''}
`;

export const MassActionsMarginBlock = styled.div<{
  isProfilesSettingsOpen: boolean;
}>`
  margin: 12px;
  display: flex;
  flex-wrap: wrap;

  ${(props): SerializedStyles => css`
    width: ${props.isProfilesSettingsOpen ? 'calc(100% - 505px)' : '100%'};
    min-width: ${props.isProfilesSettingsOpen ? '650px' : 'auto'};
  `}
`;

const massActionsButtonLineStylesBase = css`
  height: 27px;
  border-radius: 2px;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 12px;
  font-size: 12px;

  :hover {
    opacity: 0.7;
  }
`;

export const getActionsButtonStyles = (
  isProcessing: boolean,
): IActionsButton => ({
  isDisabled: isProcessing,
  iconColor: isProcessing ? 'var(--00000040-antd)' : 'var(--696973)',
  iconHoveredColor: isProcessing ? 'var(--00000040-antd)' : 'var(--36363D)',
  iconType: 'stroke',
  textColor: isProcessing ? 'var(--00000040-antd)' : 'var(--696973)',
  textHoveredColor: isProcessing ? 'var(--00000040-antd)' : 'var(--36363D)',
});

export const MassActionsButtonLine = styled(Button)`
  color: var(--359D73-profile-table-profiles-menu);
  border-color: var(--359D73-profile-table-profiles-menu);
  ${massActionsButtonLineStylesBase}
`;

export const MassActionsButtonLineDanger = styled(Button)`
  color: var(--A24747-profile-table-profiles-menu);
  border-color: var(--A24747-profile-table-profiles-menu);

  :hover {
    color: var(--A24747-profile-table-profiles-menu);
    border-color: var(--A24747-profile-table-profiles-menu);
  }

  :focus {
    color: var(--A24747-profile-table-profiles-menu);
    border-color: var(--A24747-profile-table-profiles-menu);
  }

  ${massActionsButtonLineStylesBase}
`;

// @ts-ignore: https://github.com/ant-design/ant-design/issues/9581
MassActionsButtonLine.__ANT_BUTTON = true;

export const MassActionsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

export const MassActionsButtonText = styled.span`
  letter-spacing: 0.2px;
`;

export const MassActionsBlockEnd = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;
