import { Icon } from 'antd';
import React, { FC, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { searchBold } from '../../features/newProfile/components/folders-select/search';
import { LoaderDiv } from '../../features/profileSettingsComponents/extensionsTab/domElements';
import {
  GEOPROXY_MODERN_SELECT_ALL_COUNTRIES_TITLE,
  GEOPROXY_MODERN_SELECT_RECENT_COUNTRIES_TITLE,
} from '../../features/proxy/proxy-manager/proxy-edit-view/geoproxy-edit-form-fields/styles';
import { IItem, ISelectGroupItems, ISelectItem } from './interfaces';
import { ContainerFoundItem, Divider, Ellipsis, FlagContainer, Hint, Option, SubtitleContainer } from './styles';

const PAGE_SIZE = 100;

const Item: FC<IItem> = props => {
  const { option, onSelect, hasDivider, currentValue, searchValue } = props;
  const [itemsShown, setItemsShown] = useState<number>(PAGE_SIZE);

  const onClick = (value: string, city?: string): void => {
    onSelect(value, city);
  };

  const loadMore = (): void => {
    setItemsShown(prev => prev + PAGE_SIZE);
  };

  const getLoader = (): JSX.Element | null => (
    <LoaderDiv>
      <Icon type="loading" />
    </LoaderDiv>
  );

  const isOptionGroup = Object.prototype.hasOwnProperty.call(option, 'groupTitle');
  const allItems: ISelectItem[] = isOptionGroup ? (option as ISelectGroupItems).selectItems : [option as ISelectItem];
  const shownSelectItems = allItems.filter(item => !item.hidden);
  const limitedItems = shownSelectItems.slice(0, itemsShown);
  const hasMore = limitedItems.length < shownSelectItems.length;

  const showGroupTitle = (): JSX.Element | null => {
    if (!(isOptionGroup || (option as ISelectGroupItems).groupTitle)) {
      return null;
    }

    let subtitleStyles: React.CSSProperties = {};

    switch ((option as ISelectGroupItems).groupTitle) {
      case GEOPROXY_MODERN_SELECT_ALL_COUNTRIES_TITLE:
        subtitleStyles = { paddingTop: '8px', marginLeft: '14px' };
        break;
      case GEOPROXY_MODERN_SELECT_RECENT_COUNTRIES_TITLE:
        subtitleStyles = { paddingTop: '8px', marginLeft: '14px' };
        break;
      case 'Available cities':
        subtitleStyles = { display: 'none' };
        break;
      default:
        break;
    }

    return <SubtitleContainer style={subtitleStyles}>{(option as ISelectGroupItems).groupTitle}</SubtitleContainer>;
  };

  const showDivider = (): JSX.Element | null => {
    if (!hasDivider) {
      return null;
    }

    let dividerStyles: React.CSSProperties = {};
    if ((option as ISelectGroupItems).groupTitle === GEOPROXY_MODERN_SELECT_ALL_COUNTRIES_TITLE || GEOPROXY_MODERN_SELECT_RECENT_COUNTRIES_TITLE) {
      dividerStyles = { margin: '7px auto', width: '96%' };
    }

    return <Divider style={dividerStyles} />;
  };

  const renderOption = (item: ISelectItem): JSX.Element => {
    const { title, hint, flag, isSearchIgnored } = item;
    if (!searchValue || isSearchIgnored) {
      return (
        <>
          {hint ? <Hint>{hint}</Hint> : null}
          {flag ? <>{flag}</> : null}
          <Ellipsis>{title}</Ellipsis>
          {searchBold}
        </>
      );
    }

    let itemText = title;
    if (hint) {
      itemText = `${hint} ${itemText}`;
    }

    const transformedText = searchBold(searchValue.replace('-', '−'), itemText);

    return (
      <ContainerFoundItem toHighlightFoundOptions={!flag}>
        {flag ? (
          <FlagContainer>
            {flag} <span>{itemText}</span>
          </FlagContainer>
        ) : (
          transformedText
        )}
      </ContainerFoundItem>
    );
  };

  return (
    <InfiniteScroll
      dataLength={limitedItems.length}
      next={loadMore}
      hasMore={hasMore}
      loader={getLoader()}
      scrollableTarget="new-select-options"
      style={{ overflow: 'hidden', width: '100%' }}
    >
      <div style={{ width: '100%', display: 'contents' }}>
        {showGroupTitle()}
        {limitedItems.map(item => {
          const { value, city, key, flag } = item;

          let optionStyles: React.CSSProperties = {};

          if (flag) {
            optionStyles = { paddingLeft: '12px', gap: '0' };
          }

          return (
            <Option
              style={optionStyles}
              key={key}
              onClick={() => onClick(value, city)}
              isSelected={currentValue.toLowerCase().trim() === value?.toLowerCase().trim()}
            >
              {renderOption(item)}
            </Option>
          );
        })}
        {showDivider()}
      </div>
    </InfiniteScroll>
  );
};

export default Item;
