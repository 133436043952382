/* eslint-disable max-lines */
import { CloseOutlined } from '@material-ui/icons';
import { useTranslate } from '@tolgee/react';
import { Dropdown, Icon } from 'antd';
import React, { FC, memo, useContext, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';

import CryptoForm from './crypto-form';
import {
  AnnuallyTxt,
  CustomAnnuallyMenu,
  CustomItemMenu,
  CustomPaymentModal,
  GreenCheckoutIcon,
  HeaderTitleContainer,
  LoadingContainer,
  ModalCheckMarkContainer,
  ModalCheckMarkTxt,
  ModalDivider,
  ModalHeaderBoldTxt,
  ModalHeaderTxt,
  ModalParamsLeftTxt,
  ModalParamsLine,
  ModalParamsRightTxt,
  ModalSwitcher,
  ModalSwitcherItem,
  ModalSwitcherItemSelected,
  PaymentModalCancelContainer,
} from './domElements';
import PaymentIconsBar from './external/payment-icons';
import PayPalForm from './paypal-form';
import SigmaForm from './sigma-form';
import StripeForm from './stripe-form';
import { PaymentModalIconsBarContainer, ThreeDSecureStyled } from './styles';
import { E_ACTION_INFO, E_ANALYTICS_ACTIONS } from '../../../../common/constants/analytics';
import { useQuery } from '../../../hooks';
import { E_DISCOUNT_TYPE } from '../../../interfaces';
import { IPlan } from '../../../interfaces/plan';
import {
  convertDiscountIdToPeriod,
  setPaymentModalPaymentType,
  useCurrentTabShownCurrency,
  useDiscountObjById,
  usePlansAddonPricingConfig,
  useModalShownTabsConfig,
  usePaymentModalPaymentType,
  usePlanObjById,
  usePlansList,
  usePriceConfig,
  usePromoDiscountAvaliable,
  workspaceContext,
  useDefaultCountryPaymentType,
} from '../../../state';
import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import { useWorkspaceLimits } from '../../../state/limits/workspace-limits.atom';
import {
  useIsLimitsPurchase,
  useIsSamePlan,
  useHasAddonLimitsChosen,
  useSelectedDiscount,
  useSelectedPlan,
  useAddonLimitsChosen,
} from '../../../state/pricing/pricing-page.atom';
import {
  setShouldShowUpgradeDiscountLine,
  useShouldShowUpgradeDiscountLine,
  useIsUpgradeDiscountAvaliable,
  useUpgradeDiscount,
} from '../../../state/pricing/upgrade-discount.atom';
import IconPaymentModalCheckmark from '../../../ui/icons/IconPaymentModalCheckmark';
import { sendActionAnalytics } from '../../common/api';
import { ANNUAL_DISCOUNT, APPLE_PAY, GOOGLE_PAY, MONTH_DISCOUNT } from '../../modalsComponents/components/checkout-modal/constants';
import { isDiscountAnnual, getIsGooglePay, getNextPaymentDate, getPaymentTypes } from '../../modalsComponents/components/checkout-modal/utils';
import SuccessPaymentModal from '../../modalsComponents/components/success-payment-modal';
import { fetchUserVisitedPageAnalytics } from '../api';
import { EMPTY_UPGRADE_DISCOUNT } from '../constants';
import { E_PERIOD, ICalcModalHeightParams, IUpgradeDiscount, PaymentType } from '../interfaces';
import { QUICK_PRICING_MODAL_Z_INDEX } from '../styles';
import { Price, calcModalHeight, getAnalyticsPaymentType, getBaseLimitsFromPlan, planByPlanId, substractLimits } from '../utils';

export interface IPlanPriceParams {
  isDiscount: boolean;
  isAnnual?: boolean;
  isMobilePayment?: boolean;
  upgradeDiscount?: boolean;
}

interface IPaymentModal {
  modalVisible: boolean;
  changeMonthlyPaymentPeriod: (checked: boolean) => any;
  closePaymentModal: () => void;
  getPlanInfo: (planId: string, field: keyof IPlan) => JSX.Element | string;
  workspaceId?: string;
  userData: {
    email: string;
    plan: IPlan;
    isLastPaymentTrialEnded: boolean;
  };
}

const PaymentModal: FC<IPaymentModal> = (props) => {
  const {
    modalVisible,
    changeMonthlyPaymentPeriod,
    closePaymentModal,
    getPlanInfo,
    workspaceId,
    userData,
  } = props;

  const plans = usePlansList();
  const currentPaymentType = usePaymentModalPaymentType();
  const promoDiscount = usePromoDiscountAvaliable();
  const priceConfig = usePriceConfig();
  const selectedPlan = useSelectedPlan();
  const selectedDiscount = useSelectedDiscount();
  const selectedPlanObj = usePlanObjById(selectedPlan);
  const selectedDiscounObj = useDiscountObjById(selectedDiscount);
  const currency = useCurrentTabShownCurrency();
  const paymentTabsConfig = useModalShownTabsConfig();
  const isUpgrade = useIsUpgradeDiscountAvaliable();
  const upgradeDiscount = useUpgradeDiscount();
  const showUpgradeDiscountLine = useShouldShowUpgradeDiscountLine();
  const limitsPricingSetings = usePlansAddonPricingConfig();

  const { planId: workspacePlanId = '', paymentDiscount = '', planExpiresAt } = useContext(workspaceContext);

  const isLimitsPurchase = useIsLimitsPurchase(workspacePlanId, paymentDiscount);
  const isSamePlan = useIsSamePlan(workspacePlanId, paymentDiscount);
  const isPurchaseWithAddonLimits = useHasAddonLimitsChosen(selectedPlan);
  const addonLimitsChosen = useAddonLimitsChosen(selectedPlan);
  const workspaceLimits = useWorkspaceLimits();
  const defaultCountryPaymentType =  useDefaultCountryPaymentType();

  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const [threeDSecureUrl, setThreeDSecureUrl] = useState<string>('');
  const [showThreeDSecureModal, setShowThreeDSecureModal] = useState<boolean>(false);
  const [isCloseModalDisabled, setIsCloseModalDisabled] = useState<boolean>(false);
  const [isLoadingModalShown, setIsLoadingModalShown] = useState<boolean>(false);
  const [isRussianIp, setIsRussianIp] = useState<boolean>(false);
  const [planForSuccessModal, setPlanForSuccessModal] = useState<string>(selectedPlan);

  const {
    email,
    plan,
    isLastPaymentTrialEnded,
  } = userData;

  const { t: translation } = useTranslate();

  const paymentTypes = getPaymentTypes(paymentTabsConfig);
  const isAnnual = isDiscountAnnual(selectedDiscount);
  const needChangePlan = !(plan.id || selectedPlan === plan.id);
  const successPayment = false;
  const periodType = convertDiscountIdToPeriod(selectedDiscount);
  const disableMonthPlan = isUpgrade && upgradeDiscount.periodType === E_PERIOD.ANNUAL;
  const workspacePlanObj = planByPlanId(workspacePlanId, plans);
  const actionInfo = isUpgrade ? E_ACTION_INFO.UPGRADE : E_ACTION_INFO.EMPTY;
  const { daysLeftFraction = 1 } = upgradeDiscount;
  const isRenew = isSamePlan && isUpgrade && !isLimitsPurchase;

  const basePlanLimits = getBaseLimitsFromPlan(selectedPlanObj);
  const workspaceAddonLimits = substractLimits(workspaceLimits, basePlanLimits);
  const addonLimits = isRenew ? workspaceAddonLimits : addonLimitsChosen;
  const { maxMembers = 0 } = workspaceAddonLimits;
  const isRenewWithAddonLimits = isRenew && !!maxMembers;
  const isUpgradeWithAddonLimits =  isPurchaseWithAddonLimits && isUpgrade && !isSamePlan;

  const query = useQuery();

  useEffect(() => {
    if (modalVisible) {
      sendActionAnalytics(E_ANALYTICS_ACTIONS.visitedPaymentMethodsPage, { actionInfo }).catch(() => null);
      fetchUserVisitedPageAnalytics().catch(() => null);
      setPaymentModalPaymentType(defaultCountryPaymentType);
    }
  }, [modalVisible]);

  useEffect(() => {
    const checkoutStatus = query.get('status');
    const orderId = query.get('order_id');
    const sessionId = query.get('session_id');
    if (checkoutStatus === 'success' && orderId) {
      setPaymentModalPaymentType(PaymentType.Crypto);
    }

    if (checkoutStatus === 'success' && sessionId) {
      setPaymentModalPaymentType(PaymentType.Card);
    }
  }, []);

  useEffect(() => {
    const { active: isUpgradeDiscountActive = false, periodType: period = E_PERIOD.ANNUAL } = upgradeDiscount;
    const upgradeAmount = new Price(workspacePlanObj).saveUpgrade(upgradeDiscount).getAmount();
    const planPrice = new Price(selectedPlanObj).period(selectedDiscounObj).getAmount();
    const isPeriodDowngrade = period === E_PERIOD.ANNUAL && !isAnnual;
    const isPriceDowngrade = planPrice < upgradeAmount;
    const isPaypal = currentPaymentType === PaymentType.PayPal;
    const { priceForMonth: selectedPriceForMonth = 0 } = selectedPlanObj || {};
    const { priceForMonth: workspacePriceForMonth = 0 } = workspacePlanObj || {};
    const isPlanDowngrade = selectedPriceForMonth < workspacePriceForMonth;
    const canShowUpgradeLine = isUpgradeDiscountActive && !(isPaypal || isPriceDowngrade || isSamePlan || isPeriodDowngrade || isPlanDowngrade);

    setShouldShowUpgradeDiscountLine(canShowUpgradeLine);
  }, [currentPaymentType, selectedDiscount, selectedPlan]);

  const correctUpgradeDiscount = (): IUpgradeDiscount => {
    if (!showUpgradeDiscountLine) {
      return EMPTY_UPGRADE_DISCOUNT;
    }

    return upgradeDiscount;
  };

  const modalHeightParams: ICalcModalHeightParams = {
    paymentType: currentPaymentType,
    isNewStripeFlow: NEW_FEATURES.newStripeCheckout,
    isTrialEnded: isLastPaymentTrialEnded,
    isUpgradeDiscount: showUpgradeDiscountLine || isLimitsPurchase,
    isPurchaseWithAddonLimits: isPurchaseWithAddonLimits || isRenewWithAddonLimits,
    isUpgradeWithAddonLimits,
  };

  const modalHeight = calcModalHeight(modalHeightParams);

  const setPaymentTypeAndSendAnalytics = (type: PaymentType): void => {
    setPaymentModalPaymentType(type);
    const paymentMethod = getAnalyticsPaymentType(type, getIsGooglePay());
    sendActionAnalytics(E_ANALYTICS_ACTIONS.visitedPaymentTab, { actionInfo, paymentMethod }).catch(() => null);
  };

  const stripeButtonTitle = isRussianIp ? translation('pricing.paymentModal.stripeTitleRU') : translation('pricing.paymentModal.stripeTitle');

  const getSelectedPaymentTypeButton = (): (JSX.Element|null)[] => paymentTypes.map(({ type }) => {
    const PAYMENT_TITLES = {
      [PaymentType.Paddle]: stripeButtonTitle,
      [PaymentType.Card]: stripeButtonTitle,
      [PaymentType.Mobile]: getIsGooglePay() ? GOOGLE_PAY : APPLE_PAY,
      [PaymentType.PayPal]: translation('pricing.paymentModal.paypal.title'),
      [PaymentType.Crypto]: translation('pricing.paymentModal.crypto.title'),
      [PaymentType.Sigma]: translation('pricing.paymentModal.ruCardButton'),
    };

    if (type === PaymentType.PayPal && (isUpgrade || isPurchaseWithAddonLimits)) {
      return null;
    }

    if (type === currentPaymentType) {
      return (
        <ModalSwitcherItemSelected
          key={type}
        >
          {PAYMENT_TITLES[type]}
        </ModalSwitcherItemSelected>
      );
    }

    return (
      <ModalSwitcherItem
        key={type}
        onClick={(): void => setPaymentTypeAndSendAnalytics(type)}
      >
        {PAYMENT_TITLES[type]}
      </ModalSwitcherItem>
    );
  });

  const getCheckmarkLine = (): JSX.Element => {
    if (PaymentType.Crypto === currentPaymentType) {
      return (
        <>
          <ModalCheckMarkContainer>
            <IconPaymentModalCheckmark />
            <ModalCheckMarkTxt>
              {translation('pricing.paymentModal.crypto.autoSubscription')}
            </ModalCheckMarkTxt>
          </ModalCheckMarkContainer>
          <ModalCheckMarkContainer style={{ marginTop: 0 }}>
            <IconPaymentModalCheckmark />
            <ModalCheckMarkTxt style={{ width: 360 }}>
              {translation('pricing.paymentModal.crypto.manualRenew')}
            </ModalCheckMarkTxt>
          </ModalCheckMarkContainer>
        </>
      );
    }

    if (PaymentType.Sigma === currentPaymentType) {
      return (
        <>
          <ModalCheckMarkContainer>
            <IconPaymentModalCheckmark />
            <ModalCheckMarkTxt>
              {translation('pricing.paymentModal.autoSubscription')}
              {' '}
              {getNextPaymentDate(isAnnual)}
            </ModalCheckMarkTxt>
          </ModalCheckMarkContainer>
          <ModalCheckMarkContainer style={{ marginTop: 0 }}>
            <IconPaymentModalCheckmark />
            <ModalCheckMarkTxt style={{ width: 360 }}>
              {translation('pricing.paymentModal.cancelTxt')}
            </ModalCheckMarkTxt>
          </ModalCheckMarkContainer>
        </>
      );
    }

    const planNextPaymentDate = isLimitsPurchase ? planExpiresAt.toString() : '';

    return (
      <>
        <ModalCheckMarkContainer>
          <IconPaymentModalCheckmark />
          <ModalCheckMarkTxt>
            {translation('pricing.paymentModal.autoSubscription')}
            {' '}
            {getNextPaymentDate(isAnnual, planNextPaymentDate)}
          </ModalCheckMarkTxt>
        </ModalCheckMarkContainer>
        <ModalCheckMarkContainer style={{ marginTop: 0 }}>
          <IconPaymentModalCheckmark />
          <ModalCheckMarkTxt>
            {translation('pricing.paymentModal.cancelTxt')}
          </ModalCheckMarkTxt>
        </ModalCheckMarkContainer>
      </>
    );
  };

  const openSuccessModal = (planId: string): void => {
    setPlanForSuccessModal(planId);
    setIsLoadingModalShown(false);
    setShowSuccessModal(true);
  };

  const getPaymentButton = (): JSX.Element => {
    // if (currentPaymentType === PaymentType.Mobile) {
    //   return (<MobilePayment
    //     selectedPlan={selectedPlan}
    //     selectedDiscount={selectedDiscount}
    //     plans={plans}
    //     hasSuccessPayment={successPayment}
    //     getPlanPeriodPrice={getPlanPeriodPrice}
    //     needChangePlan={needChangePlan}
    //     getCheckmarkLine={getCheckmarkLine}
    //     setShowSuccessModal={setShowSuccessModal}
    //     workspaceId={workspaceId}
    //     setThreeDSecureUrl={setThreeDSecureUrl}
    //     setShowThreeDSecureModal={setShowThreeDSecureModal}
    //     setIsCloseModalDisabled={setIsCloseModalDisabled}
    //   />);
    // }

    if (currentPaymentType === PaymentType.Crypto) {
      return (
        <CryptoForm
          selectedPlan={selectedPlan}
          selectedDiscount={selectedDiscount}
          hasSuccessPayment={successPayment}
          plans={plans}
          getCheckmarkLine={getCheckmarkLine}
          workspaceId={workspaceId}
          closePaymentModal={closePaymentModal}
          setIsLoadingModalShown={setIsLoadingModalShown}
          openSuccessModal={openSuccessModal}
          isLimitsPurchase={isLimitsPurchase}
          isRenewWithAddonLimits={isPurchaseWithAddonLimits}
          addonLimits={addonLimits}
        />
      );
    }

    if (currentPaymentType === PaymentType.PayPal) {
      return (
        <PayPalForm
          selectedPlan={selectedPlan}
          selectedDiscount={selectedDiscount}
          plans={plans}
          getCheckmarkLine={getCheckmarkLine}
          workspaceId={workspaceId}
          setIsLoadingModalShown={setIsLoadingModalShown}
          openSuccessModal={openSuccessModal}
        />
      );
    }

    if (currentPaymentType === PaymentType.Sigma) {
      return (
        <SigmaForm
          selectedPlan={selectedPlan}
          selectedDiscount={selectedDiscount}
          plans={plans}
          hasSuccessPayment={successPayment}
          getCheckmarkLine={getCheckmarkLine}
          workspaceId={workspaceId}
          openSuccessModal={openSuccessModal}
        />
      );
    }

    return (
      <StripeForm
        plans={plans}
        hasSuccessPayment={successPayment}
        needChangePlan={needChangePlan}
        getCheckmarkLine={getCheckmarkLine}
        openSuccessModal={openSuccessModal}
        workspaceId={workspaceId}
        setThreeDSecureUrl={setThreeDSecureUrl}
        setShowThreeDSecureModal={setShowThreeDSecureModal}
        setIsCloseModalDisabled={setIsCloseModalDisabled}
        setIsLoadingModalShown={setIsLoadingModalShown}
        setClosePaymentModal={closePaymentModal}
        isLimitsPurchase={isLimitsPurchase}
        isPurchaseWithAddonLimits={isPurchaseWithAddonLimits || isRenew}
        addonLimits={addonLimits}
      />
    );
  };

  const paymentDropdownMenu = (): JSX.Element => (
    <CustomAnnuallyMenu selectable={true} selectedKeys={[selectedDiscount]}>
      <CustomItemMenu
        key={ANNUAL_DISCOUNT}
        onClick={(): void => changeMonthlyPaymentPeriod(false)}
      >
        {translation('pricing.checkoutModal.annually')}
        {' '}
        {isAnnual ? <GreenCheckoutIcon /> : null}
      </CustomItemMenu>
      <CustomItemMenu
        key={MONTH_DISCOUNT}
        disabled={disableMonthPlan}
        onClick={(): void => changeMonthlyPaymentPeriod(true)}
      >
        {translation('pricing.checkoutModal.monthly')}
        {' '}
        {!isAnnual && <GreenCheckoutIcon />}
      </CustomItemMenu>
    </CustomAnnuallyMenu>
  );

  const getPaymentDropdown = (): JSX.Element => (
    <Dropdown
      overlay={paymentDropdownMenu}
      placement='bottomCenter'
      overlayStyle={{ width: 240, zIndex: 1210, zIndex: 1300 }}
      trigger={['click']}
    >
      <span>
        <AnnuallyTxt>
          {isAnnual ?
            translation('pricing.checkoutModal.annually').toLowerCase() :
            translation('pricing.checkoutModal.monthly').toLowerCase()}
        </AnnuallyTxt>
        <Icon style={{ fontSize: 14, cursor: 'pointer' }} type='caret-down' />
      </span>
    </Dropdown>
  );

  const getPromoOrAnnualDiscountLine = (): JSX.Element => {
    const { active = false, discountType = E_DISCOUNT_TYPE.FIX, value } = promoDiscount;
    let saved = new Price(selectedPlanObj, currency).savePeriod(selectedDiscounObj).toLocalString(null, priceConfig);
    let leftText = translation('pricing.checkoutModal.discountTitle');
    const noDiscount = !(active || isAnnual);
    if (active && !isAnnual) {
      saved = new Price(selectedPlanObj, currency)
        .applyLimits({ limits: addonLimitsChosen, planId: selectedPlan, pricingSettings: limitsPricingSetings })
        .savePromo(promoDiscount, priceConfig)
        .toLocalString(null, priceConfig);

      if (discountType === E_DISCOUNT_TYPE.FIX) {
        leftText = translation('pricing.paymentModal.promoFixText');
      }

      if (discountType === E_DISCOUNT_TYPE.PERCENT) {
        leftText = translation('pricing.paymentModal.promoPercentText', { percent: value });
      }
    }

    if (isLimitsPurchase && isAnnual) {
      saved = new Price(0, currency)
        .applyLimits({ limits: addonLimitsChosen, planId: selectedPlan, pricingSettings: limitsPricingSetings })
        .savePeriod(selectedDiscounObj)
        .toLocalString(null, priceConfig);
    }

    return (
      <>
        <ModalParamsLine>
          <ModalParamsLeftTxt
            greyTxt={noDiscount}
          >
            {leftText}
          </ModalParamsLeftTxt>
          <ModalParamsRightTxt
            greyTxt={noDiscount}
          >
            {!noDiscount ?
              `- ${saved}` : '–'}
          </ModalParamsRightTxt>
        </ModalParamsLine>
        <ModalDivider />
      </>
    );
  };

  const renderTrialMessage = (): JSX.Element|null => {
    if (!isLastPaymentTrialEnded) {
      return null;
    }

    return (
      <>
        {translation('pricing.paymentModal.trial.ended')}
        <br />
      </>
    );
  };

  let totalPrice = new Price(selectedPlanObj, currency)
    .period(selectedDiscounObj)
    .promo(promoDiscount, priceConfig)
    .upgrade(correctUpgradeDiscount())
    .toLocalString(null, priceConfig);

  if (isPurchaseWithAddonLimits || isRenew) {
    totalPrice = new Price(selectedPlanObj, currency)
      .applyLimits({ limits: addonLimits, planId: selectedPlan, pricingSettings: limitsPricingSetings, priceConfig })
      .period(selectedDiscounObj)
      .promo(promoDiscount, priceConfig)
      .upgrade(correctUpgradeDiscount())
      .toLocalString(null, priceConfig);
  }

  if (isLimitsPurchase) {
    totalPrice = new Price(0, currency)
      .applyLimits({ limits: addonLimitsChosen, planId: selectedPlan, pricingSettings: limitsPricingSetings, daysLeftFraction, priceConfig })
      .period(selectedDiscounObj)
      .toLocalString(null, priceConfig);
  }

  const getUpgradeDiscountLine = (): JSX.Element|null => {
    if (!showUpgradeDiscountLine) {
      return null;
    }

    return (
      <>
        <ModalParamsLine>
          <ModalParamsLeftTxt>
            {translation('pricing.paymentModal.prorationDiscountText')}
          </ModalParamsLeftTxt>
          <ModalParamsRightTxt>
            {`-${new Price(selectedPlanObj, currency).saveUpgrade(correctUpgradeDiscount()).toLocalString(null, priceConfig)}`}
          </ModalParamsRightTxt>
        </ModalParamsLine>
        <ModalDivider />
      </>
    );
  };

  const getAddonLimitsLine = (): JSX.Element|null => {
    if (isLimitsPurchase) {
      return null;
    }

    if (!(isPurchaseWithAddonLimits || isRenewWithAddonLimits)) {
      return null;
    }

    const { maxMembers: extraMaxMembers = 0 } = addonLimitsChosen;
    const addonLimitsPrice = new Price(0, currency)
      .applyLimits({ limits: addonLimits, planId: selectedPlan, pricingSettings: limitsPricingSetings })
      .period(selectedDiscounObj)
      .toLocalString(null, priceConfig);

    return (
      <>
        <ModalParamsLine>
          <ModalParamsLeftTxt>
            <Trans i18nKey={'pricing.paymentModal.teamMembersText'} values={{ count: extraMaxMembers }} />
          </ModalParamsLeftTxt>
          <ModalParamsRightTxt>
            {`${addonLimitsPrice}`}
          </ModalParamsRightTxt>
        </ModalParamsLine>
        <ModalDivider />
      </>
    );
  };

  const getProrateLimitsDiscountLine = (): JSX.Element|null => {
    if (!isLimitsPurchase) {
      return null;
    }

    const prorateDiscount = new Price(0, currency)
      .applyLimits({ limits: addonLimitsChosen, planId: selectedPlan, pricingSettings: limitsPricingSetings })
      .period(selectedDiscounObj)
      .saveProrate(daysLeftFraction, priceConfig)
      .toLocalString(null, priceConfig);

    return (
      <>
        <ModalParamsLine>
          <ModalParamsLeftTxt>
            {translation('pricing.paymentModal.prorationDiscountText')}
          </ModalParamsLeftTxt>
          <ModalParamsRightTxt>
            {`-${prorateDiscount}`}
          </ModalParamsRightTxt>
        </ModalParamsLine>
        <ModalDivider />
      </>
    );
  };

  const getProductLine = (): JSX.Element => {
    if (isLimitsPurchase) {
      const { maxMembers } = addonLimitsChosen;
      const fullLimitsPrice = new Price(0, currency)
        .applyLimits({ limits: addonLimitsChosen, planId: selectedPlan, pricingSettings: limitsPricingSetings })
        .multiply(periodType)
        .toLocalString(null, priceConfig);

      return (
        <>
          <ModalParamsLine>
            <ModalParamsLeftTxt>
              <Trans i18nKey={'pricing.paymentModal.teamMembersText'} values={{ count: maxMembers }} />
            </ModalParamsLeftTxt>
            <ModalParamsRightTxt>
              {fullLimitsPrice}
            </ModalParamsRightTxt>
          </ModalParamsLine>
          <ModalDivider />
        </>
      );
    }

    return (
      <>
        <ModalParamsLine>
          <ModalParamsLeftTxt>
            {isAnnual ?
              getPlanInfo(selectedPlan, 'name') + ` ${translation('pricing.checkoutModal.billedAnnually')}` :
              'GoLogin ' + getPlanInfo(selectedPlan, 'name')}
          </ModalParamsLeftTxt>
          <ModalParamsRightTxt>
            {new Price(selectedPlanObj, currency).multiply(periodType).toLocalString(0, priceConfig)}
          </ModalParamsRightTxt>
        </ModalParamsLine>
        <ModalDivider />
      </>
    );
  };

  const getTitleMargin = (): number => isAnnual ? 32 : 64;
  const getPaymentModalContent = (): JSX.Element => (
    <>
      <PaymentModalCancelContainer>
        <CloseOutlined
          style={{ cursor: 'pointer', fontSize: 30 }}
          onClick={closePaymentModal}
        />
      </PaymentModalCancelContainer>
      <HeaderTitleContainer>
        <ModalHeaderTxt style={{ marginBottom: getTitleMargin() }}>
          {renderTrialMessage()}
          {translation('pricing.paymentModal.trial.contentTxt1')}
          {' '}
          <ModalHeaderBoldTxt>
            {getPlanInfo(selectedPlan, 'name')}
            {' '}
            {translation('pricing.paymentModal.trial.plan')}
            ,
          </ModalHeaderBoldTxt>
          <br />
          {translation('pricing.paymentModal.trial.contentTxt2')}
          {' '}
          {getPaymentDropdown()}
          {' '}
          {translation('pricing.paymentModal.trial.at')}
          {' '}
          {new Price(selectedPlanObj, currency).period(selectedDiscounObj).perMonth(periodType).toLocalString(0, priceConfig)}
          {' '}
          {translation('pricing.paymentModal.trial.contentTxt3')}
          <br />
          {isAnnual ? ` ${translation('pricing.paymentModal.trial.with')} ` : ''}
          {isAnnual ? (
            <ModalHeaderBoldTxt>
              {translation('pricing.paymentModal.trial.contentTxt4')}
            </ModalHeaderBoldTxt>
          ) : null}
        </ModalHeaderTxt>
      </HeaderTitleContainer>
      <ModalSwitcher>
        {getSelectedPaymentTypeButton()}
      </ModalSwitcher>
      <ModalDivider />
      <ModalParamsLine>
        <ModalParamsLeftTxt>
          {translation('pricing.checkoutModal.accountName')}
        </ModalParamsLeftTxt>
        <ModalParamsRightTxt>
          {email}
        </ModalParamsRightTxt>
      </ModalParamsLine>
      <ModalDivider />
      {getProductLine()}
      {getAddonLimitsLine()}
      {getPromoOrAnnualDiscountLine()}
      {getUpgradeDiscountLine()}
      {getProrateLimitsDiscountLine()}
      <ModalParamsLine style={{ fontWeight: 700 }}>
        <ModalParamsLeftTxt>
          {translation('pricing.checkoutModal.chargedTitle')}
        </ModalParamsLeftTxt>
        <ModalParamsRightTxt>
          {totalPrice}
        </ModalParamsRightTxt>
      </ModalParamsLine>
      <ModalDivider />
      {getPaymentButton()}
      {currentPaymentType === PaymentType.Card ? (
        <PaymentModalIconsBarContainer>
          <PaymentIconsBar />
        </PaymentModalIconsBarContainer>
      ) : null}
    </>
  );

  const getModalContent = (): JSX.Element => {
    if (showThreeDSecureModal) {
      return (
        <ThreeDSecureStyled
          src={threeDSecureUrl}
          id='three-d-secure-iframe'
        />
      );
    }

    if (showSuccessModal) {
      return (
        <SuccessPaymentModal
          modalVisible={showSuccessModal}
          selectedPlan={planForSuccessModal}
          getPlanInfo={getPlanInfo}
        />
      );
    }

    if (isLoadingModalShown) {
      return (
        <LoadingContainer>
          <Icon type='loading' />
        </LoadingContainer>
      );
    }

    return getPaymentModalContent();
  };

  return (
    <CustomPaymentModal
      width={636}
      title={null}
      footer={null}
      closable={false}
      zIndex={QUICK_PRICING_MODAL_Z_INDEX + 1}
      maskClosable={!isCloseModalDisabled}
      onCancel={closePaymentModal}
      visible={modalVisible}
      bodyStyle={{ padding: 0 }}
    >
      <div
        style={{
          height: modalHeight,
          transition: 'height 0.2s ease-in-out 0s',
          overflowY: 'hidden',
        }}
      >
        {getModalContent()}
      </div>
    </CustomPaymentModal>
  );
};

export default memo(PaymentModal);
